import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { GetUsers } from '../../../api';

export const fetchUserListThunk = createAsyncThunk('user/fetchList', async (params, { rejectWithValue }) => {
  try {
    const { data, status, statusText } = await GetUsers(params);
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const fetchUserListReducers = {
  [fetchUserListThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'list.response',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
  [fetchUserListThunk.pending]: (state) => {
    set(
      state,
      'list.isLoading',
      true,
    );
  },
  [fetchUserListThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'list.error',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
};
