import client from '../httpClient';
import ErrorFactory from '../../shared/errors';

export async function AccountListRequest({ queryKey }) {
  try {
    const [, params] = queryKey;
    const response = await client.get('/accounts/hierarchy?type=tree', {
      params,
    });
    return response?.data?.accounts;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export async function AccountFullListRequest(params) {
  return client.get('/accounts', {
    params,
  });
}

export async function GetAccount({ id, params }) {
  return client.get(`/accounts/${id}`, {
    params,
  });
}
