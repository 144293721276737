import {
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';

import usePermissions from '../../../IAM/usePermissions';
import { YupValidations } from '../../../shared/validations';
import { ControlledSelectDropdown } from '../SelectDropdown';
import {
  DEED_OPTIONS, FOOTAGE_OPTIONS, ZONING_OPTIONS, TYPE_OPTIONS,
} from './options';

const FormFields = {
  TYPE: 'type',
  FOOTAGE: 'footage',
  ZONING: 'zoning',
  DEED: 'deed',
};

export const ValidationSchema = {
  [FormFields.TYPE]: YupValidations.requiredOption,
  [FormFields.FOOTAGE]: YupValidations.requiredOption,
  [FormFields.ZONING]: YupValidations.requiredOption,
  [FormFields.DEED]: YupValidations.requiredOption,
};

function SiteInspection({
  name,
  control,
  validate,
  disabled: inputDisabled = false,
}) {
  const permissions = usePermissions();
  const disabled = inputDisabled || !permissions.merchants.editMerchantSensitive();

  return (
    <>
      <Grid item xs={6}>
        <ControlledSelectDropdown
          disabled={disabled}
          control={control}
          options={TYPE_OPTIONS}
          label="Type of building"
          name={name.concat('.', FormFields.TYPE)}
          required={validate}
          disableClear={validate}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledSelectDropdown
          disabled={disabled}
          options={FOOTAGE_OPTIONS}
          label="Square footage"
          control={control}
          name={name.concat('.', FormFields.FOOTAGE)}
          required={validate}
          disableClear={validate}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledSelectDropdown
          disabled={disabled}
          options={ZONING_OPTIONS}
          label="Location zoning"
          control={control}
          name={name.concat('.', FormFields.ZONING)}
          required={validate}
          disableClear={validate}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledSelectDropdown
          disabled={disabled}
          options={DEED_OPTIONS}
          label="Property deed"
          control={control}
          name={name.concat('.', FormFields.DEED)}
          required={validate}
          disableClear={validate}
        />
      </Grid>
    </>
  );
}

SiteInspection.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validate: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
};

export default SiteInspection;
