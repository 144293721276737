import httpDeviceClient from '../../../../../api/httpDeviceClient';
import ErrorFactory from '../../../../../shared/errors';

async function DeviceSnippetPOSRequest(merchantId, devicesIds) {
  try {
    const { data } = await httpDeviceClient.post(
      `/merchant/${merchantId}/snippet`,
      {
        devices: devicesIds,
      },
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export default DeviceSnippetPOSRequest;
