import isEmpty from 'lodash/isEmpty';
import client from '../httpClient';
import ErrorFactory from '../../shared/errors';

export const UserResetPassword = async ({ password, token }) => client.post(
  '/users/lost-password/reset-password',
  {
    password,
    recoverToken: token,
  },
);

export const UserLogin = async ({
  email, password, secondFactorCode, rememberMe,
}) => client.post('/users/login',
  {
    email,
    password,
    secondFactorCode,
    RememberMe: rememberMe,
  });

export const UserLogout = async () => client.post('/users/logout');

export const UserCreate = async (payload) => client.post(
  '/users',
  {
    ...payload,
  },
);

export const UserRequestToken = async ({ email }) => client.post(
  '/users/lost-password/request-token',
  { email },
);

export const UserForgotPassword = async ({ email }) => {
  try {
    const data = await client.post(
      '/users/lost-password/request-token',
      { email },
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
};

export const UserUpdatePassword = async (payload) => {
  try {
    const data = await client.post(
      `/users/${payload?.userId}/update-password`,
      {
        currentPassword: payload?.currentPassword,
        newPassword: payload?.newPassword,
      },
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
};

export const RenewPassword = async ({ payload }) => {
  try {
    if (isEmpty(payload)) throw new Error('Empty payload');
    const { data } = await client.post('/users/reset-inactive', {
      email: payload?.email,
      password: payload?.currentPassword,
      newPassword: payload?.newPassword,
    });
    return data;
  } catch (e) {
    throw ErrorFactory(e);
  }
};

export const createUserApiKey = async ({ userId }) => {
  try {
    const { data } = await client.post(`/users/${userId}/api-keys`);
    return data;
  } catch (e) {
    throw ErrorFactory(e);
  }
};
