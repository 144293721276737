import { MuiTelInput } from 'mui-tel-input';

function PhoneInput({
  autoFocus, name, label, value, onChange, disabled, required, helperText, error, ...rest
}) {
  const handleChange = (string) => {
    if (string?.length <= 20) {
      onChange(name, string);
    }
  };

  return (
    <MuiTelInput
      autoFocus={autoFocus}
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      helperText={helperText}
      error={!!error}
      onChange={handleChange}
      fullWidth
      onlyCountries={['US', 'CA', 'MX']}
      defaultCountry="US"
      {...rest}
    />
  );
}

export default PhoneInput;
