import { useMemo } from 'react';
import { experimentalStyled } from '@mui/material';
import MuiGrid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';

import { RowTable } from '../RowTable';

import './styles.css';
import { fetchMID } from '../../redux/slices/merchants';
import usePermissions from '../../IAM/usePermissions';
import MutationAccordion from '../shared/Accordion/MutationAccordion';
import SiteInspection from '../forms/SiteInspection';
import ContactInfo from '../forms/ContactInfo';
import EditMerchantRequest from './edit/EditMerchantRequest';
import AccountInformationForm from './edit/AccountInformationForm';
import AddressInformationForm from './edit/AddressInformationForm';
import PaymentIntegrationForm from './edit/PaymentIntegrationForm';

import {
  accountInformation, addressInformation, paymentSoftware, siteInspection,
} from './row_tables';
import { ValidationSchema, FormFields } from './edit/Schema';
import { ACTIONS, RESOURCES } from '../../IAM/configuration';

const Grid = experimentalStyled(MuiGrid)(() => ({
  marginBottom: 10,
}));

const toAccordionForm = (Component, name, validate) => ({ control }) => (
  <Grid
    container
    spacing={1}
    sx={{ py: 1 }}
  >
    <Component control={control} name={name} validate={validate} />
  </Grid>
);

const ApplicationManagerForm = toAccordionForm(ContactInfo, FormFields.APPLICATION_MANAGER, true);
const AccountManagerForm = toAccordionForm(ContactInfo, FormFields.ACCOUNT_MANAGER, true);
const SiteInspectionForm = toAccordionForm(SiteInspection, FormFields.LOCATION, true);

const Overview = ({ merchant }) => {
  const permissions = usePermissions();
  const dispatcher = useDispatch();
  const accordionCommonProps = useMemo(() => {
    return {
      request: EditMerchantRequest(permissions.merchants.editMerchantSensitive()),
      successText: 'Merchant has been successfully updated',
      validationSchema: ValidationSchema,
      onMutated: (mutation) => {
        fetchMID({ id: mutation?.id })(dispatcher);
      },
      payload: {
        ...merchant,
        [FormFields.ACCOUNT_MANAGER]: {
          ...merchant[FormFields.ACCOUNT_MANAGER],
          confirmEmail: merchant[FormFields.ACCOUNT_MANAGER]?.email,
        },
        [FormFields.APPLICATION_MANAGER]: {
          ...merchant[FormFields.APPLICATION_MANAGER],
          confirmEmail: merchant[FormFields.APPLICATION_MANAGER]?.email,
        },
      },
    };
  }, [merchant]);
  return (
    <>
      <Grid container direction="row">
        <MutationAccordion
          expanded
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          title="Merchant Information"
          content={(
            <RowTable
              data={merchant}
              rows={accountInformation}
            />
          )}
          FormComponent={AccountInformationForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Address Information"
          disableEdit={!permissions.merchants.editMerchantSensitive()}
          content={(
            <RowTable
              data={merchant}
              rows={addressInformation}
            />
          )}
          FormComponent={AddressInformationForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Account Manager"
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          content={(
            <RowTable
              data={merchant?.accountManager}
              autoGenerateRows
            />
          )}
          FormComponent={AccountManagerForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Application Manager"
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          content={(
            <RowTable
              data={merchant?.applicationManager}
              autoGenerateRows
            />
          )}
          FormComponent={ApplicationManagerForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Site Inspection"
          disableEdit={!permissions.merchants.editMerchantSensitive()}
          content={(
            <RowTable
              data={merchant}
              rows={siteInspection}
            />
          )}
          FormComponent={SiteInspectionForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          title="Payment/Software Integration"
          content={(
            <RowTable
              data={merchant}
              rows={paymentSoftware}
            />
          )}
          FormComponent={PaymentIntegrationForm}
          {...accordionCommonProps}
        />
      </Grid>
    </>
  );
};

export default Overview;
