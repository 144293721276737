import { React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import get from 'lodash/get';
import APIDataGrid from '../../../components/shared/APIDataGrid';
import { mainContainerSX } from '../../../shared/styles';
import getColumns from './columns';
import { fetchAccountsMap, accountsReducer } from '../../../redux/slices/accounts';

const Merchants = ({
  gridProps: {
    filteredMerchantsQuery,
    handleGridFetch,
  },
  openModal,
  ...rest
}) => {
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const accounts = useSelector((state) => get(state, `${accountsReducer}.map`, {}));

  const cols = getColumns({
    onGoToDetails: (row) => {
      openModal(row?.id);
    },
    getAccountName: (accountId) => {
      if (accounts.hasError) return 'Something went wrong';
      return accounts[accountId];
    },
    goToAccount: (accountId) => {
      return navigate(`/accounts/${accountId}`);
    },
  });

  useEffect(() => fetchAccountsMap()(dispatcher), []);

  return (
    <>
      <Box sx={{ ...mainContainerSX, py: 0 }}>
        <Card>
          <APIDataGrid
            cols={cols}
            src={filteredMerchantsQuery?.data || {}}
            dataProp="merchants"
            onFetch={handleGridFetch}
            loading={filteredMerchantsQuery.isLoading}
            {...rest}
          />
        </Card>
      </Box>
    </>
  );
};

export default Merchants;
