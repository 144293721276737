import TradeReference, { FormFields } from './TradeReference';

import { YupValidations } from '../../../shared/validations';

const defaultValue = {
  [FormFields.COMPANY_NAME]: '',
  [FormFields.FIRST_NAME]: '',
  [FormFields.LAST_NAME]: '',
  [FormFields.PHONE]: '',
  [FormFields.CREDIT_TERMS]: '',
};

const ValidationSchema = ({ validate = false } = {}) => {
  const schema = {
    [FormFields.COMPANY_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.FIRST_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.LAST_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.PHONE]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.CREDIT_TERMS]: validate ? YupValidations.requiredString : YupValidations.string,
  };
  return schema;
};

export {
  ValidationSchema,
  defaultValue,
  FormFields,
};

export default TradeReference;
