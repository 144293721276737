import { useCallback, useEffect, useMemo } from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import omit from 'lodash/omit';
import { checkOptions } from './FilterOptions';

export default ({
  checkedOptions,
  setCheckedOptions,
  setBankTypeOptions,
  setTenderTypesFilter,
  accoutTypes,
  noTenderSelected,
}) => {
  const theme = useTheme();
  // parse options grouped by type to easily handle the types selectend based on the tender types
  const bankAccountsOptions = useMemo(
    () => accoutTypes.reduce((acc, cur) => {
      if (acc[cur.type]) acc[cur.type].push(cur);
      if (!acc[cur.type]) acc[cur.type] = [cur];
      return acc;
    }, {}),
    [accoutTypes],
  );

  const setAllOptionsChecked = useCallback(
    (data, val) => Object.fromEntries(Object.entries(data).map(([key]) => [key, val])),
    [],
  );
  const handleCheckedAll = (data) => !Object.entries(omit(data, 'all')).find(([_, val]) => !val);

  const handleCheck = (e) => {
    if (e.target.name === 'all') {
      return setCheckedOptions({
        ...setAllOptionsChecked(checkedOptions, e.target.checked),
      });
    }
    return setCheckedOptions({
      ...checkedOptions,
      [e.target.name]: e.target.checked,
      all: !e.target.checked
        ? e.target.checked
        : handleCheckedAll({
          ...checkedOptions,
          [e.target.name]: e.target.checked,
        }),
    });
  };

  const handleBankTypeOptions = () => {
    const tenderTypeValues = [];
    if (checkedOptions.all) tenderTypeValues.push('all');
    // filter the options by the tender types checked
    const newOptions = [
      ...(bankAccountsOptions?.['no-type'] || []),
      ...Object.entries(omit(checkedOptions, 'all')).reduce(
        (acc, [key, val]) => {
          if (val) {
            acc.push(...(bankAccountsOptions[key] || []));
            if (tenderTypeValues[0] !== 'all') tenderTypeValues.push(key);
          }
          return acc;
        },
        [],
      ),
    ];
    setTenderTypesFilter((filter) => ({
      ...filter,
      value: tenderTypeValues.join(','),
    }));
    return setBankTypeOptions(newOptions);
  };

  useEffect(() => {
    handleBankTypeOptions();
  }, [checkedOptions]);

  return (
    <>
      {checkOptions.map(({ text, value }) => (
        <FormControlLabel
          key={`check-${text}`}
          sx={{ mr: 3, color: noTenderSelected && theme.palette.error.main }}
          control={(
            <Checkbox
              onChange={handleCheck}
              name={value}
              color="primary"
              sx={{ color: noTenderSelected && theme.palette.error.main }}
            />
          )}
          checked={checkedOptions[value]}
          label={text}
        />
      ))}
      {noTenderSelected && (<FormHelperText sx={{ color: theme.palette.error.main, mt: -1 }}>You need to select at least one</FormHelperText>)}
    </>
  );
};
