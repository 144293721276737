import { useMemo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useGetParams = () => {
  const { search } = useLocation();
  return useMemo(() => Object.fromEntries(new URLSearchParams(search), [search]));
};

export const useParamsListener = (params, triggers, { onLoad }) => {
  const navigate = useNavigate();
  const urlParams = useGetParams();
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(Object.entries(params));
    navigate({ search: `?${urlSearchParams.toString()}` });
  }, [triggers]);

  useEffect(() => {
    onLoad(urlParams);
  }, []);
};

export const useUrlState = (trigger, { transformToUrl = Object.entries, transformFromUrl, onLoad } = {}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState();
  const [hasInit, setHasInit] = useState(false);

  useEffect(() => {
    setHasInit(true);
    const newQueryParams = transformFromUrl(search);
    setQueryParams(newQueryParams);
    onLoad(newQueryParams);
  }, []);

  useEffect(() => {
    if (!hasInit) return;
    setQueryParams(transformFromUrl(search));
  }, [search]);

  useEffect(() => {
    if (!hasInit) return;
    const urlSearchParams = new URLSearchParams(transformToUrl(trigger));
    navigate({ search: `?${urlSearchParams.toString()}` });
  }, [trigger]);

  return {
    queryParams,
  };
};
