import {
  Box,
} from '@mui/material';

import { Texts } from './FilterOptions';

const chipStyle = {
  display: 'flex', alignItems: 'center', fontWeight: 600, borderRadius: 1, fontSize: '14px', height: '28px', justifyContent: 'center', padding: 2,
};

const variantColor = {
  success: {
    color: '#FFFFFF',
    backgroundColor: '#357938',
  },
  error: {
    color: '#FFFFFF',
    backgroundColor: '#D0180B',
  },
  default: {
    color: '#25272A',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  info: {
    color: '#0A6AB8',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;',
  },
};

const handleValueLabel = (value) => {
  const hasSeparatorsRegex = new RegExp('%2C|,', 'g');
  if (!hasSeparatorsRegex.test(value)) return Texts[value] || value;
  const values = value.split(hasSeparatorsRegex);
  return values.map((text) => Texts[text] || text).join(' - ');
};

const Chip = ({
  label, variant, minWidth = 250, width,
}) => {
  const { color, background, backgroundColor } = variantColor[variant] || variantColor.default;
  return (
    <Box sx={{
      ...chipStyle, color, background, backgroundColor, minWidth, width,
    }}
    >
      {label}
    </Box>
  );
};

function FilterDisplay({ operator, filter, value }) {
  return (
    <Box sx={{ paddingBottom: 1, width: '90%', margin: '0 auto' }} display="flex" gap={2}>
      <Chip
        minWidth={200}
        width="100%"
        label={Texts[filter]}
        variant="default"
      />
      <Chip
        minWidth={150}
        width="50%"
        label={Texts[operator]?.toLowerCase()}
        variant={operator === 'EQUAL' ? 'success' : 'error'}
      />
      <Chip
        minWidth={250}
        width="100%"
        variant="info"
        label={handleValueLabel(value)}
      />
    </Box>
  );
}

export default FilterDisplay;
