import { useNavigate, useLocation } from 'react-router-dom';
import {
  Grid, CircularProgress, Button, Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { RowTable } from '../../../components/RowTable';
import { accountInformation } from '../../../components/merchants/row_tables';
import Dialog from '../../../components/shared/Dialogs/Dialog';
import { MerchantListRequestById } from '../../../api';

export const MerchantModal = ({ isOpen, onClose, merchantId }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    data, isLoading, isSuccess, isError,
  } = useQuery(
    ['merchant-list-by-id', merchantId],
    () => MerchantListRequestById({ id: merchantId }),
  );

  const goToMerchant = () => {
    return navigate(`/accounts/${data?.accountId}/merchants/${data?.id}`, { state: { from: 'bulk-edit', query: search } });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      contentStyles={{ padding: 0 }}
      titleStyles={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
      title={<Typography variant="h5">Merchant Information</Typography>}
    >
      {
        isLoading && <CircularProgress sx={{ margin: '0 auto', display: 'block', my: 3 }} />
      }
      {
        isSuccess && (
        <>
          <RowTable data={data} rows={accountInformation} labelWidth="50%" />
        </>
        )
      }
      {
        isError && <Typography sx={{ textAlign: 'center', mt: 3 }}>Somethign went worng...</Typography>
      }
      <Grid container justifyContent="end" sx={{ mt: 3, px: 2 }}>
        <Button onClick={onClose} variant="outlined" sx={{ mr: 1 }}>
          Close
        </Button>
        <Button onClick={goToMerchant} variant="contained" autoFocus>
          View all merchant details
        </Button>
      </Grid>
    </Dialog>
  );
};
