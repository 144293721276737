import { YupValidations } from '../../../shared/validations';
import PersonalInfo, { FormFields } from './PersonalInfo';

const ValidationSchema = ({ validate = false, withOwnership = false } = {}) => {
  const schema = {
    [FormFields.DATE]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.DRIVER_LICENSE]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.SSN]: validate ? YupValidations.requiredSsn : YupValidations.ssn,
  };
  if (validate) {
    schema[FormFields.DRIVER_LICENSE_STATE] = YupValidations.requiredOption;
    if (withOwnership) {
      schema[FormFields.OWNERSHIP] = YupValidations.requiredNumber;
    }
  }
  return schema;
};

const defaultValue = {
  [FormFields.DATE]: '',
  [FormFields.DRIVER_LICENSE_STATE]: '',
  [FormFields.DRIVER_LICENSE]: '',
  [FormFields.SSN]: '',
  [FormFields.OWNERSHIP]: '',
};

export {
  ValidationSchema,
  defaultValue,
  FormFields,
};

export default PersonalInfo;
