import client from '../httpClient';

import ErrorFactory from '../../shared/errors';

export async function ChargebacksListRequest({ queryKey }) {
  try {
    const [, params] = queryKey;
    const response = await client.get(
      '/chargebacks',
      {
        params,
      },
    );
    return response.data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export async function ChargeBackListRequest({ id, isChargeBack }) {
  if (!isChargeBack) return '';
  try {
    if (!id) {
      return {};
    }
    const response = await client.get(`/chargebacks/${id}`);
    return response?.data?.transaction;
  } catch (err) {
    throw ErrorFactory(err);
  }
}
