import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { client } from '../../../api';

export const removeMerchantThunk = createAsyncThunk('fundingGroups.removeMerchant', async (payload, { rejectWithValue }) => {
  const URL = `/funding/${payload.fundingGroup}/members/${payload.merchant}`;
  try {
    const { data, status, statusText } = await client.delete(URL);
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const removeMerchantReducers = {
  [removeMerchantThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'isLoading',
      false,
    );
    set(
      state,
      'removingMerchant',
      payload,
    );
  },
  [removeMerchantThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },
  [removeMerchantThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'isLoading',
      false,
    );
    set(
      state,
      'removingMerchant',
      payload,
    );
  },
};
