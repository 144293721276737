import * as Yup from 'yup';
import { YupValidations } from '../../../shared/validations';
import { ValidationSchema as AddressSchema, defaultValue as addressDefaultValue } from '../Address';
import CorporateAccount, { FormFields } from './CorporateAccount';

const defaultValue = {
  [FormFields.TAX_ID]: '',
  [FormFields.INTACCT_ID]: '',
  [FormFields.LEGAL_BUSINESS_NAME]: '',
  [FormFields.TIME_IN_OPERATION]: '',
  [FormFields.PHONE]: '',
  [FormFields.CHARGEBACK_FAX]: '',
  [FormFields.WEBSITE]: '',
  [FormFields.LOCATION_ADDRESS]: addressDefaultValue,
  [FormFields.MAILING_ADDRESS]: addressDefaultValue,
};

const ValidationSchema = {
  [FormFields.TAX_ID]: YupValidations.requiredString,
  [FormFields.INTACCT_ID]: YupValidations.requiredIntacctID,
  [FormFields.LEGAL_BUSINESS_NAME]: YupValidations.requiredString,
  [FormFields.TIME_IN_OPERATION]: YupValidations.requiredOption,
  [FormFields.PHONE]: YupValidations.requiredPhone,
  [FormFields.CHARGEBACK_FAX]: YupValidations.requiredString,
  [FormFields.WEBSITE]: YupValidations.requiredString,
  [FormFields.LOCATION_ADDRESS]: Yup.object().shape(AddressSchema({ validate: true })),
  [FormFields.MAILING_ADDRESS]: Yup.object().shape(AddressSchema({ validate: true })),
};

export {
  ValidationSchema,
  defaultValue,
  FormFields,
};

export default CorporateAccount;
