import { isMerchant, hasRestrictiveRelationships } from '../../IAM/utils';

export const useGetAccounts = ({ role, merchants, accounts }) => {
  const isAMerchant = isMerchant(role);
  const needsAccounts = hasRestrictiveRelationships(role);
  const getAccounts = () => {
    if (!isAMerchant) {
      return accounts?.map((account) => {
        return { id: account, merchants: [] };
      });
    }

    return [{ id: accounts?.[0], merchants }];
  };

  return {
    needsAccounts,
    getAccounts,
  };
};
