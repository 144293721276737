import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { UserCreate, createUserApiKey } from '../../../api';

export const addUserThunk = createAsyncThunk('users/addUser', async ({ body }, { rejectWithValue }) => {
  try {
    const response = await UserCreate(body);
    if (body?.generateApiKey) {
      createUserApiKey({ userId: response?.data?.id });
    }
    return response.data;
  } catch (e) {
    const { data, status, statusText } = e.response;
    const { error = {} } = data;
    return rejectWithValue({ ...error, status, statusText });
  }
});

export const addUserReducers = {
  [addUserThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'add.response.result',
      payload,
    );
    set(
      state,
      'add.response.isLoading',
      false,
    );
    set(
      state,
      'add.response.error',
      null,
    );
  },
  [addUserThunk.pending]: (state) => {
    set(
      state,
      'add.response.result',
      null,
    );
    set(
      state,
      'add.response.isLoading',
      true,
    );
    set(
      state,
      'add.response.error',
      null,
    );
  },

  [addUserThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'add.response.result',
      null,
    );
    set(
      state,
      'add.response.error',
      payload,
    );
    set(
      state,
      'add.response.isLoading',
      false,
    );
  },
};
