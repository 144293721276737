import {
  Alert,
  Button, Divider, Grid, Link, TextField, Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useLogout } from '../../hooks/useLogout';

import { TwoFactorExplanationTooltip } from '../../shared/utils';

const SecurityCode = ({ loginValues }) => {
  const { control, getValues } = useForm({ mode: 'all' });
  const { login } = useAuth();
  const logout = useLogout();
  const codeFormName = 'code';

  const authenticate = async () => {
    try {
      await login(loginValues?.email, loginValues?.password, loginValues?.rememberMe, getValues(codeFormName));
    } catch (error) {
      logout('Two-factor authentication failed', { variant: 'error' });
    }
  };

  const render = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography
              fontWeight="bold"
              fontSize="26px"
              variant="h4"
            >
              Verify that it&apos;s you
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography>
              Please enter the generated 6-digit code from your
              {' '}
              <i>authenticator app</i>
              <TwoFactorExplanationTooltip />
            </Typography>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <Controller
              name={codeFormName}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Alert severity="info">You can also provide a one time backup code to proceed</Alert>
          </Grid>
          <Button
            color="primary"
            fullWidth
            size="large"
            variant="contained"
            onClick={async () => {
              await authenticate();
            }}
            sx={{ mt: 2, ml: 1 }}
          >
            Verify and sign in
          </Button>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Link
          color="textSecondary"
          component={RouterLink}
          to="/"
          variant="body2"
          onClick={() => {
            window.reload();
          }}
        >
          Return to login
        </Link>
      </>
    );
  };

  return render();
};
export default SecurityCode;
