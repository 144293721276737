import { useEffect, useState } from 'react';
import {
  Grid, Button,
} from '@mui/material';
import {
  isEmpty,
} from 'lodash';
import Dialog from '../../../components/shared/Dialogs/Dialog';
import SelectionStep from './steps/SelectionStep';
import EditBankStep from './steps/EditBankStep';
import ConfirmationStep from './steps/ConfirmationStep';
import RequestStep from './steps/RequestStep/RequestStep';

const STEPPER = {
  selection: {
    id: 'selection',
    title: 'Attribute Selection',
    Component: SelectionStep,
    nextButtonText: 'Continue to Edit',
    previousButtonText: 'Cancel',
    nextAction: 'editBank',
    previousAction: 'cancel',
  },
  editBank: {
    id: 'editBank',
    title: 'Change Bank Account',
    Component: EditBankStep,
    nextButtonText: 'Save Changes',
    previousButtonText: 'Back',
    nextAction: 'confirm',
    previousAction: 'selection',
  },
  confirm: {
    id: 'confirm',
    title: null,
    Component: ConfirmationStep,
    nextButtonText: 'Confirm Changes',
    previousButtonText: 'Back',
    nextAction: 'request',
    previousAction: 'editBank',
  },
  request: {
    id: 'request',
    title: null,
    Component: RequestStep,
    nextButtonText: 'Finish',
    previousButtonText: 'Back',
    nextAction: 'finish',
    previousAction: 'editBank',
  },
};
function EditItemsModal({
  open = false,
  onClose = () => null,
  selectedMids = [],
  filtersSelected,
  onFinish = () => {},
}) {
  const [stepper, setStep] = useState(STEPPER.selection);
  const [meta, setMeta] = useState({ });
  const [validation, setValidation] = useState({});

  useEffect(() => {
    setMeta({ ...meta, selectedMids, filtersSelected });
  }, [selectedMids, filtersSelected]);

  const handleClose = () => {
    setTimeout(() => {
      setStep(STEPPER.selection);
      setMeta({ selectedMids, filtersSelected });
      setValidation({});
    }, 500);
    onClose();
  };

  const handleNext = () => {
    if (stepper.nextAction === 'finish') {
      onFinish();
      handleClose();
    } else {
      setStep(STEPPER[stepper.nextAction]);
    }
  };
  const handleBack = () => {
    if (stepper.previousAction === 'cancel') {
      handleClose();
    } else {
      setStep(STEPPER[stepper.previousAction]);
    }
  };

  const handleSectionChange = (change) => {
    setMeta({
      ...meta,
      [stepper.id]: change,
    });
  };

  const handleSectionValidation = (validations) => {
    setValidation({
      ...validation,
      [stepper.id]: validations,
    });
  };

  const getStepAggregatedData = () => {
    const previousData = meta[stepper.previousAction] || {};
    const data = {
      ...previousData,
      ...meta[stepper.id] || {},
    };
    return data;
  };

  return (
    <Dialog
      title={stepper.title}
      open={open}
      onClose={handleClose}
      fullWidth={1000}
      actionSection={[
        <Button onClick={handleBack} variant="outlined">
          {stepper.previousButtonText}
        </Button>,
        <Button
          color="primary"
          variant="contained"
          disabled={!isEmpty(validation[stepper.id])}
          onClick={handleNext}
          sx={{ ml: 1 }}
          autoFocus
        >
          {stepper.nextButtonText}
        </Button>,
      ]}
    >
      <Grid container spacing={1} paddingY={1}>
        <stepper.Component
          key={stepper.id}
          initial={getStepAggregatedData()}
          onChange={handleSectionChange}
          onValidate={handleSectionValidation}
          meta={meta}
        />
      </Grid>
    </Dialog>
  );
}

export default EditItemsModal;
