import {
  Grid,
  Button,
  Box,
} from '@mui/material';
import {
  isEmpty,
} from 'lodash';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  useMutation,
} from 'react-query';
import ProcessingDialog from '../../../../shared/Dialogs/ProcessingDialog';
import ErrorFactory from '../../../../../shared/errors';
import useToggle from '../../../../../hooks/useToggle';
import TextField from '../../../../forms/TextField';
import { deviceServicesAPI } from '../../../../../config';
import httpDeviceClient from '../../../../../api/httpDeviceClient';
import { ControlledAutocomplete } from '../../../../forms/ControlledAutocomplete';

const FormFields = {
  LABEL: 'device_label',
  GATEWAY: 'gateway',
  SERIAL_NUMBER: 'serial',
  MAKE: 'make',
  MODEL: 'model',
  DESCRIPTION: 'description',
  SDK_KEY_OVERRIDE: 'sdk_key_override',
  SECURITY_TOKEN_OVERRIDE: 'token_override',
};

async function AddDeviceRequest({ merchantId, merchantName, payload }) {
  try {
    const addedDevice = {
      client_label: merchantName,
      gateway: payload[FormFields.GATEWAY]?.value,
      description: payload[FormFields.DESCRIPTION],
      device_label: payload[FormFields.LABEL],
      domain: deviceServicesAPI.DEFAULT_DOMAIN,
      make: payload[FormFields.MAKE],
      model: payload[FormFields.MODEL],
      sdk_key_override: payload[FormFields.SDK_KEY_OVERRIDE],
      serial: payload[FormFields.SERIAL_NUMBER],
      token_override: payload[FormFields.SECURITY_TOKEN_OVERRIDE],

    };
    const data = await httpDeviceClient.post(
      `/merchant/${merchantId}/device`,
      {
        ...addedDevice,
      },
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

const AddDevice = ({
  onClose,
  user,
  merchantId,
  merchantName,
  gateways,
}) => {
  const confirmDialogToggle = useToggle();
  const addDevice = useMutation(AddDeviceRequest);
  const {
    control,
    getValues,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const disableButtons = !isDirty || !isEmpty(errors);

  const onSubmit = (form) => {
    confirmDialogToggle.turnOn();
    addDevice.mutate({
      userId: user.id,
      merchantId,
      merchantName,
      payload: {
        ...form,
      },
    });
  };

  const handleRetry = () => {
    onSubmit(getValues());
  };

  const handleFinish = () => {
    onClose(addDevice.variables);
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <>
        <Grid
          container
          spacing={1}
          sx={{
            paddingTop: '10px',
          }}
        >
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.LABEL}
              label="Label"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.SERIAL_NUMBER}
              label="Serial Number"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.MAKE}
              label="Make"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.MODEL}
              label="Model"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              name={FormFields.DESCRIPTION}
              label="Description"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <ControlledAutocomplete
              control={control}
              name={FormFields.GATEWAY}
              options={gateways}
              label="Gateway"
              defaultValue={gateways?.find((el) => el)}
              required
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              control={control}
              name={FormFields.SDK_KEY_OVERRIDE}
              label="SDK Key Override"
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              control={control}
              name={FormFields.SECURITY_TOKEN_OVERRIDE}
              label="Security Token Override"
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            paddingTop: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={disableButtons}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
        <ProcessingDialog
          isOpen={confirmDialogToggle.isActive}
          onClose={confirmDialogToggle.turnOff}
          onFinished={handleFinish}
          onRetry={handleRetry}
          status={addDevice.status}
          error={addDevice.error}
          successText="Device added successfully"
        />
      </>
    </form>
  );
};

export default AddDevice;
