/* eslint-disable no-param-reassign */
import { React, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Typography,
  FormControl,
  TextField,
  Grid,
  Badge,
  MenuItem,
} from '@mui/material';

import { AccordionForm } from '../../AccordionForm';

const singleAccountBankName = 'singleAccountBankName';
const singleAccountRoutingAbaNumber = 'singleAccountRoutingAbaNumber';
const singleAccountAccountNumber = 'singleAccountAccountNumber';
const creditDepositsRoutingAbaNumber = 'creditDepositsRoutingAbaNumber';
const creditDepositsBankName = 'creditDepositsBankName';
const creditDepositsAccountNumber = 'creditDepositsAccountNumber';
const creditChargebacksBankName = 'creditChargebacksBankName';
const creditChargebacksRoutingAbaNumber = 'creditChargebacksRoutingAbaNumber';
const creditChargebacksAccountNumber = 'creditChargebacksAccountNumber';
const creditConvenienceFeesBankName = 'creditConvenienceFeesBankName';
const creditConvenienceFeesRoutingAbaNumber = 'creditConvenienceFeesRoutingAbaNumber';
const creditConvenienceFeesAccountNumber = 'creditConvenienceFeesAccountNumber';
const creditProcessingFeesBankName = 'creditProcessingFeesBankName';
const creditProcessingFeesRoutingAbaNumber = 'creditProcessingFeesRoutingAbaNumber';
const creditProcessingFeesAccountNumber = 'creditProcessingFeesAccountNumber';
const debitDepositsBankName = 'debitDepositsBankName';
const debitDepositsRoutingAbaNumber = 'debitDepositsRoutingAbaNumber';
const debitDepositsAccountNumber = 'debitDepositsAccountNumber';
const debitChargebacksBankName = 'debitChargebacksBankName';
const debitChargebacksRoutingAbaNumber = 'debitChargebacksRoutingAbaNumber';
const debitChargebacksAccountNumber = 'debitChargebacksAccountNumber';
const debitConvenienceFeesBankName = 'debitConvenienceFeesBankName';
const debitConvenienceFeesRoutingAbaNumber = 'debitConvenienceFeesRoutingAbaNumber';
const debitConvenienceFeesAccountNumber = 'debitConvenienceFeesAccountNumber';
const chargebacksAccountBankName = 'chargebacksAccountBankName';
const chargebacksAccountRoutingAbaNumber = 'chargebacksAccountRoutingAbaNumber';
const chargebacksAccountAccountNumber = 'chargebacksAccountAccountNumber';
const returnsAccountBankName = 'returnsAccountBankName';
const returnsAccountRoutingAbaNumber = 'returnsAccountRoutingAbaNumber';
const returnsAccountAccountNumber = 'returnsAccountAccountNumber';
const amextpspRoutingAchBankName = 'amextpspRoutingAchBankName';
const amextpspRoutingAchRoutingAbaNumber = 'amextpspRoutingAchRoutingAbaNumber';
const amextpspRoutingAchAccountNumber = 'amextpspRoutingAchAccountNumber';
const amextpspDdaAchBankName = 'amextpspDdaAchBankName';
const amextpspDdaAchRoutingAbaNumber = 'amextpspDdaAchRoutingAbaNumber';
const amextpspDdaAchAccountNumber = 'amextpspDdaAchAccountNumber';

const bankNameValidation = yup.string()
  .required('Bank Name is required')
  .test('len', 'Bank Name should be from 2 to 22 characters long.', (value) => {
    if (value?.length === 0) return true;
    if (value?.length <= 22 && value?.length >= 2) return true;
    if (!value) return true;
    return false;
  });

const accountSettlementMethodValidation = yup.string().required('Account Settlement Method is required.');

const routingAbaNumberValidation = yup.string()
  .required('Routing/ABA number is required.')
  .typeError('Enter a valid Routing/ABA number.')
  .test('len', 'Enter a valid Routing/ABA number.', (value) => {
    if (!(/^[0-9]*$/).test(value) && value) return false;
    if (!value) return true;
    if (value?.toString().length === 0) return true;
    if (value?.toString().length === 9) return true;
    return false;
  });

const accountNumberValidation = yup.string()
  .required('Account Number is required')
  .typeError('Enter a valid Account Number.')
  .test('len', 'Enter a valid Account Number.', (value) => {
    if (!(/^[0-9]*$/).test(value) && value) return false;
    if (!value) return true;
    if (value?.toString().length === 0) return true;
    if (value?.toString().length === 17) return true;
    return false;
  });

const EditBankAccounts = ({
  submitting,
  resetForms,
  expand,
  onChangeForm,
  previousExpanded,
  editing,
  fundingGroupData,
  editSettings,
  tenderTypes,
}) => {
  const [accountSettlementMethod, setAccountSettlementMethod] = useState(null);
  const [schemaObject, setSchemaObject] = useState({
    accountSettlementMethod: accountSettlementMethodValidation,
  });
  const schema = yup.object().shape(schemaObject);

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const badgeStyle = { marginTop: 14, marginLeft: 17 };
  const errorQty = Object.keys(errors).length;

  useEffect(() => {
    if (accountSettlementMethod === 'single') {
      setSchemaObject({
        accountSettlementMethod: accountSettlementMethodValidation,
        singleAccountBankName: bankNameValidation,
        singleAccountRoutingAbaNumber: routingAbaNumberValidation,
        singleAccountAccountNumber: accountNumberValidation,
      });
    } else if (accountSettlementMethod === 'multiple') {
      setSchemaObject({
        accountSettlementMethod: accountSettlementMethodValidation,
        creditDepositsBankName: bankNameValidation,
        creditDepositsRoutingAbaNumber: routingAbaNumberValidation,
        creditDepositsAccountNumber: accountNumberValidation,
        creditChargebacksBankName: bankNameValidation,
        creditChargebacksRoutingAbaNumber: routingAbaNumberValidation,
        creditChargebacksAccountNumber: accountNumberValidation,
        creditConvenienceFeesBankName: bankNameValidation,
        creditConvenienceFeesRoutingAbaNumber: routingAbaNumberValidation,
        creditConvenienceFeesAccountNumber: accountNumberValidation,
        creditProcessingFeesBankName: bankNameValidation,
        creditProcessingFeesRoutingAbaNumber: routingAbaNumberValidation,
        creditProcessingFeesAccountNumber: accountNumberValidation,
        debitDepositsBankName: bankNameValidation,
        debitDepositsRoutingAbaNumber: routingAbaNumberValidation,
        debitDepositsAccountNumber: accountNumberValidation,
        debitChargebacksBankName: bankNameValidation,
        debitChargebacksRoutingAbaNumber: routingAbaNumberValidation,
        debitChargebacksAccountNumber: accountNumberValidation,
        debitConvenienceFeesBankName: bankNameValidation,
        debitConvenienceFeesRoutingAbaNumber: routingAbaNumberValidation,
        debitConvenienceFeesAccountNumber: accountNumberValidation,
        chargebacksAccountBankName: bankNameValidation,
        chargebacksAccountRoutingAbaNumber: routingAbaNumberValidation,
        chargebacksAccountAccountNumber: accountNumberValidation,
        returnsAccountBankName: bankNameValidation,
        returnsAccountRoutingAbaNumber: routingAbaNumberValidation,
        returnsAccountAccountNumber: accountNumberValidation,
        amextpspRoutingAchBankName: tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo' ? bankNameValidation : null,
        amextpspRoutingAchRoutingAbaNumber: tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo' ? routingAbaNumberValidation : null,
        amextpspRoutingAchAccountNumber: tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo' ? accountNumberValidation : null,
        amextpspDdaAchBankName: tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo' ? bankNameValidation : null,
        amextpspDdaAchRoutingAbaNumber: tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo' ? routingAbaNumberValidation : null,
        amextpspDdaAchAccountNumber: tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo' ? accountNumberValidation : null,
      });
    }
  }, [accountSettlementMethod, tenderTypes]);

  useEffect(() => {
    if (editing) {
      setAccountSettlementMethod(fundingGroupData.accountSettlementMethod);
      if (fundingGroupData.accountSettlementMethod === 'single') {
        reset({
          accountSettlementMethod: fundingGroupData.accountSettlementMethod,
          singleAccountBankName: fundingGroupData.groupAccounts.singleAccount.bankName,
          singleAccountRoutingAbaNumber: fundingGroupData.groupAccounts.singleAccount.routingAbaNumber,
          singleAccountAccountNumber: fundingGroupData.groupAccounts.singleAccount.accountNumber,
        });
      } else {
        reset({
          accountSettlementMethod: fundingGroupData.accountSettlementMethod,
          creditDepositsBankName: fundingGroupData.groupAccounts.creditDeposits.bankName,
          creditDepositsRoutingAbaNumber: fundingGroupData.groupAccounts.creditDeposits.routingAbaNumber,
          creditDepositsAccountNumber: fundingGroupData.groupAccounts.creditDeposits.accountNumber,
          creditChargebacksBankName: fundingGroupData.groupAccounts.creditChargebacks.bankName,
          creditChargebacksRoutingAbaNumber: fundingGroupData.groupAccounts.creditChargebacks.routingAbaNumber,
          creditChargebacksAccountNumber: fundingGroupData.groupAccounts.creditChargebacks.accountNumber,
          creditConvenienceFeesBankName: fundingGroupData.groupAccounts.creditConvenienceFees.bankName,
          creditConvenienceFeesRoutingAbaNumber: fundingGroupData.groupAccounts.creditConvenienceFees.routingAbaNumber,
          creditConvenienceFeesAccountNumber: fundingGroupData.groupAccounts.creditConvenienceFees.accountNumber,
          creditProcessingFeesBankName: fundingGroupData.groupAccounts.creditProcessingFees.bankName,
          creditProcessingFeesRoutingAbaNumber: fundingGroupData.groupAccounts.creditProcessingFees.routingAbaNumber,
          creditProcessingFeesAccountNumber: fundingGroupData.groupAccounts.creditProcessingFees.accountNumber,
          debitDepositsBankName: fundingGroupData.groupAccounts.debitDeposits.bankName,
          debitDepositsRoutingAbaNumber: fundingGroupData.groupAccounts.debitDeposits.routingAbaNumber,
          debitDepositsAccountNumber: fundingGroupData.groupAccounts.debitDeposits.accountNumber,
          debitChargebacksBankName: fundingGroupData.groupAccounts.debitChargebacks.bankName,
          debitChargebacksRoutingAbaNumber: fundingGroupData.groupAccounts.debitChargebacks.routingAbaNumber,
          debitChargebacksAccountNumber: fundingGroupData.groupAccounts.debitChargebacks.accountNumber,
          debitConvenienceFeesBankName: fundingGroupData.groupAccounts.debitConvenienceFees.bankName,
          debitConvenienceFeesRoutingAbaNumber: fundingGroupData.groupAccounts.debitConvenienceFees.routingAbaNumber,
          debitConvenienceFeesAccountNumber: fundingGroupData.groupAccounts.debitConvenienceFees.accountNumber,
          chargebacksAccountBankName: fundingGroupData.groupAccounts.chargebacksAccount.bankName,
          chargebacksAccountRoutingAbaNumber: fundingGroupData.groupAccounts.chargebacksAccount.routingAbaNumber,
          chargebacksAccountAccountNumber: fundingGroupData.groupAccounts.chargebacksAccount.accountNumber,
          returnsAccountBankName: fundingGroupData.groupAccounts.returnsAccount.bankName,
          returnsAccountRoutingAbaNumber: fundingGroupData.groupAccounts.returnsAccount.routingAbaNumber,
          returnsAccountAccountNumber: fundingGroupData.groupAccounts.returnsAccount.accountNumber,
          amextpspDdaAchBankName: fundingGroupData.groupAccounts.amextpspDdaAch.bankName,
          amextpspDdaAchRoutingAbaNumber: fundingGroupData.groupAccounts.amextpspDdaAch.routingAbaNumber,
          amextpspDdaAchAccountNumber: fundingGroupData.groupAccounts.amextpspDdaAch.accountNumber,
          amextpspRoutingAchBankName: fundingGroupData.groupAccounts.amextpspDdaAch.bankName,
          amextpspRoutingAchRoutingAbaNumber: fundingGroupData.groupAccounts.amextpspDdaAch.routingAbaNumber,
          amextpspRoutingAchAccountNumber: fundingGroupData.groupAccounts.amextpspDdaAch.accountNumber,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (previousExpanded === 'bankAccounts') {
      trigger();
    }
  }, [expand]);

  useEffect(() => {
    if (resetForms > 0) {
      reset();
    }
  }, [resetForms]);

  useEffect(() => {
    if (submitting > 0) {
      const useRequest = async () => {
        const valid = await trigger();
        if (valid) {
          editSettings.groupAccounts.accountSettlementMethod = getValues('accountSettlementMethod');
          if (accountSettlementMethod === 'single') {
            editSettings.groupAccounts.singleAccount = {
              bankName: getValues(singleAccountBankName),
              routingAbaNumber: getValues(singleAccountRoutingAbaNumber),
              accountNumber: getValues(singleAccountAccountNumber),
            };
          } else {
            editSettings.groupAccounts.creditDeposits = {
              bankName: getValues(creditDepositsBankName),
              routingAbaNumber: getValues(creditDepositsRoutingAbaNumber),
              accountNumber: getValues(creditDepositsAccountNumber),
            };
            editSettings.groupAccounts.creditChargebacks = {
              bankName: getValues(creditChargebacksBankName),
              routingAbaNumber: getValues(creditChargebacksRoutingAbaNumber),
              accountNumber: getValues(creditChargebacksAccountNumber),
            };
            editSettings.groupAccounts.creditConvenienceFees = {
              bankName: getValues(creditConvenienceFeesBankName),
              routingAbaNumber: getValues(creditConvenienceFeesRoutingAbaNumber),
              accountNumber: getValues(creditConvenienceFeesAccountNumber),
            };
            editSettings.groupAccounts.creditProcessingFees = {
              bankName: getValues(creditProcessingFeesBankName),
              routingAbaNumber: getValues(creditProcessingFeesRoutingAbaNumber),
              accountNumber: getValues(creditProcessingFeesAccountNumber),
            };
            editSettings.groupAccounts.debitDeposits = {
              bankName: getValues(debitDepositsBankName),
              routingAbaNumber: getValues(debitDepositsRoutingAbaNumber),
              accountNumber: getValues(debitDepositsAccountNumber),
            };
            editSettings.groupAccounts.debitChargebacks = {
              bankName: getValues(debitChargebacksBankName),
              routingAbaNumber: getValues(debitChargebacksRoutingAbaNumber),
              accountNumber: getValues(debitChargebacksAccountNumber),
            };
            editSettings.groupAccounts.debitConvenienceFees = {
              bankName: getValues(debitConvenienceFeesBankName),
              routingAbaNumber: getValues(debitConvenienceFeesRoutingAbaNumber),
              accountNumber: getValues(debitConvenienceFeesAccountNumber),
            };
            editSettings.groupAccounts.chargebacksAccount = {
              bankName: getValues(chargebacksAccountBankName),
              routingAbaNumber: getValues(chargebacksAccountRoutingAbaNumber),
              accountNumber: getValues(chargebacksAccountAccountNumber),
            };
            editSettings.groupAccounts.returnsAccount = {
              bankName: getValues(returnsAccountBankName),
              routingAbaNumber: getValues(returnsAccountRoutingAbaNumber),
              accountNumber: getValues(returnsAccountAccountNumber),
            };
            if (tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo') {
              editSettings.groupAccounts.amextpspRoutingAch = {
                bankName: getValues(amextpspRoutingAchBankName),
                routingAbaNumber: getValues(amextpspRoutingAchRoutingAbaNumber),
                accountNumber: getValues(amextpspRoutingAchAccountNumber),
              };
              editSettings.groupAccounts.amextpspDdaAch = {
                bankName: getValues(amextpspDdaAchBankName),
                routingAbaNumber: getValues(amextpspDdaAchRoutingAbaNumber),
                accountNumber: getValues(amextpspDdaAchAccountNumber),
              };
            }
          }
        }
      };
      useRequest();
    }
  }, [submitting]);

  const handleCopyDetailsChange = (event, section) => {
    if (event.target.value === 'none') {
      setValue(`${section}BankName`, '');
      setValue(`${section}RoutingAbaNumber`, '');
      setValue(`${section}AccountNumber`, '');
    } else {
      setValue(`${section}BankName`, getValues(`${event.target.value}BankName`), { shouldValidate: true });
      setValue(`${section}RoutingAbaNumber`, getValues(`${event.target.value}RoutingAbaNumber`), { shouldValidate: true });
      setValue(`${section}AccountNumber`, getValues(`${event.target.value}AccountNumber`), { shouldValidate: true });
    }
  };

  const checkIfThisFormIsExpanded = () => expand === 'bankAccounts';

  return (
    <AccordionForm
      expanded={checkIfThisFormIsExpanded()}
      onChange={() => onChangeForm('bankAccounts')}
      title={(
        <>
          <Typography variant="h6">Bank Accounts</Typography>
          {errorQty > 0 && (
            <Badge style={badgeStyle} badgeContent={errorQty} color="secondary" />
          )}
        </>
      )}
      content={(
        <>
          <form>
            <FormControl
              variant="outlined"
              sx={{ mb: 2 }}
              style={{ width: '100%', paddingInline: 20 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="accountSettlementMethod"
                    render={({ field: { onChange, value }, fieldState }) => (
                      <TextField
                        select
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          onChange(e);
                          setAccountSettlementMethod(e.target.value);
                        }}
                        label="Account settlement method"
                        error={(fieldState.error && !value) && !!fieldState.error.message}
                        helperText={(fieldState.error && !value) && fieldState.error.message}
                        value={!value ? '' : value}
                      >
                        <MenuItem value="single">
                          Single Account (Simple)
                        </MenuItem>
                        <MenuItem value="multiple">
                          Multiple Accounts (Advanced)
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                {accountSettlementMethod === 'single' && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">Single Account</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={singleAccountBankName}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            label="Bank name"
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={singleAccountRoutingAbaNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            label="Routing/ABA number"
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={singleAccountAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            label="Account Number"
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {accountSettlementMethod === 'multiple' && (
                  <>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 25 }} variant="h6">Credit Deposits</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={creditDepositsBankName}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            label="Bank name"
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditDepositsRoutingAbaNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            label="Routing/ABA number"
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditDepositsAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            label="Account Number"
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">Credit Chargebacks</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'creditChargebacks')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="creditChargebacksBankName"
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              InputLabelProps={{
                                shrink: !!value,
                              }}
                              value={!value ? '' : value}
                              label="Bank name"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditChargebacksRoutingAbaNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Routing/ABA Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditChargebacksAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Account Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">Credit Convenience Fees</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'creditConvenienceFees')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                              <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={creditConvenienceFeesBankName}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              InputLabelProps={{
                                shrink: !!value,
                              }}
                              value={!value ? '' : value}
                              label="Bank name"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditConvenienceFeesRoutingAbaNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Routing/ABA Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditConvenienceFeesAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Account Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">Credit Processing Fees</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'creditProcessingFees')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                              <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                              <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={creditProcessingFeesBankName}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              InputLabelProps={{
                                shrink: !!value,
                              }}
                              value={!value ? '' : value}
                              label="Bank name"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditProcessingFeesRoutingAbaNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Routing/ABA Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={creditProcessingFeesAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Account Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">Debit Deposits</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'debitDeposits')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                              <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                              <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                              <MenuItem value="creditProcessingFees">Credit Processing Fees</MenuItem>
                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={debitDepositsBankName}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              InputLabelProps={{
                                shrink: !!value,
                              }}
                              value={!value ? '' : value}
                              label="Bank name"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={debitDepositsRoutingAbaNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Routing/ABA Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={debitDepositsAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Account Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">Debit Chargebacks</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'debitChargebacks')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                              <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                              <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                              <MenuItem value="creditProcessingFees">Credit Processing Fees</MenuItem>
                              <MenuItem value="debitDeposits">Debit Deposits</MenuItem>
                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={debitChargebacksBankName}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              InputLabelProps={{
                                shrink: !!value,
                              }}
                              value={!value ? '' : value}
                              label="Bank name"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={debitChargebacksRoutingAbaNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Routing/ABA Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={debitChargebacksAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Account Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">Debit Convenience Fees</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'debitConvenienceFees')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                              <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                              <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                              <MenuItem value="creditProcessingFees">Credit Processing Fees</MenuItem>
                              <MenuItem value="debitDeposits">Debit Deposits</MenuItem>
                              <MenuItem value="debitChargebacks">Debit Chargebacks</MenuItem>

                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={debitConvenienceFeesBankName}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              InputLabelProps={{
                                shrink: !!value,
                              }}
                              value={!value ? '' : value}
                              label="Bank name"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="debitConvenienceFeesRoutingAbaNumber"
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Routing/ABA Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={debitConvenienceFeesAccountNumber}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            InputLabelProps={{
                              shrink: !!value,
                            }}
                            value={!value ? '' : value}
                            label="Account Number"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20 }} variant="h6">Chargebacks Account</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'chargebacksAccount')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                              <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                              <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                              <MenuItem value="creditProcessingFees">Credit Processing Fees</MenuItem>
                              <MenuItem value="debitDeposits">Debit Deposits</MenuItem>
                              <MenuItem value="debitChargebacks">Debit Chargebacks</MenuItem>
                              <MenuItem value="debitConvenienceFees">Debit Convenience Fees</MenuItem>
                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={chargebacksAccountBankName}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              label="Bank name"
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              value={!value ? '' : value}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={chargebacksAccountRoutingAbaNumber}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            label="Routing/ABA Number"
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={chargebacksAccountAccountNumber}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            label="Account Number (DDA)"
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">Returns Account</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        render={() => (
                          <>
                            <TextField
                              select
                              style={{ width: '100%' }}
                              onChange={(event) => handleCopyDetailsChange(event, 'returnsAccount')}
                              label="Copy details from"
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                              <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                              <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                              <MenuItem value="creditProcessingFees">Credit Processing Fees</MenuItem>
                              <MenuItem value="debitDeposits">Debit Deposits</MenuItem>
                              <MenuItem value="debitChargebacks">Debit Chargebacks</MenuItem>
                              <MenuItem value="debitConvenienceFees">Debit Convenience Fees</MenuItem>
                              <MenuItem value="chargebacksAccount">Chargebacks Account</MenuItem>
                            </TextField>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={returnsAccountBankName}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <>
                            <TextField
                              style={{ width: '100%' }}
                              onChange={onChange}
                              onBlur={onBlur}
                              label="Bank name"
                              error={fieldState.error && !!fieldState.error.message}
                              helperText={fieldState.error && fieldState.error.message}
                              value={!value ? '' : value}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={returnsAccountRoutingAbaNumber}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            label="Routing/ABA Number"
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={returnsAccountAccountNumber}
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            label="Account Number"
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            value={!value ? '' : value}
                          />
                        )}
                      />
                    </Grid>
                    {tenderTypes.amex_tpsp?.amexTpspFundingType === 'fbo' && (
                      <>
                        <Grid item xs={12}>
                          <Typography style={{ marginTop: 20 }} variant="h6">AmexTPSP Routing ACH</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            render={() => (
                              <>
                                <TextField
                                  select
                                  style={{ width: '100%' }}
                                  onChange={(event) => handleCopyDetailsChange(event, 'amextpspRoutingAch')}
                                  label="Copy details from"
                                >
                                  <MenuItem value="none">None</MenuItem>
                                  <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                                  <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                                  <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                                  <MenuItem value="creditProcessingFees">Credit Processing Fees</MenuItem>
                                  <MenuItem value="debitDeposits">Debit Deposits</MenuItem>
                                  <MenuItem value="debitChargebacks">Debit Chargebacks</MenuItem>
                                  <MenuItem value="debitConvenienceFees">Debit Convenience Fees</MenuItem>
                                  <MenuItem value="chargebacksAccount">Chargebacks Account</MenuItem>
                                  <MenuItem value="returnsAccount">Returns Account</MenuItem>
                                </TextField>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name={amextpspRoutingAchBankName}
                            render={({ field: { onChange, onBlur, value }, fieldState }) => (
                              <>
                                <TextField
                                  style={{ width: '100%' }}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  label="Bank name"
                                  error={fieldState.error && !!fieldState.error.message}
                                  helperText={fieldState.error && fieldState.error.message}
                                  value={!value ? '' : value}
                                />
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={amextpspRoutingAchRoutingAbaNumber}
                            render={({ field: { onChange, onBlur, value }, fieldState }) => (
                              <>
                                <TextField
                                  style={{ width: '100%' }}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  label="Routing/ABA Number"
                                  error={fieldState.error && !!fieldState.error.message}
                                  helperText={fieldState.error && fieldState.error.message}
                                  value={!value ? '' : value}
                                />
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={amextpspRoutingAchAccountNumber}
                            render={({ field: { onChange, onBlur, value }, fieldState }) => (
                              <TextField
                                style={{ width: '100%' }}
                                onChange={onChange}
                                onBlur={onBlur}
                                label="Account Number (DDA)"
                                error={fieldState.error && !!fieldState.error.message}
                                helperText={fieldState.error && fieldState.error.message}
                                value={!value ? '' : value}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ marginTop: 20, marginBottom: -5 }} variant="h6">AmexTPSP DDA ACH</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            render={() => (
                              <>
                                <TextField
                                  select
                                  style={{ width: '100%' }}
                                  onChange={(event) => handleCopyDetailsChange(event, 'amextpspDdaAch')}
                                  label="Copy details from"
                                >
                                  <MenuItem value="none">None</MenuItem>
                                  <MenuItem value="creditDeposits">Credit Deposit</MenuItem>
                                  <MenuItem value="creditChargebacks">Credit Chargebacks</MenuItem>
                                  <MenuItem value="creditConvenienceFees">Credit Convenience Fees</MenuItem>
                                  <MenuItem value="creditProcessingFees">Credit Processing Fees</MenuItem>
                                  <MenuItem value="debitDeposits">Debit Deposits</MenuItem>
                                  <MenuItem value="debitChargebacks">Debit Chargebacks</MenuItem>
                                  <MenuItem value="debitConvenienceFees">Debit Convenience Fees</MenuItem>
                                  <MenuItem value="chargebacksAccount">Chargebacks Account</MenuItem>
                                  <MenuItem value="returnsAccount">Returns Account</MenuItem>
                                  <MenuItem value="amextpspRoutingAch">AmexTPSP Routing ACH</MenuItem>
                                </TextField>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name={amextpspDdaAchBankName}
                            render={({ field: { onChange, onBlur, value }, fieldState }) => (
                              <>
                                <TextField
                                  style={{ width: '100%' }}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  label="Bank name"
                                  error={fieldState.error && !!fieldState.error.message}
                                  helperText={fieldState.error && fieldState.error.message}
                                  value={!value ? '' : value}
                                />
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={amextpspDdaAchRoutingAbaNumber}
                            render={({ field: { onChange, onBlur, value }, fieldState }) => (
                              <TextField
                                style={{ width: '100%' }}
                                onChange={onChange}
                                onBlur={onBlur}
                                label="Routing/ABA Number"
                                error={fieldState.error && !!fieldState.error.message}
                                helperText={fieldState.error && fieldState.error.message}
                                value={!value ? '' : value}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={amextpspDdaAchAccountNumber}
                            render={({ field: { onChange, onBlur, value }, fieldState }) => (
                              <TextField
                                style={{ width: '100%' }}
                                onChange={onChange}
                                onBlur={onBlur}
                                label="Account Number (DDA)"
                                error={fieldState.error && !!fieldState.error.message}
                                helperText={fieldState.error && fieldState.error.message}
                                value={!value ? '' : value}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </FormControl>
          </form>
        </>
      )}
    />
  );
};

export default EditBankAccounts;
