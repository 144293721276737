import {
  isEmpty, first, set, get,
} from 'lodash';

export const standardizeValidationFields = (validationFieldsObject) => {
  if (typeof (first(validationFieldsObject)) === 'object') {
    return validationFieldsObject.reduce((acc, field) => {
      acc.push(field.text);
      return acc;
    }, []);
  }
  return validationFieldsObject;
};

function ErrorFactory(err) {
  const { data, status } = err.response || {};
  const validationFields = get(data, ['error', 'validationFields'], []);
  const defaultError = Error(data?.error?.message || 'Something went wrong!');
  if (status >= 400 && !isEmpty(validationFields)) {
    set(defaultError, ['details', 'validationFields'], standardizeValidationFields(validationFields));
  }
  defaultError.status = status;
  return defaultError;
}

export default ErrorFactory;
