import Dialog from '@mui/material/Dialog';
import {
  Grid,
  Paper,
  DialogContentText,
  Button,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Draggable from 'react-draggable';
import { useMutation } from 'react-query';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ErrorFactory from '../../../../../shared/errors';
import httpDeviceClient from '../../../../../api/httpDeviceClient';
import ProcessingDialog from '../../../../shared/Dialogs/ProcessingDialog';
import useToggle from '../../../../../hooks/useToggle';

async function RemoveDeviceRequest({ deviceIds, merchantId }) {
  const devices = deviceIds instanceof Array ? deviceIds : [deviceIds];
  const removedDevices = {
    devices,
  };

  try {
    const data = await httpDeviceClient.delete(
      `/merchant/${merchantId}`,
      {
        data: removedDevices,
      }
      ,
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export const RemoveDeviceModal = ({
  deviceIds,
  merchantId,
  isOpen,
  onClose,
}) => {
  const confirmDialogToggle = useToggle();
  const removeDevice = useMutation(RemoveDeviceRequest);
  const onConfirm = () => {
    confirmDialogToggle.turnOn();
    removeDevice.mutate({
      merchantId,
      deviceIds,
    });
  };

  const handleRetry = () => {
    onConfirm();
  };

  const handleFinish = () => {
    onClose(removeDevice.variables);
    confirmDialogToggle.turnOff();
  };
  const renderContent = () => {
    return (
      <>
        <Grid item textAlign="center">
          <Grid item textAlign="center" xs={12}>
            <FeedbackIcon color="primary" sx={{ fontSize: '80px' }} />
          </Grid>
          <DialogContentText sx={{ typography: 'h6' }}>
            This action is irreversible and requires confirmation.
          </DialogContentText>
          <DialogContentText sx={{ typography: 'subtitle', mt: 2 }}>
            Selected device(s) will be removed, are you sure?
          </DialogContentText>
        </Grid>
      </>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Grid item textAlign="center" sx={{ mt: 2 }}>
          <Button
            color="primary"
            variant="outlined"
            autoFocus
            onClick={onClose}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </Grid>
      </>
    );
  };

  const PaperComponent = (props) => {
    return (
      <Draggable
        handle="#remove-device-modal-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="remove-device-modal-title"
        aria-describedby="remove-device-modal-description"
        PaperComponent={PaperComponent}
      >
        <DialogContent>
          {renderContent()}
          {renderActions()}
          <ProcessingDialog
            isOpen={confirmDialogToggle.isActive}
            onClose={confirmDialogToggle.turnOff}
            onFinished={handleFinish}
            onRetry={handleRetry}
            status={removeDevice.status}
            error={removeDevice.error}
            successText="Device(s) removed successfully"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
