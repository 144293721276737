import httpDeviceClient from '../../../../../api/httpDeviceClient';
import ErrorFactory from '../../../../../shared/errors';

async function DeviceNetworkDetailsRequest(merchantId, deviceId) {
  try {
    const { data } = await httpDeviceClient.get(
      `/merchant/${merchantId}/device/${deviceId}/network_details`,
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export default DeviceNetworkDetailsRequest;
