import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState = {
  login: {
    multiFactorEnabled: false,
    multiFactorRequired: false,
    loggedUser: {},
    forceRedirectToProfile: false,
  },
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateMultiFactorEnabled(state, action) {
      const { payload } = action;
      set(state?.login, 'multiFactorEnabled', payload);
    },
    updateMultiFactorRequired(state, action) {
      const { payload } = action;
      set(state?.login, 'multiFactorRequired', payload);
    },
    updateLoggedUser(state, action) {
      const { payload } = action;
      set(state?.login, 'loggedUser', payload);
    },
    setRedirectToProfile(state, action) {
      const { payload } = action;
      set(state?.login, 'forceRedirectToProfile', payload);
    },
  },
});

export const { reducer } = slice;

export const updateMultiFactorEnabled = (value) => (dispatch) => {
  dispatch(slice.actions.updateMultiFactorEnabled(value));
};

export const updateMultiFactorRequired = (value) => (dispatch) => {
  dispatch(slice.actions.updateMultiFactorRequired(value));
};

export const updateLoggedUser = (value) => (dispatch) => {
  dispatch(slice.actions.updateLoggedUser(value));
};

export const setRedirectToProfile = (value) => (dispatch) => {
  dispatch(slice.actions.setRedirectToProfile(value));
};

export default slice;

export const loginReducer = 'loginReducer';
