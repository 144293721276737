import ErrorFactory from '../../../shared/errors';
import { MerchantUpdate } from '../../../api';

const EditMerchantRequest = (withRisk) => async (payload) => {
  try {
    const omitList = withRisk ? ['accountId', 'status'] : [
      'accountId', 'status', 'b2bPercent', 'b2cPercent', 'location', 'mailing', 'companyID', 'paymentEnvironment',
    ];
    const data = await MerchantUpdate({ payload, omitList });
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
};

export default EditMerchantRequest;
