export const needsOwnership = (q1, tickerType, q2, q3) => {
  if (!['pt', 'other'].includes(q1)) return false;
  if (q1 === 'pt') return tickerType === 'Other';
  if (['is', 'sp'].includes(q2)) return true;
  if (q3 === 'other') return true;
  return false;
};

export const onlyOneBeneficialOwner = (q2) => {
  return q2 === 'sp';
};

export const needsMerchant = (q1, tickerType) => {
  if (!['pt', 'other'].includes(q1)) return false;
  if (q1 === 'pt') return tickerType === 'Other';
  return true;
};
