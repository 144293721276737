import { useState, forwardRef, useMemo } from 'react';
import {
  isEmpty, map, forEachRight, concat, forEach, join,
} from 'lodash';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Chip,
  TextField,
  Autocomplete,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { TreeItem, TreeView, useTreeItem } from '@mui/lab';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import { createFilterOptions } from '@mui/material/Autocomplete';

const useStyles = makeStyles(() => ({
  chip: {
    borderRadius: 5,
  },
}));

const CustomSelectCheckboxTree = ({
  control,
  name,
  helperText,
  setValue,
  required = false,
  data: options = [],
  label: componentlabel,
  keysSelected,
  setKeysSelected,
  singleMode = false,
  loading,
  expandByField = true,
  expandAll = true,
  disableComponent = false,
  nodesCompressed,
  setNodesCompressed,
  nodesSelected,
  setNodesSelected,
  updateForm,
}) => {
  const [filterText, setFilterText] = useState('');
  const [nodesFiltered, setNodesFiltered] = useState(options);
  const chipClasses = useStyles();

  const getChildByNode = (node) => {
    let array = [];

    const getAllChild = (item) => {
      if (item === null) return [];
      array.push(item);

      if (Array.isArray(item.children)) {
        forEach(item.children, (child) => {
          array = concat(array, getAllChild(child));
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    };

    const getNodeByValue = (object, value) => {
      if (object.value === value) {
        return object;
      } if (Array.isArray(object.children)) {
        let result = null;
        forEach(object.children, (child) => {
          if (getNodeByValue(child, value)) {
            result = getNodeByValue(child, value);
          }
        });
        return result;
      }
      return null;
    };

    return getAllChild(getNodeByValue(node, node.value));
  };

  const assignPaths = (obj, stack) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (node && typeof node === 'object') {
        node.path = stack ? `${stack}->${k}` : k;
        assignPaths(node, node.path, node.extendedLabel);
      }
    });
  };

  const getExtendedLabel = (element) => {
    let extendedLabel = '';
    const parentPath = element.path.split('->');
    parentPath.pop();
    parentPath.pop();

    parentPath.reduce((previous, current) => {
      const temp = previous[current];
      if (temp.label) {
        extendedLabel = `${element.label} (${temp.label})`;
      }
      return previous[current];
    }, options);

    return extendedLabel;
  };

  const assignExtendedLabels = () => {
    const allNodes = [];
    forEach(options[0].children, (item) => {
      const nodesTemp = getChildByNode(item);
      forEachRight(nodesTemp, (node) => {
        allNodes.push(node);
      });
    });

    let duplicated = [];
    allNodes.forEach((element) => {
      allNodes.forEach((comparing) => {
        if (comparing.value !== element.value && comparing.label === element.label) {
          // eslint-disable-next-line no-param-reassign
          element.extendedLabel = getExtendedLabel(element);

          // eslint-disable-next-line no-param-reassign
          comparing.extendedLabel = getExtendedLabel(comparing);
          duplicated.push(element, comparing);
        }
      });
    });
    duplicated = duplicated.filter((v, i) => duplicated.indexOf(v) === i);
  };

  const expandNodeToParents = (nodes) => {
    let expanded = nodes;
    forEach(options[0].children, (item) => {
      const allNodes = getChildByNode(item);

      forEachRight(allNodes, (node) => {
        if (!isEmpty(node.children)
          && node.children.length > 1
          && expanded.every((nodeExpanded) => nodeExpanded.value !== node.value)
          && node.children.every((child) => expanded.some((exp) => exp?.value === child?.value))) {
          expanded = concat(expanded, node);
        }
      });
    });
    return expanded;
  };

  const compressNodes = (nodes) => {
    let compressed = nodes;
    forEach(options[0].children, (item) => {
      const allNodes = getChildByNode(item);

      forEachRight(allNodes, (node) => {
        if (!isEmpty(node.children)
          && node.children.length > 1
          && node.children.every((child) => compressed.some((comp) => comp?.value === child?.value))) {
          compressed = compressed.filter((comp) => !node.children.find((child) => {
            return child.value === comp.value;
          }));
        }
      });
    });

    return compressed;
  };

  const shrinkNodes = (nodes) => {
    let shrinked = nodes;
    forEach(nodes, (node) => {
      if (!isEmpty(node.children)
        && node.children.length > 1
        && !node.children.every((child) => shrinked.some((comp) => comp?.value === child?.value))) {
        shrinked = shrinked.filter((comp) => node?.value !== comp?.value);
      }
    });
    return shrinked;
  };

  const getOriginalNode = (key) => {
    let originalNodes;
    options[0].children.every((item) => {
      const allNodes = getChildByNode(item);
      originalNodes = allNodes.filter((n) => n.value === key);
      if (!isEmpty(originalNodes)) {
        return false;
      }
      return true;
    });
    return originalNodes[0];
  };

  const handleSingleMode = (checked, node) => {
    let nodes = [];
    nodes = checked ? [node] : [];
    setNodesSelected(nodes);
    setKeysSelected(map(nodes, 'value'));
    setNodesCompressed(nodes);

    setValue(name, nodes[0]?.value);
    updateForm({ value: nodes[0]?.value });
  };

  const handleMultipleMode = (checked, node) => {
    let nodes = [];
    const hasSingleChild = !isEmpty(node?.children) && node.children.length === 1;

    const children = hasSingleChild ? [node] : getChildByNode(node);
    nodes = checked
      ? [...nodesSelected, ...children]
      : nodesSelected.filter((nodeSelected) => !children.find((child) => {
        return child.value === nodeSelected.value;
      }));

    nodes = checked
      ? expandNodeToParents(nodes)
      : shrinkNodes(nodes);

    setNodesSelected(nodes);
    setKeysSelected(map(nodes, 'value'));
    const compressed = compressNodes(nodes);
    setNodesCompressed(compressed);

    setValue(name, compressed.map((comp) => comp.value));
    updateForm({ value: nodes.map((nod) => nod.value) });
  };

  const handleOnChangeCheckbox = (checked, node) => {
    if (singleMode) {
      handleSingleMode(checked, node);
    } else {
      handleMultipleMode(checked, node);
    }
  };

  const isSelected = (node) => {
    if (!isEmpty(node.children) && node.children.length > 1) {
      return node.children.every((child) => keysSelected.some((keySelected) => keySelected === child.value));
    }
    return keysSelected.some((keySelected) => keySelected === node.value);
  };

  // const isPartialSelected = (node) => {
  //   if (isSelected(node)) {
  //     return false;
  //   }
  //   const allNodes = getChildByNode(node);
  //   const allChildKeys = map(allNodes, 'value');

  //   if (Array.isArray(allChildKeys)) {
  //     return keysSelected.some((key) => includes(allChildKeys, key));
  //   }
  //   return false;
  // };

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);
    // Node's label matches the search string Or a children has a matching node
    if (node.label.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
      || children.length) {
      filtered.push({ ...node, children });
    }
    return filtered;
  };

  const filterTree = (text) => {
    // Reset nodes back to unfiltered state
    if (!text || text === '') {
      setNodesFiltered(options);
      return;
    }
    setNodesFiltered(options.reduce(filterNodes, []));
  };

  const onFilterChange = (value) => {
    setFilterText(value);
    filterTree(value);
  };

  const TreeItemContent = forwardRef((props, ref) => {
    const {
      classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon,
    } = props;

    const {
      disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleExpansionClick = (event) => {
      handleExpansion(event);
      preventSelection(event);
    };

    const handleSelectionClick = (event) => {
      const node = getOriginalNode(nodeId);
      handleOnChangeCheckbox(!selected, node);
      handleSelection(event);
    };

    return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        ref={ref}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      </div>
    );
  });

  const CustomTreeItem = (props) => (
    <TreeItem ContentComponent={TreeItemContent} {...props} />
  );

  const renderTreeItems = (filteredNode) => {
    return (
      <CustomTreeItem
        label={singleMode ? (
          filteredNode.label
        ) : (
          <FormControlLabel
            control={(
              <Checkbox
                // indeterminate={singleMode ? false : isPartialSelected(getOriginalNode(filteredNode.value))}
                checked={isSelected(getOriginalNode(filteredNode.value))}
                onChange={(event) => handleOnChangeCheckbox(event.currentTarget.checked, getOriginalNode(filteredNode.value))}
                onClick={(e) => e.stopPropagation()}
              />
            )}
            label={filteredNode.label}
          />
        )}
        key={filteredNode.value}
        nodeId={filteredNode.value}
      >
        {Array.isArray(filteredNode.children) && !isEmpty(filteredNode.children)
          ? filteredNode.children.map((children) => renderTreeItems(children))
          : null}
      </CustomTreeItem>
    );
  };

  const getDefaultExpanded = useMemo(() => {
    let defaultExpanded = [];
    forEach(options[0].children, (item) => {
      const allNodes = getChildByNode(item);
      forEachRight(allNodes, (node) => {
        if (expandByField && node.expanded) {
          defaultExpanded = concat(defaultExpanded, node.value);
        }
        if (expandAll && !isEmpty(node.children)) {
          defaultExpanded = concat(defaultExpanded, node.value);
        }
      });
    });
    return defaultExpanded;
  }, []);

  const filterOptions = createFilterOptions({
    stringify: (node) => {
      const allLabels = map(getChildByNode(node), 'label');
      return join(allLabels, '');
    },
  });

  if (!isEmpty(options)) {
    assignPaths(options);
    assignExtendedLabels();
  }

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Controller
      render={({
        field, fieldState,
      }) => {
        const {
          onChange, onBlur, ref,
        } = field;
        return (
          <Autocomplete
            filterOptions={filterOptions}
            multiple
            disableClearable
            disabled={disableComponent}
            value={nodesCompressed}
            clearOnBlur={false}
            options={nodesFiltered}
            noOptionsText={`No ${componentlabel} found`}
            clearText="Clear"
            renderTags={() => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {singleMode ? (
                  // <Typography>{nodesCompressed[0].extendedLabel ? nodesCompressed[0].extendedLabel : nodesCompressed[0].label}</Typography>
                    <Chip
                      color="primary"
                      className={chipClasses.chip}
                      key={nodesCompressed[0].extendedLabel ? nodesCompressed[0].extendedLabel : nodesCompressed[0].label}
                      label={nodesCompressed[0].extendedLabel ? nodesCompressed[0].extendedLabel : nodesCompressed[0].label}
                      onDelete={() => handleOnChangeCheckbox(false, nodesCompressed[0])}
                      variant="outlined"
                    />
                  ) : nodesCompressed.map((node) => (
                    <Chip
                      color="primary"
                      className={chipClasses.chip}
                      key={node.value}
                      label={node.extendedLabel ? node.extendedLabel : node.label}
                      onDelete={() => handleOnChangeCheckbox(false, node)}
                      variant="outlined"
                    />
                  ))}
                </Box>
              );
            }}
            renderOption={(props, principal) => {
              return (
                <TreeView
                  defaultSelected={keysSelected}
                  multiSelect
                  aria-label="customized"
                  defaultExpanded={getDefaultExpanded}
                  defaultCollapseIcon={<KeyboardArrowDownIcon />}
                  defaultExpandIcon={<KeyboardArrowUpIcon />}
                  selected={keysSelected}
                >
                  {principal.children.map((node) => renderTreeItems(node))}
                </TreeView>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={componentlabel}
                  helperText={fieldState.error?.message || helperText}
                  placeholder=""
                  value={filterText}
                  required={required}
                  onChange={(e) => {
                    onFilterChange(e.target.value);
                    return onChange(e);
                  }}
                  error={fieldState.error}
                  ref={ref}
                  onBlur={(e) => {
                    return onBlur(e);
                  }}
                />
              );
            }}
          />
        );
      }}
      onChange={([, data]) => {
        return data;
      }}
      onBlur={([, data]) => {
        return data;
      }}
      name={name}
      control={control}
    />

  );
};

export default CustomSelectCheckboxTree;
