import { createAsyncThunk } from '@reduxjs/toolkit';

import { set } from 'lodash';

import { MerchantCreate } from '../../../api';

export const postAddMIDThunk = createAsyncThunk('merchants/addMID', async ({ body }, { rejectWithValue }) => {
  try {
    const response = await MerchantCreate(body);

    return response.data;
  } catch (e) {
    const { data, status, statusText } = e.response;
    const { error = {} } = data;
    return rejectWithValue({ ...error, status, statusText });
  }
});

// try {

//   const { data, status, statusText } = await client.get('/users');

//   return { ...data, status, statusText };

// } catch (err) {

//   const { data, status, statusText } = err.response;

//   return rejectWithValue({ ...data, status, statusText });

// }

// });

export const postAddMIDReducers = {
  [postAddMIDThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'result',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
    set(
      state,
      'error',
      null,
    );
  },

  [postAddMIDThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },

  [postAddMIDThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'error',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },

};
