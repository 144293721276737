/* eslint-disable no-underscore-dangle */
import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
// import { Snackbar, Alert } from '@mui/material';
import { get } from 'lodash';
import useAuth from '../hooks/useAuth';
import { useLogout } from '../hooks/useLogout';

// const notification = (open, close, message, severity) => {
//   const handleClose = () => close(false);
//   return (
//     <Snackbar
//       open={open}
//       onClose={handleClose}
//       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//     >
//       <Alert severity={severity}>{message}</Alert>
//     </Snackbar>
//   );
// };

const SessionTimeout = () => {
  // eslint-disable-next-line no-unused-vars
  const [events, setEvents] = useState(['click', 'load', 'scroll']);
  // eslint-disable-next-line no-unused-vars
  const [warning, setWarning] = useState(false);
  const forceRedirectToProfile = useSelector((state) => get(state, 'loginReducer.login.forceRedirectToProfile', false));
  const {
    isAuthenticated,
  } = useAuth();
  const logout = useLogout();

  let timeStamp;
  const warningInactiveInterval = useRef();
  const startTimerInterval = useRef();

  // warning timer
  const warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const expiredTimeMinutes = 25;
      const warningTimeMinutes = 22;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minutesPast = diff._data.minutes;

      if (minutesPast >= warningTimeMinutes) {
        setWarning(true);
      }

      if (minutesPast >= expiredTimeMinutes) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem('lastTimeStamp');
        logout('Your session has expired', { variant: 'warning', forceRedirectToProfile });
      }
    }, 1000);
  };

  // start inactive check
  const timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      const storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 35900);
  };

  // reset interval timer
  const resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }
    timeChecker();
  }, [isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  return null;
  // return notification(warning, setWarning, 'Your session has expired. Logged out due to inactivity.', 'warning');
};

export default SessionTimeout;
