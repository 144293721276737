import { YupValidations } from '../../../shared/validations';
import BankContact, { FormFields } from './BankContact';

const ValidationSchema = ({ validate = false } = {}) => {
  const schema = {
    [FormFields.BANK_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.FIRST_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.LAST_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.PHONE]: validate ? YupValidations.requiredPhone : YupValidations.phone,
    [FormFields.EMAIL]: validate ? YupValidations.requiredEmail : YupValidations.email,
  };
  return schema;
};

const defaultValue = {
  [FormFields.BANK_NAME]: '',
  [FormFields.FIRST_NAME]: '',
  [FormFields.LAST_NAME]: '',
  [FormFields.PHONE]: '',
  [FormFields.EMAIL]: '',
};

export {
  ValidationSchema,
  FormFields,
  defaultValue,
};

export default BankContact;
