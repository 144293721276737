import { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/login/AuthGuard';
import AddAccount from './containers/Accounts/AddAccount';
import Accounts from './containers/Accounts/ViewAccounts';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import RenewPassword from './containers/RenewPassword';
import FundingGroups from './containers/Funding Groups/FundingGroups';
import FundingGroupMembers from './containers/Funding Groups/FundingGroupMembers';
import FundingGroupSettings from './containers/Funding Groups/FundingGroupSettings';
import SecondFactor from './containers/SecondFactor';
import MIDOverview from './components/merchants/MIDOverview';
import RequiresPermission from './components/login/RequiresPermission';
import RedirectWrapper from './components/login/RedirectWrapper';
import BulkEditView from './containers/BulkEdit/BulkEditView';
import { ACTIONS, RESOURCES } from './IAM/configuration';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Dashboard = Loadable(lazy(() => import('./components/dashboard/DashboardLayout')));
const Home = Loadable(lazy(() => import('./containers/Home')));
const NotFound = Loadable(lazy(() => import('./containers/NotFound')));
// Users
const Users = Loadable(lazy(() => import('./containers/Users')));
const UserDetails = Loadable(lazy(() => import('./components/users/overview/UserDetails')));
const AddUser = Loadable(lazy(() => import('./components/users/add/AddUser')));
const AccountDetail = Loadable(lazy(() => import('./containers/Accounts/AccountDetail')));
const AddMID = Loadable(lazy(() => import('./components/accounts/mids/add/AddMID')));
const Chargebacks = Loadable(lazy(() => import('./containers/Chargebacks')));
const ACHReturns = Loadable(lazy(() => import('./containers/ACHReturns')));
// PayoutDetails
const SearchQuery = Loadable(lazy(() => import('./containers/SearchQuery')));
const Reports = Loadable(lazy(() => import('./containers/Reports')));

const routes = [
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/renew-password',
    element: <RenewPassword />,
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <RedirectWrapper path="/profile">
            <Home />
          </RedirectWrapper>),
      },
      {
        path: '2fa',
        element: (
          <AuthGuard>
            <SecondFactor />
          </AuthGuard>
        ),
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: <RequiresPermission permission={[RESOURCES.USERS, ACTIONS.LIST]}><Users /></RequiresPermission>,
          },
          {
            path: 'root',
            element: <NotFound />,
          },
          {
            path: ':id',
            element: <RequiresPermission selfException permission={[RESOURCES.USERS, ACTIONS.READ]}><UserDetails /></RequiresPermission>,
          },
          {
            path: 'add',
            element: <RequiresPermission permission={[RESOURCES.USERS, ACTIONS.CREATE]}><AddUser /></RequiresPermission>,
          },
        ],
      },
      {
        path: 'profile',
        element: <UserDetails ownProfile />,
      },
      {
        path: 'transactions',
        children: [
          {
            path: 'search-query',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <SearchQuery />
                  </RequiresPermission>
                ),
              },
              {
                path: ':transactionId',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.READ]}>
                    <SearchQuery />
                  </RequiresPermission>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: 'daily-payment-volume',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="line" chartId="daily-payments" chartName="Daily Payment Volume" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'card-sales',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="pie" chartId="card-sales" chartName="Card Sales" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'card-brand',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="bar" chartId="card-brand" chartName="Card Brand" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'card-authorizations',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="bar" chartId="card-authorizations" chartName="Card Authorizations" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'total-card-refunds',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="line" chartId="total-card-refunds" chartName="Total Card Refunds" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'total-card-sales',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="bar" chartId="total-card-sales" chartName="Total Card Sales" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'ach-returns',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="bar" chartId="ach-returns" chartName="ACH Returns" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'ach-sales',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="pie" chartId="ach-sales" chartName="ACH Sales" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'total-ach-sales',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="bar" chartId="total-ach-sales" chartName="Total ACH Sales Amount" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'total-ach-returns',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="line" chartId="total-ach-returns" chartName="Total ACH Returns" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'chargebacks-disputes',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="bar" chartId="chargebacks-disputes" chartName="Chargebacks/Disputes" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'alternative-payments',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports
                      chartType="bar"
                      chartId="alternative-payments"
                      chartName="Alternative Payments"
                      chartConfig={{ label: 'label', value: 'number_of_transactions' }}
                    />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'suspected-frauds',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports
                      chartType="bar"
                      chartId="suspected-frauds"
                      chartName="Suspected Fraud"
                      chartConfig={{ label: 'gateway', value: 'amount' }}
                    />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'payout-details',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="table" chartId="fundingReport" chartName="Payout Details" convertDate="true" />
                  </RequiresPermission>
                ),
              },
            ],
          },
          {
            path: 'net-settlements',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.REPORTING_TRANSACTIONS, ACTIONS.LIST]}>
                    <Reports chartType="table" chartId="daily_settlements" chartName="Net Settlements" convertDate="true" />
                  </RequiresPermission>
                ),
              },
            ],
          },
        ],
      },

      // {
      //   path: 'payers',
      //   children: [
      //     {
      //       path: '/',
      //       element: <Payers />,
      //     },
      //     {
      //       path: '/edit',
      //       element: <PayersEdit />,
      //     },
      //   ],
      // },
      {
        path: 'Accounts',
        children: [
          {
            path: '',
            element: (
              <RequiresPermission permission={[RESOURCES.ACCOUNTS, ACTIONS.LIST]}>
                <Accounts />
              </RequiresPermission>
            ),
          },
          {
            path: 'add',
            element: <RequiresPermission permission={[RESOURCES.ACCOUNTS, ACTIONS.CREATE]}><AddAccount /></RequiresPermission>,
          },
          {
            path: ':accountID',
            children: [
              {
                path: '',
                element: (
                  <RequiresPermission permission={[RESOURCES.ACCOUNTS, ACTIONS.READ]}>
                    <AccountDetail />
                  </RequiresPermission>
                ),
              },
              {
                path: 'add-merchant',
                element: (
                  <RequiresPermission permission={[RESOURCES.MERCHANTS, ACTIONS.CREATE]}>
                    <AddMID />
                  </RequiresPermission>
                ),
              },
              {
                path: 'merchants',
                children: [
                  {
                    path: ':merchantID',
                    element: (
                      <RequiresPermission permission={[RESOURCES.MERCHANTS, ACTIONS.READ]}>
                        <MIDOverview />
                      </RequiresPermission>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'administration',
        children: [
          {
            path: 'bulk-edit',
            element: (
              <RequiresPermission permission={[RESOURCES.MERCHANTS, ACTIONS.LIST, ACTIONS.UPDATE]}>
                <BulkEditView />
              </RequiresPermission>
            ),
          },
        ],
      },
      {
        path: 'services',
        children: [
          {
            path: 'chargebacks-disputes',
            children: [
              {
                path: '',
                element: <RequiresPermission permission={[RESOURCES.PAYERS, ACTIONS.LIST]}><Chargebacks /></RequiresPermission>,
              },
              {
                path: ':chargeBackId',
                element: <RequiresPermission permission={[RESOURCES.PAYERS, ACTIONS.READ]}><Chargebacks /></RequiresPermission>,
              },
            ],
          },
          {
            path: 'ach-returns',
            children: [
              {
                path: '',
                element: <RequiresPermission permission={[RESOURCES.PAYERS, ACTIONS.LIST]}><ACHReturns /></RequiresPermission>,
              },
              {
                path: ':ACHReturnId',
                element: <RequiresPermission permission={[RESOURCES.PAYERS, ACTIONS.READ]}><ACHReturns /></RequiresPermission>,
              },
            ],
          },
        ],
      },
      {
        path: 'payment-processing',
        children: [
          {
            path: 'funding-groups',
            element: <FundingGroups />,
          },
          {
            path: 'funding-groups/:id/members',
            element: <FundingGroupMembers />,
          },
          {
            path: 'funding-groups/:id/settings',
            element: <FundingGroupSettings />,
          },
          {
            path: 'funding-groups/settings',
            element: <FundingGroupSettings />,
          },
          // {
          //   path: '/credits-without-debits',
          //   element: <Debits />,
          // },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
