import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { client } from '../../../api';

export const addFundingGroupThunk = createAsyncThunk('fundingGroups.addFundingGroup', async (payload, { rejectWithValue }) => {
  const URL = '/funding';
  try {
    const { data, status, statusText } = await client.post(URL, payload);
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const addFundingGroupReducers = {
  [addFundingGroupThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'isLoading',
      false,
    );
    set(
      state,
      'addingFundingGroup',
      payload,
    );
  },
  [addFundingGroupThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },
  [addFundingGroupThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'isLoading',
      false,
    );
    set(
      state,
      'addingFundingGroup',
      payload,
    );
  },
};
