import React, { useEffect } from 'react';
import {
  Grid, Typography, Box, DialogContentText, Button, CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMutation,
} from 'react-query';
import { get, isEmpty } from 'lodash';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { YupValidations } from '../../shared/validations';
import { UserUpdatePassword } from '../../api';

import {
  closeRenewPasswordDialog,
  renewPasswordState,
} from '../../redux/slices/users';
import UpdatePasswordForm from './UpdatePasswordForm';
import { useLogout } from '../../hooks/useLogout';
import Dialog from '../shared/Dialogs/Dialog';
import { DialogError } from '../shared/Dialogs/DialogError';

export const RenewPasswordDialog = () => {
  const dispatcher = useDispatch();
  const logout = useLogout();
  const updatePasswordRequest = useMutation(UserUpdatePassword);
  const { isOpen, userId } = useSelector((state) => get(state, renewPasswordState, {}));
  const loadingState = updatePasswordRequest.isLoading;
  const confirmState = (!loadingState && !updatePasswordRequest.isSuccess && isEmpty(updatePasswordRequest.error));
  const successState = updatePasswordRequest.isSuccess;
  const errorState = !isEmpty(updatePasswordRequest.error);

  const { control, getValues, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        currentPassword: YupValidations.password,
        newPassword: YupValidations.password,
        confirmPassword: YupValidations.confirmPassword('newPassword'),
      }),
    ),
  });

  const onSubmit = () => {
    const payload = getValues();
    updatePasswordRequest.mutate({ userId, ...payload });
  };

  const handleClose = (success) => () => {
    closeRenewPasswordDialog()(dispatcher);
    if (success) {
      logout('You\'ve been logged out.');
    }
  };

  const renderContent = () => {
    return (
      <>
        <Grid item textAlign="initial" xs={12}>
          <Box sx={{ mb: 1, mt: -1 }}>
            <Typography
              color="textSecondary"
              sx={{ mt: 1 }}
              variant="body2"
            >
              Please enter a new valid password.
            </Typography>
          </Box>
          <UpdatePasswordForm
            control={control}
          />
        </Grid>
      </>
    );
  };

  const renderSuccess = () => (
    <>
      <Grid item textAlign="center">
        <Grid item textAlign="center" xs={12}>
          <CheckCircleIcon color="primary" sx={{ fontSize: '80px' }} />
        </Grid>
        <DialogContentText sx={{ typography: 'h6' }}>
          Password changed successfully.
        </DialogContentText>
        <DialogContentText>
          For your security, you will be logged out.
        </DialogContentText>
      </Grid>
    </>
  );

  const renderError = () => {
    return <DialogError error={updatePasswordRequest.error} />;
  };

  const renderLoading = () => {
    return (
      <>
        <Grid item textAlign="center">
          <Grid item textAlign="center" xs={12}>
            <PendingIcon color="primary" sx={{ fontSize: '80px', mb: 1 }} />
          </Grid>
          <DialogContentText sx={{ typography: 'h6', mb: 3 }}>
            Your request is being processed...
          </DialogContentText>
          <Grid item textAlign="center" xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderFooter = () => {
    return (
      (confirmState || errorState) ? (
        <>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}
            onClick={errorState ? updatePasswordRequest.reset : handleClose(false)}
          >
            { errorState ? 'Back' : 'Cancel' }
          </Button>
          <Button
            color="primary"
            disabled={loadingState}
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            { errorState ? 'retry' : 'Save changes' }
          </Button>
        </>
      ) : (
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={handleClose(successState)}
        >
          OK
        </Button>
      )
    );
  };

  useEffect(() => {
    if (isOpen) {
      updatePasswordRequest.reset();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      title={<Typography variant="h5">Change Password</Typography>}
      fullWidth
      maxWidth="xs"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(successState)();
        }
      }}
      actionSection={renderFooter()}
      aria-labelledby="reset-password-dialog-title"
      aria-describedby="reset-password-dialog-description"
    >
      {confirmState && renderContent()}
      {loadingState && renderLoading()}
      {successState && renderSuccess()}
      {errorState && renderError()}
    </Dialog>
  );
};
