import {
  Grid,
} from '@mui/material';
import { ControlledSelectDropdown } from '../../forms/SelectDropdown';
import TextField from '../../forms/TextField';
import {
  NON_INTEGRATED_OPTIONS,
  INTEGRATED_OPTIONS,
} from './options';
import {
  FormFields,
} from './Schema';

function PaymentIntegrationForm({ control }) {
  return (
    <Grid
      container
      spacing={1}
      sx={{ py: 1 }}
    >
      <Grid item xs={12} sm={6}>
        <ControlledSelectDropdown
          control={control}
          name={FormFields.INTEGRATED_APPS}
          label="Integrated Apps"
          options={INTEGRATED_OPTIONS}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledSelectDropdown
          control={control}
          name={FormFields.NON_INTEGRATED}
          label="Non-integrated Apps"
          options={NON_INTEGRATED_OPTIONS}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          control={control}
          name={FormFields.APPLICATION_VERSION}
          label="Application version"
          required
        />
      </Grid>
    </Grid>
  );
}

export default PaymentIntegrationForm;
