import { YupValidations } from '../../../shared/validations';
import ContactInfo, { FormFields } from './ContactInfo';

const defaultValue = {
  [FormFields.FIRST_NAME]: '',
  [FormFields.LAST_NAME]: '',
  [FormFields.TITLE]: '',
  [FormFields.EMAIL]: '',
  [FormFields.CONFIRM_EMAIL]: '',
  [FormFields.PHONE]: '',
  [FormFields.MOBILE]: '',
};

const ValidationSchema = ({ validate, hideTitle = false }) => {
  const schema = {
    [FormFields.FIRST_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.LAST_NAME]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.EMAIL]: validate ? YupValidations.requiredEmail : YupValidations.email,
    [FormFields.CONFIRM_EMAIL]: YupValidations.confirmEmailField('email'),
    [FormFields.MOBILE]: YupValidations.phone,
    [FormFields.PHONE]: validate ? YupValidations.requiredPhone : YupValidations.phone,
  };
  if (!hideTitle) {
    schema[FormFields.TITLE] = validate ? YupValidations.requiredString : YupValidations.string;
  }
  return schema;
};

export {
  ValidationSchema,
  defaultValue,
  FormFields,
};

export default ContactInfo;
