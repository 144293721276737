import omit from 'lodash/omit';
import client from '../httpClient';
import ErrorFactory from '../../shared/errors';

export const AccountUpdate = async ({ id, editedAccount }) => client.patch(
  `/accounts/${id}`,
  {
    account: editedAccount,
  },
);

export const EditRequestBySection = async (payload) => {
  try {
    const data = await client.patch(
      `/accounts/${payload?.id}`,
      {
        account: {
          [payload.field]: payload[payload.field].reduce((acc, cur) => {
            if (cur?.firstName) {
              acc.push(omit(cur, ['DeletedAt']));
            }
            return acc;
          }, []),
        },
      },
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
};
