import isEmpty from 'lodash/isEmpty';
import client from '../httpClient';
import ErrorFactory from '../../shared/errors';

const toOptions = (items) => {
  return items.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.name || '',
    };
  });
};

export const GetMerchants = async (params) => client.get('/merchants', {
  params,
});

export async function MerchantListRequest(params) {
  try {
    if (params?.requireAccount && !params?.accountId?.length) {
      return [];
    }
    const response = await GetMerchants(params);
    return toOptions(response?.data?.merchants || []);
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export async function getMerchantById({ id }) {
  if (id) return client.get(`/merchants/${id}`);
  return {};
}

export async function MerchantListRequestById(params) {
  try {
    const { data } = await getMerchantById(params);
    return data?.merchant;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

const makeFilters = (filtersSelected) => {
  let error = false;
  return {
    filters: filtersSelected
      ?.map((filter) => {
        if (isEmpty(filter.filter) || isEmpty(filter.operator) || isEmpty(filter.value)) error = true;
        return new URLSearchParams({
          filter: JSON.stringify({
            field: filter.filter,
            op: filter.operator,
            value:
              (filter.filter === 'accountID' || filter.filter === 'accountId') ? filter.value[0] : filter.value,
          }),
        }).toString();
      })
      .join('&'),
    error,
  };
};

export const MerchantFilterRequest = async ({ filtersSelected, ...params }) => {
  try {
    if (isEmpty(filtersSelected)) return [];
    const { filters, error } = makeFilters(filtersSelected);
    if (error) return [];
    const { data } = await client.get(`/merchants?${filters}`, {
      params,
    });
    return data;
  } catch (e) {
    throw ErrorFactory(e);
  }
};

export const MerchantFiltersTotal = async ({ filtersSelected, ...params }) => {
  try {
    // to avoid send the request  if no tender types are selected, those will come always as first
    if (isEmpty(filtersSelected) || !filtersSelected?.[0]?.value) return {};
    const { filters, error } = makeFilters(filtersSelected);
    if (error) return {};
    const { data } = await client.get(`/merchants/total?${filters}`, {
      params,
    });
    return data;
  } catch (e) {
    throw ErrorFactory(e);
  }
};
