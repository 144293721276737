/* eslint react/forbid-prop-types: 0 */
/* eslint-disable no-console */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MSPDataGrid } from '../../DataGrid';

const LIMIT_OPTIONS = [10, 25, 50, 100];
const DEFAULT_LIMIT = 50;
const DEFAULT_PAGE = 1;

const getValidLimit = (limit) => {
  const isInvalidLimit = !LIMIT_OPTIONS.includes(limit);
  return isInvalidLimit
    ? DEFAULT_LIMIT
    : limit;
};

const getValidPage = (page) => {
  return page > 0
    ? page
    : DEFAULT_PAGE;
};

const APIDataGrid = ({
  src, dataProp = 'data', onFetch, refreshTriggers = [], loading, quickFilterOptions = {
    showQuickFilter: false, filterPlaceholder: '', filterErrorMessage: '', noResultsMessage: '',
  }, ...rest
}) => {
  const {
    [dataProp]: tableData,
    totalCount = 0,
    page = DEFAULT_PAGE,
    limit = DEFAULT_LIMIT,
  } = src;

  useEffect(() => {
    onFetch({
      refreshTriggers, limit: getValidLimit(limit), page: 0,
    });
  }, refreshTriggers);

  const refreshSet = (changes) => {
    onFetch({
      refreshTriggers, limit: getValidLimit(changes.limit), page: changes.page,
    });
  };

  const handlePageChange = (change) => {
    if (!loading) { refreshSet({ limit, page: change + 1 }); }
  };

  const handleLimitChange = (change) => {
    refreshSet({ limit: change, page: 0 });
  };

  return (
    <MSPDataGrid
      {...rest}
      // disabling while discussing end-2-end implementation
      disableColumnFilter
      tableData={tableData}
      rowCount={totalCount}
      pagination
      loading={loading}
      paginationMode="server"
      page={getValidPage(page) - 1}
      pageSize={getValidLimit(limit)}
      onPageChange={handlePageChange}
      onPageSizeChange={handleLimitChange}
      rowsPerPageOptions={LIMIT_OPTIONS}
      quickFilterOptions={quickFilterOptions}
    />
  );
};

// This is a conventional component
// the src structure is based on the redux structure
// in order to ease its use
APIDataGrid.propTypes = {
  src: PropTypes.shape({
    totalCount: PropTypes.number,
    page: PropTypes.number,
    limit: PropTypes.number, // || PropTypes.oneOf(LIMIT_OPTIONS),
  }).isRequired,
  dataProp: PropTypes.string,
  onFetch: PropTypes.func.isRequired,
  refreshTriggers: PropTypes.arrayOf(PropTypes?.any),
};

export default APIDataGrid;
