import ErrorFactory from '../../../shared/errors';
import client from '../../httpClient';

export const SetTenderStatusRequest = ({ merchantId, tenderId, status }) => {
  try {
    return client.post(
      `/merchants/${merchantId}/tenders/${tenderId}/status`,
      { status },
    );
  } catch (err) {
    throw ErrorFactory(err);
  }
};
