import {
  useEffect,
  useMemo,
  useState,
  cloneElement,
  useCallback,
} from 'react';

import {
  Divider,
  Typography,
  Box,
  List,
  ListItemText,
  IconButton,
  ListItemButton,
  Button,
  createTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  cloneDeep,
  filter,
  get,
  isArray,
  isEmpty,
  isEqual,
  isNumber,
  map,
  set,
} from 'lodash';
import { createStyled } from '@mui/system';
import InputText from '../../forms/InputText';
import usePrevious from '../../../hooks/usePrevious';
import { ReactComponent as NoRecordsIcon } from '../../../icons/NoRecords.svg';
import { svgColors } from '../../../shared/styles';

const defaultTheme = createTheme();
const styled = createStyled({ defaultTheme });

export const useEntityManager = ({
  getValues, onUpdate, onReset, entities = [], onDelete,
}) => {
  return {
    onSaveNew: () => {
      const newEntity = getValues();
      onUpdate([...entities || [], newEntity]);
    },
    onDelete: (entity) => (
      onDelete(
        filter(
          entities,
          (e) => !isEqual(e, entity),
        ),
      )
    ),
    onSelected: (entityIndex) => {
      onReset(entities[entityIndex]);
    },
    onSaveEdit: (entityIndex) => {
      const updatedEntities = cloneDeep(entities);
      set(updatedEntities, entityIndex, { ...getValues() });
      onUpdate([...updatedEntities]);
    },
  };
};

export const generateManageFormProps = (formProps) => {
  const {
    formKey,
    errors,
    trigger,
    getValues,
    setValue,
    defaultValue = {},
    clearErrors,
    reset,
  } = formProps;
  return {
    ...formProps,
    trigger: async () => trigger(formKey),
    errors: get(errors, formKey, {}),
    getValues: () => {
      if (formKey) { return get(getValues(), formKey, {}); }
      return getValues();
    },
    setValues: (entity) => {
      if (formKey) {
        setValue(formKey, entity);
        setTimeout(() => trigger(formKey), 200);
      } else {
        reset(entity);
        setTimeout(() => trigger(), 200);
      }
    },
    setValue: (name, value, options = {}) => {
      if (formKey) {
        setValue(`${formKey}.${name}`, value);
        setTimeout(() => trigger(`${formKey}.${name}`), 200);
      } else {
        setValue(name, value, { ...options });
        trigger(name);
      }
    },
    clearErrors: () => {
      if (formKey) return clearErrors(formKey);
      return clearErrors();
    },
    reset: () => {
      if (formKey) {
        return setValue(formKey, defaultValue);
      } return reset(defaultValue);
    },
  };
};

export const useManageStandardFormProps = ({
  savedEntities,
  setSavedEntities,
  reset,
  trigger,
  getValues,
  setValues,
  clearErrors = () => {},
}) => {
  /* Create new entity & clean up form */
  const onSaveNew = async (newEntityData) => {
    const noErrors = await trigger();
    if (noErrors) {
      let newEntity = newEntityData;
      if (!newEntity) {
        newEntity = getValues();
      }
      setSavedEntities([...savedEntities, newEntity]);
      reset();
      setTimeout(() => {
        clearErrors();
      }, 500);
    }
  };

  /* Delete entity */
  const onDelete = (entity) => (
    setSavedEntities(
      filter(
        savedEntities,
        (e) => !isEqual(e, entity),
      ),
    ));

  /* Update form */
  const onSelected = (entityIndex) => setValues(savedEntities.at(entityIndex));

  /* Update entity */
  const onSaveEdit = (entityIndex) => {
    const updatedEntities = cloneDeep(savedEntities);
    set(updatedEntities, entityIndex, { ...getValues() });
    setSavedEntities([...updatedEntities]);
    reset();
  };

  return {
    sidebarList: savedEntities,
    onSaveNew,
    onDelete,
    onSelected,
    onSaveEdit,
  };
};

export const MODES = {
  ADD: 'add',
  EDIT: 'edit',
};

const StyledEmptyEntitiesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(244, 245, 247, 0.38)',
  height: '200px',
  ...svgColors({ theme }),
}));

export const ManageEntities = ({
  initialMode = MODES.ADD,
  description,
  sidebarHeader,
  sidebarList,
  mainHeader,
  disableSave = false,
  headerOnEdit,
  children,
  maxHeight,
  onSaveNew = () => {},
  onSaveEdit = () => {},
  getEntityListAttributes = () => {},
  onDelete = () => {},
  onSelected = () => {},
  contentReset = () => {},
  onModeChange = () => {},
  dataTestId,
}) => {
  const HEADER_SX = { ml: 1, fontWeight: 'bold' };
  const DIVIDER_SX = { mb: 1 };
  const DEFAULT_MAX_HEIGHT = maxHeight || 300;
  const [mode, setMode] = useState(initialMode);
  const previousMode = usePrevious(mode);
  const [filteredEntities, setFilteredEntities] = useState(sidebarList);
  const [headerText, setHeaderText] = useState(mainHeader);
  const [searchValue, setSearchValue] = useState('');
  const [selectedEntityIndex, setSelectedEntityIndex] = useState(null);
  const shouldEnableSearch = sidebarList?.length > 1;
  const SCROLLABLE_CONTENT_SX = { maxHeight: DEFAULT_MAX_HEIGHT, overflow: 'auto' };
  const SIDEBAR_CONTENT_SX = {
    ...SCROLLABLE_CONTENT_SX,
    maxHeight: {
      xs: 200,
      sm: 200,
      md: DEFAULT_MAX_HEIGHT,
    },
  };

  useEffect(() => {
    const nothingChanged = !previousMode || mode === previousMode;

    if (nothingChanged) return;

    if (mode === MODES.ADD) {
      contentReset();
      setHeaderText(mainHeader);
      setSelectedEntityIndex(null);
    }

    if (mode === MODES.EDIT) {
      setHeaderText(headerOnEdit);
    }

    onModeChange(mode);
  }, [headerOnEdit, mode, previousMode]);

  useEffect(() => {
    if (isNumber(selectedEntityIndex)) {
      setMode(MODES.EDIT);
      onSelected(selectedEntityIndex);
    }
  }, [selectedEntityIndex]);

  useEffect(() => {
    // reset when not showing input search
    if (!shouldEnableSearch) {
      setFilteredEntities(sidebarList);
      setSearchValue('');
    }
  }, [shouldEnableSearch]);

  const handleToAddMode = () => {
    setMode(MODES.ADD);
  };

  const handleSearchInputChange = (_, value) => {
    setSearchValue(value);
  };

  const MainButton = useCallback(({ styles, size = 'medium' }) => (
    <Button
      size={size}
      color="primary"
      variant="contained"
      onClick={() => {
        if (mode === MODES.ADD) {
          onSaveNew();
        } else if (mode === MODES.EDIT) {
          onSaveEdit(selectedEntityIndex);
          setMode(MODES.ADD);
        }
      }}
      sx={styles}
      disabled={disableSave}
      data-testid={`${dataTestId || ''}Entity${mode}Btn`}
    >
      {mode === MODES.ADD ? 'Add' : 'Update'}
    </Button>
  ), [mode, selectedEntityIndex, disableSave, dataTestId]);

  const SecondaryButton = useCallback(({ styles, size = 'medium' }) => (
    mode !== MODES.ADD && (
    <Button
      color="primary"
      variant="contained"
      onClick={handleToAddMode}
      size={size}
      sx={{ ...styles, p: 0.5, mr: 1 }}
      data-testid={`${dataTestId}HandleToAddModeBtn`}
    >
      Clear
    </Button>
    )
  ), [mode, dataTestId]);

  useEffect(() => {
    const filtered = filter(
      sidebarList,
      (entity) => {
        const { title = '', subtitle = '' } = getEntityListAttributes(entity);
        return title.concat(subtitle).toLowerCase().includes(searchValue.toLowerCase());
      },
    );
    setFilteredEntities(filtered);
  }, [searchValue, sidebarList]);

  const sidebarContent = useMemo(() => {
    if (!filteredEntities || !isArray(filteredEntities)) {
      return null;
    }
    return (
      <>
        {isEmpty(filteredEntities) ? (
          <StyledEmptyEntitiesContainer>
            <NoRecordsIcon />
            <Typography sx={{ mt: 1 }}>No entities were found</Typography>
          </StyledEmptyEntitiesContainer>
        ) : (
          <List>
            {map(filteredEntities, (e, i) => {
              const { title = '', subtitle = '' } = getEntityListAttributes(e);
              return (
                <ListItemButton
                  divider
                  key={title.concat(subtitle).concat(i)}
                  selected={selectedEntityIndex === i}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (e?.readOnly) return;
                    setSelectedEntityIndex(i);
                  }}
                  data-testid={`${dataTestId}EntityListItem`}
                >
                  {!e?.readOnly
              && (
              <IconButton
                edge="start"
                aria-label="delete"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (selectedEntityIndex === i) {
                    setMode(MODES.ADD);
                  }
                  return onDelete(e);
                }}
              >
                <DeleteIcon />
              </IconButton>
              )}
                  <ListItemText data-testid={`${dataTestId}ListTitle`} primary={title} secondary={subtitle} />
                </ListItemButton>
              );
            })}
          </List>
        )}
      </>
    );
  }, [filteredEntities, selectedEntityIndex]);

  return (
    <>
      <Box sx={{ mb: 2, ml: 1 }}>{description}</Box>
      <Divider sx={{ ...DIVIDER_SX, mb: 2 }} />
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridAutoFlow: 'row',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr',
            md: '0.7fr 0.01fr 0.3fr',
          },
          minHeight: DEFAULT_MAX_HEIGHT,
        }}
      >
        <Box
          sx={{
            ml: 0,
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '0.1fr 1fr',
            borderBottom: { xs: '1px solid rgba(0, 0, 0, 0.20)', md: 'none' },
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mb: { md: 2 },
          }}
          >
            <Typography sx={{ ...HEADER_SX, ml: 1 }}>{headerText}</Typography>
            <Box sx={{
              display: 'flex',
              margin: 'auto',
              marginRight: '0',
            }}
            >
              <SecondaryButton
                styles={{
                  mt: 0, display: { md: 'none', sm: 'block' },
                }}
                size="small"
              />
              <MainButton styles={{ margin: 'auto', marginRight: '0', display: { md: 'none', sm: 'block' } }} size="small" />
            </Box>
            <Divider sx={{ ...DIVIDER_SX }} />
          </Box>
          <Box sx={{ ...SCROLLABLE_CONTENT_SX, mr: 0, my: 2 }}>
            {cloneElement(children, { mode, sx: { py: 0 } })}
          </Box>
          <Box sx={{
            verticalAlign: 'bottom',
            justifySelf: 'end',
            display: 'flex',
          }}
          >
            <SecondaryButton styles={{ display: { md: 'block', sm: 'none', xs: 'none' } }} />
            <MainButton styles={{ display: { md: 'block', sm: 'none', xs: 'none' } }} />
          </Box>
        </Box>
        <Divider
          variation="vertical"
          sx={{ borderRightWidth: 0.5, borderBottomWidth: 0, display: { md: 'block', sm: 'none', xs: 'none' } }}
        />
        <Box
          sx={{
            ml: 0,
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '0.1fr 1fr',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ ...HEADER_SX }}>{sidebarHeader}</Typography>
            </Box>
            <Divider sx={{ ...DIVIDER_SX }} />
            <InputText
              name="entitySearch"
              label="Search"
              value={searchValue}
              size="small"
              sx={{ marginBottom: 2 }}
              onChange={handleSearchInputChange}
              disabled={!shouldEnableSearch}
              data-testid={`${dataTestId || ''}SearchInput`}
            />
          </Box>
          <Box sx={{ ...SIDEBAR_CONTENT_SX }}>
            {sidebarContent}
          </Box>
        </Box>
      </Box>
    </>
  );
};
