import { useSnackbar } from 'notistack';
import useAuth from './useAuth';
import './style.css';

export const useLogout = () => {
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  return (message = '', options = {}) => {
    const { variant = 'info', callback = () => {}, forceRedirectToProfile = true } = options;
    if (message) {
      enqueueSnackbar(message, { variant, callback });
    }
    logout({ forceRedirectToProfile });
    return callback();
  };
};
