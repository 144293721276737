import { useMemo } from 'react';

import {
  Typography,
  Badge as RawBadge,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { experimentalStyled } from '@mui/material/styles';

const BaseAccordion = experimentalStyled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = experimentalStyled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .02)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiBadge-root': {
    maxWidth: '95%',
  },
}));

const AccordionDetails = experimentalStyled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Badge = experimentalStyled(RawBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -14,
    top: 14,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

function AccordionTitle({ label, amountAlerts = 0 }) {
  const title = useMemo(() => <Typography color="textPrimary" variant="h6">{label}</Typography>, [label]);
  return amountAlerts ? (
    <Badge badgeContent={amountAlerts} color="secondary">
      {title}
    </Badge>
  ) : title;
}

function Accordion({
  children, expanded, label, amountAlerts = 0, onExpandedToggle,
}) {
  return (
    <BaseAccordion
      TransitionProps={{ timeout: 500 }}
      expanded={expanded}
      onChange={onExpandedToggle}
      disableGutters
      elevation={0}
      sx={{ padding: 0 }}
    >
      <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
        <AccordionTitle label={label} amountAlerts={amountAlerts} />
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </BaseAccordion>
  );
}

export default Accordion;
