import {
  Button, Box, Grid, Alert, AlertTitle,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { capitalize } from 'lodash';

export const RenewPasswordError = ({
  error, onClick, setOnRetry, isLoading,
}) => {
  const handleGoBack = () => setOnRetry(false);

  return (
    <Box>
      <Alert severity="error">
        <AlertTitle>
          <strong>Something went wrong</strong>
        </AlertTitle>
        {capitalize(error.message)}
      </Alert>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 3 }}
      >
        {
          !error.status && (
          <LoadingButton
            color="primary"
            loading={isLoading}
            onClick={onClick}
            type="submit"
            variant="contained"
            sx={{ mb: 1 }}
            fullWidth
          >
            Retry
          </LoadingButton>
          )
        }
        <Button
          onClick={handleGoBack}
          variant="outlined"
          fullWidth
        >
          Go Back
        </Button>
      </Grid>
    </Box>
  );
};
