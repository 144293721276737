import AddIcon from '@mui/icons-material/Add';

const menuActions = ({
  onAdd,
}) => {
  return [
    {
      label: 'Add Account',
      onClick: onAdd,
      icon: <AddIcon />,
      permissions: ['accounts.create'],
    },
  ];
};
export default menuActions;
