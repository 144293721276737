import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  get,
  keyBy,
  map,
  join,
  defaultTo,
  set,
  clone,
  capitalize,
  upperCase,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  Accordion,
  AccordionSummary,
  Box,
  Tabs,
  Tab,
  Button,
  Switch,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

import { MSPDataGrid } from '../../DataGrid';

import { RowTable } from '../../RowTable';
import { fetchFundingGroupById, resetUpdatedTenders, updateMIDTenders } from '../../../redux/slices/fundingGroups';

const paymentMethods = {
  cards: { name: 'Credit/Debit Card', value: 'cards' },
  ach: { name: 'ACH', value: 'ach' },
  amex_tpsp: { name: 'Amex TPSP', value: 'amex_tpsp' },
};

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '70%',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 3,
// };

const TenderTypes = ({ data, style }) => {
  const { id: fundingGroupID } = useParams();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('cards');
  const [membersModal, setMembersModal] = useState(false);
  const [confirmButton, setConfirmButton] = useState(true);
  // const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [midsPayload, setMidsPayload] = useState({});
  const isLoading = useSelector((state) => get(state, ['fundingGroupsReducer', 'isLoading'], false));
  const updatedTenders = useSelector((state) => get(state, ['fundingGroupsReducer', 'updatedTenders'], undefined));
  const successActivating = typeof updatedTenders === 'boolean' && updatedTenders;
  const failActivating = typeof updatedTenders === 'boolean' && !updatedTenders;
  const tenderTypes = keyBy(data.tenders, 'paymentMethodType');
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    resetUpdatedTenders()(dispatch);
  }, []);

  useEffect(() => {
    fetchFundingGroupById(fundingGroupID)(dispatch);
  }, [updatedTenders]);

  const notification = (open, close, message, severity) => {
    // const handleClose = () => close(false);
    return (
      <Snackbar
        open={open}
        autoHideDuration={300}
        onClose={() => {}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    );
  };

  const handleSwitchChange = (row, status) => {
    const { merchantID } = row;
    const currentMids = clone(midsPayload);
    set(currentMids, [merchantID], status);
    setMidsPayload(currentMids);
    setConfirmButton(false);
  };

  const handleModalClose = () => {
    setConfirmButton(true);
    resetUpdatedTenders()(dispatch);
    setMembersModal(false);
  };

  const handleConfirm = ({ id }) => () => {
    setTimeout(() => {
      updateMIDTenders({ tender: id, merchants: midsPayload })(dispatch);
      setConfirmButton(true);
    }, 100);
    setTimeout(() => {
      resetUpdatedTenders()(dispatch);
    }, 2000);
  };

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Merchant',
      flex: 2,
      renderCell: ({ formattedValue }) => formattedValue,
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Switch
            onChange={(e) => handleSwitchChange(row, e.target.checked)}
            defaultChecked={row.merchantTenders[currentTab]}
          />
        );
      },
    },
  ];

  const paymentMethodRow = {
    label: 'Payment Method',
    valueGetter: (row) => defaultTo(`${capitalize(paymentMethods[get(row, 'paymentMethodType', '-')]?.name)}`, ''),
  };

  const activeMembersRow = {
    label: 'Merchants',
    valueGetter: (row) => {
      return (
        <>
          <Button
            onClick={() => setMembersModal(true)}
            startIcon={<SettingsIcon />}
            style={{ width: 110, border: '1px solid #265da6', borderRadius: '8px' }}
          >
            Manage
          </Button>
          <Dialog
            open={membersModal}
            onClose={handleModalClose}
            maxWidth="lg"
          >
            <DialogContent>
              <Grid
                container
                direction="column"
                spacing={0}
                sx={{
                  minWidth: '480px',
                  minHeight: '150px',
                }}
              >
                <Grid
                  item
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >

                  <DialogActions>
                    <Typography
                      variant="title"
                      component="h2"
                      sx={{
                        mr: 'auto',
                      }}
                    >
                      {capitalize(paymentMethods[get(row, 'paymentMethodType', '-')].name)}
                    </Typography>
                    <LoadingButton
                      onClick={handleConfirm(row)}
                      variant="contained"
                      loading={isLoading}
                      color="primary"
                      disabled={confirmButton}
                    >
                      Confirm
                    </LoadingButton>
                    <Button
                      onClick={handleModalClose}
                      startIcon={<CloseIcon sx={{ marginLeft: '10px' }} />}
                      variant="contained"
                      color="secondary"
                      sx={{ height: '95%' }}
                    />
                  </DialogActions>
                </Grid>
                <Grid item>
                  <Alert sx={{ mt: 1 }} severity="info">Changes will trigger an e-mail notification to the MID Primary Contact</Alert>
                  <MSPDataGrid
                    cols={columns}
                    checkboxSelection={false}
                    getRowId={(r) => r.merchantID}
                    tableData={get(data, 'members', [])}
                  />
                </Grid>
              </Grid>
              {notification(successActivating, () => {}, 'Updated successfully!', 'success')}
              {notification(failActivating, () => {}, 'Something went wrong.', 'error')}
            </DialogContent>
          </Dialog>
        </>
      );
    },
  };

  const fundingSourceRow = {
    label: 'Funding Source',
    valueGetter: (row) => `${capitalize(get(row, 'fundingSource', '-'))}`,
  };

  const gatewayRow = {
    label: 'Gateway',
    valueGetter: (row) => `${upperCase(get(row, 'gateway', '-'))}`,
  };

  const processorRow = {
    label: 'Processor',
    valueGetter: (row) => `${upperCase(get(row, 'processor', '-'))}`,
  };

  const platformRow = {
    label: 'Platform',
    valueGetter: (row) => `${upperCase(get(row, 'platform', '-'))}`,
  };

  const convenienceFeesNetDepositRow = {
    label: 'Convenience Fees Net Deposit',
    valueGetter: (row) => `${capitalize(get(row, 'convenienceFeesNetDeposit', '-'))}`,
  };

  const rows = {
    cards: [
      paymentMethodRow,
      activeMembersRow,
      {
        label: 'Accepted Cards',
        valueGetter: (row) => {
          const cards = get(row, 'acceptedCards', '-');
          const acceptedCards = [];
          map(cards, (value, key) => {
            if (value) acceptedCards.push(capitalize(key));
          });
          return `${join(acceptedCards, ', ')}`;
        },
      },
      {
        label: 'Card Funding Type',
        valueGetter: (row) => `${capitalize(get(row, 'cardFundingType', '-'))}`,
      },
      {
        label: 'Card Funding Delay',
        valueGetter: (row) => `${get(row, 'cardFundingDelay', '-')}`,
      },
      fundingSourceRow,
      gatewayRow,
      processorRow,
      platformRow,
      convenienceFeesNetDepositRow,
    ],
    ach: [
      paymentMethodRow,
      activeMembersRow,
      {
        label: 'ACH Funding Type',
        valueGetter: (row) => `${capitalize(get(row, 'achFundingType', '-'))}`,
      },
      {
        label: 'ACH Funding Delay',
        valueGetter: (row) => `${get(row, 'achFundingDelay', '-')}`,
      },
      fundingSourceRow,
      gatewayRow,
      processorRow,
      platformRow,
      convenienceFeesNetDepositRow,
    ],
    amex_tpsp: [
      paymentMethodRow,
      activeMembersRow,
      {
        label: 'AmexTPSP Funding Type',
        valueGetter: (row) => `${capitalize(get(row, 'amexTpspFundingType', '-'))}`,
      },
      {
        label: 'AmexTPSP Funding Delay',
        valueGetter: (row) => `${get(row, 'amexTpspFundingDelay', '-')}`,
      },
      fundingSourceRow,
      gatewayRow,
      processorRow,
      platformRow,
      convenienceFeesNetDepositRow,
    ],
  };

  return (
    <Accordion disableGutters style={style}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
      >
        <Typography variant="h6" style={{ fontSize: '130%' }}>Tender Types</Typography>
      </AccordionSummary>
      <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {map(paymentMethods, (tab) => (
            <Tab
              key={tab.value}
              label={tab.name}
              value={tab.value}
              disabled={!tenderTypes[tab.value]}
            />
          ))}
        </Tabs>
      </Box>
      {RowTable({
        data: tenderTypes[currentTab],
        labelWidth: '20%',
        rows: rows[currentTab],
      })}
    </Accordion>
  );
};

export default TenderTypes;
