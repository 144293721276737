import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import Autocomplete from '../../../components/forms/Autocomplete';
import { operatorOptions, filterTypeOptions } from './FilterOptions';

export const FiltersSection = ({
  filter,
  filterOptions,
  onRemove,
  onChange,
  render,
}) => {
  const [nameToCheck, setNameToCheck] = useState('');
  const [dirty, setDirty] = useState({
    filter: false,
    value: false,
    operator: false,
  });
  const [errors, setErrors] = useState({
    filter: !filter.filter,
    value: !filter.value,
    operator: !filter.operator,
  });

  const validate = () => {
    const newErrors = {};
    const validationExpression = (key) => isEmpty(filter[key]);
    Object.keys(errors).forEach((key) => {
      newErrors[key] = Boolean(validationExpression(key));
    });
    setErrors(newErrors);
    onChange({ id: filter.id, prop: 'error', value: Object.values(newErrors).some(Boolean) });
  };

  const handleChange = (name, value) => {
    onChange({ id: filter.id, prop: name, value });
    setDirty({ ...dirty, [name]: true });
  };

  const handleBlur = (name) => {
    setNameToCheck(name);
  };

  const handleRemove = () => {
    onRemove(filter.id);
  };

  useEffect(() => {
    validate();
  }, [filter.value, filter.operator, filter.filter]);

  useEffect(() => {
    if (nameToCheck) {
      validate();
      setDirty({ ...dirty, [nameToCheck]: true });
      setNameToCheck('');
    }
  }, [nameToCheck]);

  return (
    <Grid container spacing={2} sx={{ mb: 1, justifyContent: 'center' }}>
      <Grid item xs={4}>
        <Autocomplete
          name="filter"
          error={dirty.filter && errors.filter && { message: 'Required' }}
          value={filter.filter}
          options={filterTypeOptions}
          filterOptions={filterOptions}
          onChange={handleChange}
          onBlur={() => handleBlur('filter')}
          label="Filter type"
          required
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          error={
            dirty.operator && errors.operator && {
              message: 'Required',
            }
          }
          value={filter.operator}
          options={operatorOptions}
          label="Operator"
          required
          name="operator"
          onChange={handleChange}
          onBlur={() => handleBlur('operator')}
        />
      </Grid>
      <Grid item xs={4}>
        {render({
          name: 'value',
          onChange: handleChange,
          onBlur: () => handleBlur('value'),
          helperText: dirty.value && errors?.value && 'Required',
          error: dirty.value && errors?.value && { message: 'Required' },
          required: true,
          value: filter.value,
        })}
      </Grid>
      <Grid item>
        <IconButton
          edge="center"
          aria-label="delete"
          onClick={handleRemove}
          sx={{ mt: 2 }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
