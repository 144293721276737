import {
  reduce, find, omitBy, get, isObject, isNil,
} from 'lodash';
import {
  BankingFieldsPerType,
  BankTypeValuePerFormField,
} from '../../../components/merchants/tenders/FormFields';

export const useAddTenderRequest = (raw, accountTypes) => {
  const {
    serviceFeetype,
    serviceFeeFixedAmount,
    serviceFeePercentage,
    ...payload
  } = raw;

  const buildBankingSettings = () => {
    const { paymentMethodType } = raw;
    const bankTypes = BankingFieldsPerType[paymentMethodType];
    return reduce(
      bankTypes,
      (acc, cur) => {
        const valueToFind = BankTypeValuePerFormField[cur];
        const bankType = find(
          accountTypes,
          ({ value = '' }) => value === valueToFind,
        );
        return [
          ...acc,
          {
            accountTypeID: bankType?.id,
            ...get(raw, cur, {}),
          },
        ];
      },
      [],
    );
  };
  return {
    ...omitBy(payload, (prop) => isObject(prop) || isNil(prop)),
    paymentMethodType: raw?.paymentMethodType,
    cardFundingType: raw?.cardFundingType,
    processor: raw?.processor,
    gateway: raw?.gateway,
    platform: 'CORE',
    fundingSource: raw?.fundingSource,
    user: raw?.user,
    password: raw?.password,
    merchantAccount: raw?.merchantAccount,
    merchantAccountCode: raw?.merchantAccountCode,
    convenienceFeesNetDeposit: true,
    combinedDeposit: true,
    achFundingDelay: Number(raw.achFundingDelay),
    cardFundingDelay: Number(raw.cardFundingDelay),
    amexTpspFundingDelay: Number(raw.amexTpspFundingDelay),
    serviceFee: {
      type: serviceFeetype,
      fixedAmount: (serviceFeeFixedAmount && ['fixed', 'combination'].includes(serviceFeetype)) ? Number(serviceFeeFixedAmount) : 0,
      percentage: (serviceFeePercentage && ['percentage', 'combination'].includes(serviceFeetype)) ? Number(serviceFeePercentage) : 0,
    },
    bankingSettings: buildBankingSettings(),
  };
};
