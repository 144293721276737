import { Divider, Grid, Typography } from '@mui/material';

function TitleSection({ children, sx = { mb: 2, mt: 1 } }) {
  return (
    <Grid item xs={12} sx={sx}>
      <Typography fontWeight="bold" variant="h6" sx={{ mb: 1 }}>
        { children }
      </Typography>
      <Divider />
    </Grid>
  );
}

export default TitleSection;
