import httpDeviceClient from '../../../../../api/httpDeviceClient';
import ErrorFactory from '../../../../../shared/errors';

async function UpdateDeviceRequest({ merchantId, deviceId, payload }) {
  try {
    const updatedDevice = {
      description: payload.description,
      label: payload.label,
      make: payload.make,
      model: payload.model,
      sdk_key_override: payload.sdk_key_override,
      serial: payload.serial,
      token_override: payload.token_override,
    };
    const data = await httpDeviceClient.put(
      `/merchant/${merchantId}/device/${deviceId}`,
      {
        ...updatedDevice,
      },
    );
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export default UpdateDeviceRequest;
