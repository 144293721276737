import client from '../httpClient';
import ErrorFactory from '../../shared/errors';
import { useGetAccounts } from '../../hooks/users/useGetAccounts';

export const UserUpdateSecurity = async ({ user, toggle }) => client.patch(`/users/${user.id}`, {
  security: {
    ...user?.security,
    multiFactor: {
      ...user?.security?.multiFactor,
      ...toggle,
    },
  },
});

export const UserUpdate = async ({ id, editedUser }) => client.patch(
  `/users/${id}`,
  {
    ...editedUser,
  },
);

export async function EditUserRequest({ id, payload, loggedUser }) {
  try {
    const {
      needsAccounts,
      getAccounts,
    } = useGetAccounts({
      role: payload?.role,
      accounts: payload?.accounts,
      merchants: payload?.merchants,
    });
    const editedUser = {
      username: payload.username,
      accounts: needsAccounts ? getAccounts() : [],
      role: payload.role,
      ...payload?.contactInformation,
      name: {
        first: payload?.contactInformation?.firstName,
        last: payload?.contactInformation?.lastName,
      },
      contact: {
        phone: payload?.contactInformation?.phone,
        email: payload?.contactInformation?.email,
        mobile: payload?.contactInformation?.mobile,
      },
      address: {
        address1: payload?.contactInformation?.address1,
        address2: payload?.contactInformation?.address2,
        city: payload?.contactInformation?.city,
        state: payload?.contactInformation?.state,
        country: payload?.contactInformation?.country,
        postalCode: payload?.contactInformation?.postalCode,
      },
    };
    const data = await UserUpdate({ id, editedUser });

    if (id === loggedUser?.id) {
      localStorage.setItem('loggedUser', JSON.stringify({ ...loggedUser, ...editedUser }));
    }
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export async function EditUserNotificationsRequest({ user, changes }) {
  try {
    const editedUser = {
      notifications: {
        ...user?.notifications,
        ...changes,
      },
    };
    const data = await UserUpdate({ id: user?.id, editedUser });
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}
