import {
  ValidationSchema as ContactSchema,
  defaultValue as contactDefault,
  FormFields as ContactFields,
} from '../ContactInfo';
import {
  ValidationSchema as AddressSchema,
  defaultValue as addressDefault,
  FormFields as AddressFields,
} from '../Address';
import { ValidationSchema as PersonalSchema, defaultValue as personalDefault, FormFields as PersonalInfoFields } from '../PersonalInfo';
import FullContactInformation from './FullContactInformation';

export const FormFields = {
  ...PersonalInfoFields,
  ...AddressFields,
  ...ContactFields,
};

export const ValidationSchema = ({ validate, withOwnership }) => ({
  ...ContactSchema({ validate }),
  ...AddressSchema({ validate }),
  ...PersonalSchema({ validate, withOwnership }),
});

export const defaultValue = {
  ...contactDefault,
  ...addressDefault,
  ...personalDefault,
};

export default FullContactInformation;
