import { React } from 'react';

import {
  Button, Dialog, DialogActions,
} from '@mui/material';
import { RowTable } from '../RowTable';

const TenderCredentials = ({
  data, open, onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {RowTable({
        data: {
          user: data?.user,
          password: data?.password,
          merchantAccount: data?.merchantAccount,
          merchantAccountCode: data?.merchantAccountCode,
          regKey: data?.regKey,
        },
        autoGenerateRows: true,
      })}
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TenderCredentials;
