import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { client } from '../../../api';

export const fetchAvailableMerchantsThunk = createAsyncThunk('fundingGroups.fetchMerchants', async (payload, { rejectWithValue }) => {
  const URL = `/merchants?excludeFGMember=true&accountID=${payload}`;
  try {
    const { data, status, statusText } = await client.get(URL);
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const fetchAvailableMerchantsReducers = {
  [fetchAvailableMerchantsThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'availableMerchants',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },
  [fetchAvailableMerchantsThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },
  [fetchAvailableMerchantsThunk.rejected]: (state) => {
    set(
      state,
      'isLoading',
      false,
    );
  },
};
