import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
  FormHelperText,
} from '@mui/material';

import ControlledComponent from '../../utils/ControlledComponent';

function RadioGroup({
  name, label, value, onChange, options, error, required, ...rest
}) {
  const handleChange = (_, v) => {
    onChange(name, v);
  };
  return (
    <FormControl component="fieldset" error={!!error} required={required} {...rest}>
      <FormLabel color={error ? 'error' : 'primary'}>
        <strong style={{ color: '#172b4d' }}>
          {label}
        </strong>
      </FormLabel>
      <MUIRadioGroup
        aria-label="answer"
        name={name}
        value={value}
        sx={{ ml: 1 }}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            checked={value === option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MUIRadioGroup>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}

export const ControlledRadioGroup = ControlledComponent(RadioGroup);

export default RadioGroup;
