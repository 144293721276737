import { createSlice } from '@reduxjs/toolkit';

import {
  fetchACHTransactionsThunk, ACHTransactionsReducers, fetchTransactionsThunk, transactionsReducers,
} from '../api/transactions';

const initialState = {
  ACH: {
    data: [],
  },
  list: {
    isLoading: false,
    response: [],
    error: {},
  },
};

const slice = createSlice({
  name: 'transactions',
  initialState,
  extraReducers: {
    ...ACHTransactionsReducers,
    ...transactionsReducers,
  },
});

export const { reducer } = slice;

export const fetchTransactions = (params) => (dispatch) => {
  dispatch(fetchTransactionsThunk(params));
};
export const getACHTransactions = () => (dispatch) => {
  dispatch(fetchACHTransactionsThunk());
};

export const getChargeBackTransactions = () => (dispatch) => {
  dispatch(fetchACHTransactionsThunk());
};

export const getDebitsTransactions = () => (dispatch) => {
  dispatch(fetchACHTransactionsThunk());
};

export default slice;
