import ErrorFactory from '../../../shared/errors';
import client from '../../httpClient';

export const SetTenderDeleteRequest = ({ merchantId, tenderId }) => {
  try {
    return client.delete(
      `/merchants/${merchantId}/tenders/${tenderId}`,
    );
  } catch (err) {
    throw ErrorFactory(err);
  }
};
