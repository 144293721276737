import { TextField } from '@mui/material';
import BaseInputMask from 'react-input-mask';

function InputMask({
  name, error, helperText, onChange, mask, value, ...rest
}) {
  const handleTextChange = (e) => {
    onChange(name, e?.target?.value);
  };
  return (
    <BaseInputMask
      {...rest}
      mask={mask}
      value={value || ''}
      onChange={handleTextChange}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          fullWidth
          error={!!error}
          helperText={error?.message || helperText}
        />
      )}
    </BaseInputMask>
  );
}

export default InputMask;
