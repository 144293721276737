import { useEffect, useState } from 'react';
import {
  find, first, get, reduce,
} from 'lodash';
import {
  Grid,
} from '@mui/material';
import Autocomplete from '../../../../../components/forms/Autocomplete';
import { AccountTypeNames, BankingAccountTypeValues } from '../../../../../shared/constants';

const CARD_OPTIONS = [
  {
    label: AccountTypeNames[BankingAccountTypeValues.CREDIT_DEPOSITS],
    value: {
      tenderType: 'card',
      type: BankingAccountTypeValues.CREDIT_DEPOSITS,
    },
  },
  {
    label: AccountTypeNames[BankingAccountTypeValues.CREDIT_CHARGEBACKS],
    value: {
      tenderType: 'card',
      type: BankingAccountTypeValues.CREDIT_CHARGEBACKS,
    },
  },
  {
    label: AccountTypeNames[BankingAccountTypeValues.DEBIT_DEPOSITS],
    value: {
      tenderType: 'card',
      type: BankingAccountTypeValues.DEBIT_DEPOSITS,
    },
  },
];

const ACH_OPTIONS = [
  {
    label: AccountTypeNames[BankingAccountTypeValues.ACH_RETURN_ACCOUNT],
    value: {
      tenderType: 'ach',
      type: BankingAccountTypeValues.ACH_RETURN_ACCOUNT,
    },
  },
  {
    label: AccountTypeNames[BankingAccountTypeValues.ACH_CHARGEBACK_ACCOUNT],
    value: {
      tenderType: 'ach',
      type: BankingAccountTypeValues.ACH_RETURN_ACCOUNT,
    },
  },
];

const AMEX_OPTIONS = [
  {
    label: AccountTypeNames[BankingAccountTypeValues.AMEX_DDA_ACCOUNT],
    value: {
      tenderType: 'amex_tpsp',
      type: BankingAccountTypeValues.AMEX_DDA_ACCOUNT,
    },
  },
  {
    label: AccountTypeNames[BankingAccountTypeValues.AMEX_ROUTING_ACCOUNT],
    value: {
      tenderType: 'amex_tpsp',
      type: BankingAccountTypeValues.AMEX_ROUTING_ACCOUNT,
    },
  },
];

const OPTIONS = {
  card: CARD_OPTIONS,
  ach: ACH_OPTIONS,
  amex_tpsp: AMEX_OPTIONS,
};

const DEFAULT_FORM = {
  bankAccountType: null,
};

const getOptions = (tenderTypes) => {
  if (first(tenderTypes) === 'all') {
    return [
      ...CARD_OPTIONS,
      ...ACH_OPTIONS,
      ...AMEX_OPTIONS,
    ];
  }
  return reduce(tenderTypes, (acc, cur) => {
    const options = get(OPTIONS, cur, []);
    return [
      ...acc,
      ...options,
    ];
  }, []);
};

function SelectionStep({
  onChange, onValidate, initial = DEFAULT_FORM, meta,
}) {
  const [form, setForm] = useState(initial);
  const { filtersSelected = [] } = meta;
  const { value: tenderValues = '' } = (find(filtersSelected, ({ filter = '' }) => filter === 'tenderType') || {});
  const hasSeparatorsRegex = new RegExp('%2C|,', 'g');
  const tenderTypes = tenderValues.split(hasSeparatorsRegex);
  const handleChange = (name, value) => {
    const changes = {
      ...form,
      [name]: value,
    };
    setForm(changes);
    onChange(changes);
  };

  const validate = (data) => {
    const validationResult = {};
    if (!data?.bankAccountType) {
      validationResult.required = 'Must select at least one item to edit';
    }
    return validationResult;
  };

  useEffect(() => {
    const validationResult = validate(form);
    onValidate(validationResult);
  }, [form]);

  return (
    <Grid item xs={12}>
      <Autocomplete
        name="bankAccountType"
        label="Select a bank account type to modify"
        placeholder="Select a bank account type"
        options={getOptions(tenderTypes)}
        onChange={handleChange}
        value={form.bankAccountType}
      />
    </Grid>
  );
}

export default SelectionStep;
