export const merchantServicesAPI = {
  URL: process.env.REACT_APP_MS_URL || 'http://localhost:8080',
};

export const deviceServicesAPI = {
  URL: process.env.REACT_APP_DS_URL || 'http://localhost:8085',
  DEFAULT_DOMAIN: 'saasconexterminal.net',
};

// Possibly use in the future with payout details and others
export const corePortalAPI = {
  URL: process.env.REACT_APP_CORE_URL || 'http://localhost:8086',
};

export const coreSupportURL = 'https://www.corebt.com/support';
