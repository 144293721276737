/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  get, set, remove, sortBy, toNumber, reduce, forEach, keys,
} from 'lodash';
import { GetAccount, AccountListRequest } from '../../api';
import { fetchAccountsReducers, fetchAccountsThunk } from '../api/accounts/fetchAccounts';

export const accountsReducer = 'accountsReducer';
export const addAccountState = `${accountsReducer}.add`;
export const listAccountsState = `${accountsReducer}.list`;

export const initialState = {
  add: {
    formStep: 'orgInfo',
    prevFormStep: '',
    formErrors: {},
    stepErrors: {},
    controlSignerPOW: 0,
    controlAsBO: false,
    isResetDialogOpen: false,
    request: {
      exchangeID: null,
      orgInfo: {
        partnerID: '',
        parentID: null,
        orgType: 'ga',
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        ticker: '',
        tickerType: 'NYSE',
      },
      applicantContact: {
        firstName: '',
        lastName: '',
        professionalTitle: '',
        email: '',
        confirmEmail: '',
        phone: '',
        mobile: '',
      },
      beneficialOwner: {
        saved: [],
        form: {
          firstName: '',
          lastName: '',
          professionalTitle: '',
          email: '',
          confirmEmail: '',
          phone: '',
          mobile: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
          birthDate: '',
          driversLicense: '',
          driversLicenseState: '',
          SSN: '',
          POW: '',
        },
      },
      controlSigner: {
        firstName: '',
        lastName: '',
        professionalTitle: '',
        email: '',
        confirmEmail: '',
        phone: '',
        mobile: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        birthDate: '',
        driversLicense: '',
        driversLicenseState: '',
        SSN: '',
        POW: '',
      },
      itManager: {
        firstName: '',
        lastName: '',
        professionalTitle: '',
        email: '',
        confirmEmail: '',
        phone: '',
        mobile: '',
      },
      corporateContacts: {
        form: {
          firstName: '',
          lastName: '',
          professionalTitle: '',
          email: '',
          confirmEmail: '',
          phone: '',
          mobile: '',
        },
        saved: [],
        mode: 'add',
      },
      corporateAccount: {
        taxID: '',
        intacctID: '',
        legalBusinessName: '',
        typeOfOrganization: '',
        timeInOperation: '',
        ticker: '',
        tickerType: '',
        phone: '',
        chargebackFax: '',
        website: '',
        location: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        mailing: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      },
      primaryBank: {
        bankName: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      tradeReferences: {
        form: {
          companyName: '',
          firstName: '',
          lastName: '',
          phone: '',
          creditTerms: '',
        },
        saved: [],
      },
      comments: '',
    },
    response: {
      isDialogOpen: false,
      isSubmitting: false,
      error: null,
      result: null,
    },
  },
  list: {
    response: {},
    isLoading: false,
    error: null,
  },
  mids: {
    add: {
      merchantInfo: {
        midType: '',
      },
    },
  },
  currentTab: 'overview',
  isLoading: false,
  status: null,
  error: null,
  entities: {

  },
  map: {

  },
};

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    resetForm(state) {
      set(state, 'add', initialState.add);
    },
    resetGetAccount(state) {
      set(state, 'status', initialState.status);
      set(state, 'isLoading', initialState.isLoading);
      set(state, 'error', initialState.error);
    },
    updateFormStep(state, action) {
      const { payload } = action;
      set(state?.add, 'prevFormStep', get(state?.add, 'formStep', ''));
      set(state?.add, 'formStep', payload);
    },
    updateFormErrors(state, action) {
      const { payload } = action;
      set(state?.add, 'formErrors', payload);
    },
    storeFormErrors(state, action) {
      const { payload } = action;
      const { section, value } = payload;
      set(state?.add, `stepErrors.${section}`, value);
    },
    updateAddAccountForm(state, action) {
      const { payload } = action;
      const { path, value } = payload;
      set(state?.add?.request, path, value);
    },
    copyForms(state, action) {
      const { payload } = action;
      const { copySchema } = payload;
      forEach(keys(copySchema), (s) => {
        set(
          state?.add.request,
          [...copySchema[s].split('-')],
          get(
            state?.add.request,
            [...s.split('-')],
          ),
        );
      });
    },
    openAddRequestDialog(state) {
      set(state?.add?.response, 'isDialogOpen', true);
    },
    closeAddRequestDialog(state) {
      set(state?.add?.response, 'isDialogOpen', false);
    },
    openResetAddAccountDialog(state) {
      set(state?.add, 'isResetDialogOpen', true);
    },
    closeResetAddAccountDialog(state) {
      set(state?.add, 'isResetDialogOpen', false);
    },
    addSubmit(state) {
      set(state, 'add.response.isSubmitting', true);
      set(state, 'add.response.isDialogOpen', true);
    },
    setAddResult(state, action) {
      const { payload } = action;
      set(state?.add?.response, 'isSubmitting', false);
      set(state?.add?.response, 'error', null);
      set(state?.add?.response, 'result', payload);
    },
    setAddError(state, action) {
      const { payload } = action;
      set(state?.add?.response, 'isSubmitting', false);
      set(state?.add?.response, 'result', null);
      set(state?.add?.response, 'error', payload);
    },
    saveBeneficialOwner(state, action) {
      const { payload } = action;
      const boState = state?.add?.request?.beneficialOwner || {};
      const saved = boState?.saved || [];
      const newState = {
        form: {},
        saved: sortBy([...saved, payload], (o) => -toNumber(get(o, 'POW', '0'))),
      };
      state.add.request.beneficialOwner = newState;
    },
    saveControlSignerAsBeneficialOwner(state, action) {
      const { payload } = action;
      const boState = get(state?.add?.request, 'beneficialOwner', {});
      const saved = boState?.saved || [];
      const newState = {
        form: boState?.form || {},
        saved: sortBy([...saved, { ...payload, isCS: true }], (o) => -toNumber(get(o, 'POW', '0'))),
      };
      state.add.request.beneficialOwner = newState;
      state.add.controlAsBO = true;
      state.add.controlSignerPOW = toNumber(get(payload, 'POW', 0));
    },
    removeBeneficialOwner(state, action) {
      const { payload } = action;
      const { firstName = '', lastName = '' } = payload;
      const boState = state?.add?.request?.beneficialOwner || {};
      const saved = boState?.saved || [];
      remove(saved, { firstName, lastName });
      state.add.request.beneficialOwner.saved = saved;
    },
    removeControlSignerBeneficialOwner(state, action) {
      const { payload } = action;
      const { firstName = '', lastName = '' } = payload;
      const boState = state?.add?.request?.beneficialOwner || {};
      const saved = boState?.saved || [];
      remove(saved, { firstName, lastName });
      state.add.request.beneficialOwner.saved = saved;
      state.add.controlAsBO = false;
      state.add.controlSignerPOW = 0;
    },
    copyControlSignerToBeneficialOwner(state) {
      set(
        state,
        'add.request.beneficialOwner.form',
        get(state, 'add.request.controlSigner', {}),
      );
      set(
        state,
        'add.request.beneficialOwner.form.POW',
        100,
      );
    },
    updateControlSignerPOW(state, action) {
      const { payload } = action;
      const { POW } = payload;
      set(state, 'add.controlSignerPOW', POW);
    },
    resetControlAsBO(state) {
      set(
        state,
        'add.controlAsBO',
        false,
      );
    },
    receiveAccountList(state, action) {
      const { payload } = action;
      const { accounts } = payload;
      const normalizedPayload = {
        ...payload,
        accounts: reduce(
          accounts,
          (acc, curr) => {
            acc[curr?.id] = curr;
            return acc;
          },
          {},
        ),
      };
      set(
        state,
        'list.response',
        normalizedPayload,
      );
      set(
        state,
        'list.isLoading',
        false,
      );
    },
    failAccountList(state, action) {
      const { payload } = action;
      set(
        state,
        'list.error',
        payload,
      );
      set(
        state,
        'list.isLoading',
        false,
      );
    },
    requestAccount(state) {
      set(
        state,
        'isLoading',
        true,
      );
    },
    receiveAccount(state, action) {
      const { payload } = action;
      const { account } = payload;
      const { id = 'MISSING' } = account;
      set(
        state,
        `entities.${id}`,
        account,
      );
      set(
        state,
        'isLoading',
        false,
      );
      set(
        state,
        'status',
        payload?.status,
      );
    },
    failAccount(state, action) {
      const { payload } = action;
      set(
        state,
        'error',
        payload,
      );
      set(
        state,
        'isLoading',
        false,
      );
      set(
        state,
        'status',
        payload?.status,
      );
    },
    updateAccountTab(state, action) {
      const { payload } = action;
      set(state, 'currentTab', payload);
    },
    setAccountsMap(state, action) {
      const { payload } = action;
      set(state, 'map', { ...payload, hasError: false });
    },
    setAccountsMapError(state, _action) {
      set(state, 'map', { hasError: true });
    },
  },
  extraReducers: {
    ...fetchAccountsReducers,
  },
});

export const { reducer } = slice;

export const resetForm = () => (dispatch) => {
  dispatch(slice.actions.resetForm());
};

export const resetGetAccount = () => (dispatch) => {
  dispatch(slice.actions.resetGetAccount());
};

export const updateFormStep = (value) => (dispatch) => {
  dispatch(slice.actions.updateFormStep(value));
};

export const updateFormErrors = (value) => (dispatch) => {
  dispatch(slice.actions.updateFormErrors(value));
};

export const storeFormErrors = ({ section, value }) => (dispatch) => {
  dispatch(slice.actions.storeFormErrors({ section, value }));
};

export const updateAddAccountForm = ({ path, value }) => (dispatch) => {
  const changes = { path, value };
  dispatch(slice.actions.updateAddAccountForm(changes));
};

export const toggleCopyFromApplication = () => (dispatch) => {
  dispatch(slice.actions.toggleCopyFromApplication());
};

export const clickCopyMailingAddress = () => (dispatch) => {
  dispatch(slice.actions.clickCopyMailingAddress());
};

export const openAddAccountDialog = () => (dispatch) => {
  dispatch(slice.actions.openAddRequestDialog());
};

export const closeAddAccountDialog = () => (dispatch) => {
  dispatch(slice.actions.closeAddRequestDialog());
};

export const openResetAddAccountDialog = () => (dispatch) => {
  dispatch(slice.actions.openResetAddAccountDialog());
};

export const closeResetAddAccountDialog = () => (dispatch) => {
  dispatch(slice.actions.closeResetAddAccountDialog());
};

export const addSubmit = (isSubmitting) => (dispatch) => {
  dispatch(slice.actions.addSubmit(isSubmitting));
};

export const setAddResult = (result) => (dispatch) => {
  dispatch(slice.actions.setAddResult(result));
};

export const setAddError = (error) => (dispatch) => {
  dispatch(slice.actions.setAddError(error));
};
export const saveBeneficialOwner = (bo) => (dispatch) => {
  dispatch(slice.actions.saveBeneficialOwner(bo));
};

export const removeBeneficialOwner = (bo) => (dispatch) => {
  dispatch(slice.actions.removeBeneficialOwner(bo));
};

export const saveControlSignerAsBeneficialOwner = (bo) => (dispatch) => {
  dispatch(slice.actions.saveControlSignerAsBeneficialOwner(bo));
};

export const removeControlSignerBeneficialOwner = (bo) => (dispatch) => {
  dispatch(slice.actions.removeControlSignerBeneficialOwner(bo));
};

export const updateControlSignerPOW = (POW) => (dispatch) => {
  dispatch(slice.actions.updateControlSignerPOW({ POW }));
};

export const copyControlSignerToBeneficialOwner = (cs) => (dispatch) => {
  dispatch(slice.actions.copyControlSignerToBeneficialOwner(cs));
};

export const resetControlAsBO = () => (dispatch) => {
  dispatch(slice.actions.resetControlAsBO());
};

export const updateAccountTab = (value) => (dispatch) => {
  dispatch(slice.actions.updateAccountTab(value));
};

export const fetchAccountList = (params) => async (dispatch) => {
  dispatch(fetchAccountsThunk(params));
};
export const copyForms = ({
  id, copySchema, callback = () => {},
}) => (dispatch) => {
  dispatch(slice.actions.copyForms({ id, copySchema }));
  callback();
};

export const fetchAccount = ({ id }) => async (dispatch) => {
  dispatch(slice.actions.requestAccount());
  try {
    const { data, status, statusText } = await GetAccount({ id });
    dispatch(slice.actions.receiveAccount({ ...data, status, statusText }));
  } catch (e) {
    const { response: { data, status, statusText } } = e;
    dispatch(slice.actions.failAccount({ data, status, statusText }));
  }
};

export const fetchAccountsMap = () => async (dispatch) => {
  try {
    const data = await AccountListRequest({ queryKey: [] });
    const parsedData = data?.reduce((acc, cur) => {
      acc[cur.id] = cur.legalBusinessName;
      return acc;
    }, {});
    dispatch(slice.actions.setAccountsMap(parsedData));
  } catch (e) {
    dispatch(slice.actions.setAccountsMapError());
  }
};

export default slice;
