import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useQuery,
} from 'react-query';

import TreeDropdown from '../../forms/TreeDropdown';
import { AccountListRequest } from '../../../api';

const queryKey = 'accounts-list-selector';

const useAccountQuery = ({ enabled, onSuccess = () => null }) => {
  const accountQuery = useQuery([queryKey, { limit: 1000 }], AccountListRequest, {
    refetchOnWindowFocus: false, cacheTime: 300000, enabled, onSuccess,
  });
  return accountQuery;
};

function AccountsSelector({
  disable, dataTestId, ...rest
}) {
  const accountQuery = useAccountQuery({ enabled: true });
  return (
    <TreeDropdown
      {...rest}
      data={[{ id: 'root', legalBusinessName: 'root', children: accountQuery.data }]}
      loading={accountQuery.isLoading}
      labelProp="legalBusinessName"
      disabled={disable}
      dataTestId={dataTestId}
    />
  );
}

export function useGetAccountsFromId(id) {
  const [account, setAccount] = useState({ legalBusinessName: 'Loading...' });
  const accountQuery = useAccountQuery({
    enabled: false,
    onSuccess: (data) => {
      const findAccount = (items) => {
        // eslint-disable-next-line consistent-return, array-callback-return
        items.find((item) => {
          if (item.id === id[0]) {
            setAccount(item);
            return true;
          }
          if (item.children) {
            findAccount(item.children);
          }
        });
      };
      findAccount(data);
    },
  });

  useEffect(() => {
    if (id) {
      accountQuery.refetch();
    }
  }, [id]);
  return account;
}

AccountsSelector.propTypes = {
  disable: PropTypes.bool,
};

export default AccountsSelector;
