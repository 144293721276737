import {
  Grid,
  IconButton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';

function CopyInfoForm({
  children, onCopy, copyFrom, copyTo, dataTestId,
}) {
  const handleOnCopyClick = () => onCopy(copyFrom, copyTo);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ mb: '12px' }}>
        <IconButton
          size="small"
          sx={{ fontSize: 'medium', mt: -1, textAlign: 'left' }}
          onClick={handleOnCopyClick}
          data-testid={dataTestId}
        >
          <ContentCopyIcon />
          { children }
        </IconButton>
      </Grid>
    </Grid>
  );
}

CopyInfoForm.propTypes = {
  children: PropTypes.node.isRequired,
  onCopy: PropTypes.func.isRequired,
  copyFrom: PropTypes.string.isRequired,
  copyTo: PropTypes.string.isRequired,
};

export default CopyInfoForm;
