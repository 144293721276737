/* eslint-disable no-nested-ternary */
import { React, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  get, toUpper, capitalize,
} from 'lodash';
import {
  Visibility, Add, Remove, Delete, PointOfSale,
} from '@mui/icons-material';
import { Button, Card, IconButton } from '@mui/material';

import {
  fetchMIDDevices, listDevicesState, merchantsReducer,
} from '../../redux/slices/merchants';
import { AddDeviceModal } from '../accounts/mids/add/devices/AddDeviceModal';
import { DeviceDetailsModal } from '../accounts/mids/add/devices/DeviceDetailsModal';
import { clearStatus } from '../../redux/slices/users';
import { RemoveDeviceModal } from '../accounts/mids/add/devices/RemoveDeviceModal';
import { POSFormModal } from '../accounts/mids/add/devices/POSFormModal';
import { DateTimeFormat } from '../../utils/formatting/Dates';
import { SearchInput } from '../shared/SearchInput';
import APIDataGrid from '../shared/APIDataGrid';
import DeviceGatewaysRequest from '../accounts/mids/add/devices/DeviceGetwaysRequest';

export const MIDDevices = ({ merchant, user }) => {
  const dispatcher = useDispatch();
  const [search, setSearch] = useState('');
  const [deviceDetailsModal, setDeviceDetailsModal] = useState(false);
  const [deviceDetailsData, setDeviceDetailsData] = useState('');
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [addDeviceModal, setAddDeviceModal] = useState(false);
  const [removeDeviceModal, setRemoveDeviceModal] = useState(false);
  const [posFormModal, setPosFormModal] = useState(false);
  const devicesResponse = useSelector((state) => get(state, [merchantsReducer, 'devices'], {}));
  const isListLoading = useSelector((state) => get(state, `${listDevicesState}.isLoading`, false));

  const { merchantID } = useParams();
  const deviceGateways = useQuery(['device-gateway-list'], DeviceGatewaysRequest, { refetchOnWindowFocus: false });

  const handleGridFetch = ({ refreshTriggers, limit, page }) => {
    const [tsearch] = refreshTriggers;
    fetchMIDDevices({ limit, page, search: tsearch }, merchantID)(dispatcher);
  };

  const handleAllModalsClose = () => {
    if (merchantID) {
      dispatcher(fetchMIDDevices({ limit: '50', page: '0' }, merchantID));
    }
    clearStatus()(dispatcher);
    setAddDeviceModal(false);
    setDeviceDetailsModal(false);
    setRemoveDeviceModal(false);
  };

  const closeModalAndRemoveScripts = () => {
    if (merchantID) {
      dispatcher(fetchMIDDevices({ limit: '50', page: '0' }, merchantID));
    }
    clearStatus()(dispatcher);
    setPosFormModal(false);
    const posDeviceSdk = document.getElementById('pos-device-sdk-script');
    const posDeviceInit = document.getElementById('pos-service-sdk-init');
    if (posDeviceSdk) document.head.removeChild(posDeviceSdk);
    if (posDeviceInit) document.head.removeChild(posDeviceInit);
  };

  const cols = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'label',
      headerName: 'Label',
      headerAlign: 'left',
      flex: 1,
      valueGetter: (params) => capitalize(`${params.row?.label}`),
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      headerAlign: 'left',
      flex: 1,
      valueGetter: (params) => toUpper(`${params.row?.serial}`),
    },
    {
      field: 'make',
      headerName: 'Make',
      headerAlign: 'left',
      flex: 1,
      valueGetter: (params) => toUpper(`${params.row?.make}`),
    },
    {
      field: 'gateway',
      headerName: 'Gateway',
      headerAlign: 'left',
      flex: 1,
      hide: true,
      valueGetter: (params) => toUpper(`${params.row?.gateway}`),
    },
    {
      field: 'model',
      headerName: 'Model',
      headerAlign: 'left',
      flex: 1,
      valueGetter: (params) => toUpper(`${params.row?.model}`),
    },
    {
      field: 'addedOn',
      headerName: 'Added On',
      headerAlign: 'left',
      flex: 1,
      valueGetter: (params) => DateTimeFormat(params?.row?.CreatedAt),
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.4,
      align: 'center',
      headerAlign: 'left',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          color="primary"
          onClick={(e) => {
            setDeviceDetailsData(row);
            setDeviceDetailsModal(true);
            e.stopPropagation();
          }}
          size="large"
        >
          <Visibility />
        </IconButton>
      ),
    },
    {
      field: 'removeDevice',
      headerName: 'Remove',
      flex: 0.4,
      align: 'center',
      headerAlign: 'left',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          color="primary"
          onClick={(e) => {
            setDeviceDetailsData(row);
            setRemoveDeviceModal(true);
            e.stopPropagation();
          }}
          size="large"
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={() => setAddDeviceModal(true)}
        startIcon={<Add />}
        sx={{
          mr: 2,
        }}
      >
        Add
      </Button>
      <Button
        disabled={!selectedDeviceIds.length}
        variant="contained"
        onClick={() => setRemoveDeviceModal(true)}
        startIcon={<Remove />}
        sx={{
          mr: 2,
        }}
      >
        Remove
      </Button>
      <Button
        variant="contained"
        onClick={() => setPosFormModal(true)}
        startIcon={<PointOfSale />}
        sx={{
          mr: 2,
        }}
      >
        Build POS Form
      </Button>
      <SearchInput placeholder="Search devices" value={search} setValue={setSearch} />
      <Card sx={{ mt: 3 }}>
        <APIDataGrid
          cols={cols}
          src={devicesResponse}
          dataProp="devices"
          loading={isListLoading}
          getRowId={(r) => r.ID}
          refreshTriggers={[search]}
          onFetch={handleGridFetch}
          onSelectionModelChange={(ids) => {
            setSelectedDeviceIds(ids);
          }}
        />
        <AddDeviceModal
          isOpen={!!addDeviceModal}
          data={addDeviceModal}
          user={user}
          merchantId={merchantID}
          merchantName={merchant?.name}
          gateways={deviceGateways?.data}
          onClose={handleAllModalsClose}
        />
        <RemoveDeviceModal
          isOpen={!!removeDeviceModal}
          deviceIds={selectedDeviceIds.length ? selectedDeviceIds : deviceDetailsData.ID}
          merchantId={merchantID}
          onClose={handleAllModalsClose}
        />
        <DeviceDetailsModal
          isOpen={!!deviceDetailsModal}
          data={deviceDetailsData}
          merchantId={merchantID}
          deviceId={deviceDetailsData.ID}
          onClose={handleAllModalsClose}
        />
        <POSFormModal
          isOpen={!!posFormModal}
          merchantId={merchantID}
          deviceIds={selectedDeviceIds.length ? selectedDeviceIds : (deviceDetailsData.ID ? deviceDetailsData.ID : [])}
          onClose={closeModalAndRemoveScripts}
        />
      </Card>
    </>
  );
};
