import Dialog from '@mui/material/Dialog';
import {
  Grid,
  Paper,
  DialogTitle,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Draggable from 'react-draggable';
import POSForm from './POSForm';

export const POSFormModal = ({
  data,
  merchantId,
  deviceIds,
  isOpen,
  onClose,
}) => {
  const renderContent = () => {
    return (
      <>
        <Grid item textAlign="initial" xs={12}>
          <POSForm
            data={data}
            merchantId={merchantId}
            deviceIds={deviceIds}
            onClose={onClose}
          />
        </Grid>
      </>
    );
  };

  const PaperComponent = (props) => {
    return (
      <Draggable
        handle="#pos-form-modal-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper
          {...props}
        />
      </Draggable>
    );
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="pos-form-modal-title"
        aria-describedby="pos-form-modal-description"
        PaperComponent={PaperComponent}
        PaperProps={{
          sx: {
            minWidth: 1000,
          },
        }}
      >
        <DialogTitle
          id="pos-form-modal-title"
          style={{ cursor: 'move' }}
          sx={{
            typography: 'h4', color: 'textPrimary',
          }}
        >
          POS Form
        </DialogTitle>
        <DialogContent>
          {renderContent()}
        </DialogContent>
      </Dialog>
    </>
  );
};
