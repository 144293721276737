import httpClient from './httpClient';
import httpDeviceClient from './httpDeviceClient';

// experimental stuff
import coreHttpClient from './coreHttpClient';

// export api calls
export * from './Accounts';
export * from './ACHs';
export * from './ChargeBacks';
export * from './Merchants';
export * from './SystemConfig';
export * from './Transactions';
export * from './Users';
export * from './Reports';
// export * from './Payouts'; //UNDO THIS

export const client = {
  get: (url, config = {}) => httpClient.get(url, config),
  post: (url, data = {}, config = {}) => httpClient.post(url, data, config),
  put: (url, data = {}, config = {}) => httpClient.put(url, data, config),
  patch: (url, data, config = {}) => httpClient.patch(url, data, config),
  delete: (url, config = {}) => httpClient.delete(url, config),
  modifyHeaders: (headers) => {
    httpClient.defaults.headers = {
      ...httpClient.defaults.headers,
      ...headers,
    };
  },
};

// Setting almost every environment the same except the baseURL for coreClient. This will be changed as needed
export const coreClient = {
  get: (url, config = {}) => coreHttpClient.get(url, config),
  post: (url, data = {}, config = {}) => coreHttpClient.post(url, data, config),
  put: (url, data = {}, config = {}) => coreHttpClient.put(url, data, config),
  patch: (url, data, config = {}) => coreHttpClient.patch(url, data, config),
  delete: (url, config = {}) => coreHttpClient.delete(url, config),
  modifyHeaders: (headers) => {
    coreHttpClient.defaults.headers = {
      ...coreHttpClient.defaults.headers,
      ...headers,
    };
  },
};

export const deviceClient = {
  get: (url, config) => httpDeviceClient.get(url, config),
  post: (url, data = {}, config) => httpDeviceClient.post(url, data, config),
  put: (url, data = {}, config) => httpDeviceClient.put(url, data, config),
  patch: (url, data, config) => httpDeviceClient.patch(url, data, config),
  delete: (url, config) => httpDeviceClient.delete(url, config),
  modifyHeaders: (headers) => {
    httpClient.defaults.headers = {
      ...httpClient.defaults.headers,
      ...headers,
    };
  },
};
