import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Breadcrumbs as MUIBreadcrumbs,
  Link as MUILink,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '../../../icons/ChevronRight';
import { useIsMobileView } from '../../../hooks/useIsMobileView';

const Breadcrumb = ({
  onClick, redirect, render, bold,
}) => {
  const handleClick = () => {
    onClick(redirect);
  };

  const InnerContent = (typeof render === 'string')
    ? (
      <Typography
        fontWeight={bold ? 'bold' : 'normal'}
        color="textSecondary"
        variant="subtitle2"
      >
        {render}
      </Typography>
    ) : render;

  if (redirect) {
    return (
      <MUILink
        onClick={handleClick}
        underline="hover"
        color="inherit"
        href="#"
        sx={{
          textDecoration: 'underline',
          textUnderlineOffset: '1px',
        }}
      >
        {InnerContent}
      </MUILink>
    );
  }

  return InnerContent;
};

function Breadcrumbs({ items = [], ...rest }) {
  const mobile = useIsMobileView();
  const navigate = useNavigate();
  const handleOptionClick = (nextPath) => {
    if (nextPath) navigate(nextPath);
  };

  const breadcrumbsItems = useMemo(() => {
    const filteredItems = items.filter((item) => !item.hide);
    const result = filteredItems.map((item, index) => (
      <Breadcrumb
        key={item.id}
        onClick={handleOptionClick}
        {...item}
        bold={item.bold || index === filteredItems.length - 1}
      />
    ));
    return result;
  }, [items]);

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightIcon fontSize="small" sx={{ ml: -1, mr: -1 }} />}
      sx={{ mt: 1 }}
      {...rest}
      maxItems={!mobile ? rest?.maxItems || 5 : 1}
      itemsBeforeCollapse={0}
      itemsAfterCollapse={2}
    >
      {breadcrumbsItems}
    </MUIBreadcrumbs>
  );
}

Breadcrumb.propTypes = {
  redirect: PropTypes.string,
  bold: PropTypes.bool,
  render: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    hide: PropTypes.bool,
    ...Breadcrumb.propTypes,
  })),
};

export default Breadcrumbs;
