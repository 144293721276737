import { createAsyncThunk } from '@reduxjs/toolkit';

import { set } from 'lodash';

import { getMerchantById } from '../../../api';

export const getMIDThunk = createAsyncThunk('merchants/getMID', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await getMerchantById({ id });

    return response.data;
  } catch (e) {
    const { data, status, statusText } = e.response;
    const { error = {} } = data;
    return rejectWithValue({ ...error, status, statusText });
  }
});

export const getMIDReducers = {
  [getMIDThunk.fulfilled]: (state, { payload }) => {
    const { merchant = {} } = payload;
    const { id = 'UNMAPPED' } = merchant;
    set(
      state,
      ['entities', id],
      merchant,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },

  [getMIDThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },

  [getMIDThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'error',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },

};
