import { useEffect } from 'react';
import { get } from 'lodash';
import { useStore, useDispatch } from 'react-redux';
import {
  useMutation,
} from 'react-query';
import { AccountCreate } from '../../api/Accounts/Post';
import { resetAll, submitSuccess, submitError } from '../../redux/slices/persistedForms';

function useAddAccountRequest() {
  const store = useStore();
  const dispatcher = useDispatch();
  const {
    mutate, isError, isLoading, isSuccess, reset, error, data,
  } = useMutation(AccountCreate);
  const request = () => {
    const state = store.getState();
    const addAccount = get(state, 'persistedFormsReducer.addAccount', {});
    const payload = {
      orgInfo: addAccount.orgInfo.form,
      applicantContact: addAccount.applicantContact.form,
      itManagerInfo: addAccount.itManagerInfo.form,
      corporateContacts: addAccount.corporateContacts.savedEntities,
      corporateAccount: addAccount.corporateAccount.form,
      bankAccount: addAccount.bankAccount.form,
      tradeReferences: addAccount.tradeReferences.savedEntities,
      controlSigner: addAccount.controlSigner.form,
      beneficialOwners: addAccount.beneficialOwners.savedEntities,
      singleBeneficialOwner: addAccount.singleBeneficialOwner.form,
      comments: addAccount.comments.form,
    };
    mutate({ payload });
  };

  useEffect(() => {
    dispatcher(submitError({ sectionPath: 'addAccount' }));
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      dispatcher(submitSuccess({ sectionPath: 'addAccount' }));
      dispatcher(resetAll({ sectionPath: 'addAccount' }));
    }
  }, [isSuccess]);

  return {
    request,
    isError,
    isLoading,
    isSuccess,
    error,
    reset,
    result: data?.data,
  };
}

export default useAddAccountRequest;
