import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';

import { GetTransactions } from '../../../api';

export const fetchACHTransactionsThunk = createAsyncThunk('transactions/ACH', async (_, { rejectWithValue }) => {
  try {
    const response = await GetTransactions({ merchantId: '030a4479-437a-46f6-86ec-80b64b2e882d' });
    return response.data.transactions;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const ACHTransactionsReducers = {
  [fetchACHTransactionsThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'ACH.data',
      payload,
    );
    set(
      state,
      'ACH.isLoading',
      false,
    );
  },
  [fetchACHTransactionsThunk.pending]: (state) => {
    set(
      state,
      'ACH.isLoading',
      true,
    );
  },
  [fetchACHTransactionsThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'ACH.error',
      payload,
    );
    set(
      state,
      'ACH.isLoading',
      false,
    );
  },
};
