import ErrorFactory from '../../../shared/errors';
import client from '../../httpClient';

export const SetTenderTestModeRequest = ({ merchantId, tenderId }) => {
  try {
    return client.post(
      `/merchants/${merchantId}/tenders/${tenderId}/test`,
    );
  } catch (err) {
    throw ErrorFactory(err);
  }
};
