import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { experimentalStyled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography, Grid } from '@mui/material';

const Accordion = experimentalStyled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: '1px',
  },
}));

const AccordionSummary = experimentalStyled(MuiAccordionSummary)(() => ({
  height: '60px',
  verticalAlign: 'middle',
}));

const AccordionDetails = experimentalStyled(MuiAccordionDetails)(() => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 0,
}));

function AccordionTable({
  title, actionIcon, content, ...rest
}) {
  return (
    <Accordion
      TransitionProps={{ timeout: 500 }}
      disableGutters
      elevation={0}
      sx={{ padding: 0 }}
      {...rest}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: '1.5rem' }} />} sx={{ height: '100%' }}>
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          { actionIcon && <div style={{ marginRight: '8px' }}>{ actionIcon }</div>}
          {' '}
          <Typography variant="h6">{title}</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ overflow: 'auto' }}>
        {content}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionTable;
