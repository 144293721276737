import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { AccountFullListRequest } from '../../../api';

export const fetchAccountsThunk = createAsyncThunk('account/fetchAccounts', async (params, { rejectWithValue }) => {
  try {
    const { data, status, statusText } = await AccountFullListRequest(params);
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const fetchAccountsReducers = {
  [fetchAccountsThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'list.response',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
  [fetchAccountsThunk.pending]: (state) => {
    set(
      state,
      'list.isLoading',
      true,
    );
  },
  [fetchAccountsThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'list.error',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
};
