import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import PersistedSectionForm from '../../shared/PersistedSectionForm';
import { AccordionPersist } from '../../shared/Accordion/Accordion';

import BankContact, { FormFields, ValidationSchema, defaultValue } from '../../forms/BankContact';

export {
  ValidationSchema,
  FormFields,
};

const PrimaryBankContact = ({ formKey, control }) => {
  return (
    <>
      <Box
        display="grid"
      >
        <Typography color="textPrimary" variant="body1" sx={{ mb: 2 }}>
          Please provide the following details as they relate to your organization.
        </Typography>
        <Grid container spacing={1}>
          <BankContact control={control} name={formKey} />
        </Grid>
      </Box>
    </>
  );
};

const section = 'addAccount.bankAccount';

export default AccordionPersist({
  label: 'Primary Bank Contact',
  section,
  Component: PersistedSectionForm({
    section,
    Component: PrimaryBankContact,
    schema: ValidationSchema,
    defaultValues: defaultValue,
  }),
});
