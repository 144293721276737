import { v4 as uuidv4 } from 'uuid';
import { toNumber, get, filter } from 'lodash';
import client from '../httpClient';
import ErrorFactory from '../../shared/errors';
import { needsOwnership } from '../../utils/accountBusiness';

export const AccountCreate = async ({ payload = {} }) => {
  try {
    const {
      orgInfo = {},
      corporateAccount = {},
      applicantContact = {},
      controlSigner = {},
      singleBeneficialOwner = {},
      itManagerInfo = {},
      bankAccount = {},
      comments = {},
      tradeReferences = [],
      beneficialOwners = [],
      corporateContacts = [],
    } = payload;
    let organizationType = orgInfo.q1;

    if (orgInfo.q1 === 'other') {
      organizationType = orgInfo.q2;
      if (orgInfo.q2 === 'other') {
        organizationType = orgInfo.q3;
        if (orgInfo.q3 === 'other') {
          organizationType = orgInfo.q4;
        }
      }
    }

    const serverAccount = {
      account: {
        parentID: get(orgInfo, 'parentID[0]', null),
        organizationInfo: {
          location: {
            address1: corporateAccount.location?.address1,
            address2: corporateAccount.location?.address2,
            city: corporateAccount.location?.city,
            state: corporateAccount.location?.state,
            postalCode: corporateAccount.location?.postalCode,
            country: corporateAccount.location?.country,
          },
          mailing: {
            address1: corporateAccount.mailing?.address1,
            address2: corporateAccount.mailing?.address2,
            city: corporateAccount.mailing?.city,
            state: corporateAccount.mailing?.state,
            postalCode: corporateAccount.mailing?.postalCode,
            country: corporateAccount.mailing?.country,
          },
          taxID: corporateAccount.taxID,
          intacctCustomerID: corporateAccount.intacctCustomerID,
          legalBusinessName: corporateAccount.legalBusinessName,
          timeInOperation: corporateAccount.timeInOperation,
          phone: corporateAccount.phone,
          chargebackFax: corporateAccount.chargebackFax,
          website: corporateAccount.website,
          partnerID: null,
          parentID: orgInfo?.parentID,
          ticker: orgInfo.ticker,
          tickerType: orgInfo.tickerType,
          q1: orgInfo.q1,
          q2: orgInfo.q2,
          q3: orgInfo.q3,
          q4: orgInfo.q4,
          isSubsidiary: orgInfo.isSubsidiary,
          holdingCompany: orgInfo.holdingCompany,
          holdingTaxId: orgInfo.holdingTaxId,
          holdingOwnershipPercentage: toNumber(orgInfo.holdingOwnershipPercentage),
          organizationType,
        },
        applicantContact: {
          firstName: applicantContact.firstName,
          lastName: applicantContact.lastName,
          professionalTitle: applicantContact.professionalTitle,
          email: applicantContact.email,
          confirmEmail: applicantContact.confirmEmail,
          phone: applicantContact.phone,
          mobile: applicantContact.mobile,
        },
        controlSigner: {
          firstName: controlSigner.firstName,
          lastName: controlSigner.lastName,
          professionalTitle: controlSigner.professionalTitle,
          email: controlSigner.email,
          confirmEmail: controlSigner.confirmEmail,
          phone: controlSigner.phone,
          mobile: controlSigner.mobile,
          address1: controlSigner.address1,
          address2: controlSigner.address2,
          city: controlSigner.city,
          state: controlSigner.state,
          postalCode: controlSigner.postalCode,
          country: controlSigner.country,
          birthDate: controlSigner.dateOfBirth,
          driversLicense: controlSigner.driverLicense,
          driversLicenseState: controlSigner.driverLicenseState,
          SSN: controlSigner.ssn,
        },
        itManager: {
          firstName: itManagerInfo.firstName,
          lastName: itManagerInfo.lastName,
          professionalTitle: itManagerInfo.professionalTitle,
          email: itManagerInfo.email,
          confirmEmail: itManagerInfo.confirmEmail,
          phone: itManagerInfo.phone,
          mobile: itManagerInfo.mobile,
        },
        primaryBank: bankAccount?.bankName ? {
          firstName: bankAccount.firstName,
          lastName: bankAccount.lastName,
          bankName: bankAccount.bankName,
          phone: bankAccount.phone,
          email: bankAccount.email,
        } : undefined,
        beneficialOwners: beneficialOwners.map((bo = {}) => ({
          firstName: bo.firstName,
          lastName: bo.lastName,
          professionalTitle: bo.professionalTitle,
          email: bo.email,
          confirmEmail: bo.confirmEmail,
          phone: bo.phone,
          mobile: bo.mobile,
          address1: bo.address1,
          address2: bo.address2,
          city: bo.city,
          state: bo.state,
          postalCode: bo.postalCode,
          country: bo.country,
          birthDate: bo.dateOfBirth,
          driversLicense: bo.driverLicense,
          driversLicenseState: bo.driverLicenseState,
          SSN: bo.ssn,
          POW: toNumber(bo.ownershipPercentage),
        })),
        corporateContacts: corporateContacts.map((contact) => ({
          firstName: contact.firstName,
          lastName: contact.lastName,
          professionalTitle: contact.professionalTitle,
          email: contact.email,
          confirmEmail: contact.confirmEmail,
          phone: contact.phone,
          mobile: contact.mobile,
        })),
        tradeReferences: tradeReferences.map((reference) => ({
          companyName: reference.companyName,
          firstName: reference.firstName,
          lastName: reference.lastName,
          phone: reference.phone,
          creditTerms: reference.creditTerms,
        })),
        comments: comments.comments,
      },
      exchangeID: uuidv4(),
    };

    const {
      q1, tickerType, q2, q3,
    } = orgInfo;
    if (needsOwnership(q1, tickerType, q2, q3)) {
      serverAccount.account.controlSigner.POW = toNumber(controlSigner.ownershipPercentage);
    }

    if (singleBeneficialOwner.ownershipPercentage) {
      serverAccount.account.beneficialOwners = [
        singleBeneficialOwner,
      ];
    } else if (beneficialOwners.length > 0) {
      if (serverAccount.account.controlSigner.POW > 0) {
        serverAccount.account.beneficialOwners = [
          {
            ...serverAccount.account.controlSigner, isCS: true,
          },
          ...filter(serverAccount.account.beneficialOwners, (bo) => bo.email),
        ];
      }
    }
    const data = await client.post('/accounts', serverAccount);
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
};
