import {
  Grid,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ControlledInputText } from '../InputText';
import { ControlledInputMask } from '../InputMask';
import { ControlledPhoneInput } from '../PhoneInput';
import { ControlledSelectDropdown } from '../SelectDropdown';
import Address from '../Address';
import CopyInfoForm from '../../shared/CopyInfoForm';
import { TIME_IN_OPERATION_OPTIONS } from '../../../shared/constants';
import { prefixFormName } from '../../../utils/formatting/Form';

export const FormFields = {
  TAX_ID: 'taxID',
  INTACCT_ID: 'intacctCustomerID',
  LEGAL_BUSINESS_NAME: 'legalBusinessName',
  TIME_IN_OPERATION: 'timeInOperation',
  PHONE: 'phone',
  CHARGEBACK_FAX: 'chargebackFax',
  WEBSITE: 'website',
  LOCATION_ADDRESS: 'location',
  MAILING_ADDRESS: 'mailing',
};

function CorporateAccount({
  name,
  disabled = false,
  control,
  validate,
  onCopy,
}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <ControlledInputMask
          mask="99-9999999"
          control={control}
          disabled={disabled}
          name={prefixFormName(name, FormFields.TAX_ID)}
          data-testid="corpAccountFederalTaxId"
          label="Federal Tax ID"
          helperText="XX-XXXXXXX"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          control={control}
          disabled={disabled}
          required={validate}
          name={prefixFormName(name, FormFields.INTACCT_ID)}
          data-testid="corpAccountIntacctId"
          helperText="Must be alphanumeric"
          label="Intacct Customer ID"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          control={control}
          disabled={disabled}
          name={prefixFormName(name, FormFields.LEGAL_BUSINESS_NAME)}
          data-testid="corpAccountLegalBusinessName"
          label="Legal business name"
          helperText="Must match IRS records"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledSelectDropdown
          control={control}
          disabled={disabled}
          disableClear={validate}
          name={prefixFormName(name, FormFields.TIME_IN_OPERATION)}
          data-testid="corpAccountTimeInOperation"
          label="Time in operation"
          options={TIME_IN_OPERATION_OPTIONS}
          required={validate}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Contact Information
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ControlledPhoneInput
          control={control}
          disabled={disabled}
          required={validate}
          name={prefixFormName(name, FormFields.PHONE)}
          data-testid="corpAccountMainPhone"
          label="Main phone"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ControlledPhoneInput
          control={control}
          disabled={disabled}
          required={validate}
          name={prefixFormName(name, FormFields.CHARGEBACK_FAX)}
          data-testid="corpAccountChargebackfax"
          label="Chargeback fax"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ControlledInputText
          control={control}
          disabled={disabled}
          required={validate}
          name={prefixFormName(name, FormFields.WEBSITE)}
          data-testid="corpAccountWebsite"
          label="Website"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Physical Location Address
        </Typography>
      </Grid>
      <Address
        name={prefixFormName(name, FormFields.LOCATION_ADDRESS)}
        control={control}
        validate
        dataTestId="corpAccountPhysical"
      />
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
          Mailing Address
        </Typography>
        <CopyInfoForm
          onCopy={onCopy}
          copyFrom={prefixFormName(name, FormFields.LOCATION_ADDRESS)}
          copyTo={prefixFormName(name, FormFields.MAILING_ADDRESS)}
          dataTestId="corpAccountPhysicalAddressCopy"
        >
          Copy from Physical Location Address
        </CopyInfoForm>
      </Grid>
      <Address
        name={prefixFormName(name, FormFields.MAILING_ADDRESS)}
        control={control}
        validate
        dataTestId="corpAccountMailing"
      />
    </>
  );
}

CorporateAccount.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validate: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default CorporateAccount;
