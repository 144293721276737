import TradeReference, {
  FormFields,
  ValidationSchema,
  defaultValue,
} from '../../forms/TradeReference';

import { AccordionPersist } from '../../shared/Accordion/Accordion';
import PersistedManageEntities from '../../shared/ManageEntities/PersistedManageEntities';

export {
  ValidationSchema,
  FormFields,
  defaultValue,
};

const section = 'addAccount.tradeReferences';
export default AccordionPersist({
  label: 'Trade References',
  section,
  Component: PersistedManageEntities({
    section,
    schema: ValidationSchema({ validate: true }),
    defaultValues: defaultValue,
    validate: true,
    entityManagerProps: {
      description: 'Please provide information regarding companies that supply goods or services to your businesses.',
      sidebarHeader: 'Saved References',
      mainHeader: 'Add New Reference',
      headerOnEdit: 'Edit Reference',
      getEntityListAttributes: (entity) => {
        return {
          title: `${entity?.[FormFields.FIRST_NAME]} ${entity?.[FormFields.LAST_NAME]}`,
          subtitle: `${entity?.[FormFields.COMPANY_NAME]}`,
        };
      },
    },
    Component: TradeReference,
  }),
});
