import { createAsyncThunk } from '@reduxjs/toolkit';

import { set } from 'lodash';

import { MerchantTendersListRequest } from '../../../api';

export const getMIDTenderTypesThunk = createAsyncThunk('merchants/getMIDTenderTypes', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await MerchantTendersListRequest({ id });

    return response.data;
  } catch (e) {
    const { data, status, statusText } = e.response;
    const { error = {} } = data;
    return rejectWithValue({ ...error, status, statusText });
  }
});

export const getMIDTenderTypesReducers = {
  [getMIDTenderTypesThunk.fulfilled]: (state, { payload }) => {
    const tenderTypes = payload;
    set(
      state,
      'tenderTypes',
      tenderTypes,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },

  [getMIDTenderTypesThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },

  [getMIDTenderTypesThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'error',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },

};
