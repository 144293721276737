import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { UserUpdatePassword } from '../../../api';
import { buildErrorMessageFromRejectedThunk } from '../../../shared/utils';

export const updatePasswordThunk = createAsyncThunk('user/updatePassword', async (payload, { rejectWithValue }) => {
  try {
    const data = await UserUpdatePassword(payload);
    return data;
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const updatePasswordReducers = {
  [updatePasswordThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'actions.resetPassword.success',
      payload,
    );
    set(
      state,
      'actions.resetPassword.isLoading',
      false,
    );
  },
  [updatePasswordThunk.pending]: (state) => {
    set(
      state,
      'actions.resetPassword.isLoading',
      true,
    );
    set(
      state,
      'actions.resetPassword.success',
      {},
    );
    set(
      state,
      'actions.resetPassword.error',
      null,
    );
  },
  [updatePasswordThunk.rejected]: (state, reject) => {
    set(
      state,
      'actions.resetPassword.error',
      buildErrorMessageFromRejectedThunk(reject),
    );
    set(
      state,
      'actions.resetPassword.success',
      {},
    );
    set(
      state,
      'actions.resetPassword.isLoading',
      false,
    );
  },
};
