import { React, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { map, isEmpty } from 'lodash';

import {
  Typography,
  FormControl,
  TextField,
  MenuItem,
  Grid,
  Badge,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Chip,
} from '@mui/material';

import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';

import { AccordionForm } from '../../AccordionForm';

const paymentMethods = {
  cards: { name: 'Credit/Debit Card', value: 'cards' },
  ach: { name: 'ACH', value: 'ach' },
  amex_tpsp: { name: 'Amex TPSP', value: 'amex_tpsp' },
};

const EditTenderTypeSettings = ({
  editing,
  fundingGroupData,
  resetForms,
  expand,
  onChangeForm,
  previousExpanded,
  tenderTypes,
  setTenderTypes,
}) => {
  const [open, setOpen] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [paymentMethodsOptions, setPaymentMethodsOptions] = useState({ ...paymentMethods });
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [fundingSource, setFundingSource] = useState(null);
  const [cardFundingType, setCardFundingType] = useState(null);
  const [achFundingType, setAchFundingType] = useState(null);
  const [amexTpspFundingType, setAmexTpspFundingType] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [acceptedCards, setAcceptedCards] = useState({
    visa: true,
    mastercard: true,
    discover: false,
    amexOptblue: false,
  });

  const noTenderTypes = isEmpty(tenderTypes);
  const requireConvenienceFeesNetDepositAndCombinedDeposit = ((paymentMethod === 'ach' && achFundingType === 'fbo') || (paymentMethod === 'amex_tpsp' && amexTpspFundingType === 'fbo') || (paymentMethod === 'cards' && cardFundingType === 'fbo'));

  const requiredSelect = yup.string().required('Required');
  const requiredTextField = yup.string().required('Required');

  const schema = yup.object().shape({
    paymentMethodType: requiredSelect,
    fundingSource: requiredSelect,
    cardFundingType: paymentMethod === 'cards' && requiredSelect,
    cardFundingDelay: (paymentMethod === 'cards' && cardFundingType === 'fbo') && requiredSelect,
    achFundingType: paymentMethod === 'ach' && requiredSelect,
    achFundingDelay: (paymentMethod === 'ach' && achFundingType !== 'fbo') && requiredSelect,
    amexTpspFundingType: paymentMethod === 'amex_tpsp' && requiredSelect,
    convenienceFeesNetDeposit: requireConvenienceFeesNetDepositAndCombinedDeposit && requiredSelect,
    combinedDeposit: requireConvenienceFeesNetDepositAndCombinedDeposit && requiredSelect,
    processor: requiredSelect,
    gateway: requiredSelect,
    merchantAccount: requiredTextField,
    merchantAccountCode: requiredTextField,
    user: integration !== 'bluefin' && requiredTextField,
    password: requiredTextField,
  });

  const {
    control,
    reset,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const badgeStyle = { marginTop: 14, marginLeft: 17 };
  const errorQty = Object.keys(errors).length;

  useEffect(() => {
    if (editing) {
      const tenderTypeObject = {};
      map(fundingGroupData.tenders, (tenderTypeSettings) => {
        tenderTypeObject[tenderTypeSettings.paymentMethodType] = tenderTypeSettings;
      });
      setTenderTypes(tenderTypeObject);
    }
  }, []);

  useEffect(() => {
    if (previousExpanded === 'editTenderTypeSettings') setOpen(true);
    if (expand !== 'editNameAndMembers' && expand !== 'editTenderTypeSettings') {
      if (noTenderTypes) trigger();
      reset();
    } else if (previousExpanded === 'editTenderTypeSettings' && expand !== 'editTenderTypeSettings') {
      if (noTenderTypes) trigger();
      reset();
    }
  }, [expand]);

  useEffect(() => {
    if (resetForms > 0) {
      reset();
      setPaymentMethod(null);
    }
  }, [resetForms]);

  const addTenderType = async () => {
    const valid = await trigger();
    if (valid) {
      const settings = getValues();
      if (settings.paymentMethodType === 'cards') settings.acceptedCards = acceptedCards;
      if (settings.convenienceFeesNetDeposit) {
        const field = settings.convenienceFeesNetDeposit;
        settings.convenienceFeesNetDeposit = field === 'true';
      }
      if (settings.combinedDeposit) {
        const field = settings.combinedDeposit;
        settings.combinedDeposit = field === 'true';
      }
      settings.platform = 'CORE';
      setTenderTypes({
        ...tenderTypes,
        [settings.paymentMethodType]: settings,
      });
      delete paymentMethodsOptions[settings.paymentMethodType];
      setPaymentMethod(null);
      reset({
        paymentMethodType: undefined,
        fundingSource: undefined,
        cardFundingType: undefined,
        cardFundingDelay: undefined,
        achFundingType: undefined,
        achFundingDelay: undefined,
        amexTpspFundingType: undefined,
        amexTpspFundingDelay: undefined,
        convenienceFeesNetDeposit: undefined,
        combinedDeposit: undefined,
        processor: undefined,
        gateway: undefined,
        merchantAccount: undefined,
        merchantAccountCode: undefined,
        user: undefined,
        password: undefined,
      });
    }
  };

  const deleteTenderType = (tenderType) => {
    const newTenderTypes = {
      ...tenderTypes,
    };
    delete newTenderTypes[tenderType];

    setTenderTypes(newTenderTypes);
    setPaymentMethodsOptions({
      ...paymentMethodsOptions,
      [tenderType]: paymentMethods[tenderType],
    });
  };

  const clickTenderType = (settings) => {
    deleteTenderType(settings.paymentMethodType);
    setPaymentMethod(settings.paymentMethodType);
    reset({
      paymentMethodType: settings.paymentMethodType,
      fundingSource: settings.fundingSource,
      cardFundingType: settings.cardFundingType,
      cardFundingDelay: settings.cardFundingDelay,
      achFundingType: settings.achFundingType,
      achFundingDelay: settings.achFundingDelay,
      amexTpspFundingType: settings.amexTpspFundingType,
      amexTpspFundingDelay: settings.amexTpspFundingDelay,
      convenienceFeesNetDeposit: settings.convenienceFeesNetDeposit,
      combinedDeposit: settings.combinedDeposit,
      processor: settings.processor,
      gateway: settings.gateway,
      merchantAccount: settings.merchantAccount,
      merchantAccountCode: settings.merchantAccountCode,
      user: settings.user,
      password: settings.password,
    });
  };

  const checkIfThisFormIsExpanded = () => expand === 'editTenderTypeSettings';

  return (
    <AccordionForm
      expanded={checkIfThisFormIsExpanded()}
      onChange={() => onChangeForm('editTenderTypeSettings')}
      title={(
        <>
          <Typography variant="h6">Tender Types</Typography>
          {errorQty > 0 && noTenderTypes && (
            <Badge style={badgeStyle} badgeContent={errorQty} color="secondary" />
          )}
          {errorQty === 0 && noTenderTypes && open && (
            <Badge style={badgeStyle} badgeContent={1} color="secondary" />
          )}
          {map(tenderTypes, (tenderTypeSettings) => {
            if (tenderTypeSettings) {
              return (
                <Chip
                  sx={{
                    ml: 2,
                    padding: 0.5,
                    mt: '2px',
                    borderRadius: 2.5,
                  }}
                  size="small"
                  color="primary"
                  variant="outlined"
                  value={tenderTypeSettings}
                  onClick={() => clickTenderType(tenderTypeSettings)}
                  onDelete={() => deleteTenderType(tenderTypeSettings.paymentMethodType)}
                  label={paymentMethods[tenderTypeSettings.paymentMethodType].name}
                />
              );
            }
            return null;
          })}
        </>
      )}
      content={(
        <>

          <form>
            <FormControl
              variant="outlined"
              style={{ width: '100%', paddingInline: 20 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="paymentMethodType"
                    render={({ field: { onChange, onBlur, value }, fieldState }) => (
                      <TextField
                        select
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          onChange(e);
                          setPaymentMethod(e.target.value);
                        }}
                        onBlur={noTenderTypes && onBlur}
                        label="Payment method"
                        error={fieldState.error && !!fieldState.error.message}
                        helperText={fieldState.error && fieldState.error.message}
                        value={!value ? '' : value}
                      >
                        {map(paymentMethodsOptions, (method) => {
                          if (method) {
                            return (
                              <MenuItem
                                value={method.value}
                              >
                                {method.name}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                      </TextField>
                    )}
                  />
                </Grid>
                {paymentMethod === 'cards' && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Select cards to be accepted:</Typography>
                    <Controller
                      control={control}
                      name="acceptedCards"
                      render={() => (
                        <FormGroup onChange={() => setValue('acceptedCards', acceptedCards)} style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                            <FormControlLabel
                              control={<Checkbox defaultChecked onChange={() => { acceptedCards.visa = !acceptedCards.visa; }} />}
                              label="Visa"
                              checked={acceptedCards.visa}

                            />
                            <FormControlLabel
                              control={<Checkbox defaultChecked onChange={() => { acceptedCards.mastercard = !acceptedCards.mastercard; }} />}
                              label="Mastercard"
                              checked={acceptedCards.mastercard}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControlLabel
                              control={<Checkbox onChange={() => { acceptedCards.discover = !acceptedCards.discover; }} />}
                              label="Discover"
                              checked={acceptedCards.discover}
                            />
                            <FormControlLabel
                              control={<Checkbox onChange={() => { acceptedCards.amexOptblue = !acceptedCards.amexOptblue; }} />}
                              label="AMEX OptBlue"
                              checked={acceptedCards.amexOptblue}
                            />
                          </div>
                        </FormGroup>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="fundingSource"
                    render={({ field: { onChange, value }, fieldState }) => (
                      <>
                        <TextField
                          select
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            onChange(e);
                            if (e.target.value === 'core') {
                              setValue('cardFundingType', 'fbo');
                              setCardFundingType('fbo');
                            }
                            setFundingSource(e.target.value);
                          }}
                          label="Funding source"
                          error={(fieldState.error && !value) && !!fieldState.error.message}
                          helperText={(fieldState.error && !value) && fieldState.error.message}
                          value={!value ? '' : value}
                        >
                          <MenuItem value="core">
                            Core Funding
                          </MenuItem>
                          <MenuItem value="external">
                            External Funding
                          </MenuItem>
                          <MenuItem value="dynamicPayout">
                            Dynamic Payout
                          </MenuItem>
                        </TextField>
                      </>
                    )}
                  />
                </Grid>
                {paymentMethod === 'cards' && (
                  <>
                    <Grid item xs={cardFundingType === 'fbo' ? 6 : 12}>
                      <Controller
                        control={control}
                        name="cardFundingType"
                        render={({ field: { onChange, value }, fieldState }) => (
                          <TextField
                            select
                            style={{ width: '100%' }}
                            onChange={(e) => {
                              onChange(e);
                              setCardFundingType(e.target.value);
                            }}
                            label="Card Funding Type"
                            error={(fieldState.error && !value) && !!fieldState.error.message}
                            helperText={(fieldState.error && !value) && fieldState.error.message}
                            value={!value ? '' : value}
                            disabled={fundingSource === 'core'}
                          >
                            <MenuItem value="fbo">
                              PayFac / FBO Funding
                            </MenuItem>
                            <MenuItem value="dynamicPayout">
                              PayFac / Dynamic Payout
                            </MenuItem>
                            <MenuItem value="processor">
                              PayFac / Processor
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    {cardFundingType === 'fbo' && (
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="cardFundingDelay"
                        render={({ field: { onChange, value }, fieldState }) => (
                          <TextField
                            select
                            style={{ width: '100%' }}
                            onChange={onChange}
                            label="Card funding delay"
                            error={(fieldState.error && !value) && !!fieldState.error.message}
                            helperText={(fieldState.error && !value) && fieldState.error.message}
                            value={!value ? '' : value}
                          >
                            <MenuItem value="0">
                              0
                            </MenuItem>
                            <MenuItem value="1">
                              1
                            </MenuItem>
                            <MenuItem value="2">
                              2
                            </MenuItem>
                            <MenuItem value="3">
                              3
                            </MenuItem>
                            <MenuItem value="4">
                              4
                            </MenuItem>
                            <MenuItem value="5">
                              5
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    )}
                  </>
                )}
                {paymentMethod === 'ach' && (
                <>
                  <Grid item xs={achFundingType !== 'fbo' ? 6 : 12}>
                    <Controller
                      control={control}
                      name="achFundingType"
                      render={({ field: { onChange, value }, fieldState }) => (
                        <TextField
                          select
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            onChange(e);
                            setAchFundingType(e.target.value);
                          }}
                          label="ACH funding type"
                          error={(fieldState.error && !value) && !!fieldState.error.message}
                          helperText={(fieldState.error && !value) && fieldState.error.message}
                          value={!value ? '' : value}
                        >
                          <MenuItem value="fbo">
                            ACH / TPS / FBO Funding
                          </MenuItem>
                          <MenuItem value="3rdParty">
                            ACH / 3rd Party Funding
                          </MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  {achFundingType !== 'fbo' && (
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="achFundingDelay"
                      render={({ field: { onChange, value }, fieldState }) => (
                        <TextField
                          select
                          style={{ width: '100%' }}
                          onChange={onChange}
                          label="ACH funding delay"
                          error={(fieldState.error && !value) && !!fieldState.error.message}
                          helperText={(fieldState.error && !value) && fieldState.error.message}
                          value={!value ? '' : value}
                        >
                          <MenuItem value="0">
                            0
                          </MenuItem>
                          <MenuItem value="1">
                            1
                          </MenuItem>
                          <MenuItem value="2">
                            2
                          </MenuItem>
                          <MenuItem value="3">
                            3
                          </MenuItem>
                          <MenuItem value="4">
                            4
                          </MenuItem>
                          <MenuItem value="5">
                            5
                          </MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  )}
                </>
                )}
                {paymentMethod === 'amex_tpsp' && (
                  <>
                    <Grid item xs={amexTpspFundingType === 'fbo' ? 6 : 12}>
                      <Controller
                        control={control}
                        name="amexTpspFundingType"
                        render={({ field: { onChange, value }, fieldState }) => (
                          <TextField
                            select
                            style={{ width: '100%' }}
                            onChange={(e) => {
                              onChange(e);
                              setAmexTpspFundingType(e.target.value);
                            }}
                            label="Amex TPSP funding type"
                            error={(fieldState.error && !value) && !!fieldState.error.message}
                            helperText={(fieldState.error && !value) && fieldState.error.message}
                            value={!value ? '' : value}
                          >
                            <MenuItem value="fbo">
                              Amex TPSP / FBO Funding
                            </MenuItem>
                            <MenuItem value="processor">
                              Amex TPSP / Processor
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    {amexTpspFundingType === 'fbo' && (
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="amexTpspFundingDelay"
                        render={({ field: { onChange, value }, fieldState }) => (
                          <TextField
                            select
                            style={{ width: '100%' }}
                            onChange={onChange}
                            label="Amex TPSP funding delay"
                            error={(fieldState.error && !value) && !!fieldState.error.message}
                            helperText={(fieldState.error && !value) && fieldState.error.message}
                            value={!value ? '' : value}
                          >
                            <MenuItem value="0">
                              0
                            </MenuItem>
                            <MenuItem value="1">
                              1
                            </MenuItem>
                            <MenuItem value="2">
                              2
                            </MenuItem>
                            <MenuItem value="3">
                              3
                            </MenuItem>
                            <MenuItem value="4">
                              4
                            </MenuItem>
                            <MenuItem value="5">
                              5
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    )}
                  </>
                )}
                {requireConvenienceFeesNetDepositAndCombinedDeposit && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="convenienceFeesNetDeposit"
                    render={({ field: { onChange, value }, fieldState }) => (
                      <TextField
                        select
                        style={{ width: '100%' }}
                        onChange={onChange}
                        label="Convenience fees net deposit"
                        error={(fieldState.error && !value) && !!fieldState.error.message}
                        helperText={(fieldState.error && !value) && fieldState.error.message}
                        value={!value ? '' : value}
                      >
                        <MenuItem value="true">
                          Yes
                        </MenuItem>
                        <MenuItem value="false">
                          No
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                )}
                {requireConvenienceFeesNetDepositAndCombinedDeposit && (
                <Grid item xs={requireConvenienceFeesNetDepositAndCombinedDeposit ? 6 : 12}>
                  <Controller
                    control={control}
                    name="combinedDeposit"
                    render={({ field: { onChange, value }, fieldState }) => (
                      <TextField
                        select
                        style={{ width: '100%' }}
                        onChange={onChange}
                        label=" CC/ACH combined deposit"
                        error={(fieldState.error && !value) && !!fieldState.error.message}
                        helperText={(fieldState.error && !value) && fieldState.error.message}
                        value={!value ? '' : value}
                      >
                        <MenuItem value="true">
                          Yes
                        </MenuItem>
                        <MenuItem value="false">
                          No
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                )}
                {paymentMethod === 'ach' ? (
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="processor"
                      render={({ field: { onChange, onBlur, value }, fieldState }) => (
                        <TextField
                          label="Processor"
                          select
                          style={{ width: '100%' }}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={fieldState.error && !!fieldState.error.message}
                          helperText={fieldState.error && fieldState.error.message}
                          value={!value ? '' : value}
                        >
                          <MenuItem value="ttech">TTech</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                )
                  : (
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="processor"
                        render={({ field: { onChange, onBlur, value }, fieldState }) => (
                          <TextField
                            label="Processor"
                            select
                            style={{ width: '100%' }}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={fieldState.error && !!fieldState.error.message}
                            helperText={fieldState.error && fieldState.error.message}
                            value={!value ? '' : value}
                          >
                            <MenuItem value="worldpay">Worldpay</MenuItem>
                            <MenuItem value="ttech">TTech</MenuItem>

                          </TextField>
                        )}
                      />
                    </Grid>
                  )}
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="gateway"
                    render={({ field: { onChange, onBlur, value }, fieldState }) => (
                      <TextField
                        label="Integration"
                        select
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          onChange(e);
                          setIntegration(e.target.value);
                        }}
                        onBlur={onBlur}
                        error={fieldState.error && !!fieldState.error.message}
                        helperText={fieldState.error && fieldState.error.message}
                        value={!value ? '' : value}
                      >
                        <MenuItem value="bridgecom">Bridgecom</MenuItem>
                        <MenuItem value="bluefin">Bluefin</MenuItem>
                        <MenuItem value="heritageBank">Heritage Bank</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="merchantAccount"
                    render={({ field: { onChange, onBlur, value }, fieldState }) => (
                      <TextField
                        label="Merchant account"
                        style={{ width: '100%' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={fieldState.error && !!fieldState.error.message}
                        helperText={fieldState.error && fieldState.error.message}
                        value={!value ? '' : value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="merchantAccountCode"
                    render={({ field: { onChange, onBlur, value }, fieldState }) => (
                      <TextField
                        label="Merchant account code"
                        style={{ width: '100%' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={fieldState.error && !!fieldState.error.message}
                        helperText={fieldState.error && fieldState.error.message}
                        value={!value ? '' : value}
                      />
                    )}
                  />
                </Grid>
                {integration !== 'bluefin' && (
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="user"
                      render={({ field: { onChange, onBlur, value }, fieldState }) => (
                        <TextField
                          label="User"
                          style={{ width: '100%' }}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={fieldState.error && !!fieldState.error.message}
                          helperText={fieldState.error && fieldState.error.message}
                          value={!value ? '' : value}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={integration !== 'bluefin' ? 6 : 12}>
                  <Controller
                    control={control}
                    name="password"
                    render={({ field: { onChange, onBlur, value }, fieldState }) => (
                      <>
                        <TextField
                          label={integration !== 'bluefin' ? 'Password' : 'API Key'}
                          type={visibility ? 'text' : 'Password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setVisibility(!visibility)}
                                >
                                  {visibility ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          style={{ width: '100%' }}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={fieldState.error && !!fieldState.error.message}
                          helperText={fieldState.error && fieldState.error.message}
                          value={!value ? '' : value}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="primary"
                    onClick={addTenderType}
                  >
                    Add Tender Type
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </>
      )}
    />
  );
};

export default EditTenderTypeSettings;
