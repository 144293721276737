import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { YupValidations } from '../../shared/validations';
import { RenewPasswordError } from './renew-password-error';
import { ControlledInputText } from '../forms/InputText';
import { RenewPassword } from '../../api';

export const RenewPasswordForm = ({ email, onRetry, setOnRetry }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const renewPasswordForm = useMutation(RenewPassword);

  const { control, handleSubmit, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: YupValidations.email,
        currentPassword: YupValidations.password,
        newPassword: YupValidations.password,
        confirmPassword: YupValidations.confirmPassword('newPassword'),
      }),
    ),
  });

  const onSubmit = () => {
    const payload = getValues();
    renewPasswordForm.mutate({ payload });
    setOnRetry(true);
  };

  const handleSuccess = () => {
    if (!renewPasswordForm.isSuccess) return;
    navigate('/');
    enqueueSnackbar('New password set. Please login again.', {
      variant: 'success',
      autoHideDuration: 12000,
    });
  };

  useEffect(() => {
    if (!renewPasswordForm.isLoading && !renewPasswordForm.isError) { handleSuccess(); }
  }, [renewPasswordForm.isSuccess]);

  return (
    onRetry && renewPasswordForm.error ? (
      <RenewPasswordError
        error={renewPasswordForm.error}
        onClick={onSubmit}
        setOnRetry={setOnRetry}
        isLoading={renewPasswordForm.isLoading}
      />
    ) : (
      <>
        <ControlledInputText
          name="email"
          label="Email"
          control={control}
          required
          disabled={email}
          sx={{ mb: 3 }}
        />
        <ControlledInputText
          name="currentPassword"
          label="Current Password"
          control={control}
          required
          type="password"
          sx={{ mb: 3 }}
        />
        <ControlledInputText
          name="newPassword"
          label="New Password"
          control={control}
          required
          type="password"
          sx={{ mb: 3 }}
        />
        <ControlledInputText
          name="confirmPassword"
          label="Confirm New Password"
          control={control}
          type="password"
          required
          sx={{ mb: 3 }}
        />
        <Box>
          <LoadingButton
            color="primary"
            loading={renewPasswordForm.isLoading}
            fullWidth
            size="large"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Box>
      </>
    )
  );
};
