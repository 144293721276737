import { cloneDeep, debounce, isFunction } from 'lodash';
import { useController } from 'react-hook-form';

const ControlledComponent = (Component, { defaultValue = null } = {}) => ({
  name, control, externalChange = () => {}, ...rest
}) => {
  const {
    field,
    fieldState,
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: cloneDeep(defaultValue),
  });
  const debouncedExternalChange = debounce(externalChange, 1000);

  const handleChange = (_, v) => {
    field.onChange(v);
    if (isFunction(externalChange)) {
      debouncedExternalChange(name, v);
    }
  };

  return (
    <Component
      name={field.name}
      value={field.value}
      onChange={handleChange}
      onBlur={field.onBlur}
      error={fieldState.error}
      {...rest}
    />
  );
};

export default ControlledComponent;
