import { client } from '../api';

export const useTOTPSecretRequest = (id) => {
  return client.post(`/users/${id}/2fa/totp/request`, {});
};

export const useShowQR = async (id, secret) => {
  return client.post(`/users/${id}/2fa/totp/qr`, {
    secret,
  }, {
    responseType: 'blob',
  });
};

export const useEnable2FA = async (id, code) => {
  return client.post(`/users/${id}/2fa/enable`, {
    method: 'A',
    code,
  });
};

export const useOneTimePasswords = async (id) => {
  return client.post(`/users/${id}/2fa/one-time-password`, {
    CreateOnePasswords: true,
  });
};
