import {
  Button, Grid, TextField, Typography,
} from '@mui/material';
import { isEmpty, map } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  useEnable2FA, useOneTimePasswords, useShowQR, useTOTPSecretRequest,
} from '../hooks/useMultifactorAuthentication';
import { makeCopyableString } from '../shared/utils';

export const SecondFactorSetup = ({
  user,
  success,
  setSuccess,
  successCallback = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { security: { multiFactor } } = user;
  const { control, getValues } = useForm({ mode: 'all' });
  const { enabled: multiFactorEnabled, required: multiFactorRequired } = multiFactor;
  const [img, setImg] = useState(null);
  const [TOTP, setTOTP] = useState({});
  const [oneTimePasswords, setOneTimePasswords] = useState([]);
  const codeFormName = 'code';

  useEffect(() => {
    if (!multiFactorEnabled && multiFactorRequired && isEmpty(TOTP)) {
      const useRequest = async () => {
        const { data } = await useTOTPSecretRequest(user.id);
        setTOTP(data);
      };
      useRequest();
    }
  }, [multiFactorEnabled, multiFactorRequired, TOTP]);

  useEffect(() => {
    if (TOTP?.secret) {
      const useRequest = async () => {
        const { data: blob } = await useShowQR(user.id, TOTP?.secret);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64String = reader.result;
          setImg(base64String);
        };
      };
      useRequest();
    }
  }, [TOTP]);

  useEffect(() => {
    if (success) {
      const useRequest = async () => {
        const { data } = await useOneTimePasswords(user?.id);
        const { oneTimePasswords: otps = [] } = data;
        setOneTimePasswords(otps);
        successCallback();
      };
      try {
        useRequest();
      } catch {
        enqueueSnackbar('Failed to get one time passwords', { variant: 'error' });
      }
    }
  }, [success]);

  const handleSubmit = async () => {
    try {
      const codeInForm = getValues(codeFormName);
      await useEnable2FA(user.id, codeInForm);
      setSuccess(true);
    } catch (error) {
      enqueueSnackbar('6-digit code confirmation failed', { variant: 'error' });
    }
  };

  const renderSetup = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item>
          <Typography fontWeight="bold">
            Step 1:
            Register by scanning the QR code:
          </Typography>
        </Grid>
        <Grid item>
          {img
&& (
  <img src={img} alt="QR" width="200" height="200" />
)}
        </Grid>
        <Grid item>
          <Typography
            sx={{ mt: -2, fontSize: '14px' }}
          >
            Or enter the following code manually:
          </Typography>
        </Grid>
        <Grid item sx={{ mt: -2 }}>
          {makeCopyableString(TOTP?.secret)}
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            Step 2: Confirm with the generated 6-digit code:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={codeFormName}
            control={control}
            defaultValue=""
            rules={{ maxLength: 6 }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Button
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
          sx={{ mt: 2, ml: 1 }}
        >
          Confirm
        </Button>
      </Grid>
    );
  };

  const renderSuccess = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={1}>
          <CheckCircleRoundedIcon color="primary" sx={{ fontSize: '60px' }} />
        </Grid>
        <Grid item xs={11} sx={{ textAlign: 'center' }}>
          <Typography
            fontWeight="bold"
            fontSize="26px"
            variant="h4"
          >
            Two-factor setup successfully
          </Typography>
        </Grid>
        <Grid item sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            If you lose access to your device, you can use one of these backup codes to login to your account. Each code may be used only once. Make a copy of these codes, and store it somewhere safe.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            startIcon={<ContentCopyIcon />}
            color="primary"
            onClick={() => { navigator.clipboard.writeText(oneTimePasswords.join('\n')); }}
          >
            Copy codes
          </Button>
        </Grid>
        <Grid item sx={{ textAlign: 'center' }}>
          {map(oneTimePasswords, (otp) => {
            return (
              <Typography fontWeight="bold">
                {otp}
              </Typography>
            );
          })}
        </Grid>

      </Grid>
    );
  };

  return success ? renderSuccess() : renderSetup();
};
