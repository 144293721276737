/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  forEach,
  get, keys, set,
} from 'lodash';

import {
  getMIDReducers, getMIDThunk, getMIDTenderTypesThunk, listMIDReducers, listMIDThunk, postAddMIDReducers, postAddMIDThunk, getMIDTenderTypesReducers, listMIDDevicesThunk, listMIDDevicesReducers,
} from '../api/merchants';

export const merchantsReducer = 'merchantsReducer';
export const merchantsListState = `${merchantsReducer}.list`;
export const listDevicesState = `${merchantsReducer}.list`;
export const getAddMIDFormStatePath = (accountID) => `${merchantsReducer}.addMIDForms.${accountID}`;

export const initialState = {
  isLoading: false,
  isSubmitDialogOpen: false,
  isResetDialogOpen: false,
  result: null,
  error: null,
  addMIDForms: {},
  entities: {},
  perAccount: {},
};

export const EMPTY_MID_FORM = { formStep: 'accountInformation' };

const slice = createSlice({
  name: 'merchants',
  initialState,
  reducers: {
    resetForm(state, action) {
      const { payload } = action;
      const { id } = payload;
      set(state?.addMIDForms, [id], EMPTY_MID_FORM);
    },
    updateAddMIDForm(state, action) {
      const { payload } = action;
      const { path, value } = payload;
      set(state?.addMIDForms, path, value);
    },
    updateAddMIDFormBySection(state, action) {
      const { payload } = action;
      const { path, section, changes } = payload;
      const sectionPath = section.split('.');
      set(
        state?.addMIDForms,
        [path, ...sectionPath],
        {
          ...changes,
        },
      );
    },
    copyForms(state, action) {
      const { payload } = action;
      const { id, copySchema } = payload;
      /* Copy Schema:
        {
          [sourceFormKey1]: destinationFormKey1,
          [sourceFormKey2]: destinationFormKey2,
          ...
        }
      */
      forEach(keys(copySchema), (s) => {
        set(
          state?.addMIDForms,
          [id, ...copySchema[s].split('-')],
          get(
            state?.addMIDForms,
            [id, ...s.split('-')],
          ),
        );
      });
    },
    updateAddMIDFormStep(state, action) {
      const { payload } = action;
      const { id, value } = payload;
      set(state?.addMIDForms, [id, 'steps', 'prevFormStep'], get(state?.addMIDForms, [id, 'steps', 'formStep'], ''));
      set(state?.addMIDForms, [id, 'steps', 'formStep'], value);
    },
    updateFormErrors(state, action) {
      const { payload } = action;
      const { id, value } = payload;
      set(state?.addMIDForms, [id, 'formErrors'], value);
    },
    openSubmitMIDDialog(state) {
      set(state, 'isSubmitDialogOpen', true);
    },
    closeSubmitMIDDialog(state) {
      set(state, 'isSubmitDialogOpen', false);
    },
    openResetAddMIDDialog(state) {
      set(state, 'isResetDialogOpen', true);
    },
    closeResetAddMIDDialog(state) {
      set(state, 'isResetDialogOpen', false);
    },
  },
  extraReducers: {
    ...postAddMIDReducers,
    ...getMIDReducers,
    ...listMIDReducers,
    ...getMIDTenderTypesReducers,
    ...listMIDDevicesReducers,
  },
});

export const { reducer } = slice;

export const resetForm = ({ id }) => (dispatch) => {
  dispatch(slice.actions.resetForm({ id }));
};

export const updateAddMIDForm = ({ path, value }) => (dispatch) => {
  dispatch(slice.actions.updateAddMIDForm({ path, value }));
};

export const updateAddMIDFormBySection = ({ path, section, changes }) => (dispatch) => {
  dispatch(slice.actions.updateAddMIDFormBySection({ path, section, changes }));
};

export const updateAddMIDFormStep = ({ id, value }) => (dispatch) => {
  dispatch(slice.actions.updateAddMIDFormStep({ id, value }));
};

export const copyForms = ({
  id, copySchema, callback = () => {},
}) => (dispatch) => {
  dispatch(slice.actions.copyForms({ id, copySchema }));
  callback();
};

export const updateFormErrors = ({ id, value }) => (dispatch) => {
  dispatch(slice.actions.updateFormErrors({ id, value }));
};

export const openSubmitMIDDialog = () => (dispatch) => {
  dispatch(slice.actions.openSubmitMIDDialog());
};

export const closeSubmitMIDDialog = () => (dispatch) => {
  dispatch(slice.actions.closeSubmitMIDDialog());
};

export const openResetAddMIDDialog = () => (dispatch) => {
  dispatch(slice.actions.openResetAddMIDDialog());
};

export const closeResetAddMIDDialog = () => (dispatch) => {
  dispatch(slice.actions.closeResetAddMIDDialog());
};

export const postAddMID = ({ id, body }) => (dispatch) => {
  dispatch(openSubmitMIDDialog());
  dispatch(postAddMIDThunk({ id, body }));
};

export const fetchMID = ({ id }) => (dispatch) => {
  if (id) { dispatch(getMIDThunk({ id })); }
};

export const fetchMIDTenderTypes = (id) => (dispatch) => {
  dispatch(getMIDTenderTypesThunk({ id }));
};

export const fetchMIDDevices = (params, id) => (dispatch) => {
  dispatch(listMIDDevicesThunk({ params, id }));
};

export const listMIDs = ({ params = {} }) => (dispatch) => {
  dispatch(listMIDThunk({ params }));
};

export default slice;
