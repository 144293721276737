import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { initializeAvailableTypes } from '../../../shared/utils';
import MutationDialog from '../../shared/Dialogs/MutationDialog';
import { DefaultValues, ValidationSchema } from '../tenders/FormFields';
import { allPaymentMethodTypes, TenderTypeForm } from '../tenders/TenderTypeForm';
import { AddTenderToMID } from '../../../api/Merchants/Tenders';
import { useAccountTypes } from '../../../hooks/systemConfig/useAccountTypes';

const getTendersFromMerchant = ({ merchant = {} }) => {
  // Temporary prop
  const tenderTypeList = merchant?.tenderTypeList;
  // Default prop
  const tenderTypes = merchant?.tenderTypes;
  return tenderTypeList || tenderTypes || [];
};

export const NewTenderDialog = ({ isOpen, merchant, handleClose }) => {
  const accountTypes = useAccountTypes();
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(Yup.object().shape(ValidationSchema)),
    defaultValues: DefaultValues,
  });
  const {
    reset,
    getValues,
    trigger,
    formState: { isDirty },
  } = methods;

  const handleMutationSuccess = () => {
    reset({ ...DefaultValues });
  };

  return (
    <MutationDialog
      successText="Tender added successfully"
      open={isOpen}
      onClose={handleClose}
      onMutationFail={trigger}
      onMutationSucceed={handleMutationSuccess}
      request={AddTenderToMID({ accountTypes })}
      getForm={() => ({ ...getValues(), merchantId: merchant?.id })}
      onReset={reset}
      data-testid="edit-merchant-dialog"
      title={(
        <Typography variant="h5">
          Add Tender Type
        </Typography>
    )}
      aria-labelledby="add-tender-dialog-title"
      aria-describedby="add-tender-dialog-description"
      disableReset={!isDirty}
    >
      <TenderTypeForm
        mode="add"
        availableTypes={initializeAvailableTypes({
          existingTenders: getTendersFromMerchant({ merchant }),
          allTypes: allPaymentMethodTypes,
        })}
        formProps={{ ...methods, formKey: '.' }}
        savedBankingInfo={[]}
      />
    </MutationDialog>
  );
};
