import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, toNumber } from 'lodash';
import {
  Alert, Box, LinearProgress, Typography,
} from '@mui/material';

import { AccordionPersist } from '../../../shared/Accordion/Accordion';
import {
  resetBeneficial,
  resetSingleBeneficial,
} from '../../../../redux/slices/persistedForms';
import ControlSigner from './ControlSigner';
import BeneficialOwners from './BeneficialOwners';
import SingleBeneficialOwner from './SingleBeneficialOwner';
import '../style.css';
import { needsOwnership, onlyOneBeneficialOwner } from '../../../../utils/accountBusiness';

const OwnershipProgress = ({ value, needsMultipleOwners }) => {
  const progressColor = useMemo(() => {
    if (value > 24 && !needsMultipleOwners) return 'success';
    if (value < 1 || value > 100) return 'error';
    if (value > 50) return 'success';
    return 'warning';
  });
  const Message51 = value < 51 && needsMultipleOwners;
  return (
    <Box sx={{ my: 2 }}>
      { Message51 && (
        <Alert severity="error">
          <Typography variant="subtitle2">
            Beneficial Owners total ownership is required to be at least 51% if no individual owner has more than 25%.
          </Typography>
        </Alert>
      ) }
      { !Message51 && value > 100 && (
        <Alert severity="error">
          <Typography variant="subtitle2">
            Beneficial Owners total ownership is invalid.
          </Typography>
        </Alert>
      ) }
      <Typography variant="overline">
        Total
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" color={progressColor} value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${value}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const MerchantApplication = ({ isActive }) => {
  const dispatcher = useDispatch();
  const needsOwnershipDistribution = useSelector((state) => {
    const {
      q1,
      q2,
      q3,
      tickerType,
    } = get(state, 'persistedFormsReducer.addAccount.orgInfo.form', {});
    return needsOwnership(q1, tickerType, q2, q3);
  });

  const oneBeneficialOnly = useSelector((state) => {
    const q2 = get(state, 'persistedFormsReducer.addAccount.orgInfo.form.q2');
    return onlyOneBeneficialOwner(q2);
  });

  const controlSignerHasOwnership = useSelector((state) => {
    const controlSignerOwnership = toNumber(get(state, 'persistedFormsReducer.addAccount.controlSigner.form.ownershipPercentage', 0));
    return Boolean(controlSignerOwnership);
  });

  const ownershipValues = useSelector((state) => {
    let anyoneHasMore25 = false;
    const controlSignerOwnership = toNumber(get(state, 'persistedFormsReducer.addAccount.controlSigner.form.ownershipPercentage', 0));
    const singleBeneficialOwnership = toNumber(get(state, 'persistedFormsReducer.addAccount.singleBeneficialOwner.form.ownershipPercentage', 0));
    const beneficialOwners = get(state, 'persistedFormsReducer.addAccount.beneficialOwners.savedEntities', []);
    const totalOwnershipBeneficials = beneficialOwners.reduce((accum, owner) => {
      if (owner.firstName === 'Control') return accum;
      const ownership = toNumber(owner.ownershipPercentage);
      if (ownership > 24 && ownership <= 100) anyoneHasMore25 = true;
      return accum + ownership;
    }, 0);
    if ((controlSignerOwnership > 24 && controlSignerOwnership <= 100) || singleBeneficialOwnership > 24) anyoneHasMore25 = true;
    const total = controlSignerOwnership + singleBeneficialOwnership + totalOwnershipBeneficials;
    return {
      total,
      remaining: 100 - total,
      anyoneHasMore25,
    };
  });

  const isOwnershipValid = useMemo(() => {
    if (ownershipValues.anyoneHasMore25) return true;
    if (ownershipValues.total < 51) return false;
    if (ownershipValues.total > 100) return false;
    return ownershipValues.total === 100;
  }, [ownershipValues]);

  useEffect(() => {
    if (!needsOwnershipDistribution || oneBeneficialOnly) dispatcher(resetBeneficial());
    if (!oneBeneficialOnly || (controlSignerHasOwnership && oneBeneficialOnly)) dispatcher(resetSingleBeneficial());
  }, [needsOwnershipDistribution, controlSignerHasOwnership, oneBeneficialOnly]);

  return (
    <>
      <ControlSigner optionalOwnership oneBeneficialOnly={oneBeneficialOnly} withOwnership={needsOwnershipDistribution} isActive={isActive} remaining={ownershipValues.remaining} isOwnershipValid={isOwnershipValid} />
      {!oneBeneficialOnly && controlSignerHasOwnership && (
        <Alert severity="info" sx={{ marginTop: 2 }}>
          <Typography variant="subtitle2">
            Control Signer has been added as a Beneficial Owner.
          </Typography>
        </Alert>
      )}
      {oneBeneficialOnly && controlSignerHasOwnership && (
        <>
          <Alert severity="info" sx={{ marginTop: 2 }}>
            <Typography variant="subtitle2">
              Control Signer has ownership, so Beneficial Owners are not required.
            </Typography>
          </Alert>
          <OwnershipProgress value={ownershipValues.total} needsMultipleOwners={!ownershipValues.anyoneHasMore25} />
        </>
      )}
      {oneBeneficialOnly && !controlSignerHasOwnership && (
        <>
          <OwnershipProgress value={ownershipValues.total} needsMultipleOwners={!ownershipValues.anyoneHasMore25} />
          <SingleBeneficialOwner />
        </>
      )}
      { needsOwnershipDistribution && !oneBeneficialOnly
      && (
      <>
        <OwnershipProgress value={ownershipValues.total} needsMultipleOwners={!ownershipValues.anyoneHasMore25} />
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
          Beneficial Owners
        </Typography>
        { !oneBeneficialOnly && <BeneficialOwners withOwnership remaining={ownershipValues.remaining} />}
      </>
      )}
    </>
  );
};

const label = 'Merchant Application Requirement';
const section = 'addAccount.controlSigner';

export default AccordionPersist({
  section,
  label,
  getAmountAlert: (persistedFormsReducer) => {
    const singleBeneficialErrors = get(persistedFormsReducer, 'addAccount.singleBeneficialOwner.errors', 0);
    const controlErrorsAmount = get(persistedFormsReducer, 'addAccount.controlSigner.errors', 0);
    return singleBeneficialErrors + controlErrorsAmount;
  },
  Component: MerchantApplication,
});
