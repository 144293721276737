import { useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material';

import { AccordionPersist } from '../../shared/Accordion/Accordion';
import { YupValidations } from '../../../shared/validations';
import PersistedSectionForm from '../../shared/PersistedSectionForm';
import { ControlledSelectDropdown } from '../../forms/SelectDropdown';
import { ControlledRadioGroup } from '../../forms/RadioGroup';
import { ControlledInputText } from '../../forms/InputText';
import { ControlledInputMask } from '../../forms/InputMask';
import { ControlledAccountsSelector } from '../../shared/AccountsSelector';
import useFormFieldWatch from '../../../hooks/useFormFieldWatch';

const FormFields = {
  q1: 'q1',
  q2: 'q2',
  q3: 'q3',
  q4: 'q4',
  TICKER: 'ticker',
  TICKER_TYPE: 'tickerType',
  PARENT_ACCOUNT: 'parentID',
  IS_SUBSIDIARY: 'isSubsidiary',
  HOLDING_COMPANY: 'holdingCompany',
  HOLDING_TAX_ID: 'holdingTaxId',
  HOLDING_OWNERSHIP_PERCENTAGE: 'holdingOwnershipPercentage',
};

export const PartnerTypeValidation = {
  [FormFields.q1]: YupValidations.requiredOption,
  [FormFields.q2]: Yup.string().when(FormFields.q1, {
    is: 'other',
    then: YupValidations.requiredOption,
  }),
  [FormFields.q3]: Yup.string().when(FormFields.q2, {
    is: 'other',
    then: YupValidations.requiredOption,
  }),
  [FormFields.q4]: Yup.string().when(FormFields.q3, {
    is: (value) => ['other', 'piv', 'np'].includes(value),
    then: YupValidations.requiredOption,
  }),
  [FormFields.TICKER_TYPE]: Yup.string().when(FormFields.q1, {
    is: 'pt',
    then: YupValidations.requiredString,
  }),
  [FormFields.TICKER]: Yup.string().when(FormFields.q1, {
    is: 'pt',
    then: Yup.string().when(FormFields.TICKER_TYPE, {
      is: 'NYSE',
      then: Yup.string().required('Required').max(4, 'NYSE Ticker must not exceed 4 characters'),
      otherwise: Yup.string().required('Required').max(5, 'Ticker must not exceed 5 characters'),
    }),
  }),
  [FormFields.IS_SUBSIDIARY]: Yup.string().when(FormFields.q1, {
    is: 'pt',
    then: YupValidations.requiredString,
  }),
  [FormFields.HOLDING_COMPANY]: Yup.string().when(FormFields.IS_SUBSIDIARY, {
    is: 'yes',
    then: YupValidations.requiredString,
  }),
  [FormFields.HOLDING_TAX_ID]: Yup.string().when(FormFields.IS_SUBSIDIARY, {
    is: 'yes',
    then: YupValidations.requiredString,
  }),
  [FormFields.HOLDING_OWNERSHIP_PERCENTAGE]: Yup.string().when(FormFields.IS_SUBSIDIARY, {
    is: 'yes',
    then: Yup.string()
      .required('Required')
      .matches('^[1-9][0-9]?$|^100$', 'Invalid Value'),
  }),
};

const updateTypeQuestionsDisplay = (form) => {
  const initialQuestions = {};
  initialQuestions.showTicker = form.q1 === 'pt';
  initialQuestions.shouldShowHolding = form.IS_SUBSIDIARY === 'yes';
  initialQuestions.shouldShowQ2 = form.q1 === 'other';
  initialQuestions.shouldShowQ3 = initialQuestions.shouldShowQ2 && form.q2 === 'other';
  initialQuestions.shouldShowQ4 = initialQuestions.shouldShowQ3 && form.q3 === 'other';
  return initialQuestions;
};

const updateTypeQuestionsValues = (typeQuestions, newValues, setValue) => {
  const cleanProp = (prop) => {
    setValue(prop, '');
  };
  if (!typeQuestions.shouldShowQ2 && newValues.q2) cleanProp(FormFields.q2);
  if (!typeQuestions.shouldShowQ3 && newValues.q3) cleanProp(FormFields.q3);
  if (!typeQuestions.shouldShowQ4 && newValues.q4) cleanProp(FormFields.q4);
  if (!typeQuestions.showTicker) {
    cleanProp(FormFields.TICKER);
    cleanProp(FormFields.TICKER_TYPE);
    if (newValues.IS_SUBSIDIARY) cleanProp(FormFields.IS_SUBSIDIARY);
  }
};

// JUST PASS CONTROL AND MOVE THIS TO WRAPPER AddPartnerTypeWithRedux
const PartnerType = ({ control = {}, setValue }) => {
  const [typeQuestions, setQuestions] = useState({});
  useFormFieldWatch(
    control,
    [FormFields.q1, FormFields.q2, FormFields.q3, FormFields.q4, FormFields.IS_SUBSIDIARY],
    ([q1, q2, q3, q4,
      IS_SUBSIDIARY,
    ]) => {
      const newValues = {
        q1,
        q2,
        q3,
        q4,
        IS_SUBSIDIARY,
      };
      const newQuestionsDisplay = updateTypeQuestionsDisplay(newValues);
      setQuestions(newQuestionsDisplay);
      updateTypeQuestionsValues(newQuestionsDisplay, newValues, setValue);
    },
  );

  return (
    <Box display="grid">
      <Typography color="textPrimary" variant="h6" sx={{ mb: 1 }}>
        Parent Account
      </Typography>
      <Typography color="textPrimary" variant="body1">
        If this account should be associated to a parent, please choose one below
      </Typography>
      <Box sx={{ mt: 3, mb: 2 }}>
        <ControlledAccountsSelector
          control={control}
          label="Account selector"
          singleMode
          dataTestId="organizationType"
          name={FormFields.PARENT_ACCOUNT}
        />
      </Box>
      <Typography color="textPrimary" variant="h6" sx={{ mb: 1 }}>
        Organization Type
      </Typography>
      <Typography color="textPrimary" variant="body1" sx={{ mb: 3 }}>
        Please answer the series of questions below to classify the organization type.
      </Typography>
      <Typography color="textPrimary" variant="body1" sx={{ mb: 2 }}>
        Please choose from the options below that best describes your organization:
      </Typography>
      <ControlledSelectDropdown
        disableClear
        name={FormFields.q1}
        control={control}
        label="Question 1"
        options={[
          { label: 'Government', value: 'ga' },
          { label: 'Public Traded', value: 'pt' },
          { label: 'Insurance Company (state regulated)', value: 'ic' },
          { label: 'Bank or Savings & Loan', value: 'bosal' },
          { label: 'Other', value: 'other' },
        ].sort((a, b) => (a.label > b.label ? 1 : -1))}
        required
      />
      {(typeQuestions.showTicker)
          && (
            <>
              <Typography color="textPrimary" variant="body1" sx={{ mt: 1, mb: 1 }}>
                Please provide the ticker symbol and the market on which the organization is traded.
              </Typography>
              <Grid container direction="row" justifyContent="space-evenly" sx={{ mb: 0 }}>
                <Grid item xs={4} sx={{ mt: 1, ml: 1 }}>
                  <ControlledRadioGroup
                    name={FormFields.TICKER_TYPE}
                    control={control}
                    aria-label="answer"
                    options={[
                      { value: 'NASDAQ', label: 'NASDAQ' },
                      { value: 'NYSE', label: 'NYSE' },
                      { value: 'Other', label: 'Other' },
                    ]}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }}>
                  <ControlledInputText
                    control={control}
                    name={FormFields.TICKER}
                    label="Stock ticker symbol"
                    required
                  />
                </Grid>

              </Grid>
              <Typography color="textPrimary" variant="body1" sx={{ mt: 1, mb: 1 }}>
                Is this organization a subsidiary of another organization?
              </Typography>
              <Grid container direction="row" justifyContent="space-evenly" sx={{ mb: 0 }}>
                <Grid item xs={4} sx={{ mt: 1, ml: 1 }}>
                  <ControlledRadioGroup
                    name={FormFields.IS_SUBSIDIARY}
                    control={control}
                    aria-label="answer"
                    options={[
                      { value: 'yes', label: 'Yes' },
                      { value: 'no', label: 'No' },
                    ]}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }} />

              </Grid>
              {typeQuestions.shouldShowHolding && (
              <>
                <Typography color="textPrimary" variant="body1" sx={{ mt: 1, mb: 1 }}>
                  Please provide the following information about the holding company:
                </Typography>
                <Grid container direction="row" sx={{ mt: 1, mb: 0 }} spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <ControlledInputText
                      control={control}
                      name={FormFields.HOLDING_COMPANY}
                      label="Company Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ControlledInputMask
                      mask="99-9999999"
                      control={control}
                      name={FormFields.HOLDING_TAX_ID}
                      label="Federal Tax ID"
                      helperText="XX-XXXXXXX"
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ControlledInputText
                      control={control}
                      type="number"
                      required
                      name={FormFields.HOLDING_OWNERSHIP_PERCENTAGE}
                      label="Percent of ownership"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
              </>
              )}
            </>
          )}
      {(typeQuestions.shouldShowQ2)
          && (
            <>
              <Typography color="textPrimary" variant="body1" sx={{ mt: 1, mb: 2 }}>
                Next, please choose the ownership type:
              </Typography>
              <ControlledSelectDropdown
                disableClear
                label="Question 2"
                name={FormFields.q2}
                control={control}
                options={[
                  { label: 'Sole Proprietor', value: 'sp' },
                  { label: 'Individual(s)', value: 'is' },
                  { label: 'Other', value: 'other' },
                ]}
                required
              />
            </>
          )}
      {(typeQuestions.shouldShowQ3)
          && (
            <>
              <Typography color="textPrimary" variant="body1" sx={{ mt: 1, mb: 2 }}>
                Now, please select the option that fits your organization
              </Typography>
              <ControlledSelectDropdown
                disableClear
                label="Question 3"
                name={FormFields.q3}
                control={control}
                options={[
                  { label: 'Non-Profit', value: 'np' },
                  { label: 'Pooled Investment Vehicle', value: 'piv' },
                  { label: 'Other', value: 'other' },
                ]}
                required
              />
            </>
          )}
      {(typeQuestions.shouldShowQ4)
          && (
            <>
              <Typography color="textPrimary" variant="body1" sx={{ mt: 1, mb: 2 }}>
                Finally, select the entity type for your organization
              </Typography>
              <ControlledSelectDropdown
                disableClear
                label="Question 4"
                name={FormFields.q4}
                control={control}
                options={[
                  { label: 'S-Corporation', value: 'sc' },
                  { label: 'Corporation', value: 'c' },
                  { label: 'Partnership (General or Limited)', value: 'p' },
                  { label: 'Limited Liability Partnership - LLP', value: 'llp' },
                  { label: 'Limited Liability Company - LLC', value: 'llc' },
                ]}
                required
              />
            </>
          )}
    </Box>
  );
};

const section = 'addAccount.orgInfo';

export default AccordionPersist({
  label: 'Partner and Organization Type',
  section,
  Component: PersistedSectionForm({
    section,
    Component: PartnerType,
    schema: PartnerTypeValidation,
    defaultValues: {
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      ticker: '',
      tickerType: '',
      parentID: '',
    },
  }),
});
