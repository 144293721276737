import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  get, isEmpty,
} from 'lodash';
import {
  fetchAccountTypes,
  accountTypesState,
} from '../../redux/slices/systemConfig';

export const useAccountTypes = () => {
  const accountTypes = useSelector((state) => get(state, `${accountTypesState}.data`, []));
  const dispatcher = useDispatch();

  useEffect(() => {
    if (isEmpty(accountTypes)) fetchAccountTypes()(dispatcher);
  }, []);

  return accountTypes;
};
