import { CircularProgress, DialogContentText, Grid } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useQuery } from 'react-query';

import { useEffect } from 'react';
import { find, map } from 'lodash';
import { DialogError } from '../../../../../components/shared/Dialogs/DialogError';
import { MerchantBulkEdit } from '../../../../../api';
import { useAccountTypes } from '../../../../../hooks/systemConfig/useAccountTypes';

const renderLoading = () => {
  return (
    <Grid container>
      <Grid item textAlign="center" xs={12}>
        <PendingIcon color="primary" sx={{ fontSize: '80px', mb: 1 }} />
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <DialogContentText sx={{ typography: 'h6', mb: 3 }}>
          Your request is being processed...
        </DialogContentText>
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

const renderSuccess = () => (
  <Grid container>
    <Grid item textAlign="center" xs={12} data-testid="actionSuccessMsg">
      <CheckCircleRoundedIcon color="primary" sx={{ fontSize: '80px' }} />
    </Grid>
    <Grid item textAlign="center" xs={12}>
      <DialogContentText sx={{ typography: 'h6' }}>
        Bulk Edit finished successfully
      </DialogContentText>
    </Grid>
  </Grid>
);

const buildRequest = ({ editBank, selectedMids, bankAccountTypes }) => {
  const { tenderType } = editBank;

  return map(selectedMids, (mid) => {
    const tender = (find(mid?.tenderTypes, ({ paymentMethod }) => {
      return paymentMethod === tenderType;
    }) || {});
    const { id: bankTypeId = {} } = (find(bankAccountTypes, ({ value }) => value === editBank?.type) || {});
    const { bankingSettings = [] } = tender;
    const bankingSettingsToEdit = find(bankingSettings, ({ accountTypeID }) => accountTypeID === bankTypeId);
    return {
      id: mid?.id,
      tenderTypes: [
        {
          id: tender?.id,
          bankingSettings: [
            {
              ...bankingSettingsToEdit,
              accountNumber: editBank?.accountNumber,
              bankName: editBank?.name,
              routingAbaNumber: editBank?.routingAbaNumber,
            },
          ],
        },
      ],
    };
  });
};

function RequestStep({
  meta = {},
  onValidate = () => {},
}) {
  const bankAccountTypes = useAccountTypes();
  const { selectedMids = [], editBank = {} } = meta;
  const payload = buildRequest({ editBank, selectedMids, bankAccountTypes });
  const bulkEditRequest = useQuery(['mid-bulk-edit'], () => MerchantBulkEdit({ payload }), { retry: false });
  const { isLoading, isSuccess } = bulkEditRequest;

  const renderRejected = () => {
    return <DialogError error={bulkEditRequest.error} />;
  };

  useEffect(() => {
    if (isLoading) {
      onValidate({ isLoading });
    } else {
      onValidate({ });
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && renderLoading()}
      {isSuccess && renderSuccess()}
      {bulkEditRequest.error && renderRejected()}
    </>
  );
}

export default RequestStep;
