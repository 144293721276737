import {
  Card,
  Chip,
  Divider,
} from '@mui/material';
import { CopyBlock, a11yLight } from 'react-code-blocks';
import parse from 'html-react-parser';
import { useQuery } from 'react-query';
import DeviceSnippetPOSRequest from './DeviceSnippetPOSRequest';

const POSForm = ({
  merchantId,
  deviceIds,
}) => {
  const { data, isLoading, error } = useQuery(['device-pos-snippet'], () => DeviceSnippetPOSRequest(merchantId, deviceIds),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    });
  return (
    <>
      {!isLoading && !error && (
        <Card sx={{ mt: 3 }}>
          <Card sx={{ height: 400, overflowY: 'auto', mb: 5 }}>
            <Divider sx={{ mb: 2 }}>
              <Chip label="Snippet" />
            </Divider>
            <CopyBlock
              language="html"
              text={data}
              theme={a11yLight}
              showLineNumbers={false}
              wrapLines
              codeBlock
            />
          </Card>
          <Divider>
            <Chip label="Preview" />
          </Divider>
          {parse(data, {
            // eslint-disable-next-line
            replace: (domNode) => {
              if (domNode.type === 'script') {
                if (!document.getElementById(domNode.attribs?.id)) {
                  const script = document.createElement('script');
                  if (domNode.attribs?.src?.includes('core-device-sdk')) {
                    script.src = domNode.attribs.src;
                  } else {
                    script.onload = () => {
                    // eslint-disable-next-line no-eval
                      eval(domNode.children[0]?.data);
                    };
                    script.text = domNode.children[0]?.data;
                  }
                  script.id = domNode.attribs.id;
                  document.head.appendChild(script);
                }
              }
            },
          })}
        </Card>
      )}
    </>

  );
};

export default POSForm;
