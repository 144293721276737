import {
  useState, useMemo, Fragment, useEffect,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Stack,
  Divider,
  Container,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
} from '@mui/material';

import { find, isEmpty, map } from 'lodash';
import { useQuery } from 'react-query';
import { FiltersModal } from './Filters/FiltersModal';
import { MerchantModal } from './Merchants/MerchantModal';
import FilterDisplay from './Filters/FilterDisplay';

import { useGetAccountsFromId } from '../../components/shared/AccountsSelector';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { ActionsMenu } from '../../components/ActionsMenu';
import useSettings from '../../hooks/useSettings';
import { mainContainerSX } from '../../shared/styles';
import PencilAltIcon from '../../icons/PencilAlt';
import { ACTIONS, RESOURCES } from '../../IAM/configuration';
import EditItemsModal from './EditItemsModal';
import ViewMerchants from './Merchants/ViewMerchants';
import { useUrlState } from '../../hooks/useParams';
import { MerchantFilterRequest } from '../../api';

const getBreadcrumbOptions = () => [
  {
    id: 'management',
    render: 'Management',
  },
  {
    id: 'administration',
    render: 'Administration',
  },
  {
    id: 'bulk-edit',
    render: 'Bulk Edit',
  },
];

const filterArrayToStringQuery = (accum, item) => {
  const itemQuery = `filter=${item.filter}&operator=${item.operator}&value=${item.value}`;
  return accum ? `${accum}&${itemQuery}` : itemQuery;
};

const BulkEditView = () => {
  const { settings } = useSettings();
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [MIDDetailsModal, setMIDDetailsModal] = useState('');
  const title = 'Bulk Edit';
  const [editModal, setEditModal] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState([]);
  const [selectedMIDs, setSelectedMIDs] = useState([]); // Only IDs
  const [selectedFullInfo, setSelectedFullInfo] = useState([]); // Full Merchants
  const filteredAccount = useGetAccountsFromId(filtersSelected.find((f) => f.filter === 'accountID')?.value);
  const [{ limit, page }, setLimitAndPage] = useState({});
  const filteredMerchantsQuery = useQuery(
    ['merchant-filter-list', filtersSelected, limit, page],
    () => MerchantFilterRequest({
      filtersSelected,
      limit,
      page,
    }),
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (!isEmpty(selectedMIDs)) {
      const { merchants = [] } = filteredMerchantsQuery?.data;
      setSelectedFullInfo(map(selectedMIDs, (id) => find(merchants, ({ id: mid }) => mid === id)));
    }
  }, [selectedMIDs]);

  const handleGridFetch = (values) => {
    setLimitAndPage(values);
  };

  useUrlState(filtersSelected, {
    onLoad: (value) => setFiltersSelected(value),
    transformToUrl: (value) => value.reduce(filterArrayToStringQuery, ''),
    transformFromUrl: (searchString) => {
      if (!searchString) return [];
      const filters = searchString.split('&');
      const filtersList = [];
      for (let i = 0; i < filters.length; i += 3) {
        const value = filters[i + 2].split('=')[1];
        const filter = filters[i].split('=')[1];
        const operator = filters[i + 1].split('=')[1];
        filtersList.push({
          id: uuidv4(),
          error: false,
          filter,
          operator,
          value: filter === 'accountID' ? [value] : value,
        });
      }
      return filtersList;
    },
  });
  const handleFiltersModalOpen = () => {
    setIsFiltersModalOpen(true);
  };

  const handleFiltersModalClose = () => {
    setIsFiltersModalOpen(false);
  };

  const handleMerchantModalOpen = (merchantId) => {
    setMIDDetailsModal(merchantId);
  };

  const handleMerchantModalClose = () => {
    setMIDDetailsModal();
  };

  const EditItemsButton = [
    {
      label: selectedMIDs.length
        ? `Edit ${selectedMIDs.length} Items`
        : 'Edit Items',
      onClick: () => setEditModal(true),
      disabled: selectedMIDs.length === 0,
      icon: <PencilAltIcon />,
      permissions: [RESOURCES.MERCHANTS, ACTIONS.UPDATE],
    },
  ];

  const onApplyFilters = (filters) => {
    setFiltersSelected(filters);
    handleFiltersModalClose();
  };

  const breadcrumbOptions = useMemo(() => {
    return getBreadcrumbOptions();
  }, []);
  return (
    <>
      <Helmet>
        <title>MSP | Bulk Edit</title>
      </Helmet>
      <Box sx={mainContainerSX}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                {title}
              </Typography>
              <Breadcrumbs maxItems={5} items={breadcrumbOptions} />
            </Grid>
            <Grid item>
              <ActionsMenu actions={EditItemsButton} />
            </Grid>
          </Grid>
          <Card sx={{ mt: 3 }}>
            <CardActions sx={{ cursor: 'pointer' }}>
              <Typography variant="h6" sx={{ mx: 1 }}>
                Filters
              </Typography>
            </CardActions>
            <Divider />
            <div>
              <Stack spacing={0}>
                {!isEmpty(filtersSelected) && (
                  <>
                    <div style={{ paddingTop: '16px', paddingBottom: '8px' }}>
                      {filtersSelected.map((filter) => (
                        <Fragment key={`${filter.type}${filter.value}`}>
                          <FilterDisplay
                            key={`${filter.filter}${filter.value}`}
                            value={filter.filter === 'accountID' ? filteredAccount.legalBusinessName : filter.value}
                            operator={filter.operator}
                            filter={filter.filter}
                          />
                        </Fragment>
                      ))}
                    </div>
                    <Divider />
                  </>
                )}
              </Stack>
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                  pb: '16px!important',
                }}
              >
                <Button
                  onClick={handleFiltersModalOpen}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  {filtersSelected.length ? 'Edit Filters' : 'Add Filters'}
                </Button>
              </CardContent>
            </div>
          </Card>
          <Card sx={{ mt: 3 }}>
            {
              isEmpty(filtersSelected)
                ? (
                  <Typography sx={{ my: 2, textAlign: 'center' }}>
                    No result found. Apply at least one filter to locate Merchant Accounts
                  </Typography>
                )
                : (
                  <ViewMerchants
                    gridProps={{
                      filteredMerchantsQuery,
                      handleGridFetch,
                    }}
                    openModal={handleMerchantModalOpen}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectedMIDs(newSelectionModel);
                    }}
                    selectionModel={selectedMIDs}
                  />
                )
            }
          </Card>
        </Container>
        <MerchantModal isOpen={!!MIDDetailsModal} onClose={handleMerchantModalClose} merchantId={MIDDetailsModal} />
        <FiltersModal initialFilters={filtersSelected} isOpen={isFiltersModalOpen} onClose={handleFiltersModalClose} onApplyFilters={onApplyFilters} />
        <EditItemsModal
          open={editModal}
          onClose={() => setEditModal(false)}
          selectedMids={selectedFullInfo}
          filtersSelected={filtersSelected}
          onFinish={() => setSelectedMIDs([])}
        />
      </Box>
    </>
  );
};

export default BulkEditView;
