import client from '../httpClient';

export async function getAccountTypes(params = {}) {
  return client.get('/account-types', {
    params,
  });
}

export async function getPaymentMethods(params = {}) {
  return client.get('/payment-methods', {
    params,
  });
}
