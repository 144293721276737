import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  Button,
} from '@mui/material';
import {
  useMutation,
} from 'react-query';
import { isFunction } from 'lodash';
import ResetDialog from '../ResetDialog';
import ProcessingDialog from '../ProcessingDialog';
import useToggle from '../../../../hooks/useToggle';
import Dialog from '../Dialog/Dialog';
import './styles.css';

function MutationDialog({
  children, onClose, request, getForm, onReset, disableSubmit, disableReset, successText, showFooter = true, onMutationFail, onMutationSucceed, ...rest
}) {
  const resetDialogToggle = useToggle();
  const processingDialogToggle = useToggle();
  const editRequest = useMutation(request);

  const handleSubmit = () => {
    processingDialogToggle.turnOn();
    editRequest.mutate(getForm());
  };

  const handleReset = () => {
    resetDialogToggle.turnOff();
    onReset();
  };

  const handleClose = () => {
    onClose();
    resetDialogToggle.turnOff();
    processingDialogToggle.turnOff();
  };

  const handleFinishedFlow = () => {
    onReset(editRequest.variables);
    onClose(editRequest.variables);
    resetDialogToggle.turnOff();
    processingDialogToggle.turnOff();
    if (isFunction(onMutationSucceed)) onMutationSucceed(editRequest.variables);
  };

  useEffect(() => {
    if (editRequest.isError && isFunction(onMutationFail)) {
      onMutationFail();
    }
  }, [editRequest.isError]);

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: { xs: 'calc(100% - 16px)', sm: 'calc(100% - 64px)' },
          margin: { xs: '8px', sm: '32px' },
        },
      }}
      actionSection={showFooter && (
        <>
          <Button
            variant="outlined"
            color="primary"
            data-testid="cancelButton"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={disableReset}
            variant="contained"
            color="secondary"
            data-testid="resetButton"
            onClick={resetDialogToggle.turnOn}
          >
            Reset
          </Button>
          <Button
            disabled={disableSubmit}
            variant="contained"
            color="primary"
            data-testid="submitButton"
            onClick={handleSubmit}
            sx={{
              padding: { xs: '6px', sm: '6px 16px' },
            }}
          >
            Save Changes
          </Button>
        </>
      )}
      {...rest}
    >
      {children}
      <ResetDialog
        onConfirm={handleReset}
        onClose={resetDialogToggle.turnOff}
        isOpen={resetDialogToggle.isActive}
      />
      <ProcessingDialog
        successText={successText}
        isOpen={processingDialogToggle.isActive}
        onClose={processingDialogToggle.turnOff}
        onFinished={handleFinishedFlow}
        onRetry={handleSubmit}
        status={editRequest.status}
        error={editRequest.error}
      />
    </Dialog>
  );
}

MutationDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
  getForm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  disableReset: PropTypes.bool,
};

export default MutationDialog;
