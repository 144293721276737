import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import {
  Box, Card, CardContent, Container, Typography, Button,
} from '@mui/material';
import ForgotPasswordForm from '../components/forgot-password/ForgotPasswordForm';
import { ReactComponent as CoreLogo } from '../components/logos/Core1.svg';

const ForgotPassword = () => {
  const [email, setEmail] = useState('pending');
  const navigate = useNavigate();

  const returnToLogin = () => navigate('/');
  return (
    <>
      <Helmet>
        <title>MSP | Forgot Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px', pt: '80px' }}
        >
          <div className="login-logo">
            <CoreLogo />
          </div>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Forgot Password
                  </Typography>
                </div>
              </Box>
              <Typography
                color="textPrimary"
                mb={2}
                variant="subtitle1"
              >
                { email === 'pending'
                  ? 'Please input your registered e-mail below.'
                  : 'An email has been sent with instructions to reset your password.'}
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {email === 'pending' && (
                  <ForgotPasswordForm
                    setEmail={setEmail}
                  />
                )}
                {email !== 'pending' && (
                <Button
                  color="primary"
                  fullWidth
                  onClick={returnToLogin}
                  variant="contained"
                >
                  Return to login
                </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
export default ForgotPassword;
