import { createSlice } from '@reduxjs/toolkit';
import { fetchAccountTypesReducers, fetchAccountTypesThunk } from '../api/systemConfig/fetchAccountTypes';
import { fetchPaymentMethodsReducer, fetchPaymentMethodsThunk } from '../api/systemConfig/fetchPaymentMethods';

export const initialState = {
  accountTypes: {
    data: [],
    status: '',
    isLoading: false,
    statusText: '',
  },
  paymentMethods: {
    data: [],
    status: '',
    isLoading: false,
    statusText: '',
  },
};

export const systemConfigReducer = 'systemConfigReducer';
export const accountTypesState = `${systemConfigReducer}.accountTypes`;
export const paymentMethodsState = `${systemConfigReducer}.paymentMethods`;

const slice = createSlice({
  name: 'systemConfig',
  initialState,
  extraReducers: {
    ...fetchAccountTypesReducers,
    ...fetchPaymentMethodsReducer,
  },
});

export const { reducer } = slice;

export const fetchAccountTypes = (params) => (dispatch) => {
  dispatch(fetchAccountTypesThunk(params));
};

export const fetchPaymentMethods = (params) => (dispatch) => {
  dispatch(fetchPaymentMethodsThunk(params));
};

export default slice;
