import {
  experimentalStyled,
  Table, TableBody, TableCell, TableHead, Typography,
} from '@mui/material';
import MuiTableRow from '@mui/material/TableRow';

import {
  capitalize,
  find,
  first,
  get,
  isEmpty,
  isNil,
  keys,
  map,
  omit as _omit,
} from 'lodash';

const TableRow = experimentalStyled(MuiTableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

const formatKeyToLabel = (k) => {
  const camelCasedToLowerCaseSpaced = k.replace(/([A-Z])/g, ' $1').toLowerCase();
  return capitalize(camelCasedToLowerCaseSpaced);
};

export const ColTable = ({
  dataArray, cols, autoGenerateCols = false, omit = [], ...rest
}) => {
  const columns = autoGenerateCols ? map(keys(_omit(first(dataArray), omit)), (field) => {
    const label = formatKeyToLabel(field);
    const alreadySpecified = find(cols, ({ field: specifiedField }) => field === specifiedField);
    if (!alreadySpecified) {
      const valueGetter = (dataSet) => `${get(dataSet, [field], '-')}`;
      return { label, valueGetter };
    }
    return alreadySpecified;
  }) : cols;
  const tableHeadContent = map(columns, ({ label }) => {
    return (
      <TableCell align="left" sx={{ width: '15%' }}>
        <Typography color="textPrimary" variant="subtitle2" fontSize={15} fontWeight="bold">
          {label}
        </Typography>
      </TableCell>
    );
  });
  const tableContent = map(dataArray, (row, key) => {
    return (
      <MuiTableRow key={key}>
        {map(columns, ({ valueGetter, cellSx = {} }) => {
          let value = valueGetter(row) || '-';
          value = (isNil(value) || value === 'null' || value === 'undefined') ? '-' : value;
          return (
            <TableCell sx={cellSx}>
              {value}
            </TableCell>
          );
        })}
      </MuiTableRow>
    );
  });
  const noData = (
    <TableRow key="no-data">
      <TableCell>
        No data found
      </TableCell>
    </TableRow>
  );
  const showContent = !isEmpty(tableContent.filter((notNull) => notNull));
  return (
    <Table {...rest}>
      {showContent && (
      <TableHead>
        <TableRow>
          {tableHeadContent}
        </TableRow>
      </TableHead>
      )}
      <TableBody>
        {showContent ? tableContent : noData}
      </TableBody>
    </Table>
  );
};
