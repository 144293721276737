import {
  FormHelperText,
} from '@mui/material';

import CurrencyTextField from '@unicef/material-ui-currency-textfield';

function CurrencyInput({
  name, onChange, error, ...props
}) {
  const handleChange = (e, change) => {
    onChange(name, change);
  };
  return (
    <>
      <CurrencyTextField
        onChange={handleChange}
        inputProps={{ sx: { display: 'flex !important' } }}
        variant="outlined"
        currencySymbol="$"
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        error={!!error}
        {...props}
      />
      <FormHelperText error>{error?.message}</FormHelperText>
    </>
  );
}

export default CurrencyInput;
