import { useEffect, useState } from 'react';
import {
  Grid,
} from '@mui/material';

import InputText from '../../../../../components/forms/InputText';
import { AccountTypeNames } from '../../../../../shared/constants';

const DEFAULT_FORM = {
  name: '',
  routingAbaNumber: '',
  accountNumber: '',
};

function EditBankStep({
  initial = DEFAULT_FORM, onValidate, onChange, meta,
}) {
  const [form, setForm] = useState(initial?.bankAccountType || DEFAULT_FORM);
  const { selection = {} } = meta;
  const { bankAccountType = {} } = selection;
  const { type = '' } = bankAccountType;
  const handleChange = (name, value) => {
    const changes = {
      ...form,
      [name]: value,
    };
    setForm(changes);
    onChange(changes);
  };

  const validate = (data) => {
    const validationResult = {};
    if (!data?.name) {
      validationResult.name = 'Required';
    }
    if (!data?.routingAbaNumber) {
      validationResult.routingAbaNumber = 'Required';
    }
    if (!data?.accountNumber) {
      validationResult.accountNumber = 'Required';
    }
    return validationResult;
  };

  useEffect(() => {
    const validationResult = validate(form);
    onValidate(validationResult);
  }, [form]);
  return (
    <>
      <h4 style={{ marginTop: '0', marginBottom: '0', marginLeft: '8px' }}>
        {AccountTypeNames[type]}
        :
        {' '}
        {form.bankAccount}
      </h4>
      <Grid item xs={12}>
        <InputText
          required
          label="Bank name"
          name="name"
          onChange={handleChange}
          value={form.name}
        />
      </Grid>
      <Grid item xs={6}>
        <InputText
          required
          label="Routing/ABA number"
          name="routingAbaNumber"
          onChange={handleChange}
          value={form.routingAbaNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <InputText
          required
          label="Account number"
          name="accountNumber"
          onChange={handleChange}
          value={form.accountNumber}
        />
      </Grid>
    </>
  );
}

export default EditBankStep;
