import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { client } from '../../../api';

export const fetchFundingGroupByIdThunk = createAsyncThunk('fundingGroupById.fetchById', async (payload, { rejectWithValue }) => {
  const URL = `/funding/${payload}`;
  try {
    const { data, status, statusText } = await client.get(URL);
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const fetchFundingGroupByIdReducers = {
  [fetchFundingGroupByIdThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'response',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },
  [fetchFundingGroupByIdThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },
  [fetchFundingGroupByIdThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'error',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },
};
