import {
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ControlledInputText } from '../InputText';
import { ControlledAutocomplete } from '../Autocomplete';
import { US_STATES } from '../../../shared/usStates';
import COUNTRIES from '../../../shared/countries';
import { prefixFormName } from '../../../utils/formatting/Form';

export const FormFields = {
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'postalCode',
  COUNTRY: 'country',
};

function Address({
  name,
  disabled = false,
  control,
  validate,
  countryFirst = false,
  dataTestId,
}) {
  return (
    <>
      {countryFirst
      && (
        <Grid item xs={12} lg={6}>
          <ControlledAutocomplete
            key={`${name}-countryField`}
            control={control}
            disabled={disabled}
            options={COUNTRIES}
            label="Country"
            name={(name && typeof name === 'string') ? name.concat('.', FormFields.COUNTRY) : FormFields.COUNTRY}
            dataTestId={`${dataTestId || ''}CountryField`}
            required={validate}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ControlledInputText
          key={`${name}-address1Field`}
          control={control}
          disabled={disabled}
          name={prefixFormName(name, FormFields.ADDRESS1)}
          data-testid={`${dataTestId || ''}Address1Field`}
          label="Address 1"
          required={validate}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledInputText
          key={`${name}-address2Field`}
          control={control}
          disabled={disabled}
          name={prefixFormName(name, FormFields.ADDRESS2)}
          data-testid={`${dataTestId || ''}Address2Field`}
          label="Address 2"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ControlledInputText
          key={`${name}-cityField`}
          control={control}
          disabled={disabled}
          name={prefixFormName(name, FormFields.CITY)}
          data-testid={`${dataTestId || ''}CityField`}
          label="City"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ControlledAutocomplete
          key={`${name}-stateField`}
          control={control}
          disabled={disabled}
          options={US_STATES}
          label="State/Region"
          name={prefixFormName(name, FormFields.STATE)}
          required={validate}
          dataTestId={`${dataTestId || ''}StateField`}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <ControlledInputText
          key={`${name}-zipCodeField`}
          control={control}
          disabled={disabled}
          name={prefixFormName(name, FormFields.ZIP)}
          label="Zip/Postal code"
          required={validate}
          data-testid={`${dataTestId || ''}ZipCodeField`}
        />
      </Grid>
      {!countryFirst && (
        <Grid item xs={12}>
          <ControlledAutocomplete
            key={`${name}-countryField`}
            control={control}
            disabled={disabled}
            options={COUNTRIES}
            label="Country"
            name={(name && typeof name === 'string') ? name.concat('.', FormFields.COUNTRY) : FormFields.COUNTRY}
            dataTestId={`${dataTestId || ''}CountryField`}
            required={validate}
          />
        </Grid>
      )}
    </>
  );
}

Address.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validate: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
};

export default Address;
