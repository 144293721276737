/* eslint-disable no-console */
import axios from 'axios';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import { merchantServicesAPI } from '../config';

const httpClient = axios.create({
  baseURL: merchantServicesAPI.URL,
  withCredentials: true,
  paramsSerializer: (p) => {
    return qs.stringify(p, { encode: false, arrayFormat: 'repeat' });
  },
});

httpClient.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
httpClient.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';
httpClient.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';

httpClient.interceptors.request.use(async (config) => {
  console.debug(
    `${get(new Date().toISOString().split('T'), [1], '')} [${config?.method.toUpperCase()} ${config?.url}]`,
    { headers: config?.headers, body: config?.data },
  );
  const finalConfig = {
    ...config,
    headers: {
      ...(config.headers || {}),
      'Idempotency-Key': uuidv4(),
    },
  };
  return finalConfig;
}, (error) => {
  console.debug(`${get(new Date().toISOString().split('T'), [1], '')} [NETWORK] [REQUEST ERROR]`, error);
  return Promise.reject(error);
});

httpClient.interceptors.response.use(async (response) => {
  console.debug(`${get(new Date().toISOString().split('T'), [1], '')} [${response?.status}] [${response?.config?.method?.toUpperCase()} ${response?.config?.url}]: `,
    { headers: response?.headers, data: response?.data });
  return response;
}, async (error) => {
  if (error?.response?.status === 401) {
    try {
      const { data } = await httpClient.get('/users/me');
      if (!data?.id) {
        throw new Error();
      }
    } catch (e) {
      const { response = {} } = e;
      const { status = 400 } = response;
      if (status >= 400) {
        localStorage.removeItem('loggedUser');
        window.location.reload();
      }
    }
  }
  console.debug(`${get(new Date().toISOString().split('T'), [1], '')} [NETWORK] [RESPONSE ERROR]`, error);
  return Promise.reject(error);
});

export default httpClient;
