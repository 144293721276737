import client from '../httpClient';
import ErrorFactory from '../../shared/errors';

export const CURRENCY_FORMAT = {
  style: 'currency',
  currency: 'USD',
};

const valueToMethodHash = {
  1: 'ACH',
  2: 'Card',
  3: 'Token',
  4: 'Related',
  5: 'Wire',
  6: 'Cash',
  7: 'Check',
  8: 'Invoice',
};

const mapFrom = (response) => ({
  ...response,
  transactions: response.transactions.map((row) => ({
    ...row,
    transactionDate: new Date(row.authorizationDate).toLocaleString('en-US'),
    methodSourceDisplay: row.methodSource === 1 ? 'Online' : 'TXT',
    methodUsedDisplay: valueToMethodHash[row.methodUsed] || 'Unregistered',
    transactionAmountDisplay: new Intl.NumberFormat(
      'en-US',
      CURRENCY_FORMAT,
    ).format(Number(row.transactionAmount) + Number(row.feeAmount?.total)),
    id: row.transactionId,
  })),
});

export async function GetTransactions(params) {
  return client.get('/transactions', { params });
}

export async function TransactionsListRequest({ queryKey }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const [_, params] = queryKey;
    const response = await GetTransactions(params);
    return mapFrom(response?.data);
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export async function TransactionListRequest({ id }) {
  try {
    if (!id) return {};
    const response = await client.get(`/transactions/${id}`);
    return response?.data?.transaction;
  } catch (err) {
    throw ErrorFactory(err);
  }
}
