import { React, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Button,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import {
  get, reduce, map, keys,
} from 'lodash';

import Breadcrumbs from '../../components/shared/Breadcrumbs';
import APIDataGrid from '../../components/shared/APIDataGrid';
import useSettings from '../../hooks/useSettings';
import {
  fetchAccountList,
  listAccountsState,
  resetGetAccount,
} from '../../redux/slices/accounts';
import { ActionsMenu } from '../../components/ActionsMenu';
import { mainContainerSX } from '../../shared/styles';
import getColumns from './columns';
import menuActions from './menuActions';
import useLoggedUser from '../../hooks/useLoggedUser';
import { SearchInput } from '../../components/shared/SearchInput';
import { useIsMobileView } from '../../hooks/useIsMobileView';

const getBreadcrumbOptions = (userRole) => [
  {
    id: 'accounts',
    // redirect: '/',
    render: 'Organization Structure',
  },
  {
    id: 'view-accounts',
    render: userRole === 'AU' ? 'My Accounts' : 'View Accounts',
  },
];

const Accounts = () => {
  const [search, setSearch] = useState('');
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const { settings } = useSettings();
  const apiResponse = useSelector((state) => get(state, `${listAccountsState}.response`, {}));
  const isListLoading = useSelector((state) => get(state, `${listAccountsState}.isLoading`, false));
  const { role } = useLoggedUser();
  const isMobileView = useIsMobileView();
  const onGoToDetails = (row) => (e) => {
    e.stopPropagation();
    resetGetAccount()(dispatcher);
    return navigate(`/accounts/${row?.id}`);
  };

  const title = useMemo(() => (role !== 'AU'
    ? 'View Accounts'
    : 'My Accounts'), [role]);

  const cols = useMemo(() => getColumns({
    onGoToDetails,
    userRole: role,
    isMobileView,
  }), [isMobileView]);

  const actionButtons = useMemo(() => menuActions({
    onAdd: () => {
      navigate('/accounts/add');
    },
  }));

  const apiResponseWithHierarchy = useMemo(() => {
    const { accounts } = apiResponse;

    const accountsById = reduce(
      accounts,
      (acc, curr) => {
        acc[curr?.id] = curr;
        return acc;
      },
      {},
    );
    const buildPath = (account, acc = [account?.id]) => {
      const parent = accountsById[account?.parentId];
      if (!parent || parent?.id === account?.id) {
        return acc;
      }
      return buildPath(parent, [parent?.id, ...acc]);
    };

    return {
      ...apiResponse,
      accounts: map(keys(accountsById), (id) => {
        const account = accountsById[id];
        const path = buildPath(account);
        return {
          ...account,
          path,
        };
      }),
      limit: apiResponse?.limit || 10,
    };
  }, [apiResponse]);

  const handleGridFetch = ({ refreshTriggers, limit, page }) => {
    const [tsearch] = refreshTriggers;
    fetchAccountList({
      legalName: tsearch, limit, page,
    })(dispatcher);
  };

  return (
    <>
      <Helmet>
        <title>MSP | Accounts</title>
      </Helmet>
      <Box sx={mainContainerSX}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={8}>
              <Typography color="textPrimary" variant="h3">
                {title}
              </Typography>
            </Grid>
            <Grid>
              <ActionsMenu actions={actionButtons} />
            </Grid>
            <Grid item xs={12}>
              <Breadcrumbs
                maxItems={5}
                items={getBreadcrumbOptions(role)}
              />
            </Grid>
          </Grid>
          <SearchInput placeholder="Search accounts" value={search} setValue={setSearch} sizeProps={{ xs: 10, md: 5, sm: 11 }} />
          <Card sx={{ mt: 3 }}>
            <APIDataGrid
              cols={cols}
              src={apiResponseWithHierarchy}
              dataProp="accounts"
              onFetch={handleGridFetch}
              loading={isListLoading}
              refreshTriggers={[search]}
              // Tree data props:
              treeData
              getTreeDataPath={(row) => row.path}
              defaultGroupingExpansionDepth={5}
              groupingColDef={{
                headerName: 'Account',
                headerAlign: 'center',
                flex: 0.7,
                hideDescendantCount: true,
                renderCell: ({ row }) => (isMobileView ? (
                  <Button
                    color="primary"
                    onClick={onGoToDetails(row)}
                    className="table-main-name-button"
                  >
                    {row?.legalBusinessName}
                  </Button>
                ) : (
                  <span className="table-main-name">
                    {row?.legalBusinessName}
                  </span>
                )),
              }}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: 'Root accounts per page',
                },
              }}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Accounts;
