import { combineReducers } from '@reduxjs/toolkit';
import { reducer as accountsReducer } from './slices/accounts';
import { reducer as fundingGroupsReducer } from './slices/fundingGroups';
import { reducer as usersReducer } from './slices/users';
import { reducer as loginReducer } from './slices/login';
import { reducer as transactionsReducer } from './slices/transactions';
import { reducer as merchantsReducer } from './slices/merchants';
import { reducer as notificationsReducer } from './slices/notifications';
import { reducer as systemConfigReducer } from './slices/systemConfig';
import { reducer as persistedFormsReducer } from './slices/persistedForms';

const allReducers = combineReducers({
  merchantsReducer,
  notificationsReducer,
  accountsReducer,
  fundingGroupsReducer,
  usersReducer,
  loginReducer,
  transactionsReducer,
  systemConfigReducer,
  persistedFormsReducer,
});

const rootReducer = (state, action) => {
  return allReducers(action.type !== 'CLEAR_PERSISTED_ROOT' ? state : undefined, action);
};

export default rootReducer;
