import {
  IconButton,
  Typography,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';

// import Label from '../../components/Label';
import { DateTimeFormat } from '../../../utils/formatting/Dates';

// const getStatusLabel = (status) => {
//   const statusMap = {
//     LOCKED: {
//       color: 'error',
//       text: 'LOCKED',
//     },
//     ACTIVE: {
//       color: 'success',
//       text: 'ACTIVE',
//     },
//     INACTIVE: {
//       color: 'warning',
//       text: 'INACTIVE',
//     },
//   };

//   const { text, color } = statusMap[status.toUpperCase()];

//   return <Label color={color}>{text}</Label>;
// };

const columns = ({ onGoToDetails, getAccountName, goToAccount }) => [
  {
    field: 'id',
    hide: true,
  },
  // {
  //   field: 'status',
  //   headerName: 'Status',
  //   valueOptions: ['active', 'inactive', 'locked'],
  //   headerAlign: 'center',
  //   align: 'center',
  //   sortable: false,
  //   flex: 0.2,
  //   renderCell: (param) => getStatusLabel(param.value.toUpperCase()),
  // },
  {
    field: 'name',
    headerName: 'Name',
    headerAlign: 'left',
    sortable: false,
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          color: '#172b4d',
        }}
      >
        {row?.name}
      </Typography>
    ),
  },
  {
    field: 'accountId',
    headerName: 'Account Name',
    headerAlign: 'left',
    sortable: false,
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography
        sx={{
          fontSize: '14px',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        color="primary"
        onClick={() => goToAccount(row?.accountId)}
      >
        {getAccountName(row?.accountId)}
      </Typography>
    ),
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    headerAlign: 'left',
    sortable: false,
    flex: 0.5,
    valueFormatter: (params) => DateTimeFormat(params.value),
  },
  {
    field: 'actions',
    headerName: 'Details',
    headerAlign: 'center',
    flex: 0.3,
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => (
      <IconButton
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          onGoToDetails(row);
        }}
        size="large"
      >
        <Visibility />
      </IconButton>
    ),
  },
];

export default columns;
