import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { client } from '../../../api';
import { merchantServicesAPI } from '../../../config';

export const fetchMerchantsInFundingGroupThunk = createAsyncThunk('fundingGroups.searchMerchants', async ({ params, fundingGroupId }, { rejectWithValue }) => {
  try {
    const URL = `${merchantServicesAPI.URL}/funding/${fundingGroupId}/members`;
    const { data, status, statusText } = await client.get(URL, {
      params,
    });
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const fetchMerchantsInFundingGroupReducers = {
  [fetchMerchantsInFundingGroupThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'merchantSearch',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
  [fetchMerchantsInFundingGroupThunk.pending]: (state) => {
    set(
      state,
      'list.isLoading',
      true,
    );
  },
  [fetchMerchantsInFundingGroupThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'list.error',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
};
