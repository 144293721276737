import { createSlice } from '@reduxjs/toolkit';
import {
  get, set,
} from 'lodash';

export const accountsReducer = 'accountsReducer';
export const addAccountState = `${accountsReducer}.add`;
export const listAccountsState = `${accountsReducer}.list`;

const generateInitialState = (name, {
  active = false, reset = false,
}) => ({
  isActive: active,
  form: {},
  mode: 'add',
  errors: 0,
  savedEntities: [],
  shouldReset: reset,
});

const addAccountSections = [
  'applicantContact', 'controlSigner', 'beneficialOwners', 'tradeReferences', 'itManagerInfo',
  'bankAccount', 'comments', 'corporateAccount', 'corporateContacts', 'orgInfo', 'singleBeneficialOwner',
];

const submitStatus = {
  IDLE: 'idle',
  SUBMITTING: 'submitting',
  SUBMITTED: 'submitted',
  SUBMIT_ERROR: 'submittedWithErrors',
};

const getAccountsInitialState = (sectionsList, withResetEnabled = false) => {
  const initialState = {};

  sectionsList.forEach((section) => {
    initialState[section] = generateInitialState(section, {
      active: section === 'orgInfo',
      reset: withResetEnabled,
    });
  });

  initialState.submit = submitStatus.IDLE;
  initialState.shouldReset = false;
  initialState.active = 'orgInfo';

  return initialState;
};

export const initialState = {
  addAccount: getAccountsInitialState(addAccountSections),
};

const AddAccountReducers = {
  resetSingleBeneficial: (state) => {
    set(state, 'addAccount.singleBeneficialOwner', {
      isActive: false,
      form: {},
      mode: 'add',
      errors: 0,
      savedEntities: [],
      shouldReset: false,
    });
  },
  resetBeneficial(state) {
    set(state, 'addAccount.beneficialOwners', {
      isActive: false,
      form: {},
      mode: 'add',
      errors: 0,
      savedEntities: [],
      shouldReset: false,
    });
  },
  resetControlSigner(state) {
    set(state, 'addAccount.controlSigner', {
      isActive: false,
      form: {},
      mode: 'add',
      errors: 0,
      savedEntities: [],
      shouldReset: false,
    });
  },
};

const beneficialOwnersPath = 'addAccount.beneficialOwners.savedEntities';
const controlOwnershipPath = 'addAccount.controlSigner.form.ownershipPercentage';

const slice = createSlice({
  name: 'persistedForms',
  initialState,
  reducers: {
    resetAll: (state, action) => {
      const { sectionPath } = action.payload;
      set(state, sectionPath, getAccountsInitialState(addAccountSections, true));
    },
    setProp(state, action) {
      const { sectionPath, value } = action.payload;
      if (sectionPath === controlOwnershipPath) {
        const savedEntities = get(state, beneficialOwnersPath, []);
        if (get(state, controlOwnershipPath, 0) > 0) {
          const firstEntity = savedEntities[0];
          const newEntity = {
            ownershipPercentage: value,
            readOnly: true,
            firstName: 'Control',
            lastName: 'Signer',
          };
          const updatedEntities = (firstEntity?.firstName === 'Control' && firstEntity?.lastName === 'Signer')
            ? [newEntity, ...savedEntities.slice(1)]
            : [
              newEntity,
              ...savedEntities,
            ];

          set(state, beneficialOwnersPath, updatedEntities);
        } else {
          set(state, beneficialOwnersPath, savedEntities.slice(1));
        }
      }
      set(state, sectionPath, value);
    },
    submitIDLE(state, action) {
      const { sectionPath } = action.payload;
      set(state, `${sectionPath}.submit`, submitStatus.IDLE);
    },
    submit(state, action) {
      const { sectionPath } = action.payload;
      set(state, `${sectionPath}.submit`, submitStatus.SUBMITTING);
    },
    submitSuccess(state, action) {
      const { sectionPath } = action.payload;
      set(state, `${sectionPath}.submit`, submitStatus.SUBMITTED);
    },
    submitError(state, action) {
      const { sectionPath } = action.payload;
      set(state, `${sectionPath}.submit`, submitStatus.SUBMIT_ERROR);
    },
    changeActive(state, action) {
      const { value: path } = action.payload;
      const selected = get(state, path);
      if (selected) { selected.isActive = true; }
      const currentActiveProp = get(state, `${path.split('.')?.[0]}.active`);
      set(state, `${path.split('.')?.[0]}.${currentActiveProp}.isActive`, false);
      set(state, `${path.split('.')?.[0]}.${path.split('.')?.[1]}.isActive`, true);
      set(state, `${path.split('.')?.[0]}.active`, path.split('.')?.[1]);
    },
    ...AddAccountReducers,
  },
});

export const { reducer } = slice;

export const {
  setProp, setContext, changeActive, resetAll, submit, resetBeneficial, resetSingleBeneficial, resetControlSigner, submitSuccess, submitIDLE, submitError,
} = slice.actions;

export default slice;
