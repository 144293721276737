import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import {
  CssBaseline, ThemeProvider, StyledEngineProvider, Slide, Button,
} from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import SessionTimeout from './components/SessionTimeout';

import routes from './routes';

import RTL from './components/RTL';

import useSettings from './hooks/useSettings';
import useScrollReset from './hooks/useScrollReset';
import { RenewPasswordDialog } from './components/users/RenewPasswordDialog';

import { createCustomTheme } from './theme';

import './App.css';
import './i18n';

const queryClient = new QueryClient();

function App() {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            hideIconVariant
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            TransitionComponent={Slide}
            maxSnack={3}
            ref={notistackRef}
            action={(key) => (
              <Button sx={{ color: 'white' }} onClick={onClickDismiss(key)}>
                <CloseIcon />
              </Button>
            )}
          >
            <RTL direction={settings.direction}>
              <CssBaseline />
              <Toaster position="top-center" />
              {content}
              <RenewPasswordDialog />
            </RTL>
            <SessionTimeout />
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
