import {
  Grid,
} from '@mui/material';
import { ControlledInputText } from '../forms/InputText';

const UpdatePasswordForm = ({ control }) => {
  return (
    <Grid>
      <ControlledInputText
        name="currentPassword"
        label="Current Password"
        control={control}
        required
        type="password"
        sx={{ mb: 1 }}
      />
      <ControlledInputText
        name="newPassword"
        label="New Password"
        control={control}
        required
        type="password"
        sx={{ mb: 1 }}
      />
      <ControlledInputText
        name="confirmPassword"
        label="Confirm New Password"
        control={control}
        type="password"
        required
      />
    </Grid>
  );
};

export default UpdatePasswordForm;
