import omit from 'lodash/omit';
import ErrorFactory from '../../shared/errors';
import client from '../httpClient';

export const MerchantUpdate = async ({ payload, omitList }) => client.patch(
  `/merchants/${payload.id}`,
  omit(payload, omitList),
);

export const MerchantBulkEdit = async ({ payload }) => {
  try {
    const response = await client.patch(
      '/bulk-edit/merchants',
      { merchants: payload },
    );
    return response;
  } catch (err) {
    throw ErrorFactory(err);
  }
};
