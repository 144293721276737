import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { getAccountTypes } from '../../../api';
import { AccountTypeNames } from '../../../shared/constants';

// parse banck account types to have the type and the texts t display in the UI correctly
const parseBankAccountTypes = (accountTypes) => accountTypes?.reduce(
  (acc, { name, paymentMethod, ...rest }) => (AccountTypeNames[name]
    ? [
      ...acc,
      {
        ...rest,
        paymentMethod,
        text: AccountTypeNames[name],
        value: name,
        type: paymentMethod?.name || 'no-type',
      },
    ]
    : acc),
  [],
);

export const fetchAccountTypesThunk = createAsyncThunk('fetchAccountTypes', async (params, { rejectWithValue }) => {
  try {
    const {
      data, status, statusText, isLoading,
    } = await getAccountTypes(params);
    return {
      data: parseBankAccountTypes(data?.bankingAccountTypes) || [], status, statusText, isLoading,
    };
  } catch (err) {
    const {
      data, status, statusText, isLoading,
    } = err.response;
    return rejectWithValue({
      ...data, status, statusText, isLoading,
    });
  }
});

export const fetchAccountTypesReducers = {
  [fetchAccountTypesThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'accountTypes',
      payload,
    );
  },
  [fetchAccountTypesThunk.pending]: (state) => {
    set(
      state,
      'accountTypes.isLoading',
      true,
    );
  },
  [fetchAccountTypesThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'accountTypes',
      payload,
    );
  },
};
