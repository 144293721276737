import { React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { get, defaultTo } from 'lodash';

// COMPONENTS
import {
  Box,
  Container,
  Grid,
  Card,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';

// REDUX
import { fetchFundingGroups } from '../../redux/slices/fundingGroups';

// ICONS
import useSettings from '../../hooks/useSettings';

import Plus from '../../icons/Plus';
import { mainContainerSX } from '../../shared/styles';
import { ActionsMenu } from '../../components/ActionsMenu';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { MSPDataGrid } from '../../components/DataGrid';

const FundingGroups = () => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const dispatcher = useDispatch();

  const fundingGroups = useSelector((state) => get(state, 'fundingGroupsReducer.response', {}));
  const loading = useSelector((state) => get(state, 'fundingGroupsReducer.isLoading', true));

  useEffect(() => {
    fetchFundingGroups()(dispatcher);
  }, []);

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: ' Funding Group',
      flex: 2,
      renderCell: ({ id, formattedValue }) => (
        <Button
          onClick={() => navigate(`./${id}/members`)}
        >
          <Typography fontWeight="bold" variant="subtitle2">
            {formattedValue}
          </Typography>
        </Button>
      ),
    },
    {
      field: 'members',
      headerName: 'Member Count',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ formattedValue }) => (formattedValue ? formattedValue.length : 0),
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString('en-US'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 0.5,
      renderCell: ({ id }) => (
        <>
          <Tooltip placement="top" title="Members">
            <Button onClick={() => navigate(`./${id}/members`)}>
              <GroupIcon />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Settings">
            <Button onClick={() => navigate(`./${id}/settings`)}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const breadcrumbsItems = [
    {
      id: 'home',
      redirect: '/',
      render: 'Home',
    },
    {
      id: 'funding-groups',
      render: 'Funding Groups',
    },
  ];

  return (
    <>
      <Helmet>
        <title>MSP | Funding Groups</title>
      </Helmet>
      <Box
        sx={mainContainerSX}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                Funding Groups
              </Typography>
              <Breadcrumbs items={breadcrumbsItems} />
            </Grid>
            <Grid item>
              <ActionsMenu actions={[
                {
                  icon: <Plus />,
                  label: 'Add Group',
                  onClick: () => navigate('./settings/'),
                },
              ]}
              />
            </Grid>
          </Grid>
          <Card sx={{ mt: 3 }}>
            <MSPDataGrid
              cols={columns}
              tableData={defaultTo(get(fundingGroups, 'groups', null), [])}
              loading={loading}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default FundingGroups;
