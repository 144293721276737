import { Grid, Typography } from '@mui/material';

function Legend({ children }) {
  return (
    <Grid item xs={12}>
      <Typography fontWeight="bold" sx={{ mt: 1, mb: 1 }}>
        { children }
      </Typography>
    </Grid>
  );
}

export default Legend;
