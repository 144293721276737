import {
  Button, Chip, Tooltip, Typography,
} from '@mui/material';
import {
  omit, cloneDeep, isEmpty, forEach, keys, set, get, toUpper, filter,
} from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonIcon from '@mui/icons-material/Person';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import InfoIcon from '@mui/icons-material/Info';

import Label from '../components/Label';
import countries from './countries';
import { US_STATES } from './usStates';

export const wait = (time) => new Promise((res) => setTimeout(res, time));

export const removeSensitiveProps = (obj) => {
  const cloned = cloneDeep(obj);
  return omit(cloned, ['password']);
};

export const deepCheckEmptyObject = (obj) => Object.values(obj).every((value) => {
  if (value === undefined) return true;
  if ((value instanceof Array || value instanceof Object) && isEmpty(value)) return true;
  // eslint-disable-next-line
  if (value instanceof Array && !isEmpty(value)) return deepCheckEmptyArray(value);
  if (value instanceof Object && !isEmpty(value)) return deepCheckEmptyObject(value);
  return false;
});

export const deepCheckEmptyArray = (array) => array.every((value) => {
  if (value === undefined) return true;
  if ((value instanceof Array || value instanceof Object) && isEmpty(value)) return true;
  if (value instanceof Array && !isEmpty(value)) return deepCheckEmptyArray(value);
  if (value instanceof Object && !isEmpty(value)) return deepCheckEmptyObject(value);
  return false;
});

export const withinBoundaries = (array, index) => Math.max(Math.min(index, array.length - 1), 0);

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      ?.toUpperCase()
      ?.replace(/./g, (c) => String.fromCodePoint(c.charCodeAt(0) + 127397))
    : isoCode;
};

// Remove ref object
export const cleanReactHookFormErrors = (errors) => {
  const cleanDeep = {};
  const cleanFlat = {};
  if (!isEmpty(errors)) {
    forEach(keys(errors), (k) => {
      // eslint-disable-next-line no-unused-vars
      const { ref, ...rest } = errors[k];
      const [formKey, ...formField] = k.split('-');
      set(cleanDeep, [formKey, formField], { ...rest });
      set(cleanFlat, [k], { ...rest });
    });
  }
  return [cleanDeep, cleanFlat];
};

export const getDefaultValueAutoComplete = (form, key, options) => {
  const value = get(form, key.split('-'), null);
  return value ? options.find((element) => element?.value === value) : '';
};

export const getUserRoleLabel = (user) => {
  const { role = '' } = user;
  const roleToLabelMap = {
    CAWR: <Chip icon={<AdminPanelSettingsIcon />} variant="contained" color="secondary" label="Core Admin with Risk Management" />,
    CA: <Chip icon={<AdminPanelSettingsIcon />} variant="contained" color="primary" label="Core Admin" />,
    COWR: <Chip icon={<SupervisedUserCircleIcon />} variant="outlined" color="secondary" label="Core Ops with Risk Management" />,
    CO: <Chip icon={<SupervisedUserCircleIcon />} variant="outlined" color="primary" label="Core Ops" />,
    CS: <Chip icon={<SupportAgentIcon />} variant="outlined" color="primary" label="Core Support" />,
    AA: <Chip icon={<AdminPanelSettingsIcon />} variant="contained" color="primary" label="Account Admin" />,
    AU: <Chip icon={<PersonIcon />} variant="outlined" color="primary" label="Account User" />,
    MA: <Chip icon={<AdminPanelSettingsIcon />} variant="contained" color="primary" label="Merchant Admin" />,
    MF: <Chip icon={<LocalAtmIcon />} variant="outlined" color="primary" label="Merchant Financial" />,
    MM: <Chip icon={<PersonIcon />} variant="outlined" color="primary" label="Merchant User" />,
  };
  return get(roleToLabelMap, toUpper(role), 'Unknown role');
};

export const getStatusLabel = (entity = {}) => {
  const { status = '' } = entity;
  const statusToLabelMap = {
    LOCKED: {
      color: 'error',
      text: 'LOCKED',
    },
    ACTIVE: {
      color: 'success',
      text: 'Active',
    },
    INACTIVE: {
      color: 'warning',
      text: 'INACTIVE',
    },
  };

  const { text, color } = get(statusToLabelMap, toUpper(status), { text: 'Unknown', color: 'warning' });

  return <Label color={color}>{text}</Label>;
};

export const makeCopyableString = (string, className = '') => {
  return (
    <Tooltip placement="bottom" title="Click to copy to clipboard" sx={{ mt: -2 }}>
      <Button
        startIcon={<ContentCopyIcon />}
        color="inherit"
        onClick={() => { navigator.clipboard.writeText(string); }}
        data-testid="copyToClipboard"
        sx={{ p: 0, mb: 0 }}
      >
        <span className={className}>
          {string}
        </span>
      </Button>
    </Tooltip>
  );
};

export const getFullCountryName = (digits = 'XX') => {
  if (digits.length > 2) {
    return digits;
  }
  const country = countries.find((c) => c.value === digits);
  if (country && country?.text) {
    return country?.text;
  }
  return digits;
};

export const getFullStateName = (digits = 'XX') => {
  if (digits.length > 2) {
    return digits;
  }
  const state = US_STATES.find((c) => c.value === digits);
  if (state && state?.text) {
    return state?.text;
  }
  return digits;
};

export const getFormattedAddressLabel = ({ address } = {}) => {
  return (
    <>
      <Typography data-testid="addressAddress1" color="textSecondary" variant="body2">
        {address?.address1}
      </Typography>
      <Typography data-testid="addressAddress2" color="textSecondary" variant="body2">
        {address?.address2}
      </Typography>
      <Typography data-testid="addressCity" color="textSecondary" variant="body2">
        {`${address?.city}, ${getFullStateName(address?.state)}`}
      </Typography>
      <Typography data-testid="addressCountry" color="textSecondary" variant="body2">
        {getFullCountryName(address?.country)}
      </Typography>
    </>
  );
};

export const buildErrorMessageFromRejectedThunk = (reject) => {
  let errorMessage = 'Something unexpected went wrong.';
  const errorDetails = [];
  const { payload = {}, rejectError = { message: '' } } = reject;
  const { error: payloadError, status } = payload;
  if (status) {
    errorMessage = `Your request has failed with status code ${status}.`;
    if (!isEmpty(payloadError)) {
      if (!isEmpty(payloadError?.message)) {
        errorDetails.push(payloadError?.message);
      }
    }
  } else if (rejectError && rejectError?.message && typeof rejectError?.message === 'string') {
    errorDetails.push(rejectError?.message);
  }
  return {
    message: errorMessage,
    details: errorDetails,
  };
};

export const TwoFactorExplanationTooltip = ({ setup = false }) => {
  const setupMessage = 'A type of app that will be used in addition to the password to confirm the user identity, such as Authy by Twilio, Google Authenticator and others.';
  const message = 'An app that was registered by the user for enhanced security, such as Authy by Twilio, Google Authenticator and others.';
  return (
    <>
      <Tooltip title={setup ? setupMessage : message} placement="top">
        <InfoIcon color="primary" sx={{ fontSize: '16px' }} />
      </Tooltip>
    </>
  );
};

export const TenderFormSubtitle = ({ children }) => (
  <Typography fontWeight={600} fontSize={16}>{children}</Typography>
);

// From a list of existing Tenders, returns the remaining payment methods that can be added
export const initializeAvailableTypes = ({ existingTenders = [], allTypes = [] }) => {
  let available = allTypes;
  forEach(
    existingTenders,
    (t) => {
      const { paymentMethodType } = t;
      available = filter(available, (pm) => pm.value !== paymentMethodType);
    },
  );
  return available;
};

const isObjFromYup = (obj) => !keys(obj).includes('message');

export const deepKeyCount = (obj) => keys(obj).reduce(((acc, cur) => {
  const currentObject = obj[cur];
  if (typeof currentObject === 'object' && isObjFromYup(currentObject)) {
    return (acc + deepKeyCount(currentObject));
  }
  return (acc + 1);
}), 0);

export const trim = ({ value, type = '' }) => {
  if (typeof value !== 'string') {
    return value;
  }
  if (type === 'start') {
    return value.trimStart();
  } if (type === 'end') {
    return value.trimEnd();
  }
  return value.trim();
};
