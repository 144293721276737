import {
  Grid,
  InputAdornment,
} from '@mui/material';

import { US_STATES } from '../../../shared/usStates';
import { ControlledAutocomplete } from '../Autocomplete';
import { ControlledInputText } from '../InputText';
import { ControlledInputMask } from '../InputMask';
import { prefixFormName } from '../../../utils/formatting/Form';

export const FormFields = {
  DATE: 'dateOfBirth',
  DRIVER_LICENSE_STATE: 'driverLicenseState',
  DRIVER_LICENSE: 'driverLicense',
  SSN: 'ssn',
  OWNERSHIP: 'ownershipPercentage',
  TOTAL_OWNERSHIP: 'totalOwnershipPercentage',
};

function PersonalInfo({
  control, name, withOwnership = false, validate, optionalOwnership = false,
}) {
  return (
    <>
      <Grid item xs={4}>
        <ControlledInputMask
          control={control}
          mask="99/99/9999"
          name={prefixFormName(name, FormFields.DATE)}
          label="Birthdate"
          required={validate}
          data-testid="birthdate"
        />
      </Grid>
      <Grid item xs={4}>
        <ControlledAutocomplete
          control={control}
          options={US_STATES}
          label="Driver's license state"
          name={prefixFormName(name, FormFields.DRIVER_LICENSE_STATE)}
          required={validate}
          data-testid="stateField"
        />
      </Grid>
      <Grid item xs={4}>
        <ControlledInputText
          control={control}
          name={prefixFormName(name, FormFields.DRIVER_LICENSE)}
          label="Driver's license"
          required={validate}
          data-testid="driverLicense"
        />
      </Grid>
      <Grid item xs={withOwnership ? 6 : 12}>
        <ControlledInputMask
          control={control}
          mask="999-99-9999"
          name={prefixFormName(name, FormFields.SSN)}
          label="Social security number"
          required={validate}
          data-testid="ssn"
        />
      </Grid>
      {withOwnership && (
        <Grid item xs={6}>
          <ControlledInputText
            control={control}
            type="number"
            required={!optionalOwnership && validate}
            name={prefixFormName(name, FormFields.OWNERSHIP)}
            label="Percent of ownership"
            data-testid="ownershipPercentage"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
      )}
    </>
  );
}

export default PersonalInfo;
