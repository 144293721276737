import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { get, values } from 'lodash';

import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import PencilAlt from '../../icons/PencilAlt';
import useSettings from '../../hooks/useSettings';
import Breadcrumbs from '../../components/shared/Breadcrumbs';

import {
  resetForm,
  fetchFundingGroupById,
  addFundingGroup,
  editFundingGroup,
} from '../../redux/slices/fundingGroups';

// READ SETTINGS
import NameAndMembers from '../../components/funding-groups/read-settings/NameAndMembers';
import BankAccounts from '../../components/funding-groups/read-settings/BankAccounts';
import TenderTypes from '../../components/funding-groups/read-settings/TenderTypes';

// EDIT SETTINGS
import EditNameAndMembers from '../../components/funding-groups/edit-settings/EditNameAndMembers';
import EditTenderTypeSettings from '../../components/funding-groups/edit-settings/EditTenderTypeSettings';
import EditBankAccounts from '../../components/funding-groups/edit-settings/EditBankAccounts';

import { mainContainerSX } from '../../shared/styles';
import { ActionsMenu } from '../../components/ActionsMenu';

const FundingGroupSettings = () => {
  const dispatcher = useDispatch();
  const dispatch = (f) => f(dispatcher);
  const navigate = useNavigate();
  const { id } = useParams();

  const [group, setGroup] = useState([]);
  const [edit, setEdit] = useState(!id);
  const [resetForms, setResetForms] = useState(0);
  const [currentExpanded, setCurrentExpanded] = useState('editNameAndMembers');
  const [previousExpanded, setPreviousExpanded] = useState();
  const [submitting, setSubmitting] = useState(0);
  const [editSettings, setEditSettings] = useState({ groupAccounts: {} });
  const [submitFormModal, setSubmitFormModal] = useState(false);
  const [resetFormModal, setResetFormModal] = useState(false);
  const [successAdding, setSuccessAdding] = useState(false);
  const [failAdding, setFailAdding] = useState(false);
  const [tenderTypes, setTenderTypes] = useState({});

  const resetFormCount = useSelector((state) => state.fundingGroupsReducer.resetForm);
  const fundingGroup = useSelector((state) => get(state, 'fundingGroupsReducer.response.groups', {}));

  const { settings } = useSettings();

  useEffect(() => {
    fetchFundingGroupById(id)(dispatcher);
    if (fundingGroup) {
      setGroup(fundingGroup[0]);
    }
  }, []);

  useEffect(() => {
    if (resetFormCount > 0) {
      setResetForms(resetForms + 1);
      setCurrentExpanded('editNameAndMembers');
    }
  }, [resetFormCount]);

  const notification = (open, close, message, severity) => {
    const handleClose = () => close(false);
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    );
  };

  const onChangeForm = (menu) => {
    setPreviousExpanded(currentExpanded);
    setCurrentExpanded(menu);
  };

  const handleCloseSubmitFormModal = () => {
    setSubmitFormModal(false);
    setEditSettings({ groupAccounts: {} });
  };

  const validateFieldsRequired = (data) => {
    if (!data.name) return false;
    if (!data.accountID) return false;
    if (values(data.groupAccounts).length === 0) return false;
    return true;
  };

  const handleResetForm = () => {
    dispatch(resetForm());
    setEditSettings({ groupAccounts: {} });
    setCurrentExpanded('editNameAndMembers');
    setResetFormModal(false);
  };

  const handleSubmit = async () => {
    let data = {};
    data = {
      ...editSettings,
      tenders: values(tenderTypes),
      accountSettlementMethod: editSettings.groupAccounts.accountSettlementMethod,
    };
    if (id) {
      data.fundingGroupId = id;
    }
    delete data.groupAccounts.accountSettlementMethod;
    if (validateFieldsRequired(data)) {
      if (!id) {
        const { error, payload } = await addFundingGroup(data)(dispatcher);
        if (error) {
          setFailAdding(true);
          setFailAdding(true);
          handleCloseSubmitFormModal();
        } else {
          setSuccessAdding(true);
          navigate(`../${payload.id}/members`);
        }
        handleCloseSubmitFormModal();
      }
      if (id) {
        const { error } = await editFundingGroup(data)(dispatcher);
        if (error) {
          setFailAdding(true);
          setFailAdding(true);
          handleCloseSubmitFormModal();
        } else {
          setSuccessAdding(true);
          setEdit(false);
        }
        handleCloseSubmitFormModal();
      }
    } else {
      handleCloseSubmitFormModal();
    }
  };

  const style = { marginTop: 20, marginBottom: 20 };

  const breadcrumbsItems = [
    {
      id: 'home',
      redirect: '/',
      render: 'Home',
    },
    {
      id: 'funding-groups',
      redirect: '/payment-processing/funding-groups',
      render: 'Funding Groups',
    },
    {
      id: 'funding-group-detail',
      hide: !id,
      redirect: `/payment-processing/funding-groups/${id}/members`,
      render: group.name,
    },
    {
      id: id ? 'funding-settings' : 'funding-create',
      render: id ? 'Funding Settings' : 'Create Funding Group',
    },
  ];

  return (
    <>
      <Helmet>
        <title>MSP | Funding Groups</title>
      </Helmet>
      <Box
        sx={mainContainerSX}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              { id ? (
                <Typography color="textPrimary" variant="h3">
                  {`${group.name} - Funding Settings`}
                </Typography>
              )
                : (
                  <Typography color="textPrimary" variant="h3">
                    Create Funding Group
                  </Typography>
                )}
              <Breadcrumbs items={breadcrumbsItems} sx={{ mt: 1, mb: 3 }} />
            </Grid>
            <Grid
              item
              sx={{
                mb: 3,
                mt: {
                  xs: -5, sm: 0, md: 0, lg: 0, xl: 0,
                },
              }}
            >
              <ActionsMenu actions={[
                {
                  label: 'Edit',
                  onClick: () => setEdit(true),
                  icon: <PencilAlt />,
                  hideIf: edit,
                },
                {
                  label: 'Cancel',
                  onClick: () => {
                    if (id) setEdit(false);
                    else navigate('../');
                  },
                  color: 'secondary',
                  icon: <CancelIcon />,
                  hideIf: !edit,
                },
              ]}
              />
            </Grid>
          </Grid>
          {edit && (
            <Box>
              <EditNameAndMembers
                editSettings={editSettings}
                submitting={submitting}
                setEditSettings={setEditSettings}
                resetForms={resetForms}
                onChangeForm={onChangeForm}
                expand={currentExpanded}
                editing={id}
                fundingGroupData={get(fundingGroup, '[0]', {})}
                previousExpanded={previousExpanded}
              />
              <EditTenderTypeSettings
                fundingGroupData={get(fundingGroup, '[0]', {})}
                submitting={submitting}
                resetForms={resetForms}
                onChangeForm={onChangeForm}
                editing={id}
                expand={currentExpanded}
                previousExpanded={previousExpanded}
                tenderTypes={tenderTypes}
                setTenderTypes={setTenderTypes}
              />
              <EditBankAccounts
                editSettings={editSettings}
                fundingGroupData={get(fundingGroup, '[0]', {})}
                submitting={submitting}
                setEditSettings={setEditSettings}
                resetForms={resetForms}
                editing={id}
                onChangeForm={onChangeForm}
                expand={currentExpanded}
                previousExpanded={previousExpanded}
                tenderTypes={tenderTypes}
              />
            </Box>
          )}
          {!edit && (
            <Box>
              <NameAndMembers data={get(fundingGroup, '[0]', {})} />
              <TenderTypes data={get(fundingGroup, '[0]', {})} style={style} />
              <BankAccounts data={get(fundingGroup, '[0].groupAccounts', {})} style={style} />
            </Box>
          )}

          {edit && (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 4 }}
            >
              <Grid item sx={{ mr: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setResetFormModal(true)}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSubmitting(submitting + 1);
                    setSubmitFormModal(true);
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
          <Dialog
            open={submitFormModal}
            onClose={handleCloseSubmitFormModal}
            onBackdropClick={() => { }}
          >
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{
                  minWidth: '300px',
                  minHeight: '150px',
                }}
              >
                <Grid item textAlign="center" xs={12}>
                  <WarningIcon sx={{ fontSize: '80px' }} />
                </Grid>
                <Grid item textAlign="center">
                  <DialogContentText sx={{ typography: 'h6' }}>
                    {id
                      ? `Editing ${fundingGroup[0].name} settings`
                      : 'Creating Funding Group'}
                  </DialogContentText>
                </Grid>
                <Typography sx={{ mt: 1 }}>
                  {id
                    ? `Are you sure you want to edit the settings for ${fundingGroup[0].name}?`
                    : 'Do you want to create a new Funding Group with the settings provided?'}
                </Typography>
              </Grid>
              <DialogActions sx={{ mt: 1 }}>
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  onClick={handleCloseSubmitFormModal}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Yes
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Dialog
            open={resetFormModal}
            onClose={() => setResetFormModal(false)}
          >
            <DialogContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{
                  minWidth: '300px',
                  minHeight: '150px',
                }}
              >
                <Grid item textAlign="center" xs={12}>
                  <WarningIcon sx={{ fontSize: '80px' }} />
                </Grid>
                <Grid item textAlign="center">
                  <DialogContentText sx={{ typography: 'h6' }}>
                    Reset Form
                  </DialogContentText>
                </Grid>
                <Typography sx={{ mt: 1 }}>
                  Are you sure you want to reset the form? All fields will be cleared.
                </Typography>
              </Grid>
              <DialogActions sx={{ mt: 1 }}>
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  onClick={() => setResetFormModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleResetForm}
                >
                  Reset
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Container>
        {notification(successAdding, setSuccessAdding, id ? 'Funding Group updated successfully!' : 'Funding Group created sucessfully!', 'success')}
        {notification(failAdding, setFailAdding, id ? 'Failed to update Funding Group.' : 'Failed to create Funding Group.', 'error')}
      </Box>
    </>
  );
};

export default FundingGroupSettings;
