import client from '../httpClient';
import coreHttpClient from '../coreHttpClient';
import ErrorFactory from '../../shared/errors';

const CORE_PLATFORM_REPORTS = ['daily_settlements', 'fundingReport'];

export async function GetReport(params) {
  const reportParams = params.timeFrame
    ? { timeFrame: params.timeFrame }
    : {
      endDate: params.endDate,
      startDate: params.startDate,
    };
  reportParams.source = params.source;

  reportParams.paymentStatus = params.paymentStatus;

  if (params.method) {
    reportParams.paymentMethod = params.method;
  }

  reportParams.pageSize = params.pageSize;
  reportParams.pageNumber = params.pageNumber;
  if (params.startAmt) { reportParams.startAmt = params.startAmt; }
  if (params.endAmt) { reportParams.endAmt = params.endAmt; }

  if (CORE_PLATFORM_REPORTS.includes(params.chartId)) {
    return coreHttpClient.get(`/v1/merchant-portal/${params.chartId}`, { params: { ...reportParams } });
  }

  return client.get(`/report/${params.chartId}`, { params: { ...reportParams } });
}

export async function ReportListRequest({ queryKey }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const [_, params] = queryKey;
    const response = await GetReport(params);
    return response?.data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}
