import { createAsyncThunk } from '@reduxjs/toolkit';

import { set } from 'lodash';

import { MerchantDevicesListRequest } from '../../../api';

export const listMIDDevicesThunk = createAsyncThunk('devices/listMIDDevices', async ({ params, id }, { rejectWithValue }) => {
  try {
    const { data, status, statusText } = await MerchantDevicesListRequest({ id, params });
    return { ...data, status, statusText };
  } catch (e) {
    const { data, status, statusText } = e.response;
    const { error = {} } = data;
    return rejectWithValue({ ...error, status, statusText });
  }
});

export const listMIDDevicesReducers = {
  [listMIDDevicesThunk.fulfilled]: (state, { payload }) => {
    const devices = payload;
    set(
      state,
      'devices',
      devices,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },

  [listMIDDevicesThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },

  [listMIDDevicesThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'error',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },

};
