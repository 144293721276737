import axios from 'axios';
import { deviceServicesAPI } from '../config';

const httpDeviceClient = axios.create({
  baseURL: deviceServicesAPI.URL,
  withCredentials: true,
});

httpDeviceClient.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
httpDeviceClient.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';
httpDeviceClient.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';

export default httpDeviceClient;
