import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { getPaymentMethods } from '../../../api';

export const fetchPaymentMethodsThunk = createAsyncThunk('fetchPaymentMethods', async (params, { rejectWithValue }) => {
  try {
    const {
      data,
      status,
      statusText,
      isLoading,
    } = await getPaymentMethods(params);
    return {
      data: data?.paymentMethods || data?.paymentMethod,
      status,
      statusText,
      isLoading,
    };
  } catch (err) {
    const {
      data, status, statusText, isLoading,
    } = err.response;
    return rejectWithValue({
      ...data, status, statusText, isLoading,
    });
  }
});

export const fetchPaymentMethodsReducer = {
  [fetchPaymentMethodsThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'paymentMethods',
      payload,
    );
  },
  [fetchPaymentMethodsThunk.pending]: (state) => {
    set(
      state,
      'paymentMethods.isLoading',
      true,
    );
  },
  [fetchPaymentMethodsThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'paymentMethods',
      payload,
    );
  },
};
