import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import CorporateAccountForm, { FormFields, ValidationSchema, defaultValue } from '../../forms/CorporateAccount';
import PersistedSectionForm from '../../shared/PersistedSectionForm';
import { AccordionPersist } from '../../shared/Accordion/Accordion';

export {
  FormFields, ValidationSchema, defaultValue,
};

const CorporateAccount = ({
  formKey, control, setValue, getValues,
}) => {
  const handleCopyFrom = (copyFrom, copyTo) => {
    const values = getValues(copyFrom);
    setValue(copyTo, values, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  return (
    <Box
      display="grid"
    >
      <Typography color="textPrimary" variant="body1" sx={{ mb: 2 }}>
        Please provide the following details as they relate to your organization.
      </Typography>
      <Grid container spacing={1}>
        <CorporateAccountForm validate name={formKey} control={control} onCopy={handleCopyFrom} />
      </Grid>
    </Box>
  );
};

const section = 'addAccount.corporateAccount';
const label = 'Corporate Account Information';

export default AccordionPersist({
  section,
  label,
  Component: PersistedSectionForm({
    section,
    Component: CorporateAccount,
    schema: ValidationSchema,
    defaultValues: defaultValue,
  }),
});
