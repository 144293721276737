import {
  Grid,
  Typography,
} from '@mui/material';

import ContactInfo from '../ContactInfo';
import AddressInfo from '../Address';
import PersonalInformation from '../PersonalInfo';

function FullContactInformation({
  control, name, validate, withOwnership, optionalOwnership,
}) {
  return (
    <>
      <Grid container spacing={1}>
        <ContactInfo control={control} name={name} validate={validate} />
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
            Home Address
          </Typography>
        </Grid>
        <AddressInfo control={control} name={name} validate={validate} />
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
            Personal Information
          </Typography>
        </Grid>
        <PersonalInformation control={control} name={name} validate={validate} withOwnership={withOwnership} optionalOwnership={optionalOwnership} />
      </Grid>
    </>
  );
}

export default FullContactInformation;
