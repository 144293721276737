import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const noop = () => {};

function Dialog({
  title, open, onClose, children, actionStyles, actionSection, disableClose, contentStyles = {}, titleStyles = {}, ...rest
}) {
  return (
    <MUIDialog
      {...rest}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
      onBackdropClick={noop}
    >
      <DialogTitle sx={titleStyles}>
        {title}
        {!disableClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={contentStyles}>
        { children }
      </DialogContent>
      <DialogActions sx={actionStyles}>
        {actionSection}
      </DialogActions>
    </MUIDialog>
  );
}

Dialog.propTypes = {
  disableClose: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  actionSection: PropTypes.node,
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Dialog;
