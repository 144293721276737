import { React } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
} from '@mui/material';

import { get, map } from 'lodash';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { RowTable } from '../../RowTable';

const camelCaseToNormal = (string) => {
  return string
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => {
      return str.toUpperCase();
    });
};

const BankAccounts = ({ data, style }) => {
  const subtitleStyle = { padding: 15, borderTop: '1px solid #E0E0E0' };
  return (
    <Accordion disableGutters style={style}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
      >
        <Typography variant="h6" style={{ fontSize: '130%' }}>Bank Accounts</Typography>
      </AccordionSummary>

      {map(data, (account, accountName) => {
        if (account.bankName) {
          return (
            <>
              <Typography style={subtitleStyle} variant="h6">{camelCaseToNormal(accountName)}</Typography>
              {RowTable({
                data,
                rows: [
                  {
                    label: 'Bank Name',
                    valueGetter: (row) => `${get(row, `${accountName}.bankName`, '-')}`,
                  },
                  {
                    label: 'Routing/ABA Number',
                    valueGetter: (row) => `${get(row, `${accountName}.routingAbaNumber`, '-')}`,
                  },
                  {
                    label: 'Account Number',
                    valueGetter: (row) => `${get(row, `${accountName}.accountNumber`, '-')}`,
                  },
                ],
              })}
            </>
          );
        }
        return null;
      })}

      {/* <Typography style={subtitleStyle} variant="h6">Credit Chargebacks</Typography>
      {RowTable({
        data,
        rows: [
          {
            label: 'Bank Name',
            valueGetter: (row) => `${get(row, 'creditChargebacks.bankName', '-')}`,
          },
          {
            label: 'Routing/ABA Number',
            valueGetter: (row) => `${get(row, 'creditChargebacks.routingNumber', '-')}`,
          },
          {
            label: 'Account Number',
            valueGetter: (row) => `${get(row, 'creditChargebacks.accountNumber', '-')}`,
          },
        ],
      })}
      <Typography style={subtitleStyle} variant="h6">Credit Convenience Fees</Typography>
      {RowTable({
        data,
        rows: [
          {
            label: 'Bank Name',
            valueGetter: (row) => `${get(row, 'creditConvenienceFees.bankName', '-')}`,
          },
          {
            label: 'Routing/ABA Number',
            valueGetter: (row) => `${get(row, 'creditConvenienceFees.routingNumber', '-')}`,
          },
          {
            label: 'Account Number',
            valueGetter: (row) => `${get(row, 'creditConvenienceFees.accountNumber', '-')}`,
          },
        ],
      })}
      <Typography style={subtitleStyle} variant="h6">Credit Processing Fees</Typography>
      {RowTable({
        data,
        rows: [
          {
            label: 'Bank Name',
            valueGetter: (row) => `${get(row, 'creditProcessingFees.bankName', '-')}`,
          },
          {
            label: 'Routing/ABA Number',
            valueGetter: (row) => `${get(row, 'creditProcessingFees.routingNumber', '-')}`,
          },
          {
            label: 'Account Number',
            valueGetter: (row) => `${get(row, 'creditProcessingFees.accountNumber', '-')}`,
          },
        ],
      })}
      <Typography style={subtitleStyle} variant="h6">Debit Deposits</Typography>
      {RowTable({
        data,
        rows: [
          {
            label: 'Bank Name',
            valueGetter: (row) => `${get(row, 'debitDeposits.bankName', '-')}`,
          },
          {
            label: 'Routing/ABA Number',
            valueGetter: (row) => `${get(row, 'debitDeposits.routingNumber', '-')}`,
          },
          {
            label: 'Account Number',
            valueGetter: (row) => `${get(row, 'debitDeposits.accountNumber', '-')}`,
          },
        ],
      })}
      <Typography style={subtitleStyle} variant="h6">Debit Chargebacks</Typography>
      {RowTable({
        data,
        rows: [
          {
            label: 'Bank Name',
            valueGetter: (row) => `${get(row, 'debitChargebacks.bankName', '-')}`,
          },
          {
            label: 'Routing/ABA Number',
            valueGetter: (row) => `${get(row, 'debitChargebacks.routingNumber', '-')}`,
          },
          {
            label: 'Account Number',
            valueGetter: (row) => `${get(row, 'debitChargebacks.accountNumber', '-')}`,
          },
        ],
      })}
      <Typography style={subtitleStyle} variant="h6">Debit Convenience Fees</Typography>
      {RowTable({
        data,
        rows: [
          {
            label: 'Bank Name',
            valueGetter: (row) => `${get(row, 'debitConvenienceFees.bankName', '-')}`,
          },
          {
            label: 'Routing/ABA Number',
            valueGetter: (row) => `${get(row, 'debitConvenienceFees.routingNumber', '-')}`,
          },
          {
            label: 'Account Number',
            valueGetter: (row) => `${get(row, 'debitConvenienceFees.accountNumber', '-')}`,
          },
        ],
      })} */}
    </Accordion>
  );
};

export default BankAccounts;
