/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useContext,
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';

import { TreeContext } from './Tree';
import './node.styles.css';

const cancelPropagation = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const Node = ({
  labelProp, node, singleMode, style, contentStyle,
}) => {
  const {
    isSelected, deselect, select,
  } = useContext(TreeContext);

  const handleSelectionClick = (e) => {
    cancelPropagation(e);
    if (isSelected(node)) {
      deselect(node);
    } else {
      select(node);
    }
  };
  const renderChildren = () => node.children?.map((child) => {
    const childStyle = {
      position: 'relative',
      margin: '0 0 0 35px',
      width: '100%',
    };
    return (
      <Node
        style={childStyle}
        contentStyle={singleMode ? { height: '30px' } : {}}
        key={child.id}
        labelProp={labelProp}
        node={child}
        singleMode={singleMode}
        handleSelectionClick={handleSelectionClick}
      />
    );
  });

  return (
    <div style={style}>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={`tree-node ${singleMode && 'single-node'}`}
        style={contentStyle}
      >
        {singleMode ? (
          node[labelProp]
        ) : (
          <FormControlLabel
            control={(
              <Checkbox
                checked={isSelected(node)}
              />
          )}
            label={node[labelProp]}
          />
        )}
      </Typography>
      { (node.children?.length > 0) && renderChildren() }
    </div>
  );
};

export default Node;
