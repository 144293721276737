export const ACTIONS = {
  CREATE: 'Create',
  UPDATE: 'Update',
  LIST: 'List',
  READ: 'Read',
  DELETE: 'Delete',
};
export const RESOURCES = {
  ROLES_COWR: 'roles/COWR',
  ROLES_CO: 'roles/CO',
  ROLES_CS: 'roles/CS',
  ROLES_AA: 'roles/AA',
  ROLES_AU: 'roles/AU',
  ROLES_MA: 'roles/MA',
  ROLES_MF: 'roles/MF',
  ROLES_MM: 'roles/MM',
  USERS_API_KEYS: 'users/api-keys',
  USERS_LOST_PASSWORD_RESET_PASSWORD: 'users/lost-password/reset-password',
  ACCOUNTS: 'accounts',
  REPORTING_TRANSACTIONS: 'reporting/transactions',
  USERS_LOGOUT: 'users/logout',
  MERCHANTS: 'merchants',
  TOKENS: 'tokens',
  USERS: 'users',
  USERS_UPDATE_PASSWORD: 'users/update-password',
  USERS_LOST_PASSWORD_REQUEST_TOKEN: 'users/lost-password/request-token',
  USERS_2FA: 'users/2fa',
  PARTNERS: 'partners',
  MERCHANTS_UPDATE: 'merchants/update',
  USERS_ME: 'users/me',
  USERS_LOGIN: 'users/login',
  PAYERS: 'payers',
  TRANSACTIONS: 'transactions',
  MERCHANTS_RISK: 'merchants/risk',
};

export const ROLES = [
  { value: 'CAWR', text: 'Core Admin with Risk Management' },
  { value: 'CA', text: 'Core Admin' },
  { value: 'COWR', text: 'Core Ops with Risk Management' },
  { value: 'CO', text: 'Core Ops' },
  { value: 'CS', text: 'Core Support' },
  { value: 'AA', text: 'Account Admin' },
  { value: 'AU', text: 'Account User' },
  { value: 'MA', text: 'Merchant Admin' },
  { value: 'MF', text: 'Merchant Financial' },
  { value: 'MM', text: 'Merchant User' },
];
