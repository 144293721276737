import { FormFields } from '../../FormFields';
import { BankingSection } from '../BuildBankingSection';

const CardBankingAccounts = ({
  control, formKey, formProps = {}, savedBankingInfo = {},
}) => {
  return (
    <>
      <BankingSection
        key={FormFields.CREDIT_DEPOSIT}
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Credit Deposit"
        paymentMethodType="card"
        sectionKey={FormFields.CREDIT_DEPOSIT}
      />
      <BankingSection
        key={FormFields.CREDIT_CHARGEBACK}
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Credit Chargeback"
        paymentMethodType="card"
        sectionKey={FormFields.CREDIT_CHARGEBACK}
      />
      <BankingSection
        key={FormFields.CREDIT_CONVENIENCE_FEES}
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Credit Convenience Fees"
        paymentMethodType="card"
        sectionKey={FormFields.CREDIT_CONVENIENCE_FEES}
      />
      <BankingSection
        key={FormFields.CREDIT_PROCESSING_FEES}
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Credit Processing Fees"
        paymentMethodType="card"
        sectionKey={FormFields.CREDIT_PROCESSING_FEES}
      />
      <BankingSection
        key={FormFields.DEBIT_DEPOSIT}
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Debit Deposit"
        paymentMethodType="card"
        sectionKey={FormFields.DEBIT_DEPOSIT}
      />
      <BankingSection
        key={FormFields.DEBIT_CHARGEBACK}
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Debit Chargeback"
        paymentMethodType="card"
        sectionKey={FormFields.DEBIT_CHARGEBACK}
      />
      <BankingSection
        key={FormFields.DEBIT_CONVENIENCE_FEES}
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Debit Convenience Fees"
        paymentMethodType="card"
        sectionKey={FormFields.DEBIT_CONVENIENCE_FEES}
      />
    </>
  );
};

const AmexTPSPBankingAccounts = ({
  control, formKey, formProps = {}, savedBankingInfo = {},
}) => {
  return (
    <>
      <BankingSection
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Amex TPSP DDA"
        paymentMethodType="amex_tpsp"
        sectionKey={FormFields.AMEX_DDA}
      />
      <BankingSection
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: Amex TPSP Routing"
        paymentMethodType="amex_tpsp"
        sectionKey={FormFields.AMEX_ROUTING}
      />
    </>
  );
};

const ACHBankingAccounts = ({
  control, formKey, formProps = {}, savedBankingInfo = {},
}) => {
  return (
    <>
      <BankingSection
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: ACH Returns"
        paymentMethodType="ach"
        sectionKey={FormFields.ACH_RETURN}
      />
      <BankingSection
        control={control}
        formKey={formKey}
        formProps={formProps}
        savedBankingInfo={savedBankingInfo}
        label="Banking: ACH Chargeback"
        paymentMethodType="ach"
        sectionKey={FormFields.ACH_CHARGEBACK}
      />
    </>
  );
};

export {
  AmexTPSPBankingAccounts,
  CardBankingAccounts,
  ACHBankingAccounts,
};
