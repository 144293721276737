/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { createTheme, Box } from '@mui/material';
import { createStyled } from '@mui/system';
import {
  DataGridPro,
  LicenseInfo,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridOverlay,
  GridToolbarQuickFilter,
  useGridApiRef,
  gridVisibleRowCountSelector,
  gridQuickFilterValuesSelector,
} from '@mui/x-data-grid-pro';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as NoRecordsIcon } from '../icons/NoRecords.svg';
import { svgColors } from '../shared/styles';

LicenseInfo.setLicenseKey(
  'e4089f0872e994d602e9d42118484b53T1JERVI6Mjc3MDcsRVhQSVJZPTE2NTkyNjcwMTkwMDAsS0VZVkVSU0lPTj0x',
);

const defaultTheme = createTheme();

const styled = createStyled({ defaultTheme });

const Root = styled('div')(
  ({ theme }) => ({
    '&.root': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: '300px',
      marginTop: 10,
      '& .MuiFormGroup-options': {
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        '& > div': {
          minWidth: 100,
          margin: theme.spacing(2, 2, 2, 0),
        },
      },
      '& .MuiDataGrid-root': {
        border: 'none',
        overflow: 'auto',
      },
      '& .p-l-50': {
        paddingLeft: 50,
      },
      '& .MuiDataGrid-toolbarContainer': {
        marginLeft: 10,
        marginBottom: 10,
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontFamily: 'Open Sans',
        fontStyle: 'italic',
        fontWeight: '600 !important',
        fontSize: '14px',
        lineHeight: '157%',
        display: 'flex',
        letterSpacing: '0.1px',
        color: '#25272A',
      },
      '& .MuiDataGrid-row': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
      '& .MuiDataGrid-cell': {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '157%',
        letterSpacing: '0.1px',
        color: '#25272A',
        border: 'none',
      },
      '& .MuiButton-label': {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.15px',
        color: '#004983',
        textTransform: 'uppercase',
      },
      '& .MuiDataGrid-treeDataGroupingCell': {
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#172b4d',
      },
      '& .MuiDataGrid-treeDataGroupingCellToggle': {
        width: 0,
        marginRight: 0,
      },
      '& .MuiBadge-badge': {
        marginTop: '10px',
      },
      '& .MuiDataGrid-main': {
        minWidth: '700px',
      },
      '& .MuiDataGrid-main > div:first-of-type': {
        zIndex: 1,
      },
      '& .MuiDataGrid-footerContainer': {
        border: 'none',
      },
    },
  }),
);

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  ...svgColors({ theme }),
}));

const CollapsedIcon = () => <KeyboardArrowUpIcon className="icon" />;
const ExpandedIcon = () => <KeyboardArrowDownIcon className="icon" />;

const CustomToolbar = (filterOptions, filterValue, filteredItemsCount) => {
  const { showQuickFilter, filterPlaceholder, filterErrorMessage } = filterOptions;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <GridToolbarDensitySelector />
      {showQuickFilter && <GridToolbarQuickFilter debounceMs={1000} helperText={!!filterValue && filteredItemsCount === 0 ? filterErrorMessage : ''} error={!!filterValue && filteredItemsCount === 0} placeholder={filterPlaceholder} style={{ marginLeft: 'auto' }} />}
    </GridToolbarContainer>
  );
};

const CustomNoRowsOverlay = (showQuickFilter = false, noResultsMessage = '', filteredItemsCount, filterValue) => (
  <StyledGridOverlay>
    <NoRecordsIcon />
    <Box sx={{ mt: 1 }}>{showQuickFilter && !!noResultsMessage && filteredItemsCount === 0 && !!filterValue ? noResultsMessage : 'No records were found' }</Box>
  </StyledGridOverlay>
);

export const MSPDataGrid = ({
  cols, tableData, disableToolbar = false, quickFilterOptions = {
    showQuickFilter: false, filterPlaceholder: '', filterErrorMessage: '', noResultsMessage: '',
  }, ...options
}) => {
  const { showQuickFilter, noResultsMessage } = quickFilterOptions;
  const apiRef = useGridApiRef();
  const [filteredItemsCount, setFilteredItemsCount] = useState(0);

  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    apiRef.current.subscribeEvent('stateChange', () => {
      const count = gridVisibleRowCountSelector(apiRef.current.state);
      const newFilterValue = gridQuickFilterValuesSelector(apiRef.current.state);

      if (count !== undefined) { setFilteredItemsCount(count); }
      if (newFilterValue !== undefined) { setFilterValue(newFilterValue[0]); }
    });
  }, [apiRef]);

  return (
    <Root className="root">
      <DataGridPro
        apiRef={apiRef}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [''],
            },
          },
        }}
        columns={cols}
        rows={tableData || []}
        components={{
          Toolbar: disableToolbar ? null : () => CustomToolbar(quickFilterOptions, filterValue, filteredItemsCount),
          NoRowsOverlay: () => CustomNoRowsOverlay(showQuickFilter, noResultsMessage, filteredItemsCount, filterValue),
          NoResultsOverlay: () => CustomNoRowsOverlay(showQuickFilter, noResultsMessage, filteredItemsCount, filterValue),
          TreeDataCollapseIcon: ExpandedIcon,
          TreeDataExpandIcon: CollapsedIcon,
        }}
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        {...options}
      />
    </Root>
  );
};
