import {
  Box,
  Button,
  Card, CardContent, Container, Divider, Grid, Link, Typography,
} from '@mui/material';

import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { loginReducer } from '../redux/slices/login';
import { ReactComponent as CoreLogo } from '../components/logos/Core1.svg';
import { SecondFactorSetup } from '../components/SecondFactorSetup';
import { TwoFactorExplanationTooltip } from '../shared/utils';
import useAuth from '../hooks/useAuth';

const SecondFactor = () => {
  const loggedUser = useSelector((state) => get(state, `${loginReducer}.login.loggedUser`, {}));
  const [success, setSuccess] = useState(false);
  const { logout } = useAuth();

  return (
    <>
      <Helmet>
        <title>MSP | Setup 2FA</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px', pt: '80px' }}
        >
          <div style={{ paddingLeft: '60px', paddingBottom: '64px' }}>
            <CoreLogo />
          </div>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              {!success && (
              <>
                <Grid item sx={{ textAlign: 'center' }}>
                  <Typography
                    fontWeight="bold"
                    fontSize="26px"
                    variant="h4"
                    sx={{ mb: 2 }}
                  >
                    Setup two-factor authentication
                  </Typography>
                </Grid>
                <Grid item sx={{ textAlign: 'center' }}>
                  <Typography>
                    Two-factor authentication is an enhanced security measure.
                  </Typography>
                </Grid>
                <Grid item sx={{ textAlign: 'center' }}>
                  <Typography fontSize="18px" sx={{ mb: 2 }}>
                    To set it up, please follow the steps below using an
                    {' '}
                    <i>authenticator app</i>
                    <TwoFactorExplanationTooltip setup />
                  </Typography>
                </Grid>
              </>
              )}
              <SecondFactorSetup
                user={loggedUser}
                success={success}
                setSuccess={setSuccess}
                successCallback={() => {
                  logout({ force: false });
                }}
              />
              {success ? (
                <>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => {
                      logout({ force: true, clientSideOnly: true });
                    }}
                    sx={{ mt: 2, ml: 1 }}
                  >
                    Return to login
                  </Button>
                </>
              ) : (
                <>
                  <Divider sx={{ my: 3 }} />
                  <Link
                    color="textSecondary"
                    component={RouterLink}
                    to="/"
                    variant="body2"
                    onClick={() => {
                      window.reload();
                    }}
                  >
                    Return to login
                  </Link>
                </>
              )}
            </CardContent>
          </Card>

        </Container>

      </Box>

    </>
  );
};
export default SecondFactor;
