import {
  Grid,
} from '@mui/material';

import Address from '../../forms/Address';
import Legend from '../../forms/Legend';
import usePermissions from '../../../IAM/usePermissions';
import {
  FormFields,
} from './Schema';

function AddressInformation({ control }) {
  const permissions = usePermissions();
  const disableEdit = !permissions.merchants.editMerchantSensitive();

  return (
    <Grid
      container
      spacing={1}
    >
      <Legend>
        Physical Location Address
      </Legend>
      <Address control={control} name={FormFields.LOCATION} disabled={disableEdit} validate />
      <Legend>
        Mailing Location Address
      </Legend>
      <Address control={control} name={FormFields.MAILING} validate disabled={disableEdit} />
    </Grid>
  );
}

export default AddressInformation;
