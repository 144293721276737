import ContactInfo, { FormFields, ValidationSchema, defaultValue } from '../../forms/ContactInfo';
import { AccordionPersist } from '../../shared/Accordion/Accordion';
import PersistedManageEntities from '../../shared/ManageEntities/PersistedManageEntities';

export {
  ValidationSchema,
  FormFields,
  defaultValue,
};

const section = 'addAccount.corporateContacts';

export default AccordionPersist({
  label: 'Additional Corporate Contacts',
  section,
  Component: PersistedManageEntities({
    section,
    schema: ValidationSchema({ validate: true }),
    defaultValues: defaultValue,
    validate: true,
    entityManagerProps: {
      description: 'Please provide the details of additional contacts that may require access to the account. This step is optional.',
      sidebarHeader: 'Saved Contacts',
      mainHeader: 'Add New Contact',
      headerOnEdit: 'Edit Contact',
      getEntityListAttributes: (entity) => {
        return {
          title: `${entity?.[FormFields.FIRST_NAME]} ${entity?.[FormFields.LAST_NAME]}`,
          subtitle: `${entity?.[FormFields.TITLE]}`,
        };
      },
    },
    Component: ContactInfo,
  }),
});
