import client from '../../httpClient';
import ErrorFactory from '../../../shared/errors';

export async function MerchantTendersListRequest({ id }) {
  return client.get(`/merchants/${id}/tenders`);
}

export async function ListMerchantTendersRequest({ queryKey }) {
  try {
    const [, params] = queryKey;
    const response = await client.get(
      `/merchants/${params.id}/tenders`,
    );
    return response.data;
  } catch (err) {
    throw ErrorFactory(err);
  }
}
