import client from '../httpClient';

export async function GetUsers(params) {
  return client.get('/users', { params });
}

export async function GetUser({ id, params }) {
  return client.get(`/users/${id}`, { params });
}

export async function UserCheckToken({ token }) {
  return client.get(
    `/users/lost-password/check-token?recoverToken=${token}`,
  );
}

export async function UserGetMe() {
  return client.get('/users/me');
}

export async function GetUserApiKeys({ id, params }) {
  return client.get(`/users/${id}/api-keys`, { params });
}
