import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PropTypes from 'prop-types';
import Dialog from '../Dialog';

const ResetDialog = ({
  onConfirm,
  onClose,
  isOpen = false,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      actionSection={(
        <>
          <Button onClick={onClose} variant="outlined">Back</Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onConfirm}
            sx={{ ml: 1 }}
            autoFocus
          >
            Confirm Reset
          </Button>
        </>
      )}
      aria-labelledby="request-dialog-title"
      aria-describedby="request-dialog-description"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{
          minWidth: '250px',
          minHeight: '120px',
        }}
        aria-labelledby="reset-dialog-title"
        aria-describedby="reset-dialog-description"
      >
        <Grid item textAlign="center">
          <Grid item textAlign="center" xs={12}>
            <FeedbackIcon color="primary" sx={{ fontSize: '80px' }} />
          </Grid>
          <DialogContentText sx={{ typography: 'h6' }}>
            This action is irreversible and requires confirmation.
          </DialogContentText>
        </Grid>
      </Grid>
    </Dialog>
  );
};

ResetDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default ResetDialog;
