import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { client } from '../../../api';

export const fetchFundingGroupsByAccountThunk = createAsyncThunk('fundingGroupsByAccount.fetchList', async (payload, { rejectWithValue }) => {
  try {
    const { data, status, statusText } = await client.get('/funding', {
      params: {
        accountID: payload,
      },
    });
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const fetchFundingGroupsByAccountReducers = {
  [fetchFundingGroupsByAccountThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'response',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },
  [fetchFundingGroupsByAccountThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },
  [fetchFundingGroupsByAccountThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'error',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },
};
