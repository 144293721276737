import client from '../httpClient';
import ErrorFactory from '../../shared/errors';

export const UserDisableRequest = async (user) => {
  try {
    const data = await client.delete(`/users/${user?.id}`);
    return data;
  } catch (err) {
    throw ErrorFactory(err);
  }
};
