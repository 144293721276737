import { createSlice } from '@reduxjs/toolkit';
import { set, get } from 'lodash';
import {
  fetchUserListReducers,
  fetchUserListThunk,
  forgotPasswordReducers,
  forgotPasswordThunk,
  updatePasswordThunk,
  updatePasswordReducers,
  fetchUserReducers,
  fetchUserThunk,
  addUserReducers,
  addUserThunk,
  updateUserReducers,
  updateUserThunk,
} from '../api/users';

export const usersReducer = 'usersReducer';
export const getState = `${usersReducer}.get`;
export const editUserState = `${usersReducer}.edit`;
export const listUsersState = `${usersReducer}.list`;
export const addUserState = `${usersReducer}.add`;
export const renewPasswordState = `${usersReducer}.actions.renewPassword`;
export const disableUserDialogState = `${usersReducer}.actions.disableUser`;
export const lostResetPasswordState = `${usersReducer}.actions.lostResetPassword`;
export const initialState = {
  list: {
    response: {
      users: [],
    },
  },
  get: {
    status: null,
    isLoading: false,
  },
  edit: {
    success: {},
    isLoading: false,
    error: null,
    currentTab: 'account',
    formErrors: {},
    isResetDialogOpen: false,
    request: {
      user: {
        contactInformation: {
          firstName: '',
          lastName: '',
          email: '',
          confirmEmail: '',
          phone: '',
          country: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
        },
      },
    },
    response: {
      isDialogOpen: false,
      isSubmitting: false,
    },
    status: null,
  },
  actions: {
    renewPassword: {
      isOpen: false,
      userId: null,
    },
    disableUser: {
      success: null,
      isLoading: false,
      error: null,
      isOpen: false,
    },
  },
  entities: {
  },
  add: {
    formStep: 'contactInformation',
    prevFormStep: '',
    formErrors: {},
    isResetDialogOpen: false,
    request: {
      contactInformation: {
        username: '',
        defaultDashboard: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        professionalTitle: '',
        confirmEmail: '',
        mobile: '',
      },
      notifications: {
        cardChargeback: {
          email: {
            daily: false,
            weekly: false,
          },
        },
        achReturn: {
          email: false,
        },
      },
      permissions: {
        role: '',
        accounts: [],
        generateApiKey: false,
      },
    },
    response: {
      isDialogOpen: false,
      isLoading: false,
      error: null,
      result: null,
    },
  },
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUserEntity(state, action) {
      const { id, content } = action.payload;
      const current = get(state, `entities.${id}`);
      set(state, `entities.${id}`, {
        ...current,
        ...content,
      });
    },
    resetEditForm(state) {
      set(state, 'edit.formErrors', initialState.edit.formErrors);
      set(state, 'edit.isResetDialogOpen', initialState.edit.isResetDialogOpen);
      set(state, 'edit.request', { ...initialState.edit.request });
      set(state, 'edit.response', { ...initialState.edit.response });
    },
    resetForm(state) {
      set(state, 'add.request', { ...initialState.add.request });
      set(state, 'add.formStep', initialState.add.formStep);
      set(state, 'add.prevFormStep', initialState.add.prevFormStep);
      set(state, 'add.formErrors', initialState.add.formErrors);
    },
    resetAddResponse(state) {
      set(state, 'add.response', { ...initialState.add.response });
    },
    clearGetStatus(state) {
      set(state, 'get', initialState.get);
    },
    clearEditStatus(state) {
      set(state, 'edit.status', null);
      set(state, 'edit.error', null);
    },
    updateEditUserTab(state, action) {
      const { payload } = action;
      set(state, 'edit.currentTab', payload);
    },
    updateEditUserRequest(state, action) {
      const { payload } = action;
      const { path, value } = payload;
      set(state, ['edit', 'request', 'user', ...(typeof path === 'string' ? path.split('.') : path)], value);
    },
    updateNotificationState(state, action) {
      const { payload: { id, notifications } } = action;
      set(state, `entities.${id}.notifications`, notifications);
    },
    openEditUserDialog(state) {
      set(state, 'edit.response.isDialogOpen', true);
    },
    closeEditUserDialog(state) {
      set(state, 'edit.response.isDialogOpen', false);
    },
    openResetEditUserDialog(state) {
      set(state, 'edit.isResetDialogOpen', true);
    },
    closeResetEditUserDialog(state) {
      set(state, 'edit.isResetDialogOpen', false);
    },
    updateAddUser(state, action) {
      const { payload } = action;
      set(state, ['add', 'request', 'contactInformation'], { ...payload.contactInformation });
      set(state, ['add', 'request', 'permissions'], { ...payload.permissions });
      set(state, ['add', 'request', 'notifications'], { ...payload.notifications });
    },
    updateRequestUser(state, action) {
      const { payload } = action;
      set(state, 'edit.request.user', payload);
    },
    updateFormStep(state, action) {
      const { payload } = action;
      set(state, 'add.prevFormStep', get(state?.add, 'formStep', ''));
      set(state, 'add.formStep', payload);
    },
    updateFormErrors(state, action) {
      const { payload } = action;
      set(state, 'add.formErrors', payload);
    },
    openAddUserDialog(state) {
      set(state, 'add.response.isDialogOpen', true);
    },
    closeAddUserDialog(state) {
      set(state, 'add.response', { ...initialState.add.response });
      set(state, 'add.response.isDialogOpen', false);
    },
    openResetAddUserDialog(state) {
      set(state, 'add.isResetDialogOpen', true);
    },
    closeResetAddUserDialog(state) {
      set(state, 'add.isResetDialogOpen', false);
    },
    addSubmit(state) {
      set(state, 'add.response.isSubmitting', true);
      set(state, 'add.response.isDialogOpen', true);
    },
    setAddResult(state, action) {
      const { payload } = action;
      set(state, 'add.response.isSubmitting', false);
      set(state, 'add.response.error', null);
      set(state, 'add.response.result', payload);
    },
    setAddError(state, action) {
      const { payload } = action;
      set(state, 'add.response.isSubmitting', false);
      set(state, 'add.response.result', null);
      set(state, 'add.response.error', payload);
    },
    openRenewPasswordDialog(state, action) {
      const { payload } = action;
      set(state, 'actions.renewPassword.isOpen', true);
      set(state, 'actions.renewPassword.userId', payload.userId);
    },
    closeRenewPasswordDialog(state) {
      set(state, 'actions.renewPassword.isOpen', false);
      set(state, 'actions.renewPassword.userId', null);
    },
  },
  extraReducers: {
    ...fetchUserListReducers,
    ...fetchUserReducers,
    ...forgotPasswordReducers,
    ...updatePasswordReducers,
    ...updateUserReducers,
    ...addUserReducers,
  },
});

export const { reducer } = slice;

export const resetEditForm = () => (dispatch) => {
  dispatch(slice.actions.resetEditForm());
};

export const updateUserEntity = (action) => (dispatch) => {
  dispatch(slice.actions.updateUserEntity(action));
};

export const resetForm = () => (dispatch) => {
  dispatch(slice.actions.resetForm());
};

export const resetAddResponse = () => (dispatch) => {
  dispatch(slice.actions.resetAddResponse());
};

export const clearGetStatus = () => (dispatch) => {
  dispatch(slice.actions.clearGetStatus());
};

export const clearStatus = () => (dispatch) => {
  dispatch(slice.actions.clearEditStatus());
};

export const updateEditUserTab = (value) => (dispatch) => {
  dispatch(slice.actions.updateEditUserTab(value));
};

export const updateRequestUser = (value) => (dispatch) => {
  dispatch(slice.actions.updateRequestUser(value));
};

export const updateEditUserRequest = ({ path, value }) => (dispatch) => {
  dispatch(slice.actions.updateEditUserRequest({ path, value }));
};

export const updateNotificationState = (value) => (dispatch) => {
  dispatch(slice.actions.updateNotificationState(value));
};

export const openEditUserDialog = () => (dispatch) => {
  dispatch(slice.actions.openEditUserDialog());
};

export const closeEditUserDialog = () => (dispatch) => {
  dispatch(slice.actions.closeEditUserDialog());
};

export const openResetEditUserDialog = () => (dispatch) => {
  dispatch(slice.actions.openResetEditUserDialog());
};

export const closeResetEditUserDialog = () => (dispatch) => {
  dispatch(slice.actions.closeResetEditUserDialog());
};

export const updateAddUser = (action) => (dispatch) => {
  dispatch(slice.actions.updateAddUser(action));
};

export const fetchUserList = (params) => async (dispatch) => {
  dispatch(fetchUserListThunk(params));
};

export const fetchUser = ({ id }) => async (dispatch) => {
  dispatch(fetchUserThunk(id));
};

export const updateFormStep = (value) => (dispatch) => {
  dispatch(slice.actions.updateFormStep(value));
};

export const updateFormErrors = (value) => (dispatch) => {
  dispatch(slice.actions.updateFormErrors(value));
};

export const forgotPassword = (payload) => async (dispatch) => {
  dispatch(forgotPasswordThunk(payload));
};

export const openAddUserDialog = () => (dispatch) => {
  dispatch(slice.actions.openAddUserDialog());
};

export const closeAddUserDialog = () => (dispatch) => {
  dispatch(slice.actions.closeAddUserDialog());
};

export const openResetAddUserDialog = () => (dispatch) => {
  dispatch(slice.actions.openResetAddUserDialog());
};

export const closeResetAddUserDialog = () => (dispatch) => {
  dispatch(slice.actions.closeResetAddUserDialog());
};

export const addSubmit = (isSubmitting) => (dispatch) => {
  dispatch(slice.actions.addSubmit(isSubmitting));
};

export const setAddResult = (result) => (dispatch) => {
  dispatch(slice.actions.setAddResult(result));
};

export const setAddError = (error) => (dispatch) => {
  dispatch(slice.actions.setAddError(error));
};

export const addUser = ({ body }) => (dispatch) => {
  dispatch(addUserThunk({ body }));
  dispatch(openAddUserDialog());
};

export const updatePassword = (payload) => async (dispatch) => {
  dispatch(updatePasswordThunk(payload));
};

export const openRenewPasswordDialog = ({ userId }) => (dispatch) => {
  dispatch(slice.actions.openRenewPasswordDialog({ userId }));
};

export const closeRenewPasswordDialog = () => (dispatch) => {
  dispatch(slice.actions.closeRenewPasswordDialog());
};

export const updateUser = (payload) => async (dispatch) => {
  dispatch(openEditUserDialog());
  dispatch(updateUserThunk(payload));
};

export default slice;
