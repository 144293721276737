import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { get } from 'lodash';

import { Grid, Typography } from '@mui/material';
import CopyInfoForm from '../../../shared/CopyInfoForm';
import FullContactInformation, { FormFields, ValidationSchema, defaultValue } from '../../../forms/FullContactInformation';
import PersistedSectionForm from '../../../shared/PersistedSectionForm';
import '../style.css';

const section = 'addAccount.controlSigner';
const copyFrom = 'addAccount.applicantContact';

const ControlSigner = ({
  control, name, setError, clearErrors, withOwnership, isOwnershipValid, copyValues, optionalOwnership,
}) => {
  const store = useStore();
  const handleCopyFrom = (from, _) => {
    const state = store.getState();
    const fromFrom = get(state, `persistedFormsReducer.${from}.form`, {});

    copyValues(fromFrom);
  };

  useEffect(() => {
    if (!isOwnershipValid) {
      setError(FormFields.TOTAL_OWNERSHIP, { type: 'custom', message: 'Total ownership percentage must add up to 100%' });
    } else {
      clearErrors(FormFields.TOTAL_OWNERSHIP);
    }
  }, [isOwnershipValid]);
  return (
    <>
      { withOwnership && (
        <>
          <Typography color="textPrimary" variant="h5" sx={{ mb: 2 }}>
            Control Signer and Beneficial Owner Requirement
          </Typography>
          <Typography color="textPrimary" variant="body1" sx={{ mb: 2 }}>
            Financial Crimes Enforcement Network (FinCEN) is a government agency that monitors for fraudulent business practices in support of the Patriot Act. We are required by law to identify all beneficial owners using FinCEN&apos;s program called the Customer Due Diligence rule (CDD rule). The beneficial ownership disclosure is one of the requirements that we must follow for compliance.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            FinCEN requires us to identify the &quot;one person&quot; with significant control to manage or direct the legal entity. This person will be referred to as the &quot;Control Signer&quot;. The control signer will also be identified as a beneficial owner if they own equity in the legal entity.
          </Typography>
        </>
      )}
      <Typography variant="body1" sx={{ mb: 2 }}>
        Please provide the following details about the
        {' '}
        <strong>Control Signer</strong>
      </Typography>
      <Grid container>
        <CopyInfoForm onCopy={handleCopyFrom} copyFrom={copyFrom} copyTo={section}>
          Copy from Applicant Contact Information
        </CopyInfoForm>
        <FullContactInformation control={control} name={name} withOwnership={withOwnership} optionalOwnership={optionalOwnership} validate />
      </Grid>
    </>
  );
};

export default PersistedSectionForm({
  section,
  Component: ControlSigner,
  schema: ValidationSchema({ validate: true }),
  defaultValues: defaultValue,
});
