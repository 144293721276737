import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  Accordion,
  AccordionSummary,
} from '@mui/material';

import { get } from 'lodash';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { RowTable } from '../../RowTable';

import { accountsReducer, fetchAccount } from '../../../redux/slices/accounts';

const NameAndMembers = ({ data }) => {
  const dispatcher = useDispatch();
  const account = useSelector(
    (state) => get(state, `${accountsReducer}.entities.${data.accountID}`, {}),
  );

  useEffect(() => {
    try {
      fetchAccount({ id: data.accountID })(dispatcher);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ERROR', error);
    }
  }, []);

  return (
    <Accordion defaultExpanded disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        style={{ borderBottom: '1px solid #E0E0E0' }}
      >
        <Typography variant="h6" style={{ fontSize: '130%' }}>Name & Members</Typography>
      </AccordionSummary>
      {RowTable({
        data,
        labelWidth: '20%',
        rows: [
          {
            label: 'Funding Group Name',
            valueGetter: (row) => `${get(row, 'name', '-')}`,
          },
          {
            label: 'Related Account',
            valueGetter: () => `${get(account, 'organizationInfo.legalBusinessName', '-')}`,
          },
          {
            label: 'Primary Contact',
            valueGetter: () => `${get(account, 'applicantContact.firstName', '-')} ${get(account, 'applicantContact.lastName')}`,
          },
          {
            label: 'Merchants Count',
            valueGetter: (row) => `${get(row, 'members', '-')?.length || 0}`,
          },
        ],
      })}
    </Accordion>
  );
};

export default NameAndMembers;
