import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { experimentalStyled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Accordion = experimentalStyled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // // '&:not(:last-child)': {
  // //   borderBottom: 0,
  // // },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = experimentalStyled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .02)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = experimentalStyled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const AccordionForm = ({
  meta, onChange, title, content, ...props
}) => {
  const handleChange = (e, isExpanded) => {
    onChange(e, isExpanded, meta);
  };

  return (
    <Accordion
      TransitionProps={{ timeout: 1000 }}
      disableGutters
      elevation={0}
      sx={{ padding: 0 }}
      onChange={handleChange}
      {...props}
    >
      <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {content}
      </AccordionDetails>
    </Accordion>
  );
};
