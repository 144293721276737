import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Alert,
} from '@mui/material';
import { forgotPassword } from '../../redux/slices/users';

const ForgotPasswordForm = (props) => {
  const { setEmail } = props;
  const dispatch = useDispatch();
  const isSubmit = useSelector((state) => state.submit);
  return (
    <Formik
      initialValues={{
        email: '',
        submit: null,
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup
            .string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          forgotPassword(values)(dispatch);
          setSubmitting(false);
          setEmail('sent');
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, touched, values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            sx={{ mb: 3 }}
          />
          {errors.submit && (
          <Box sx={{ mt: 1, mb: 2 }}>
            <Alert severity="error">{errors.submit}</Alert>
          </Box>
          )}
          <Box sx={{ mt: 1 }}>
            <Button
              color="primary"
              disabled={isSubmit}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
