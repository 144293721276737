import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import FeedbackIcon from '@mui/icons-material/Feedback';

import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  addAccountState,
  closeResetAddAccountDialog,
} from '../../../redux/slices/accounts';
import Dialog from '../../shared/Dialogs/Dialog';

export const ConfirmResetDialog = ({
  onSuccess,
}) => {
  const isOpen = useSelector((state) => get(
    state,
    `${addAccountState}.isResetDialogOpen`,
    false,
  ));
  const dispatcher = useDispatch();
  const handleClose = () => {
    return closeResetAddAccountDialog()(dispatcher);
  };
  const handleResetClose = () => {
    onSuccess();
    return closeResetAddAccountDialog()(dispatcher);
  };

  const renderContent = () => {
    return (
      <>
        <Grid item textAlign="center">
          <Grid item textAlign="center" xs={12}>
            <FeedbackIcon color="primary" sx={{ fontSize: '80px' }} />
          </Grid>
          <DialogContentText sx={{ typography: 'h6' }}>
            This action is irreversible and requires confirmation.
          </DialogContentText>
        </Grid>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button onClick={handleClose} variant="outlined">Back</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleResetClose}
          sx={{ ml: 1 }}
          autoFocus
        >
          Confirm Reset
        </Button>
      </>
    );
  };
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
      actionSection={renderFooter()}
      aria-labelledby="confirm-reset-dialog-title"
      aria-describedby="confirm-reset-dialog-description"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{
          minWidth: '250px',
          minHeight: '120px',
        }}
      >
        {renderContent()}
      </Grid>
    </Dialog>
  );
};
