import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import {
  dropRight, get, isEmpty, last, omit,
} from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import { standardizeValidationFields } from '../../../shared/errors';
import { ROLES } from '../../../IAM/configuration';

const tagErrorMap = {
  required: 'is required',
  enum: 'is not valid',
  alpha: 'has invalid character',
  gt: 'cannot be empty',
};

const handleMessageContent = (message) => {
  if (!message.includes('can\'t access the object')) { return message; }
  const action = last(dropRight(message?.split('\'')));
  const role = message.match(/roles:(?<role>\w+)/)?.pop() || '';
  if (!role) return `Insufficient permission to execute "${action}"`;
  const roleName = ROLES.find(({ value }) => value === role).text;
  return `Insufficient permission to execute "${action}" on "Role: ${roleName}"`;
};

export const DialogError = ({ error }) => {
  let status = 0;
  let message = 'Something went wrong.';
  let details = {};
  // eslint-disable-next-line no-param-reassign
  error = error?.response || error;
  if (error?.data) {
    // Error is not standard error from ErrorFactory.js
    status = error?.status || status;
    message = error.data?.message || error?.data?.error?.message || message;
    details = { ...omit(error.data, 'message') };
  } else if (error?.validationFields) {
    // Add user case - We should look at those error messages to standardize all the cases
    status = error?.status || status;
    message = error?.message || message;
    details = { ...omit(error, ['message', 'status', 'statusText']) };
  } else {
    // Standard error from ErrorFactory.js
    status = error?.status || status;
    message = error?.message || message;
    details = error?.details || details;
  }
  const validationFields = standardizeValidationFields(get(details, 'validationFields', []));
  const renderErrorMessage = () => {
    const formatDetailedErrorMessage = () => {
      if (!isEmpty(validationFields)) {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Field</TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>Error</TableCell>
              </TableRow>
            </TableHead>
            {validationFields.length && (
              <TableBody>
                {validationFields.map((row) => {
                  // Note: this hack-ish approach can change if we get more structured data from the API
                  const splitText = row?.split('\'');
                  const field = get(splitText, 1, '');
                  const tag = last(dropRight(splitText));
                  return (
                    <TableRow
                      key={field}
                      data-testid="errorData"
                    >
                      <TableCell data-testid="errorName" size="small" component="th" scope="row">{field}</TableCell>
                      <TableCell data-testid="errorDescription" align="center" sx={{ minWidth: 112 }}>{tagErrorMap[tag] || tag}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        );
      }
      return (
        <Grid item textAlign="left">
          {handleMessageContent(message || '')}
        </Grid>
      );
    };
    const title = (status || message) ? 'Something went wrong!' : 'Something unexpected went wrong.';
    const subTitle = status ? `Your request failed with code ${status}.` : 'Your request failed.';
    return (
      <Grid item xs={12} textAlign="center">
        <Typography variant="h6">
          {title}
        </Typography>
        <Grid item xs={12} textAlign="center">
          {subTitle}
        </Grid>
        {message && (
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="error-details"
              id="error-details"
            >
              <Typography fontWeight="bold">Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {formatDetailedErrorMessage()}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        )}
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid item textAlign="center" xs={12}>
        <ErrorIcon color="error" sx={{ fontSize: '80px' }} />
      </Grid>
      {renderErrorMessage()}
    </Grid>
  );
};
