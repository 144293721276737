import {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Grid,
  Divider,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { values, keyBy, isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import Plus from '../../../icons/Plus';
import Dialog from '../../../components/shared/Dialogs/Dialog';
import useDebounce from '../../../hooks/useDebounce';

import { FiltersSection } from './FiltersSection';

import InputText from '../../../components/forms/InputText';
import Autocomplete from '../../../components/forms/Autocomplete';
import AccountsSelector from '../../../components/shared/AccountsSelector';
import BankTypeSelector from './BankTypeSelector';
import { MerchantFiltersTotal } from '../../../api';
import { useAccountTypes } from '../../../hooks/systemConfig/useAccountTypes';

const getInputByType = (filterType) => {
  switch (filterType) {
    case 'accountID':
      return AccountsSelector;
    case 'bankAccountType':
      return Autocomplete;
    default:
      return InputText;
  }
};

const getLabelByType = (filterType) => {
  switch (filterType) {
    case 'accountID':
      return 'Accounts';
    case 'bankAccountType':
      return 'Account type';
    default:
      return 'Content';
  }
};

const createEmptyFilter = () => {
  const filterId = uuidv4();
  return {
    id: filterId,
    filter: '',
    value: '',
    operator: '',
    error: true,
  };
};

const defaultFilters = [createEmptyFilter()];
const defaultTenderTypeFilter = { filter: 'tenderType', operator: 'EQUAL', value: '' };
const defaultCheckOptions = {
  card: false,
  ach: false,
  amex_tpsp: false,
  all: false,
};

const handleFiltersSelected = (selectedFilters) => {
  if (!selectedFilters.length) return { filtersSelected: [] };
  return selectedFilters.reduce((acc, filter) => {
    if (filter.filter === 'tenderType') {
      acc.tenderType = filter;
      return acc;
    }
    acc.filtersSelected.push(filter);
    return acc;
  }, { tenderType: undefined, filtersSelected: [] });
};

const handleInitialChekedOptions = (value) => {
  if (!value || value === 'all') return defaultCheckOptions;
  const splittedValues = value.split(/%2C|,/);
  return Object.fromEntries(Object.keys(defaultCheckOptions).map((key) => [key, splittedValues.includes(key)]));
};

export const FiltersModal = ({
  initialFilters = [],
  isOpen,
  onClose,
  onApplyFilters,
}) => {
  const [checkedOptions, setCheckedOptions] = useState({});
  const [noTenderSelected, setNoTendersSelected] = useState(null);
  const [filters, setFilters] = useState({});
  const [filterRows, setFilterRows] = useState([]);
  const [bankTypeOptions, setBankTypeOptions] = useState([]);
  const [tenderTypesFilter, setTenderTypesFilter] = useState({});
  const accountTypes = useAccountTypes();
  const debouncedFilters = useDebounce(filters, 500);
  const { data, isSuccess, isLoading } = useQuery(
    ['merchant-total-list', debouncedFilters, tenderTypesFilter],
    () => MerchantFiltersTotal({
      filtersSelected: [tenderTypesFilter, ...filterRows.map((id) => filters[id])],
    }, { refetchOnWindowFocus: false }),
  );
  useEffect(() => {
    if (!isOpen) return;
    const initialBankTypeOptions = accountTypes;
    const { tenderType, filtersSelected } = handleFiltersSelected(initialFilters);
    const initial = filtersSelected.length ? filtersSelected : defaultFilters;
    const tendersSelected = tenderType || defaultTenderTypeFilter;
    setFilters(keyBy(initial, 'id'));
    setFilterRows(initial.map(({ id }) => id));
    setBankTypeOptions(initialBankTypeOptions);
    setTenderTypesFilter(tendersSelected);
    setCheckedOptions(() => handleInitialChekedOptions(tendersSelected.value));
  }, [isOpen]);

  const filterOptionsHandler = useCallback(
    (options, state) => {
      const defaultFilterOptions = createFilterOptions();
      const usedOptions = filterRows.reduce((acc, id) => {
        if (filters[id]) {
          acc[filters[id].filter] = true;
        }
        return acc;
      }, {});
      const availableOptions = options.filter(
        ({ value }) => !usedOptions[value],
      );
      const result = defaultFilterOptions(availableOptions, state);
      return result;
    },
    [filters],
  );

  const cantSubmit = useMemo(() => {
    return values(filters).some(({ error }) => Boolean(error)) || noTenderSelected;
  }, [filters, noTenderSelected]);

  const handleFilterChange = ({ id, prop, value }) => {
    const changes = {
      ...filters[id],
      [prop]: value,
    };
    if (prop === 'filter') {
      changes.value = '';
    }
    setFilters({
      ...filters,
      [id]: changes,
    });
  };

  const handleRemoveFilter = (filterId) => {
    const {
      // eslint-disable-next-line no-unused-vars
      [filterId]: _,
      ...restFilters
    } = filters;
    setFilters(restFilters);
    setFilterRows(filterRows.filter((id) => id !== filterId));
  };

  const handleAddFilter = () => {
    const newFilter = createEmptyFilter();
    setFilters({
      ...filters,
      [newFilter.id]: newFilter,
    });
    setFilterRows([...filterRows, newFilter.id]);
  };

  const handleApplyFilters = () => {
    if (!tenderTypesFilter.value) {
      setNoTendersSelected(true);
      return;
    }
    if (!cantSubmit) {
      const newFilters = [tenderTypesFilter, ...filterRows.map((id) => filters[id])];
      onApplyFilters(newFilters);
    }
  };

  useEffect(() => {
    if (noTenderSelected !== null || tenderTypesFilter.value) { setNoTendersSelected(!tenderTypesFilter.value); }
  }, [tenderTypesFilter?.value]);

  const propsByTpe = (filterType) => {
    switch (filterType) {
      case 'accountID':
        return {
          singleMode: true,
        };
      case 'bankAccountType':
        return {
          options: bankTypeOptions,
        };
      default:
        return {};
    }
  };

  const handleClose = () => {
    setNoTendersSelected(null);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      title={<Typography variant="h5">Apply Filters</Typography>}
    >
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h6">Tenders</Typography>
        <BankTypeSelector
          checkedOptions={checkedOptions}
          setCheckedOptions={setCheckedOptions}
          setBankTypeOptions={setBankTypeOptions}
          accoutTypes={accountTypes}
          setTenderTypesFilter={setTenderTypesFilter}
          noTenderSelected={noTenderSelected}
        />
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid
        container
        spacing={0}
        sx={{
          minWidth: '800px',
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <Typography variant="h6">Filters</Typography>
          <Button
            color="primary"
            variant="contained"
            size="small"
            sx={{ mt: 2 }}
            onClick={handleAddFilter}
            disabled={filterRows.length > 4}
          >
            <Plus />
          </Button>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          {isOpen
            && filterRows.map((filterId) => {
              if (!filters[filterId]) return null;
              return (
                <FiltersSection
                  key={filters[filterId].id}
                  filter={filters[filterId]}
                  filterOptions={filterOptionsHandler}
                  onRemove={handleRemoveFilter}
                  onChange={handleFilterChange}
                  render={(props) => {
                    const Input = getInputByType(filters[filterId].filter);
                    const typeProps = propsByTpe(filters[filterId].filter);
                    return (
                      <Input
                        label={getLabelByType(filters[filterId].filter)}
                        {...props}
                        {...typeProps}
                      />
                    );
                  }}
                />
              );
            })}
        </Grid>
        {
          !isEmpty(filterRows) && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              sx={{ mt: 2 }}
              onClick={handleAddFilter}
              disabled={filterRows.length > 4}
            >
              <Plus />
            </Button>
          </Grid>
          )
        }
      </Grid>
      <Divider sx={{ mt: 4, mb: 2 }} />
      <Grid container justifyContent="end">
        <Grid sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          {isSuccess && (
            <>
              <Tooltip
                title="Estimated amount of filtered MIDs"
                placement="top"
              >
                <InfoIcon color="primary" sx={{ fontSize: '24px', mr: 1 }} />
              </Tooltip>
              <Typography color="primary">
                Merchants:
                {' '}
                {data?.count || 0}
              </Typography>
            </>
          )}
          {isLoading && <CircularProgress size={12} disableShrink />}
        </Grid>

        <Button onClick={handleClose} variant="outlined" sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button
          onClick={handleApplyFilters}
          variant="contained"
          autoFocus
          disabled={cantSubmit || !filterRows.length}
        >
          Apply
        </Button>
      </Grid>
    </Dialog>
  );
};
