import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { GetUser, GetUserApiKeys } from '../../../api';

export const fetchUserThunk = createAsyncThunk('/user/fetchUser', async (id, { rejectWithValue }) => {
  try {
    const { data, status, statusText } = await GetUser({ id });
    const { data: apiKeyData } = await GetUserApiKeys({ id });

    return {
      ...data, apiKeyData, status, statusText,
    };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({
      ...data, status, statusText, id,
    });
  }
});

export const fetchUserReducers = {
  [fetchUserThunk.fulfilled]: (state, { payload }) => {
    const { users, apiKeyData } = payload;
    const { id = 'MISSING' } = users[0];

    if (apiKeyData?.apiKeys != null) { users[0].apiKey = apiKeyData.apiKeys[0]?.secret; }

    set(
      state,
      `entities.${id}`,
      users[0],
    );
    set(
      state,
      'get.isLoading',
      false,
    );
    set(
      state,
      'get.status',
      payload?.status,
    );
  },
  [fetchUserThunk.pending]: (state) => {
    set(
      state,
      'get.isLoading',
      true,
    );
    set(
      state,
      'get.status',
      null,
    );
  },
  [fetchUserThunk.rejected]: (state, { payload }) => {
    const { id } = payload;
    set(
      state,
      `entities.${id}`,
      null,
    );
    set(
      state,
      'get.isLoading',
      false,
    );
    set(
      state,
      'get.status',
      payload?.status,
    );
    set(
      state,
      'get.error',
      payload,
    );
  },
};
