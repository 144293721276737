import {
  React, useEffect, useState,
} from 'react';
import {
  toUpper, capitalize, defaultTo,
} from 'lodash';
import { Add, Visibility } from '@mui/icons-material';
import {
  Button, Card, IconButton, Switch, Tooltip, tooltipClasses, styled,
} from '@mui/material';
import { useMutation } from 'react-query';
import { SetTenderStatusRequest } from '../../api/Merchants/Tenders/SetStatus';
import useToggle from '../../hooks/useToggle';

import ProcessingDialog from '../shared/Dialogs/ProcessingDialog';
import { MSPDataGrid } from '../DataGrid';
import BankingSettings from './BankingSettings';
import { NewTenderDialog } from './edit/NewTenderDialog';
import { DateTimeFormat } from '../../utils/formatting/Dates';
import { SetTenderTestModeRequest } from '../../api/Merchants/Tenders/SetTest';
import Trash from '../../icons/Trash';
import { SetTenderDeleteRequest } from '../../api/Merchants/Tenders/Delete';
import TenderCredentials from './TenderCredentials';
import { useIsMobileView } from '../../hooks/useIsMobileView';

export const MIDTenders = ({
  tenderTypes, merchant, handleReload = () => { }, accountTypes,
}) => {
  const isMobileView = useIsMobileView();
  const setTenderStatus = useMutation(SetTenderStatusRequest);
  const setTenderTest = useMutation(SetTenderTestModeRequest);
  const setTenderDelete = useMutation(SetTenderDeleteRequest);
  const [bankingSettingsModal, setBankingSettingsModal] = useState({ data: {}, open: false });
  const [tenderCredentialsModal, setTenderCredentialsModal] = useState({ data: {}, open: false });
  const [addTenderDialog, setAddTenderDialog] = useState(false);
  const tenderStatusDialog = useToggle();
  const tenderTestDialog = useToggle();
  const tenderDeleteDialog = useToggle();
  const [cols, setCols] = useState([]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    '.MuiTooltip-arrow': {
      '&::before': {
        backgroundColor: '#f5f5f9',
        border: '1px solid #dadde9',
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9',
    },
  }));

  const buildCols = ({ mobile }) => [
    {
      field: 'id',
      hide: true,
    },
    mobile && {
      field: 'banking',
      headerName: 'Banking',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          color="primary"
          onClick={(e) => {
            setBankingSettingsModal({ data: row, open: true });
            e.stopPropagation();
          }}
          size="large"
        >
          <Visibility />
        </IconButton>
      ),
    },
    mobile && {
      field: 'credentials',
      headerName: 'Credentials',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          color="primary"
          onClick={(e) => {
            setTenderCredentialsModal({ data: row, open: true });
            e.stopPropagation();
          }}
          size="large"
        >
          <Visibility />
        </IconButton>
      ),
    },
    {
      field: 'tender',
      headerName: 'Tender',
      headerAlign: 'left',
      flex: 1,
      valueGetter: (params) => capitalize(`${params.row?.paymentMethodType}`),
    },
    {
      field: 'integration',
      headerName: 'Gateway',
      headerAlign: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          params?.row?.gatewayGroup !== undefined
            ? (
              <HtmlTooltip
                title={(
                  <>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Gateway Group:</span>
                      {' '}
                      {params.row.gatewayGroup}

                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Gateway Fee Group:</span>
                      {' '}
                      {params.row.gatewayFeeGroup}
                    </div>
                  </>
        )}
              >
                <Button>{capitalize(params.row?.gateway)}</Button>
              </HtmlTooltip>
            )
            : <div>{capitalize(params?.row?.gateway)}</div>
        );
      },
    },
    {
      field: 'processor',
      headerName: 'Processor',
      headerAlign: 'left',
      flex: 1,
      valueGetter: (params) => toUpper(`${params.row?.processor}`),
    },
    {
      field: 'testMode',
      headerName: 'Test Mode',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        const isChecked = params?.row?.testMode || false;
        const handleItemChange = () => {
          tenderTestDialog.turnOn({
            tenderId: params.row.id,
            merchantId: merchant.id,
          });
        };
        return <Switch checked={isChecked} onChange={handleItemChange} />;
      },
    },
    {
      field: 'status',
      headerName: 'Enabled',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        const isChecked = params?.row?.status?.toLowerCase() === 'active';
        const handleItemChange = () => {
          tenderStatusDialog.turnOn({
            tenderId: params.row.id,
            merchantId: merchant.id,
            status: isChecked ? 'inactive' : 'active',
          });
        };
        return <Switch checked={isChecked} onChange={handleItemChange} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      headerAlign: 'left',
      flex: 1,
      hide: true,
      valueGetter: (params) => DateTimeFormat(`${params.row?.createdAt}`),
    },
    !mobile && {
      field: 'banking',
      headerName: 'Banking',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          color="primary"
          onClick={(e) => {
            setBankingSettingsModal({ data: row, open: true });
            e.stopPropagation();
          }}
          size="large"
        >
          <Visibility />
        </IconButton>
      ),
    },
    !mobile && {
      field: 'credentials',
      headerName: 'Credentials',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          color="primary"
          onClick={(e) => {
            setTenderCredentialsModal({ data: row, open: true });
            e.stopPropagation();
          }}
          size="large"
        >
          <Visibility />
        </IconButton>
      ),
    },
    {
      field: 'actions',
      headerName: 'Delete',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <Button
          onClick={() => {
            tenderDeleteDialog.turnOn({
              tenderId: params.row.id,
              merchantId: merchant.id,
            });
          }}
        >
          <Trash fontSize="small" />
        </Button>
      ),
    },
  ].filter((u) => u);

  useEffect(() => {
    setCols(buildCols({ mobile: isMobileView }));
  }, [isMobileView]);

  const handleStatusCompleted = () => {
    tenderStatusDialog.reset();
    handleReload();
    setTimeout(() => {
      setTenderStatus.reset();
    }, 300);
  };

  const handleSetStatus = () => {
    setTenderStatus.mutate(tenderStatusDialog.meta);
  };

  const handleTestCompleted = () => {
    tenderTestDialog.reset();
    handleReload();
    setTimeout(() => {
      setTenderTest.reset();
    }, 300);
  };

  const handleSetTest = () => {
    setTenderTest.mutate(tenderTestDialog.meta);
  };

  const handleDeleteComplete = () => {
    tenderDeleteDialog.reset();
    handleReload();
    setTimeout(() => {
      setTenderDelete.reset();
    }, 300);
  };

  const handleDelete = () => {
    setTenderDelete.mutate(tenderDeleteDialog.meta);
  };

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={() => setAddTenderDialog(true)}
        startIcon={<Add />}
        sx={{
          mr: 2,
        }}
      >
        Add
      </Button>
      <Card sx={{ mt: 3 }}>
        <MSPDataGrid
          cols={cols}
          tableData={defaultTo(tenderTypes?.toSorted((a, b) => a.id.localeCompare(b.id)), [])}
        />
        <BankingSettings
          open={bankingSettingsModal.open}
          data={bankingSettingsModal.data}
          onClose={() => setBankingSettingsModal({ open: false, data: bankingSettingsModal.data })}
          accountTypes={accountTypes}
        />
        <TenderCredentials
          open={tenderCredentialsModal.open}
          data={tenderCredentialsModal.data}
          onClose={() => setTenderCredentialsModal({ open: false, data: tenderCredentialsModal.data })}
        />
        <NewTenderDialog
          isOpen={addTenderDialog}
          merchant={merchant}
          handleClose={() => {
            handleReload();
            setAddTenderDialog(false);
          }}
        />
        <ProcessingDialog
          isOpen={tenderStatusDialog.isActive}
          onFinished={handleStatusCompleted}
          onClose={tenderStatusDialog.turnOff}
          onRetry={handleSetStatus}
          onConfirm={handleSetStatus}
          status={setTenderStatus.status}
          error={setTenderStatus.error}
          confirmationSubtitle={tenderStatusDialog.meta?.status?.toLowerCase() === 'active' ? 'Are you sure you want to activate this tender?' : 'Are you sure you want to deactivate this tender?'}
          successText="Tender status updated successfully"
        />
        <ProcessingDialog
          isOpen={tenderTestDialog.isActive}
          onFinished={handleTestCompleted}
          onClose={tenderTestDialog.turnOff}
          onRetry={handleSetTest}
          onConfirm={handleSetTest}
          status={setTenderTest.status}
          error={setTenderTest.error}
          confirmationSubtitle="Are you sure you want to toggle Test Mode?"
          successText="Test Mode updated successfully"
        />
        <ProcessingDialog
          isOpen={tenderDeleteDialog.isActive}
          onFinished={handleDeleteComplete}
          onClose={tenderDeleteDialog.turnOff}
          onRetry={handleDelete}
          onConfirm={handleDelete}
          status={setTenderDelete.status}
          error={setTenderDelete.error}
          confirmationSubtitle="Are you sure you want to delete this tender?"
          successText="Tender deleted successfully"
        />
      </Card>
    </>
  );
};
