export const NON_INTEGRATED_OPTIONS = [
  { label: 'Authorize.net', value: 'authorize-net' },
  { label: 'Dejavoo Z11', value: 'dejavoo-z11' },
  { label: 'Dejavoo Z9', value: 'dejavoo-z9' },
  { label: 'Equinox Apollo AIO', value: 'equinox-apollo-aio' },
  { label: 'Ingenico iCT250', value: 'ingenico-ict250' },
  { label: 'Omni Vx520', value: 'omni-vx520' },
  { label: 'PAX A80 - BridgePay', value: 'pax-a80-bridgepay' },
  { label: 'PAX SP30', value: 'pax-sp30' },
  { label: 'Pay Guardian', value: 'pay-guardian' },
  { label: 'Tyler Parks & Rec', value: 'tyler-parks-rec' },
  { label: 'USAePay', value: 'usaepay' },
  { label: 'VitalPOS', value: 'vital-pos' },
];

export const INTEGRATED_OPTIONS = [
  { label: 'Eagle Recorder', value: 'eagle-recorder' },
  { label: 'Eagle Treasurer/Tax', value: 'eagle-treas-tax' },
  { label: 'Incode - Cash Collections 10', value: 'incode-cash-10' },
  { label: 'Incode9.01 - Cash Collections', value: 'incode-901-cash' },
  { label: 'Ingenico iCT250', value: 'ingenico-ict250' },
  { label: 'LGD - TaxWise', value: 'lgd-taxwise' },
  { label: 'Munis IVR', value: 'munis-ivr' },
  { label: 'Munis Payment Processing', value: 'munis-payment' },
  { label: 'New World ERP', value: 'new-world-erp' },
  { label: 'Tyler Cashiering', value: 'tyler-cashiering' },
  { label: 'Tyler Parks & Rec', value: 'tyler-parks-rec' },
  { label: 'Cash Receipts - Z11', value: 'cash-receipts-z11' },
  { label: 'Cash Receipts - Z9', value: 'cash-receipts-z9' },
  { label: 'AdComp Systems', value: 'adcomp-systems' },
  { label: 'Amano McGann Parking Solutions', value: 'amano-mcgann' },
  { label: 'BS&A Software', value: 'bsa-software' },
  { label: 'Bypass POS', value: 'bypass-pos' },
  { label: 'CivicRec', value: 'civic-rec' },
  { label: 'Club Prophet', value: 'club-prophet' },
  { label: 'Comprise', value: 'comprise' },
  { label: 'EnvisionWare', value: 'envision-ware' },
];

export const ACCOUNT_TYPE_OPTIONS = [
  { label: 'Credit/Debit (including Amex OptBlue)', value: 'credit-debit' },
  { label: 'ACH/eCheck', value: 'ach' },
  { label: 'Wire Transfer', value: 'wire' },
  { label: 'American Express TPSP', value: 'tpsp' },
];

export const PAYMENT_ENVIRONMENT_OPTIONS = [
  { label: 'Internet Website', value: 'internet' },
  { label: 'Mail/Phone Order', value: 'mail-or-phone' },
  { label: 'Retail', value: 'retail' },
  { label: 'Retail with Tip', value: 'retail-with-tip' },
  { label: 'Service', value: 'service' },
  { label: 'Restaurant', value: 'restaurant' },
  { label: 'Fast Food', value: 'fast-food' },
  { label: 'Lodging', value: 'lodging' },
  { label: 'Petrolium', value: 'petrolium' },
];
