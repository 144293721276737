export const FOOTAGE_OPTIONS = [
  { value: '0-500', label: '0 - 500' },
  { value: '501-2500', label: '501 - 2500' },
  { value: '2501-5000', label: '2501 - 5000' },
  { value: '5001-10000', label: '5001 - 10000' },
  { value: '10000+', label: '10000 +' },
];

export const DEED_OPTIONS = [
  { value: 'own', label: 'Own the property' },
  { value: 'rental', label: 'Rental property' },
];

export const ZONING_OPTIONS = [
  { label: 'Commercial', value: 'commercial' },
  { label: 'Industrial', value: 'industrial' },
  { label: 'Residencial', value: 'residencial' },
];

export const TYPE_OPTIONS = [
  { value: 'shopping-center', label: 'Shopping Center' },
  { value: 'office-building', label: 'Office Building' },
  { value: 'separate-building', label: 'Separate Building' },
  { value: 'residence', label: 'Residence' },
];
