import Button from '@mui/material/Button';
import {
  CircularProgress, Grid,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';

import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FeedbackIcon from '@mui/icons-material/Feedback';

import { useEffect } from 'react';

import { DialogError } from '../DialogError';
import Dialog from '../Dialog';

const ProcessingDialog = ({
  isOpen, onClose, onFinished, onRetry, status, error, onConfirm,
  confirmationSubtitle, onSuccess, successText, dataTestId,
  confirmationActionText = 'Confirm',
  confirmationActionColor = 'primary',
  customSuccessActions = null,
}) => {
  const isSuccess = status === 'success';
  const isRejected = status === 'error';
  const isLoading = status === 'loading';
  const isUnconfirmed = typeof onConfirm === 'function' && status === 'idle';

  useEffect(() => {
    if (status === 'success' && typeof onSuccess === 'function') {
      onSuccess();
    }
  }, [status]);

  const renderConfirmation = () => {
    return (
      <>
        <Grid item textAlign="center">
          <Grid item textAlign="center" xs={12}>
            <FeedbackIcon color="warning" sx={{ fontSize: '80px' }} />
          </Grid>
          <DialogContentText sx={{ typography: 'h6' }}>
            This action requires confirmation
          </DialogContentText>
          {confirmationSubtitle && (
          <DialogContentText sx={{ mt: 1 }} data-testid="deleteConfirmationText">
            {confirmationSubtitle}
          </DialogContentText>
          )}
        </Grid>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Grid item textAlign="center">
          <Grid item textAlign="center" xs={12}>
            <PendingIcon color="primary" sx={{ fontSize: '80px', mb: 1 }} />
          </Grid>
          <DialogContentText sx={{ typography: 'h6', mb: 3 }}>
            Your request is being processed...
          </DialogContentText>
          <Grid item textAlign="center" xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderSuccess = () => (
    <>
      <Grid item textAlign="center" xs={12} data-testid="actionSuccessMsg">
        <CheckCircleRoundedIcon color="primary" sx={{ fontSize: '80px' }} />
      </Grid>
      <Grid item textAlign="center">
        <DialogContentText sx={{ typography: 'h6' }}>
          { successText }
        </DialogContentText>
      </Grid>
    </>
  );

  const renderSuccessFooter = () => (
    customSuccessActions
      ? customSuccessActions({ onFinished })
      : (
        <>
          <Button
            color="primary"
            onClick={onFinished}
            variant="contained"
            data-testid="okButton"
          >
            OK
          </Button>
        </>
      )
  );

  const renderRejected = () => {
    return <DialogError error={error} />;
  };

  const renderRejectedFooter = () => (
    <>
      <Button onClick={onClose} variant="outlined" data-testid="backBtn">Back</Button>
      <Button onClick={onRetry} variant="contained" autoFocus data-testid="retryBtn">
        Retry
      </Button>
    </>
  );

  const renderUnconfirmedFooter = () => {
    return (
      <>
        <Button onClick={onClose} variant="outlined" data-testid="backBtn">Back</Button>
        <Button
          color={confirmationActionColor}
          variant="contained"
          onClick={onConfirm}
          sx={{ ml: 1 }}
          autoFocus
          data-testid="confirmActionBtn"
        >
          {confirmationActionText}
        </Button>
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      disableClose={isLoading}
      onClose={(event, reason) => {
        if (isSuccess) onFinished();
        if (reason !== 'backdropClick' && !isSuccess) {
          onClose(event, reason);
        }
      }}
      actionSection={
          (isUnconfirmed && renderUnconfirmedFooter())
          || (isSuccess && renderSuccessFooter())
          || (isRejected && renderRejectedFooter())
        }
      aria-labelledby="request-dialog-title"
      aria-describedby="request-dialog-description"
      data-testid={dataTestId}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{
          minHeight: '150px',
        }}
        aria-labelledby="procesing-dialog-title"
        aria-describedby="procesing-dialog-description"
      >
        {isUnconfirmed && renderConfirmation()}
        {isLoading && renderLoading()}
        {isSuccess && renderSuccess()}
        {isRejected && renderRejected()}
      </Grid>
    </Dialog>
  );
};

export default ProcessingDialog;
