import { forEach, map, pick } from 'lodash';

const changePropertyValue = (items, propertyName, value, newValue) => map(items, (item) => {
  return item?.[propertyName] === value
    ? { ...item, [propertyName]: newValue }
    : item;
});

const changePropertyValues = (items, changeValues) => {
  let result = items;
  forEach(changeValues, ({ propertyName, value, newValue }) => {
    result = changePropertyValue(result, propertyName, value, newValue);
  });
  return result;
};

const changePropertyName = (items, propertyName, newPropertyName) => map(items, (item) => {
  const { [propertyName]: temp, ...otherProps } = item;
  return ({ [newPropertyName]: temp, ...otherProps });
});

const changePropertyNames = (items, propertyNames) => {
  let result = items;
  forEach(propertyNames, ({ propertyName, newPropertyName }) => {
    result = changePropertyName(result, propertyName, newPropertyName);
  });
  return result;
};

const pickProperties = (items, itemsToInclude) => {
  return map(items, (item) => {
    return pick(item, itemsToInclude);
  });
};

// eslint-disable-next-line
const createChildren = (flatArray, value = null, parentPropertyName) => flatArray
  .filter((element) => element[parentPropertyName] === value)
  .map((element) => ({ ...element, children: createChildren(flatArray, element.value, parentPropertyName) }));

const addRootNode = (items) => ([{
  value: '',
  label: '',
  children: items,
}]);

export const hierarchyBuilder = (items, config = {
  changeValues: [
    {
      propertyName: 'parentId',
      value: '',
      newValue: null,
    },
  ],
  changeKeys: [
    {
      propertyName: 'id',
      newPropertyName: 'value',
    }, {
      propertyName: 'legalBusinessName',
      newPropertyName: 'label',
    },
  ],
  itemsToInclude: [
    'value',
    'label',
    'parentId',
  ],
}) => {
  let hierarchy = items;
  const { changeValues, changeKeys, itemsToInclude } = config;
  hierarchy = changePropertyValues(hierarchy, changeValues);
  hierarchy = changePropertyNames(hierarchy, changeKeys);
  hierarchy = pickProperties(hierarchy, itemsToInclude);
  // hierarchy = createChildren(hierarchy, null, 'parentId');
  return addRootNode(hierarchy);
};
