import PersistedManageEntities from '../../../shared/ManageEntities/PersistedManageEntities';
import FullContactInformation, {
  FormFields,
  ValidationSchema,
  defaultValue,
} from '../../../forms/FullContactInformation';

export { FormFields, defaultValue, ValidationSchema };

const section = 'addAccount.beneficialOwners';

export default PersistedManageEntities({
  section,
  schema: ValidationSchema({ validate: true, withOwnership: true }),
  defaultValues: defaultValue,
  validate: true,
  withContainer: false,
  entityManagerProps: {
    description: 'Please provide information regarding beneficial owners. In case there are multiple, please add them by descending percent of ownership.',
    sidebarHeader: 'Saved Owners',
    mainHeader: 'Add New Owner',
    headerOnEdit: 'Edit Owner',
    getEntityListAttributes: (entity) => {
      return {
        title: `${entity?.[FormFields.FIRST_NAME]} ${entity?.[FormFields.LAST_NAME]}`,
        subtitle: `${entity?.[FormFields.OWNERSHIP]}%`,
      };
    },
  },
  Component: FullContactInformation,
});
