import {
  Grid,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { prefixFormName } from '../../../utils/formatting/Form';
import { ControlledPhoneInput } from '../PhoneInput';
import { ControlledInputText } from '../InputText';
import { ControlledAutocomplete } from '../Autocomplete';

export const FormFields = {
  COMPANY_NAME: 'companyName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  CREDIT_TERMS: 'creditTerms',
};

const CreditTermOptions = [
  {
    text: 'Net 30',
    value: 'net30',
  },
  {
    text: 'Net 60',
    value: 'net60',
  },
  {
    text: 'Net 90',
    value: 'net90',
  },
  {
    text: 'Month End',
    value: 'monthEnd',
  },
  {
    text: 'Month Following Invoice',
    value: 'monthFollowingInvoice',
  },
  {
    text: 'Payment on Order',
    value: 'paymentOnOrder',
  },
  {
    text: 'Payment on Shipment',
    value: 'paymentOnShipping',
  },
  {
    text: 'Payment on Delivery',
    value: 'paymentOnDelivery',
  },
  {
    text: 'Other',
    value: 'other',
  },
];

function TradeReference({
  title = '',
  name,
  disabled = false,
  validate,
  control,
}) {
  return (
    <>
      {
      title
      && (
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          {title}
        </Typography>
      </Grid>
      )
    }
      <Grid item xs={12}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.COMPANY_NAME)}
          data-testid="tradeReferenceCompanyName"
          label="Company name"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.FIRST_NAME)}
          data-testid="tradeReferenceFirstName"
          label="First name"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.LAST_NAME)}
          data-testid="tradeReferenceLastName"
          label="Last name"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledPhoneInput
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.PHONE)}
          data-testid="tradeReferencePhoneNumber"
          label="Phone number"
          required={validate}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ControlledAutocomplete
          control={control}
          disabled={disabled}
          options={CreditTermOptions}
          label="Credit terms"
          name={prefixFormName(name, FormFields.CREDIT_TERMS)}
          data-testid="tradeReferenceCreditTerms"
          required={validate}
        />
      </Grid>
    </>
  );
}

TradeReference.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validate: PropTypes.bool,
  title: PropTypes.string,
};

export default TradeReference;
