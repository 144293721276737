import {
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ControlledInputText } from '../InputText';
import { ControlledPhoneInput } from '../PhoneInput';
import useFormFieldWatch from '../../../hooks/useFormFieldWatch';
import { prefixFormName } from '../../../utils/formatting/Form';

export const FormFields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  TITLE: 'professionalTitle',
  EMAIL: 'email',
  CONFIRM_EMAIL: 'confirmEmail',
  PHONE: 'phone',
  MOBILE: 'mobile',
  OWNERSHIP: 'pow',
};

function ContactInfo({
  title = '',
  name,
  disabled = false,
  control,
  validate,
  hideTitle = false,
  fields = FormFields,
  dataTestId,
}) {
  const areEmailEqual = useFormFieldWatch(control,
    [prefixFormName(name, fields.CONFIRM_EMAIL), prefixFormName(name, fields.EMAIL)],
    (changes) => {
      return changes[0] === changes[1];
    });

  return (
    <>
      {
      title
      && (
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          {title}
        </Typography>
      </Grid>
      )
    }
      <Grid item xs={12} sm={6} md={hideTitle ? 6 : 4}>
        <ControlledInputText
          control={control}
          disabled={disabled}
          name={prefixFormName(name, fields.FIRST_NAME)}
          data-testid={`${dataTestId || ''}FirstName`}
          label="First name"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={hideTitle ? 6 : 4}>
        <ControlledInputText
          control={control}
          disabled={disabled}
          required={validate}
          name={prefixFormName(name, fields.LAST_NAME)}
          data-testid={`${dataTestId || ''}LastName`}
          label="Last name"
        />
      </Grid>
      { !hideTitle && (
      <Grid item xs={12} md={4}>
        <ControlledInputText
          control={control}
          disabled={disabled}
          name={prefixFormName(name, fields.TITLE)}
          data-testid={`${dataTestId || ''}ProfessionalTitle`}
          label="Professional title"
          required={validate}
        />

      </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          control={control}
          disabled={disabled}
          name={prefixFormName(name, fields.EMAIL)}
          data-testid={`${dataTestId || ''}EmailField`}
          label="Email address"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          control={control}
          disabled={disabled || areEmailEqual}
          name={prefixFormName(name, fields.CONFIRM_EMAIL)}
          data-testid={`${dataTestId || ''}EmailConfirmation`}
          label="Confirm email address"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledPhoneInput
          control={control}
          disabled={disabled}
          name={prefixFormName(name, fields.PHONE)}
          data-testid={`${dataTestId || ''}PhoneNumber`}
          label="Phone number"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledPhoneInput
          control={control}
          disabled={disabled}
          name={prefixFormName(name, fields.MOBILE)}
          data-testid={`${dataTestId || ''}MobilePhone`}
          label="Mobile phone number"
        />
      </Grid>
      {name === 'controlSigner' && (
      <Grid item xs={6}>
        <ControlledInputText
          control={control}
          type="number"
          required={validate}
          name={prefixFormName(name, FormFields.OWNERSHIP)}
          label="Percent of ownership"
          data-testid="ownershipPercentage"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      )}

    </>
  );
}

ContactInfo.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  validate: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
};

export default ContactInfo;
