import { map } from 'lodash';
import httpDeviceClient from '../../../../../api/httpDeviceClient';
import ErrorFactory from '../../../../../shared/errors';

async function DeviceGatewaysRequest() {
  try {
    const gatewaysTransformed = [];
    const { data } = await httpDeviceClient.get(
      '/gateways',
    );
    map(data, (gateway) => {
      gatewaysTransformed.push({ text: gateway, value: gateway });
    });
    return gatewaysTransformed;
  } catch (err) {
    throw ErrorFactory(err);
  }
}

export default DeviceGatewaysRequest;
