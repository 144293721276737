import AccountsSelector, { useGetAccountsFromId } from './AccountsSelector';
import ControlledComponent from '../../../utils/ControlledComponent';

const ControlledAccountsSelector = ControlledComponent(AccountsSelector);
export {
  ControlledAccountsSelector,
  useGetAccountsFromId,
};

export default AccountsSelector;
