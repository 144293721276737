import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Button, Container, Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setRedirectToProfile } from '../redux/slices/login';

const NotFound = () => {
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  // If session times out in the 404 page, we redirect next login to the profile page
  useEffect(() => {
    setRedirectToProfile(true)(dispatcher);
    // when leaving the page we disable this flag, but only after 1 second to allow timeout code to process it
    return () => setTimeout(() => {
      setRedirectToProfile(false)(dispatcher);
    }, 1000);
  }, []);
  return (
    <>
      <Helmet>
        <title>MSP | Not Found</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h4' : 'h1'}
          >
            404
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Button
              color="primary"
              onClick={() => {
                navigate(-1);
              }}
              variant="outlined"
            >
              Back
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
