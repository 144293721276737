import {
  TextField,
} from '@mui/material';
import {
  first, isEmpty, toLower, toUpper,
} from 'lodash';
import { Controller } from 'react-hook-form';

// f("This Is An Example") = "This is an example"
const standardizeLabelString = (label = '') => {
  if (typeof label !== 'string' || isEmpty(label)) return '';
  return toUpper(first(label)) + toLower(label.slice(1));
};

const FormTextField = ({
  label,
  required = false,
  autoFocus = false,
  disabled = false,
  dataTestId,
  name,
  multiline,
  rows,
  readOnly,
  sx,
  endAdornment,
  helperText,
  InputLabelProps,
  variant,
  standardizeLabel = true,
  ...rest
}) => {
  return (
    <Controller
      {...rest}
      name={name}
      rules={{ required }}
      render={({ field, fieldState }) => (
        <TextField
          multiline={multiline}
          rows={rows}
          autoFocus={autoFocus}
          disabled={disabled}
          label={standardizeLabel ? standardizeLabelString(label) : label}
          helperText={fieldState.error?.message || helperText || null}
          onChange={field.onChange}
          required={required}
          error={!!fieldState.error}
          value={field.value}
          fullWidth
          inputRef={field.ref}
          ref={field.ref}
          data-testid={dataTestId || name}
          sx={sx}
          InputProps={{
            readOnly,
            endAdornment,
          }}
          InputLabelProps={InputLabelProps}
          variant={variant}
        />
      )}
    />
  );
};

export default FormTextField;
