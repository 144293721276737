import {
  Grid,
} from '@mui/material';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { YupValidations } from '../../../../shared/validations';
import { ControlledInputText } from '../../../forms/InputText';

export const FormFields = {
  BANK_NAME: 'bankName',
  ROUTING_NUMBER: 'routingAbaNumber',
  ACCOUNT_NUMBER: 'accountNumber',
};

export const DefaultValues = {
  [FormFields.BANK_NAME]: '',
  [FormFields.ROUTING_NUMBER]: '',
  [FormFields.ACCOUNT_NUMBER]: '',
};

export const BankingAccountValidation = ({ required = false } = {}) => (required ? Yup.object().shape({
  [FormFields.BANK_NAME]: YupValidations.requiredString,
  [FormFields.ACCOUNT_NUMBER]: YupValidations.requiredString,
  [FormFields.ROUTING_NUMBER]: YupValidations.requiredString,
}) : Yup.object().shape({
  [FormFields.BANK_NAME]: YupValidations.string,
  [FormFields.ACCOUNT_NUMBER]: YupValidations.string,
  [FormFields.ROUTING_NUMBER]: YupValidations.string,
}));

function BankingAccountForm({
  name,
  disabled = false,
  control,
  required = true,
}) {
  return (
    <>
      <Grid item xs={12}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={name.concat('.', FormFields.BANK_NAME)}
          data-testid={FormFields.BANK_NAME}
          label="Bank name"
          required={required}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={name.concat('.', FormFields.ROUTING_NUMBER)}
          data-testid={FormFields.ROUTING_NUMBER}
          label="Routing/ABA Number"
          required={required}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={name.concat('.', FormFields.ACCOUNT_NUMBER)}
          data-testid={FormFields.ACCOUNT_NUMBER}
          label="Account number"
          required={required}
        />
      </Grid>
    </>
  );
}

BankingAccountForm.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default BankingAccountForm;
