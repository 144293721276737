import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { get } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import {
  openResetAddAccountDialog,
} from '../../redux/slices/accounts';
import {
  resetAll, submit, resetBeneficial, resetControlSigner,
} from '../../redux/slices/persistedForms';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import useSettings from '../../hooks/useSettings';
import ProcessingDialog from '../../components/shared/Dialogs/ProcessingDialog';
import AddPartnerType from '../../components/accounts/add/PartnerType';
import AddApplicantContact from '../../components/accounts/add/ApplicantContact';
import ITManagerInfo from '../../components/accounts/add/ITManagerInfo';
import CorporateContacts from '../../components/accounts/add/CorporateContacts';
import CorporateAccount from '../../components/accounts/add/CorporateAccount';
import PrimaryBankContact from '../../components/accounts/add/PrimaryBankContact';
import TradeReferences from '../../components/accounts/add/TradeReferences';
import MerchantApplication from '../../components/accounts/add/MerchantApplication';
import Comments from '../../components/accounts/add/Comments';
import { ConfirmResetDialog } from '../../components/accounts/add/ConfirmResetDialog';
import { mainContainerSX } from '../../shared/styles';
import useAddAccountRequest from './useAddAccountRequest';
import useToggle from '../../hooks/useToggle';
import { needsMerchant } from '../../utils/accountBusiness';

const breadcrumbsOptions = [
  {
    id: 'accounts',
    render: 'Organization Structure',
  },
  {
    id: 'add-rel',
    render: 'Add Account',
  },
];

const AddAccount = () => {
  const { settings } = useSettings();
  const dispatcher = useDispatch();
  const processingToggle = useToggle();
  const addAccountRequest = useAddAccountRequest();
  const shouldDisplayMerchant = useSelector((state) => {
    const {
      q1,
      tickerType,
    } = get(state, 'persistedFormsReducer.addAccount.orgInfo.form', {});
    return needsMerchant(q1, tickerType);
  });

  const [errorToastState, setErrorToastState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    message: 'Please review highlighted fields',
  });
  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: errorOpen,
    message: errorMessage,
  } = errorToastState;

  const handleStatus = () => {
    if (addAccountRequest.isLoading) return 'loading';
    if (addAccountRequest.isError) return 'error';
    if (addAccountRequest.isSuccess) return 'success';
    return '';
  };
  const renderSuccessAccountCreationFooter = ({ onFinished }) => (
    <>
      <Button onClick={onFinished} variant="outlined">Back</Button>
      <Button
        color="primary"
        component={RouterLink}
        to={`/accounts/${addAccountRequest.result?.id}`}
        variant="contained"
        onClick={onFinished}
        sx={{ ml: 1 }}
        autoFocus
      >
        Go to Account
      </Button>
      <Button
        color="primary"
        component={RouterLink}
        to={`/accounts/${addAccountRequest.result?.id}/add-merchant`}
        variant="contained"
        onClick={onFinished}
        sx={{ ml: 1 }}
        autoFocus
      >
        Add Merchant Account
      </Button>
    </>
  );

  useEffect(() => {
    if (!shouldDisplayMerchant) {
      dispatcher(resetBeneficial());
      dispatcher(resetControlSigner());
    }
  }, [shouldDisplayMerchant]);

  const handleResetConfirm = () => {
    dispatcher(resetAll({ sectionPath: 'addAccount' }));
  };

  const handleSubmit = () => {
    dispatcher(submit({ sectionPath: 'addAccount' }));
    processingToggle.turnOn();
    addAccountRequest.request();
  };

  return (
    <>
      <Helmet>
        <title>MSP | Add Account</title>
      </Helmet>
      <Box
        sx={mainContainerSX}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                Add Account
              </Typography>
              <Breadcrumbs items={breadcrumbsOptions} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <AddPartnerType />
            <AddApplicantContact />
            { shouldDisplayMerchant && <MerchantApplication /> }
            <ITManagerInfo />
            <CorporateContacts />
            <CorporateAccount />
            <PrimaryBankContact />
            <TradeReferences />
            <Comments />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 4 }}
          >
            <Grid item sx={{ mr: 1 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => dispatcher(openResetAddAccountDialog())}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <ProcessingDialog
            isOpen={processingToggle.isActive}
            onFinished={processingToggle.turnOff}
            onRetry={addAccountRequest.request}
            onConfirm={addAccountRequest.request}
            status={handleStatus()}
            error={addAccountRequest.error}
            successText="Account created successfully!"
            onClose={processingToggle.turnOff}
            customSuccessActions={renderSuccessAccountCreationFooter}
          />
          <ConfirmResetDialog onSuccess={handleResetConfirm} />
          <Snackbar
            anchorOrigin={{ vertical: errorVertical, horizontal: errorHorizontal }}
            open={errorOpen}
            autoHideDuration={8000}
            onClose={() => {
              setErrorToastState({ ...errorToastState, open: false });
            }}
            key={errorVertical + errorHorizontal}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
        </Container>
      </Box>
    </>
  );
};

export default AddAccount;
