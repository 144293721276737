import {
  createContext, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { UserLogin, UserGetMe, UserLogout } from '../api';
import { updateLoggedUser } from '../redux/slices/login';
import { createNotification } from '../redux/slices/notifications';

const expiredMessageError = 'expired password';
const parseLoginError = ({ status, data }) => {
  if (status) {
    if (status === 400) {
      return new Error('Invalid credentials');
    }
    if (status === 404) {
      return new Error('Service not found');
    }
    if (status === 403) {
      const { details, message } = data?.error;
      if (message.toLowerCase() === 'expired password') { return { message }; }
      return { message: 'Too many failed login attempts. Please try again after', lastLoginAttempt: details.lastLoginAttempt };
    }
  }
  return 'Something unexpected went wrong';
};

const initialState = {
  isAuthenticated: false,
  requires2fa: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, requires2fa } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      requires2fa: requires2fa || false,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isInitialized: false,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  useEffect(() => {
    if (state.isInitialized && !state.isAuthenticated) {
      dispatcher({ type: 'CLEAR_PERSISTED_ROOT' });
    }
  }, [state.isAuthenticated, state.isInitialized]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const { data } = await UserGetMe();

        const { id } = data;

        if (id) {
          const { security = {} } = data;
          const { multiFactor = {} } = security;
          const { enabled, required } = multiFactor;
          if (required && !enabled) {
            throw new Error('2fa required');
          }
          localStorage.setItem('loggedUser', JSON.stringify(data));
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isInitialized: true,
              user: data,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              isInitialized: true,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isInitialized: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, rememberMe = false, secondFactorCode = '',
    setMultiFactorRequired, setMultiFactorEnabled) => {
    let loginResponse = {};
    try {
      ({ data: loginResponse } = await UserLogin({
        email, password, rememberMe, secondFactorCode,
      }));
    } catch (e) {
      if (e?.response?.status) {
        if (e?.response?.status === 400 && e?.response?.data?.error?.message === '2fa required') {
          setMultiFactorRequired(true);
          setMultiFactorEnabled(true);
        } else {
          throw parseLoginError(e.response);
        }
        throw parseLoginError(e.response);
      }
      if (e?.message === 'Network Error') e.message = 'Network error. Your IP might not be in the allow list or the server is unreachable, please contact your administrator.';
      throw e;
    }
    updateLoggedUser(loginResponse)(dispatcher);
    if (loginResponse?.daysToExpire && loginResponse?.daysToExpire <= 7) {
      createNotification(loginResponse.daysToExpire)(dispatcher);
    }
    localStorage.setItem('loggedUser', JSON.stringify(loginResponse));
    if (loginResponse?.permissions) {
      localStorage.setItem('permissions', JSON.stringify(loginResponse?.permissions));
    }
    if (loginResponse?.security?.multiFactor?.required
      && !loginResponse?.security?.multiFactor?.enabled) {
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          requires2fa: true,
          user: loginResponse,
        },
      });
      navigate('/2fa');
    }

    // remove this?
    if (!loginResponse.security?.multiFactor?.required
      || (loginResponse.security?.multiFactor?.required && loginResponse.security?.multiFactor?.enabled)) {
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          user: loginResponse,
        },
      });
    }
  };

  const logout = async ({ force = true, clientSideOnly = false, forceRedirectToProfile = true }) => {
    if (!clientSideOnly) {
      await UserLogout();
    }
    if (force) {
      localStorage.removeItem('loggedUser');
      dispatch({ type: 'LOGOUT' });
      dispatcher({ type: 'CLEAR_PERSISTED_ROOT' });
    }
    if (forceRedirectToProfile) {
      navigate('/');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        expiredMessageError,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
