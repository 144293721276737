import * as Yup from 'yup';

import { YupValidations } from '../../../shared/validations';
import { ValidationSchema as AddressSchema } from '../../forms/Address';
import { ValidationSchema as ContactInfoSchema } from '../../forms/ContactInfo';

export const FormFields = {
  TYPE: 'type',
  CARD_NAME: 'cardName',
  CS_NUMBER: 'customerServiceNumber',
  LOCATION: 'location',
  MAILING: 'mailing',
  ACCOUNT_MANAGER: 'accountManager',
  APPLICATION_MANAGER: 'applicationManager',
  INTEGRATED_APPS: 'integratedApp',
  NON_INTEGRATED: 'nonIntegrated',
  APPLICATION_VERSION: 'applicationVersion',
  PAYMENT_ENV: 'paymentEnvironment',
  SERVICES_DESCRIPTION: 'servicesOrSalesDescription',
  B2B_TRANSACTIONS_PERC: 'b2bPercent',
  B2C_TRANSACTIONS_PERC: 'b2cPercent',
  B2TOTAL: 'b2total',
  MONTHLY_AVERAGE: 'monthlyAverage',
  SALE_AVERAGE: 'saleAverage',
  CP_SWIPE_CHIP: 'cpSwipeChip',
  CP_KEYED: 'cpKeyed',
  CNP_KEYED: 'cnpKeyed',
  CARD_TOTAL: 'CARD_TOTAL',
  TRANSACTION_TOTAL: 'TRANSACTION_TOTAL',
  COMMENTS: 'comments',
};

export const ValidationSchema = {
  [FormFields.CARD_NAME]: YupValidations.requiredString,
  [FormFields.CS_NUMBER]: YupValidations.requiredString,
  [FormFields.MID_TYPE]: YupValidations.requiredOption,
  [FormFields.INTEGRATED_APPS]: YupValidations.string,
  [FormFields.NON_INTEGRATED]: YupValidations.string,
  [FormFields.PAYMENT_ENV]: YupValidations.requiredOption,
  [FormFields.APPLICATION_VERSION]: YupValidations.requiredString,
  [FormFields.LOCATION]: Yup.object().shape(AddressSchema({ validate: true })),
  [FormFields.MAILING]: Yup.object().shape(AddressSchema({ validate: true })),
  [FormFields.ACCOUNT_MANAGER]: Yup.object().shape(ContactInfoSchema({ validate: true })),
  [FormFields.APPLICATION_MANAGER]: Yup.object().shape(ContactInfoSchema({ validate: true })),
  [FormFields.B2B_TRANSACTIONS_PERC]: Yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' ? undefined : value);
    })
    .typeError('Must be a number')
    .required('Required')
    .positive(),
  [FormFields.B2C_TRANSACTIONS_PERC]: Yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' ? undefined : value);
    })
    .typeError('Must be a number')
    .required('Required')
    .positive(),
  [FormFields.MONTHLY_AVERAGE]: YupValidations.requiredString,
  [FormFields.SALE_AVERAGE]: YupValidations.requiredString,
  [FormFields.CP_SWIPE_CHIP]: Yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' ? undefined : value);
    })
    .typeError('Must be a number'),
  [FormFields.CP_KEYED]: Yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' ? undefined : value);
    })
    .typeError('Must be a number'),
  [FormFields.CNP_KEYED]: Yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' ? undefined : value);
    })
    .typeError('Must be a number'),
};
