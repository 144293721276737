import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { get } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  AlertTitle,
  Button,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { YupValidations } from '../shared/validations';
import { UserResetPassword, UserCheckToken } from '../api';
import { ReactComponent as CoreLogo } from '../components/logos/Core1.svg';
import Loading from '../components/pageutils/Loading';

const FormTextField = ({
  name,
  label,
  control,
  required = false,
  autoFocus = false,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field, fieldState }) => (
        <TextField
          autoFocus={autoFocus}
          label={label}
          helperText={fieldState.error?.message}
          error={!!fieldState.error}
          value={field.value}
          onChange={field.onChange}
          fullWidth
          inputRef={field.ref}
          ref={field.ref}
          required={required}
          {...rest}
        />
      )}
    />
  );
};

const ResetPasswordError = ({ error, onClick }) => {
  const message = get(error, 'error.message') === 'invalid lost-password token'
    ? 'Token is invalid or expired.'
    : 'Something went wrong.';
  return (
    <Box sx={{ mt: 1 }}>
      <Alert severity="error">
        <AlertTitle>
          <strong>Error</strong>
        </AlertTitle>
        {message}
      </Alert>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        <Button
          onClick={onClick}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Retry
        </Button>
      </Grid>
    </Box>
  );
};

const ResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [resetPasswordForm, setResetPasswordForm] = useState({
    isLoading: false,
    error: null,
  });
  const [tokenValidation, setTokenValidation] = useState({
    validating: true,
    isValid: false,
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get('requestToken');
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        password: YupValidations.password,
        confirmPassword: YupValidations.confirmPassword('password'),
      }),
    ),
  });

  const onSubmit = async (payload) => {
    try {
      setResetPasswordForm({ ...resetPasswordForm, isLoading: true });
      await UserResetPassword({ password: payload.password, token });
      navigate('/');
      enqueueSnackbar('Password changed successfully.', {
        variant: 'success',
        autoHideDuration: 12000,
      });
    } catch (err) {
      const { data } = err.response;
      setResetPasswordForm({ error: data, isLoading: false });
    }
  };

  const handleRetry = () => {
    navigate('/forgot-password');
  };

  useEffect(() => {
    try {
      const useRequest = async () => {
        await UserCheckToken({ token });
      };
      useRequest();
      setTokenValidation({ isValid: true, validating: false });
    } catch (err) {
      handleRetry();
      enqueueSnackbar('Invalid Token. Please request a new one.', {
        variant: 'error',
        autoHideDuration: 12000,
      });
    }
  }, []);

  const showResetForm = !tokenValidation.validating && tokenValidation.isValid;
  const showTitle = token && !resetPasswordForm.error;

  return (
    <>
      <Helmet>
        <title>MSP | Reset Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '80px', pt: '80px' }}>
          <div style={{ paddingLeft: '60px', paddingBottom: '64px' }}>
            <CoreLogo />
          </div>
          {tokenValidation.validating && (
            <Loading label="Validating Token..." />
          )}

          {showResetForm && (
            <Card>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 3,
                  }}
                >
                  <div>
                    <Typography color="textPrimary" gutterBottom variant="h4">
                      Reset Password
                    </Typography>
                  </div>
                </Box>
                {showTitle && (
                  <Typography color="textPrimary" mb={2} variant="subtitle1">
                    Please enter a new password. Your password must contain 7
                    characters and be alphanumeric.
                  </Typography>
                )}

                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    {resetPasswordForm.error ? (
                      <ResetPasswordError
                        error={resetPasswordForm.error}
                        onClick={handleRetry}
                      />
                    ) : (
                      <>
                        <FormTextField
                          name="password"
                          label="Password"
                          control={control}
                          required
                          type="password"
                          sx={{ mb: 3 }}
                        />
                        <FormTextField
                          name="confirmPassword"
                          label="Confirm Password"
                          control={control}
                          type="password"
                          required
                        />
                        <Box sx={{ mt: 3 }}>
                          <LoadingButton
                            color="primary"
                            loading={resetPasswordForm.isLoading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Submit
                          </LoadingButton>
                        </Box>
                      </>
                    )}
                  </form>
                </Box>
              </CardContent>
            </Card>
          )}
        </Container>
      </Box>
    </>
  );
};
export default ResetPassword;
