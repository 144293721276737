import { createAsyncThunk } from '@reduxjs/toolkit';

import { first, isArray, set } from 'lodash';

import { GetMerchants } from '../../../api';

export const listMIDThunk = createAsyncThunk('merchants/listMIDs', async ({ params }, { rejectWithValue }) => {
  try {
    const { data, status, statusText } = await GetMerchants(params);
    return {
      ...data, status, statusText, accountId: params.accountID,
    };
  } catch (e) {
    const { data, status, statusText } = e.response || {};
    const { error = {} } = data;
    return rejectWithValue({
      ...error, status, statusText,
    });
  }
});

export const listMIDReducers = {
  [listMIDThunk.fulfilled]: (state, { payload = {} }) => {
    let accountId = 'UNMAPPED';
    if (isArray(payload?.merchants)) {
      if (first(payload?.merchants)?.accountId) {
        accountId = first(payload?.merchants)?.accountId;
      }
    }

    set(
      state,
      ['perAccount', accountId],
      payload,
    );
    set(
      state,
      'list.response',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },
  [listMIDThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },

  [listMIDThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'list.error',
      payload,
    );
    set(
      state,
      'isLoading',
      false,
    );
  },
};
