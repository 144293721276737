function PortalPermission(serverPermissions) {
  const config = (serverPermissions || []).reduce((accum, item) => {
    return {
      ...accum,
      [item.object]: item.actions,
    };
  }, {});
  return config;
}

export default PortalPermission;
