import {
  useEffect, useState,
} from 'react';
import { debounce } from 'lodash';

function useTreeFilterInput(data, { filterProp = 'label' } = {}) {
  const [result, setResult] = useState([]);
  const [hasInit, setInit] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!hasInit && data && data.length) {
      setResult(data);
      setInit(true);
    }
  }, [data, hasInit]);

  // necessary for UX purposes as it eases the recalculation of the tree elements
  const changeFilterState = (change) => {
    setIsFiltering(true);
    setResult([]);
    setTimeout(() => {
      setIsFiltering(false);
      setResult(change);
    }, 300);
  };

  const matchAndFilter = (value) => {
    const normalizedFilter = value.toLowerCase().trim();
    const filterNodes = (filtered, node) => {
      const children = (node.children || []).reduce(filterNodes, []);
      if (node[filterProp].toLowerCase().includes(normalizedFilter) || children.length) {
        filtered.push({ ...node, children });
      }
      return filtered;
    };
    const filteredSet = data.reduce(filterNodes, []);
    return filteredSet;
  };

  const onInputFocus = ({ target: { value } = {} } = {}) => {
    if (!value || value.length < 2) {
      setResult(data);
    } else {
      const filtered = matchAndFilter(value);
      setResult(filtered);
    }
    setTimeout(() => setIsFocused(true), 100);
  };

  const onInputChange = debounce(({ target: { value } = {} } = {}) => {
    if (!value || value.length < 2) return setResult(data);
    const filtered = matchAndFilter(value);
    return changeFilterState(filtered);
  }, 500);

  const onInputBlur = () => {
    if (isFocused) setIsFocused(false);
  };

  return {
    onInputChange,
    onInputFocus,
    onInputBlur,
    result,
    isFiltering,
    isFocused,
  };
}

export default useTreeFilterInput;
