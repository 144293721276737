import { React } from 'react';

import {
  Button, Dialog, DialogActions,
} from '@mui/material';

import { get, find } from 'lodash';
import { ColTable } from '../ColTable';

const BankingSettings = ({
  data = {}, open, onClose, accountTypes,
}) => {
  const { bankingSettings } = data;
  return (
    <Dialog open={open} onClose={onClose}>
      {ColTable({
        dataArray: bankingSettings,
        autoGenerateCols: true,
        cols: [
          {
            field: 'accountTypeID',
            label: 'Account type',
            valueGetter: (src) => find(
              accountTypes,
              (obj) => obj.id === get(src, 'accountTypeID'),
              '',
            )?.text,
            cellSx: { fontWeight: 600 },
          },
          {
            field: 'routingAbaNumber',
            label: 'ABA Routing',
            valueGetter: (src) => get(src, 'routingAbaNumber', ''),
          },
        ],
        omit: ['id'],
      })}
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankingSettings;
