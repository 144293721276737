import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, set, omit } from 'lodash';
import { UserUpdate } from '../../../api';
import { buildErrorMessageFromRejectedThunk } from '../../../shared/utils';

export const updateUserThunk = createAsyncThunk('user/updateUser', async (payload, { rejectWithValue }) => {
  try {
    const editedUser = omit(payload, ['id', 'createdAt', 'updatedAt', 'lastLogin']);
    const data = await UserUpdate({ id: payload?.id, editedUser });
    const { status } = data;
    return { status, user: payload };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const updateUserReducers = {
  [updateUserThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'edit.status',
      payload?.status,
    );
    set(
      state,
      `entities.${payload?.user?.id}`,
      {
        ...get(state, `entities.${payload?.user?.id}`, {}),
        ...payload?.user,
      },
    );
    set(
      state,
      'edit.success',
      payload?.user,
    );
    set(
      state,
      'edit.isLoading',
      false,
    );
    set(
      state,
      'edit.error',
      null,
    );
  },
  [updateUserThunk.pending]: (state) => {
    set(
      state,
      'edit.success',
      {},
    );
    set(
      state,
      'edit.isLoading',
      true,
    );
    set(
      state,
      'edit.error',
      null,
    );
  },
  [updateUserThunk.rejected]: (state, reject) => {
    set(
      state,
      'edit.success',
      {},
    );
    set(
      state,
      'edit.isLoading',
      false,
    );
    set(
      state,
      'edit.error',
      buildErrorMessageFromRejectedThunk(reject),
    );
  },
};
