export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const CHARGEBACK_STATUS = {
  CRMR: 'Credit Merchant',
  CHGM: 'Debit Merchant',
};

export const USER_ROLES = [
  { text: 'Core Admin with Risk Management', value: 'CAWR', editableBy: ['CAWR', 'COWR', 'CA'] },
  { text: 'Core Admin', value: 'CA', editableBy: ['CAWR', 'COWR', 'CA'] },
  { text: 'Core M. Ops with Risk Management', value: 'COWR', editableBy: ['CAWR', 'COWR', 'CA'] },
  { text: 'Core M. Ops', value: 'CO', editableBy: ['CAWR', 'COWR', 'CA'] },
  { text: 'Core Support', value: 'CS', editableBy: ['CAWR', 'COWR', 'CA'] },
  { text: 'Account Admin', value: 'AA', editableBy: ['CAWR', 'COWR', 'CA', 'AA'] },
  { text: 'Account User', value: 'AU', editableBy: ['CAWR', 'COWR', 'CA', 'AA', 'AU'] },
  { text: 'Merchant Admin', value: 'MA', editableBy: ['CAWR', 'COWR', 'CA', 'AA', 'AU'] },
  { text: 'Merchant Financial', value: 'MF', editableBy: ['CAWR', 'COWR', 'CA', 'AA', 'AU', 'MA'] },
  { text: 'Merchant User', value: 'MM', editableBy: ['CAWR', 'COWR', 'CA', 'AA', 'AU', 'MA', 'MF'] },
];

export const MerchantAccounts = ['MA', 'MF', 'MM'];
export const RestrictiveRelationshipsRoles = ['AA', 'AU', ...MerchantAccounts];

export const NotificationsInfo = {
  cardChargeback: {
    title: 'Card chargebacks/disputes',
    description: 'Notify me when a chargeback is encountered or a dispute is raised',
  },
  achReturn: {
    title: 'ACH Returns',
    description: 'Notify me when a return is encountered',
  },
};

export const STATUS = [
  { value: '0', label: 'Incomplete' },
  { value: '1', label: 'Completed' },
  { value: '2', label: 'Void' },
  { value: '3', label: 'Refund' },
  { value: '4', label: 'Returned' },
  { value: '5', label: 'Chargeback' },
  { value: '6', label: 'Authorized' },
].sort((a, b) => (a.label > b.label ? 1 : -1));

export const METHODS = [
  { value: '1', label: 'ACH' },
  { value: '2', label: 'Card' },
  { value: '3', label: 'Token' },
  { value: '4', label: 'Related' },
  { value: '5', label: 'Wire' },
  { value: '6', label: 'Cash' },
  { value: '7', label: 'Check' },
  { value: '8', label: 'Invoice' },
].sort((a, b) => (a.label > b.label ? 1 : -1));

export const TIME_IN_OPERATION_OPTIONS = [
  { value: 'new', label: 'New Startup' },
  { value: '1', label: '1 Year' },
  { value: '2', label: '2 Years' },
  { value: '3', label: '3 Years' },
  { value: '4', label: '4 Years' },
  { value: '5', label: '5 Years' },
  { value: '6', label: '6 Years' },
  { value: '7', label: '7 Years' },
  { value: '8', label: '8 Years' },
  { value: '9', label: '9 Years' },
  { value: '10', label: '10 Years' },
  { value: '15', label: '15 Years' },
  { value: '20plus', label: '20+ Years' },
];

export const BankingAccountTypeValues = {
  SINGLE_ACCOUNT: 'single-account',
  CREDIT_DEPOSITS: 'credit-deposits',
  CREDIT_CHARGEBACKS: 'credit-chargebacks',
  CREDIT_CONVENIENCE_FEES: 'credit-convenience-fees',
  CREDIT_PROCESSING_FEES: 'credit-processing-fees',
  DEBIT_DEPOSITS: 'debit-deposits',
  DEBIT_CHARGEBACKS: 'debit-chargebacks',
  DEBIT_CONVENIENCE_FEES: 'debit-convenience-fees',
  ACH_CHARGEBACK_ACCOUNT: 'chargeback-account',
  ACH_RETURN_ACCOUNT: 'return-account',
  AMEX_ROUTING_ACCOUNT: 'amex-routing-account',
  AMEX_DDA_ACCOUNT: 'amex-dda-account',
};

export const AccountTypeNames = {
  [BankingAccountTypeValues.SINGLE_ACCOUNT]: 'Single account',
  [BankingAccountTypeValues.CREDIT_DEPOSITS]: 'Credit card deposits',
  [BankingAccountTypeValues.CREDIT_CHARGEBACKS]: 'Credit card chargebacks',
  [BankingAccountTypeValues.CREDIT_CONVENIENCE_FEES]: 'Credit card convenience fees',
  [BankingAccountTypeValues.CREDIT_PROCESSING_FEES]: 'Credit card processing fees',
  [BankingAccountTypeValues.DEBIT_DEPOSITS]: 'Debit card deposits',
  [BankingAccountTypeValues.DEBIT_CHARGEBACKS]: 'Debit card chargebacks',
  [BankingAccountTypeValues.DEBIT_CONVENIENCE_FEES]: 'Debit card convenience fees',
  [BankingAccountTypeValues.ACH_CHARGEBACK_ACCOUNT]: 'ACH chargebacks',
  [BankingAccountTypeValues.ACH_RETURN_ACCOUNT]: 'ACH returns',
  [BankingAccountTypeValues.ACH_CHARGEBACK_ACCOUNT]: 'ACH chargebacks',
  [BankingAccountTypeValues.AMEX_ROUTING_ACCOUNT]: 'Amex TPSP routing',
  [BankingAccountTypeValues.AMEX_DDA_ACCOUNT]: 'Amex TPSP DDA',
};

export const CHARTS_COLOR_PALETTE = [
  '#173963', '#9c27b0', '#3f51b5', '#027db6', '#008577', '#5a822b', '#946f00', '#5e7a87',
];
