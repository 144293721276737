import { YupValidations } from '../../../shared/validations';
import Address, { FormFields } from './Address';

const defaultValue = {
  [FormFields.ADDRESS1]: '',
  [FormFields.ADDRESS2]: '',
  [FormFields.CITY]: '',
  [FormFields.ZIP]: '',
  [FormFields.STATE]: '',
  [FormFields.COUNTRY]: '',
};

const ValidationSchema = ({ validate = false } = {}) => {
  const schema = {
    [FormFields.ADDRESS1]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.ADDRESS2]: YupValidations.string,
    [FormFields.CITY]: validate ? YupValidations.requiredString : YupValidations.string,
    [FormFields.ZIP]: validate ? YupValidations.requiredString : YupValidations.string,
  };
  if (validate) {
    schema[FormFields.STATE] = YupValidations.requiredOption;
    schema[FormFields.COUNTRY] = YupValidations.requiredOption;
  }
  return schema;
};

export {
  ValidationSchema,
  defaultValue,
  FormFields,
};

export default Address;
