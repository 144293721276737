import { useState } from 'react';

const initialState = {
  isActive: false,
  meta: {},
};

function useToggle(defaultState = null) {
  const [toggleState, setToggle] = useState(defaultState || initialState);

  const handleChange = (isActive, meta) => {
    if (meta && meta.type !== 'click') {
      setToggle({
        meta,
        isActive,
      });
    } else {
      // setToggle and the timeout, both clear the dialog data and
      // keeps the animation consistent
      setToggle({
        meta: toggleState.meta,
        isActive,
      });
      setTimeout(() => {
        setToggle({
          isActive,
          meta: {},
        });
      }, 200);
    }
  };

  const toggle = (meta) => handleChange(!toggleState.isActive, meta);

  const turnOn = (meta) => handleChange(true, meta);

  const turnOff = (meta) => handleChange(false, meta);

  const reset = () => {
    setToggle(defaultState || initialState);
  };

  return {
    isActive: toggleState.isActive,
    meta: toggleState.meta,
    toggle,
    turnOn,
    turnOff,
    reset,
  };
}

export default useToggle;
