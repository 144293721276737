import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { ListMerchantTendersRequest } from '../../api/Merchants/Tenders/List';
import useSettings from '../../hooks/useSettings';
import Overview from './Overview';
import { MIDTenders } from './MIDTenders';
import { MIDDevices } from './Devices';
import { fetchMID, merchantsReducer } from '../../redux/slices/merchants';
import { accountsReducer } from '../../redux/slices/accounts';
import { mainContainerSX } from '../../shared/styles';
import Breadcrumbs from '../shared/Breadcrumbs';
import useLoggedUser from '../../hooks/useLoggedUser';
import { useAccountTypes } from '../../hooks/systemConfig/useAccountTypes';
import { useIsMobileView } from '../../hooks/useIsMobileView';

const tabs = [
  { label: 'Overview', value: 'overview' },
  { label: 'Tenders', value: 'tenders' },
  { label: 'Devices', value: 'devices' },
];

const getBreadcrumbItems = ({
  accountID, account, location, query = '', mobile,
}) => {
  if (location === 'bulk-edit') {
    return [
      {
        id: 'management',
        render: 'Management',
      },
      {
        id: 'administration',
        render: 'Administration',
      },
      {
        id: 'bulk-edit',
        render: 'Bulk Edit',
        redirect: `/administration/bulk-edit${query}`,
      },
      {
        id: 'merchant-details',
        render: 'MID Details',
      },
    ];
  }
  if (location !== 'account') {
    return [
      {
        id: 'organization-structure',
        render: 'Organization Structure',
      },
      {
        id: 'view-merchants',
        render: 'View Merchants',
      },
      {
        id: 'merchant-details',
        render: 'MID Details',
      },
    ];
  }
  return [
    {
      id: 'organization-structure',
      render: 'Organization Structure',
    },
    {
      id: 'view-rel',
      redirect: '/accounts',
      render: mobile ? 'Accounts' : 'View Accounts',
    },
    {
      id: 'rel-id',
      redirect: `/accounts/${accountID}`,
      render: account?.organizationInfo?.legalBusinessName || 'Account Details',
    },
    {
      id: 'merchant-details',
      render: 'MID Details',
    },
  ];
};

const MIDOverview = () => {
  const mobile = useIsMobileView();
  const dispatch = useDispatch();
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState('overview');
  const { merchantID: id, accountID } = useParams();
  const merchant = useSelector(
    (state) => get(state, `${merchantsReducer}.entities.${id}`, {}),
  );
  const account = useSelector(
    (state) => get(state, `${accountsReducer}.entities.${accountID}`, {}),
  );
  const accountTypes = useAccountTypes();
  const { loggedUser } = useLoggedUser();
  const location = useLocation();
  const breadcrumbItems = getBreadcrumbItems({
    accountID, account, location: location?.state?.from, query: location?.state?.query, mobile,
  });

  const tendersQuery = useQuery([`merchant-${id}-tender-list`, { id }], ListMerchantTendersRequest, { enabled: !!id });

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleReload = () => {
    if (id) {
      tendersQuery.refetch();
    }
  };

  useEffect(() => {
    fetchMID({ id })(dispatch);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>MSP | MID Overview</title>
      </Helmet>
      <Box
        sx={mainContainerSX}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                {merchant?.name || 'Merchant'}
              </Typography>
              <Breadcrumbs items={breadcrumbItems} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'overview' && <Overview merchant={merchant} />}
            {currentTab === 'tenders' && (
            <MIDTenders
              merchant={merchant}
              tenderTypes={tendersQuery?.data?.tenderTypes}
              accountTypes={accountTypes}
              handleReload={handleReload}
            />
            )}
            {currentTab === 'devices' && <MIDDevices merchant={merchant} user={loggedUser} />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MIDOverview;
