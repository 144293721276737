import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { UserRequestToken } from '../../../api';
import { buildErrorMessageFromRejectedThunk } from '../../../shared/utils';

export const forgotPasswordThunk = createAsyncThunk('user/forgotPassword', async (payload, { rejectWithValue }) => {
  try {
    const data = await UserRequestToken(payload);
    return data;
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const forgotPasswordReducers = {
  [forgotPasswordThunk.fulfilled]: (state, { payload = {} }) => {
    const { data = { } } = payload;
    set(
      state,
      'actions.resetPassword.success',
      data,
    );
    set(
      state,
      'actions.resetPassword.isLoading',
      false,
    );
  },
  [forgotPasswordThunk.pending]: (state) => {
    set(
      state,
      'actions.resetPassword.isLoading',
      true,
    );
    set(
      state,
      'actions.resetPassword.success',
      {},
    );
    set(
      state,
      'actions.resetPassword.error',
      null,
    );
  },
  [forgotPasswordThunk.rejected]: (state, reject) => {
    set(
      state,
      'actions.resetPassword.error',
      buildErrorMessageFromRejectedThunk(reject),
    );
    set(
      state,
      'actions.resetPassword.success',
      {},
    );
    set(
      state,
      'actions.resetPassword.isLoading',
      false,
    );
  },
};
