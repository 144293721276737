import { React } from 'react';

import {
  Box,
  Button,
  Grid,
} from '@mui/material';
import { isEmpty } from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import UpdateDeviceRequest from './UpdateDeviceRequest';
import useToggle from '../../../../../hooks/useToggle';
import ProcessingDialog from '../../../../shared/Dialogs/ProcessingDialog';
import TextField from '../../../../forms/TextField';
import DeviceNetworkDetailsRequest from './DeviceNetworkDetailsRequest';

export const FormFields = {
  LABEL: 'label',
  SERIAL_NUMBER: 'serial',
  MAKE: 'make',
  MODEL: 'model',
  DESCRIPTION: 'description',
  SDK_KEY_OVERRIDE: 'sdk_key_override',
  SECURITY_TOKEN_OVERRIDE: 'token_override',
  GATEWAY: 'gateway',
  IP: 'ip',
  MAC: 'mac',
  PORT: 'port',
};

const DeviceDetails = ({
  data, merchantId, deviceId, onClose,
}) => {
  const confirmDialogToggle = useToggle();
  const updateDevice = useMutation(UpdateDeviceRequest);
  const {
    control,
    getValues,
    formState: { isDirty, errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      [FormFields.LABEL]: data?.label || [],
      [FormFields.SERIAL_NUMBER]: data?.serial || '',
      [FormFields.MAKE]: data?.make || '',
      [FormFields.MODEL]: data?.model || '',
      [FormFields.DESCRIPTION]: data?.description || '',
      [FormFields.SDK_KEY_OVERRIDE]: data?.sdk_key_override || '',
      [FormFields.SECURITY_TOKEN_OVERRIDE]: data?.token_override || '',
      [FormFields.GATEWAY]: data?.gateway || '',
      [FormFields.IP]: '',
      [FormFields.MAC]: '',
      [FormFields.PORT]: '',
    },
  });
  const onSubmit = (form) => {
    confirmDialogToggle.turnOn();
    updateDevice.mutate({
      merchantId,
      deviceId,
      payload: {
        ...form,
      },
    });
  };

  const handleFinish = () => {
    onClose(updateDevice.variables);
  };
  const handleRetry = () => {
    onSubmit(getValues());
  };

  const handleClick = async () => {
    const networkData = await DeviceNetworkDetailsRequest(merchantId, deviceId);
    setValue(FormFields.IP, networkData?.ip);
    setValue(FormFields.PORT, networkData?.port);
    setValue(FormFields.MAC, networkData?.mac);
  };

  const disableButtons = !isDirty || !isEmpty(errors);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <>
        <Grid
          container
          spacing={1}
          sx={{
            paddingTop: '10px',
          }}
        >
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.LABEL}
              label="Label"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.SERIAL_NUMBER}
              label="Serial Number"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.MAKE}
              label="Make"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.MODEL}
              label="Model"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              control={control}
              name={FormFields.DESCRIPTION}
              label="Description"
            />
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              paddingTop: '20px',
              paddingLeft: '8px',
            }}
          >
            <Grid item lg={12} xs={12}>
              <TextField
                control={control}
                name={FormFields.GATEWAY}
                label="Gateway"
                readOnly
                sx={{
                  backgroundColor: '#E8E8E8',
                  opacity: '0.6',
                }}
              />
            </Grid>

            <Grid item lg={6} xs={12}>
              <TextField
                control={control}
                name={FormFields.SDK_KEY_OVERRIDE}
                label="SDK Key Override"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                control={control}
                name={FormFields.SECURITY_TOKEN_OVERRIDE}
                label="Security Token Override"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              paddingLeft: '8px',
              paddingTop: '20px',
            }}
          >
            <Grid item lg={6} xs={12}>
              <TextField
                control={control}
                name={FormFields.IP}
                label="IP"
                readOnly
                sx={{
                  backgroundColor: '#E8E8E8',
                  opacity: '0.7',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                control={control}
                name={FormFields.MAC}
                label="MAC"
                readOnly
                sx={{
                  backgroundColor: '#E8E8E8',
                  opacity: '0.6',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                control={control}
                name={FormFields.PORT}
                label="Port"
                readOnly
                sx={{
                  backgroundColor: '#E8E8E8',
                  opacity: '0.6',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              sx={{
                paddingLeft: '8px',
                paddingTop: '10px',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={handleClick}
              >
                Get network details
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            paddingTop: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={disableButtons}
            variant="contained"
            color="primary"
            type="submit"
          >
            Edit
          </LoadingButton>
        </Box>
        <ProcessingDialog
          isOpen={confirmDialogToggle.isActive}
          onClose={confirmDialogToggle.turnOff}
          onFinished={handleFinish}
          onRetry={handleRetry}
          status={updateDevice.status}
          error={updateDevice.error}
          successText="Device edited successfully"
        />
      </>
    </form>
  );
};

export default DeviceDetails;
