import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import ContactInfo, { FormFields, ValidationSchema, defaultValue } from '../../forms/ContactInfo';
import PersistedSectionForm from '../../shared/PersistedSectionForm';
import { AccordionPersist } from '../../shared/Accordion/Accordion';

export {
  ValidationSchema,
  FormFields,
};

const ApplicantContact = (props) => {
  const { control, formKey } = props;
  return (
    <>
      <Box
        display="grid"
      >
        <Typography color="textPrimary" variant="body1" sx={{ mb: 2 }}>
          Who should we contact about the information provided in this application?
        </Typography>
        <Box>
          <Grid container spacing={1}>
            <ContactInfo
              name={formKey}
              control={control}
              validate
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const label = 'Applicant Contact Information';
const section = 'addAccount.applicantContact';

export default AccordionPersist({
  section,
  label,
  Component: PersistedSectionForm({
    section,
    Component: ApplicantContact,
    schema: ValidationSchema({ validate: true }),
    defaultValues: defaultValue,
  }),
});
