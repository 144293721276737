import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  TextField,
  Divider,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  get,
} from 'lodash';

export const getDefaultValueAutoComplete = (value, options) => {
  return value ? options.find((element) => element?.value === value) : '';
};

export const ControlledAutocomplete = ({
  // eslint-disable-next-line no-unused-vars
  onChange: ignored,
  control,
  name,
  label,
  options = [],
  defaultValue,
  helperText,
  dataTestId,
  onFormChange = () => {},
  required = false,
  fullWidth = true,
  disabled = false,
}) => {
  const [highlightedOption, setHighlightedOption] = useState(defaultValue);
  return (
    <Controller
      render={({
        field, fieldState,
      }) => {
        const {
          onChange, onBlur, value, ref,
        } = field;
        return (
          <Autocomplete
            disabled={disabled}
            defaultValue={defaultValue}
            value={defaultValue}
            autoComplete
            options={options}
            getOptionLabel={(option) => {
              return get(option, 'text', '');
            }}
            renderInput={((params) => {
              return (
                <TextField
                  {...params}
                  label={label}
                  helperText={fieldState.error?.message || helperText}
                  onChange={(e) => {
                    return onChange(e);
                  }}
                  onBlur={(e) => {
                    return onBlur(e);
                  }}
                  error={!!fieldState.error}
                  value={value}
                  ref={ref}
                  required={required}
                  disabled={disabled}
                  fullWidth={fullWidth}
                  data-testid={dataTestId}
                />
              );
            })}
            onChange={(e, data) => {
              setHighlightedOption(data);
              onFormChange(data?.value);
              return (typeof onChange === 'function' ? onChange(data?.value) : null);
            }}
            renderOption={(props, option) => (
              <>
                <li {...props}>
                  {option.text}
                </li>
                {option.showDivider && <Divider variant="middle" /> }
              </>
            )}
            onHighlightChange={(_, data, reason) => {
              if (reason === 'keyboard') setHighlightedOption(data);
            }}
            onBlur={(e, data) => {
              onFormChange(highlightedOption?.value || data?.value);
              return (typeof onBlur === 'function' ? onBlur(data?.value) : null);
            }}
          />
        );
      }}
      onChange={([, data]) => {
        return data;
      }}
      onBlur={([, data]) => {
        return data;
      }}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};
