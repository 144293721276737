import { React, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, map } from 'lodash';

import {
  Typography,
  FormControl,
  TextField,
  Grid,
  Badge,
  MenuItem,
} from '@mui/material';

import { AccordionForm } from '../../AccordionForm';

import { fetchAccountList, listAccountsState } from '../../../redux/slices/accounts';
import { fetchAvailableMerchants } from '../../../redux/slices/fundingGroups';
import CustomSelectCheckboxTree from '../../CustomSelectCheckboxTree';
import { hierarchyBuilder } from '../../shared/HierarchyReader/hierarchyBuilder';

const nameValidation = (name) => {
  return yup.string()
    .min(2, `${name} must be at least 2 characters.`)
    .max(42, `${name} must be at most 42 characters.`)
    .required(`${name} is required`);
};

const schema = yup.object().shape({
  fundingGroupName: nameValidation('Funding Group Name'),
  account: nameValidation('Account'),
});

const EditNameAndMembers = ({
  fundingGroupData,
  setEditSettings,
  editSettings,
  submitting,
  resetForms,
  expand,
  onChangeForm,
  editing,
}) => {
  const {
    control,
    formState: { errors },
    trigger,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  const dispatcher = useDispatch();

  const accounts = useSelector((state) => get(state, `${listAccountsState}.response`, {}));
  const availableMerchants = useSelector((state) => get(state, 'fundingGroupsReducer.availableMerchants.merchants', []));

  const badgeStyle = { marginTop: 14, marginLeft: 17 };
  const errorQty = Object.keys(errors).length;

  useEffect(() => {
    try {
      fetchAccountList({ limit: 50000 })(dispatcher);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ERROR', error);
    }
    if (editing) {
      reset({
        fundingGroupName: fundingGroupData.name,
        account: fundingGroupData.accountID,
      });
      trigger();
    }
  }, []);

  useEffect(() => {
    if (expand !== 'editNameAndMembers') {
      trigger();
    }
  }, [expand]);

  useEffect(() => {
    if (resetForms > 0) {
      reset();
    }
  }, [resetForms]);

  useEffect(() => {
    if (submitting > 0) {
      const useRequest = async () => {
        const valid = await trigger();
        if (valid) {
          setEditSettings({
            ...editSettings,
            name: getValues('fundingGroupName'),
            accountID: getValues('account'),
            members: getValues('merchants'),
          });
        }
      };
      useRequest();
    }
  }, [submitting]);

  const checkIfThisFormIsExpanded = () => expand === 'editNameAndMembers';

  const merchantsConfig = {
    changeValues: [
      {
        propertyName: 'parentId',
        value: undefined,
        newValue: null,
      },
    ],
    changeKeys: [
      {
        propertyName: 'id',
        newPropertyName: 'value',
      }, {
        propertyName: 'name',
        newPropertyName: 'label',
      },
    ],
    itemsToInclude: [
      'value',
      'label',
      'parentId',
    ],
  };
  const [merchantsSelected, setMerchantsSelected] = useState([]);
  const [merchantsCompressed, setMerchantsCompressed] = useState([]);
  const [merchantsNodesSelected, setMerchantsNodesSelected] = useState([]);
  let [merchantsNodes] = useState(hierarchyBuilder(availableMerchants, merchantsConfig));
  const updateMerchants = ({ value }) => {
    setValue('merchants', value.map((item) => item.value));
  };
  const handleAccountSelect = async (event, onChange) => {
    onChange(event);
    setValue('merchants', []);
    merchantsNodes = [];
    await fetchAvailableMerchants(event.target.value)(dispatcher);
  };

  return (
    <AccordionForm
      expanded={checkIfThisFormIsExpanded()}
      onChange={() => onChangeForm('editNameAndMembers')}
      title={(
        <>
          <Typography variant="h6">Name & Members</Typography>
          {errorQty > 0 && (
            <Badge style={badgeStyle} badgeContent={errorQty} color="secondary" />
          )}
        </>
      )}
      content={(
        <>
          <Typography>Please enter a name for the Funding Group and select the Merchant members that will inherit these settings</Typography>
          <form>
            <FormControl
              variant="outlined"
              sx={{ mt: 1 }}
              style={{ width: '100%', paddingInline: 20 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="fundingGroupName"
                    render={({ field: { onChange, onBlur, value }, fieldState }) => (
                      <>
                        <TextField
                          style={{ width: '100%' }}
                          onChange={onChange}
                          onBlur={onBlur}
                          label="Funding Group name"
                          error={fieldState.error && !!fieldState.error.message}
                          helperText={fieldState.error && fieldState.error.message}
                          value={!value ? '' : value}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="account"
                    render={({ field: { onChange, onBlur, value }, fieldState }) => (
                      <TextField
                        select
                        style={{ width: '100%' }}
                        onChange={(e) => handleAccountSelect(e, onChange)}
                        onBlur={onBlur}
                        disabled={editing}
                        label="Account"
                        error={fieldState.error && !!fieldState.error.message}
                        helperText={fieldState.error && fieldState.error.message}
                        value={!value ? '' : value}
                      >
                        {map(accounts.accounts, (option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.legalBusinessName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomSelectCheckboxTree
                    control={control}
                    name="merchants"
                    setValue={setValue}
                    data={merchantsNodes}
                    label="Merchants"
                    keysSelected={merchantsSelected}
                    setKeysSelected={setMerchantsSelected}
                    nodesCompressed={merchantsCompressed}
                    setNodesCompressed={setMerchantsCompressed}
                    nodesSelected={merchantsNodesSelected}
                    setNodesSelected={setMerchantsNodesSelected}
                    updateForm={updateMerchants}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </>
      )}
    />
  );
};

export default EditNameAndMembers;
