import {
  IconButton,
  Link,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';

import Label from '../../components/Label';
import { DateTimeFormat } from '../../utils/formatting/Dates';

const getStatusLabel = (status) => {
  const statusMap = {
    LOCKED: {
      color: 'error',
      text: 'LOCKED',
    },
    ACTIVE: {
      color: 'success',
      text: 'ACTIVE',
    },
    INACTIVE: {
      color: 'warning',
      text: 'INACTIVE',
    },
  };

  const { text, color } = statusMap[status.toUpperCase()];

  return <Label color={color}>{text}</Label>;
};

const getActions = (onGoToDetails) => ({
  field: 'actions',
  headerName: 'Details',
  headerAlign: 'center',
  flex: 0.3,
  align: 'center',
  sortable: false,
  renderCell: ({ row }) => (
    <IconButton
      color="primary"
      onClick={onGoToDetails(row)}
      size="large"
    >
      <Visibility />
    </IconButton>
  ),
});

const columns = ({ onGoToDetails, userRole, isMobileView }) => {
  const baseCols = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueOptions: ['active', 'inactive', 'locked'],
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 0.2,
      renderCell: (param) => getStatusLabel(param.value.toUpperCase()),
    },
    {
      field: 'partner',
      headerName: 'Partner',
      headerAlign: 'left',
      flex: 1,
      hide: true,
      sortable: false,
      valueGetter: (params) => `${params.row?.partnerName || '-'}`,
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      headerAlign: 'left',
      sortable: false,
      flex: 0.5,
      renderCell: ({ row }) => (userRole !== 'AU' && !row?.createdByName?.includes('(deleted)') ? (
        <>
          <Link underline="hover" color="primary" href={`/users/${row?.createdBy}`}>
            {row?.createdByName}
          </Link>
        </>
      ) : row?.createdByName),
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      headerAlign: 'left',
      sortable: false,
      flex: 0.5,
      valueFormatter: (params) => DateTimeFormat(params.value),
    },
  ];

  return isMobileView ? [...baseCols] : [...baseCols, getActions(onGoToDetails)];
};

export default columns;
