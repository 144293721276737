import {
  Card, Grid, InputAdornment, TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

function SearchInput({
  placeholder = 'Search',
  value = '',
  setValue = () => {},
  debounceTime = 600,
  sizeProps = { xs: 5 },
}) {
  const [localValue, setLocalValue] = useState(value);
  const debouncedCallback = useCallback(debounce(setValue, debounceTime), []);

  useEffect(() => {
    debouncedCallback(localValue);
  }, [localValue]);

  const onChange = (e) => {
    setLocalValue(e?.target?.value || '');
  };

  return (
    <Card sx={{
      mt: 3,
    }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Grid item {...sizeProps}>
          <TextField
            placeholder={placeholder}
            value={localValue}
            onChange={onChange}
            onBlur={() => {}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              my: 2,
              ml: 2,
              width: '100%',
            }}
            data-testid="searchInput"
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default SearchInput;
