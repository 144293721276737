import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import PencilAltIcon from '../../../../icons/PencilAlt';
import useToggle from '../../../../hooks/useToggle';
import MutationDialog from '../../Dialogs/MutationDialog';
import AccordionTable from '../AccordionTable';
import './styles.css';

function MutationAccordion({
  onMutated,
  successText,
  disableEdit = false,
  validationSchema,
  FormComponent,
  payload,
  request,
  title,
  content,
  data,
  showFooter = true,
  actionText = 'Edit',
  ...rest
}) {
  if (!payload?.id) return null;
  const dialogToggle = useToggle();
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(Yup.object().shape(validationSchema)),
    defaultValues: {
      ...payload,
    },
  });
  const {
    control,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = methods;

  const handleIconClick = (e) => {
    e.stopPropagation();
    dialogToggle.turnOn();
  };

  const handleClose = (mutation) => {
    dialogToggle.turnOff();
    if (mutation?.id) onMutated(mutation);
  };

  useEffect(() => {
    reset(payload);
  }, [payload]);

  return (
    <FormProvider {...methods}>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <AccordionTable
          actionIcon={
            disableEdit ? null : <PencilAltIcon onClick={handleIconClick} />
          }
          title={title}
          content={content}
          {...rest}
        />
      </Grid>
      <MutationDialog
        successText={successText}
        open={dialogToggle.isActive}
        onClose={handleClose}
        request={request}
        getForm={getValues}
        onReset={reset}
        data-testid="edit-merchant-dialog"
        title={(
          <Typography variant="h5">
            {`${actionText} ${title}`}
          </Typography>
        )}
        className={!showFooter && 'without-footer'}
        aria-labelledby="edit-merchant-dialog-title"
        aria-describedby="edit-merchant-dialog-description"
        disableSubmit={!isDirty || !isEmpty(errors)}
        disableReset={!isDirty}
        showFooter={showFooter}
      >
        <FormComponent
          control={control}
          setError={setError}
          clearErrors={clearErrors}
          methods={methods}
          data={data}
        />
      </MutationDialog>
    </FormProvider>
  );
}

export default MutationAccordion;
