import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  get, isArray, isEmpty, isObject,
} from 'lodash';

import { setProp } from '../../../redux/slices/persistedForms';

const useFormPersist = (
  path,
  {
    watch,
    reset,
    resetValues,
    setValue,
    validate = false,
    dirty = false,
    touch = false,
    trigger,
    errors,
  },
) => {
  const rootForm = path.split('.')[0];
  const dispatcher = useDispatch();
  const submitStatus = useSelector((state) => {
    return get(state, `persistedFormsReducer.${rootForm}.submit`);
  });

  const initialForm = useSelector((state) => {
    return get(state, `persistedFormsReducer.${path}.form`, {});
    // returning true in order to not refresh
  }, () => true);

  const shouldReset = useSelector((state) => {
    return get(state, `persistedFormsReducer.${path}.shouldReset`);
  });

  useEffect(() => {
    if (shouldReset) {
      reset(resetValues);
      dispatcher(setProp({ sectionPath: `${path}.shouldReset`, value: false }));
      dispatcher(setProp({ sectionPath: `${path}.form`, value: resetValues }));
    }
  }, [shouldReset]);

  useEffect(() => {
    async function checkStatus() {
      if (submitStatus === 'submitting') {
        await trigger();
      }
    }
    checkStatus();
  }, [submitStatus]);

  useEffect(() => {
    if (!shouldReset) {
      dispatcher(setProp({ sectionPath: `${path}.errors`, value: Object.keys(errors).length }));
    }
  }, [Object.keys(errors).length]);

  useEffect(() => {
    if (!isEmpty(initialForm)) {
      let anyDirty = false;
      Object.keys(initialForm).forEach((key) => {
        if (initialForm[key] && (!isObject(initialForm[key]) || isArray(initialForm[key]))) {
          anyDirty = true;
          setValue(key, initialForm[key], {
            shouldValidate: validate,
            shouldDirty: dirty,
            shouldTouch: touch,
          });
          return;
        }
        if (initialForm[key] && (isObject(initialForm[key]) || !isArray(initialForm[key]))) {
          Object.keys(initialForm[key]).forEach((subKey) => {
            if (initialForm[key][subKey]) {
              anyDirty = true;
              setValue(`${key}.${subKey}`, initialForm[key][subKey], {
                shouldValidate: validate,
                shouldDirty: dirty,
                shouldTouch: touch,
              });
            }
          });
        }
      });

      if (anyDirty) trigger();
    }
  }, [
    path,
    setValue,
  ]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      const isReset = !name && !type;
      const actionPayload = {
        sectionPath: isReset ? `${path}.form` : `${path}.form.${name}`,
        value: isReset ? values : get(values, name),
      };
      dispatcher(setProp(actionPayload));
    });
    return () => subscription.unsubscribe();
  }, [watch, shouldReset]);
  return {
    shouldReset,
  };
};

export default useFormPersist;
