import {
  useEffect, useState,
} from 'react';
import {
  difference, values, isFunction,
} from 'lodash';
import { AutoSizer, List } from 'react-virtualized';
import {
  Box,
  Chip,
  TextField,
  Autocomplete,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import Node from './Node';
import Tree, { getFlatChildren, ToFlatHash } from './Tree';
import useTreeFilterInput from './useTreeFilterInput';

const useStyles = makeStyles(() => ({
  chip: {
    borderRadius: 5,
  },
}));

function SelectionChip({ node, labelProp, onRemove }) {
  const chipClasses = useStyles();
  const handleDelete = () => {
    onRemove(node);
  };

  return (
    <Chip
      color="primary"
      className={chipClasses.chip}
      key={node.id}
      label={node[labelProp]}
      onDelete={handleDelete}
      variant="outlined"
    />
  );
}

const initial = {
  selectedHash: {},
  selectedChips: [],
};

function TreeDropdown({
  name,
  required,
  error,
  helperText,
  data = [],
  label: componentlabel,
  labelProp,
  singleMode = false,
  loading,
  disabled = false,
  onChange,
  onBlur,
  value = [],
  noOptionsText = 'No accounts found',
  dataTestId,
}) {
  const [{
    selectedHash,
    selectedChips,
  }, setLocal] = useState(initial);
  const treeFilter = useTreeFilterInput(data, { filterProp: labelProp });

  useEffect(() => {
    const loaded = data[0];
    if (!loaded?.id) return;
    const hashedOptions = ToFlatHash(data[0]);
    const updatedSelectedHash = {};
    (value || []).forEach((nodeId) => {
      if (hashedOptions[nodeId]) {
        updatedSelectedHash[nodeId] = hashedOptions[nodeId];
      }
    });
    setLocal({
      selectedHash: updatedSelectedHash,
      selectedChips: values(updatedSelectedHash),
    });
  }, [JSON.stringify(value), JSON.stringify(data)]);

  const handleMultipleSelection = (node, checked) => {
    const allChildren = getFlatChildren(node);
    const allChildIds = allChildren.map((child) => child.id);
    let keysSelected = [];
    if (checked) {
      keysSelected = keysSelected.concat([...value, node.id, ...allChildIds]);
    } else {
      keysSelected = difference(value, [node.id, ...allChildIds]);
    }
    onChange(name, keysSelected);
  };

  const handleSingleSelection = (selectedNode) => {
    onChange(name, [selectedNode.id]);
  };

  const handleChipRemoval = (node) => {
    if (singleMode) {
      onChange(name, []);
    } else {
      handleMultipleSelection(node, false);
    }
  };

  const handleNodeSelected = (selectedNode, checked) => {
    if (singleMode) {
      handleSingleSelection(selectedNode);
    } else {
      handleMultipleSelection(selectedNode, checked);
    }
  };

  const getEstimatedRowHeight = ({ index }) => {
    const node = treeFilter.result[0]?.children[index];
    const firstLevelHeight = singleMode ? 30 : 47;
    const childHeight = singleMode ? 30 : 42;
    const childrenHeight = (pnode, height) => {
      let total = height;
      pnode.children?.forEach((child) => {
        total += childrenHeight(child, childHeight);
      });
      return total;
    };
    const result = childrenHeight(node, firstLevelHeight);
    return result;
  };

  const Row = ({
    index, key, style,
  }) => {
    const node = treeFilter.result[0]?.children[index];
    if (!node) return null;
    return (
      <Node
        style={{ ...style, marginLeft: '10px' }}
        contentStyle={singleMode ? { height: '30px' } : {}}
        key={key}
        labelProp={labelProp}
        node={node}
        singleMode={singleMode}
      />
    );
  };

  const handleOnClose = (e, reason) => {
    if (reason === 'blur' && isFunction(onBlur)) {
      onBlur();
    }
  };

  return (
    <Autocomplete
      data-testid={dataTestId}
      filterOptions={(x) => x}
      multiple
      open={treeFilter.isFocused}
      disableClearable
      disabled={disabled}
      value={selectedChips}
      clearOnBlur
      options={treeFilter.result}
      noOptionsText={treeFilter.isFiltering ? 'Filtering...' : noOptionsText}
      clearText="Clear"
      getOptionLabel={(option) => option[labelProp]}
      onClose={handleOnClose}
      renderTags={() => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {singleMode ? (
              <SelectionChip node={selectedChips[0]} labelProp={labelProp} onRemove={handleChipRemoval} />
            ) : selectedChips.map((node) => {
              return (
                <SelectionChip
                  key={node.id}
                  node={node}
                  labelProp={labelProp}
                  onRemove={handleChipRemoval}
                />
              );
            })}
          </Box>
        );
      }}
      renderOption={(_, rootNode) => {
        if (!rootNode.children?.length) return null;
        return (
          <Tree selected={selectedHash} onChange={handleNodeSelected}>
            <div style={{ height: rootNode.children?.length >= 10 ? '300px' : `${rootNode.children?.length * 30}px`, overflowY: 'hidden' }}>
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <List
                      width={width}
                      height={height}
                      rowCount={rootNode.children?.length}
                      rowHeight={getEstimatedRowHeight}
                      rowRenderer={Row}
                    />
                  );
                }}
              </AutoSizer>
            </div>
          </Tree>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onChange={treeFilter.onInputChange}
            onFocus={treeFilter.onInputFocus}
            onBlur={treeFilter.onInputBlur}
            disabled={loading}
            label={loading ? 'Loading...' : componentlabel}
            helperText={helperText}
            placeholder=""
            required={required}
            error={!!error}
          />
        );
      }}
    />
  );
}

export default TreeDropdown;
