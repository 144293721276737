/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  set,
} from 'lodash';
import { TendersCreate } from '../../../api';

export const updateMIDTendersThunk = createAsyncThunk('fundingGroups.updateMidTenders', async (payload, { rejectWithValue }) => {
  try {
    const { data, status, statusText } = await TendersCreate(payload);
    return { ...data, status, statusText };
  } catch (err) {
    const { data, status, statusText } = err.response;
    return rejectWithValue({ ...data, status, statusText });
  }
});

export const updateMIDTendersReducers = {
  [updateMIDTendersThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'isLoading',
      false,
    );
    set(
      state,
      'updatedTenders',
      true,
    );
  },
  [updateMIDTendersThunk.pending]: (state) => {
    set(
      state,
      'isLoading',
      true,
    );
  },
  [updateMIDTendersThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'isLoading',
      false,
    );
    set(
      state,
      'updatedTenders',
      false,
    );
  },
};
