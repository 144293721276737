import { AccountTypeNames } from '../../../shared/constants';

export const PermissionsFields = {
  FILTER: 'filter',
  OPERATOR: 'operator',
  VALUE: 'value',
};

export const Texts = {
  EQUAL: 'Is equal to',
  NOT_EQUAL: 'Is not equal to',
  accountID: 'Parent account',
  bankAccountType: 'Bank account type',
  bankName: 'Bank name',
  accountNumber: 'Account number',
  routingAbaNumber: 'Routing number',
  tenderType: 'Tender Types',
  card: 'Credit/Debit Card',
  ach: 'ACH',
  amex_tpsp: 'Amex TPSP',
  all: 'All',
  ...AccountTypeNames,
};

export const filterTypeOptions = [
  { text: Texts.accountID, value: 'accountID' },
  { text: Texts.bankAccountType, value: 'bankAccountType' },
  { text: Texts.bankName, value: 'bankName' },
  { text: Texts.routingAbaNumber, value: 'routingAbaNumber' },
  { text: Texts.accountNumber, value: 'accountNumber' },
];

export const operatorOptions = [
  { text: Texts.EQUAL, value: 'EQUAL' },
  { text: Texts.NOT_EQUAL, value: 'NOT_EQUAL' },
];

export const checkOptions = [
  { text: 'Credit/Debit Card', value: 'card' },
  { text: 'ACH', value: 'ach' },
  { text: 'Amex TPSP', value: 'amex_tpsp' },
  { text: 'All', value: 'all' },
];
