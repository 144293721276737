import {
  Grid, IconButton, Menu, MenuItem,
} from '@mui/material';
import {
  filter, map, pick, uniqBy, values,
} from 'lodash';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { TenderFormSubtitle } from '../../../../shared/utils';
import { BankingFieldsPerType } from '../FormFields';
import BankingAccountForm from './BankingAccountForm';

const CopyBankingInfo = ({
  paymentMethodType = '', formProps, srcKey, savedBankingInfo = {},
}) => {
  const {
    getValues = () => {},
    setValue = () => {},
  } = formProps;
  const createOptions = (popupState) => {
    const onClick = (newValues) => {
      setValue(srcKey, newValues, { shouldValidate: true });
      return popupState.close();
    };
    const currentBankingForms = pick(getValues(), BankingFieldsPerType[paymentMethodType]);
    const validUniqueOptions = map(
      uniqBy( // Don't repeat entries
        filter( // Only use completely filled settings
          [
            ...values(savedBankingInfo.cards), // maybe refactor these first three lines into something more generic as we add more payment methods
            ...values(savedBankingInfo.ach),
            ...values(savedBankingInfo.amex_tpsp),
            ...values(currentBankingForms)],
          ({ bankName, routingAbaNumber, accountNumber } = {}) => bankName && routingAbaNumber && accountNumber,
        ),
        JSON.stringify,
      ), ({ bankName, routingAbaNumber, accountNumber } = {}) => (
        <MenuItem onClick={() => onClick({ bankName, routingAbaNumber, accountNumber })}>
          <TenderFormSubtitle>{`${bankName} / ${routingAbaNumber} / ${accountNumber}`}</TenderFormSubtitle>
        </MenuItem>
      ),
    );
    return validUniqueOptions;
  };

  return (
    <PopupState variant="popover" popupId="demoMenu">
      {(popupState) => {
        const options = createOptions(popupState);
        return (
          <>
            <IconButton
              size="small"
              sx={{ fontSize: 'small', mt: 2 }}
              onClick={() => null}
              {...bindTrigger(popupState)}
            >
              <ContentCopyIcon />
              Copy from...
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              {options}
            </Menu>
          </>
        );
      } }
    </PopupState>
  );
};

export const BankingSection = ({
  paymentMethodType,
  control,
  formKey = '',
  formProps,
  savedBankingInfo,
  sectionKey,
  label,
}) => {
  return (
    <>
      <Grid item sx={{ ml: 1, mt: 3 }}>
        <TenderFormSubtitle>{label}</TenderFormSubtitle>
      </Grid>
      <Grid item>
        <CopyBankingInfo
          paymentMethodType={paymentMethodType}
          formProps={formProps}
          savedBankingInfo={savedBankingInfo}
          srcKey={formKey.length ? formKey.concat('.', sectionKey) : sectionKey}
        />
      </Grid>
      <BankingAccountForm
        control={control}
        name={formKey.length ? formKey.concat('.', sectionKey) : sectionKey}
      />
    </>
  );
};
