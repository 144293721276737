import { get, isFunction } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { changeActive } from '../../../../redux/slices/persistedForms';

const useAccordionPersist = (path, getAmountAlert) => {
  const dispatch = useDispatch();
  const expanded = useSelector((state) => {
    return get(state, `persistedFormsReducer.${path}.isActive`, false);
  });
  const amountAlerts = useSelector((state) => {
    if (isFunction(getAmountAlert)) {
      return getAmountAlert(state.persistedFormsReducer);
    }
    const errorsAmount = get(state, `persistedFormsReducer.${path}.errors`, 0);
    return errorsAmount;
  });

  const toggle = () => {
    dispatch(changeActive({ value: path }));
  };

  return {
    expanded,
    toggle,
    amountAlerts,
  };
};

export default useAccordionPersist;
