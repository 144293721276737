import { createSlice } from '@reduxjs/toolkit';
import {
  set, get, filter, values, keys, forEach, omit,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const notificationsReducer = 'notificationsReducer';
export const getNotifications = (state) => state[notificationsReducer].map;
export const getUnreadNotifications = (state) => state[notificationsReducer].unread;

const setUnreadNotifications = (state, newState) => {
  const unread = filter(values(newState), ({ read }) => !read)?.length;
  set(
    state,
    ['unread'],
    unread,
  );
};

export const initialState = {
  map: {},
  unread: 0,
};

const mockedNotification = (days) => ({
  id: uuidv4(),
  title: 'Your password is about to expire',
  description: `Your password will expire in ${days} days. Click to renew.`,
  createdAt: new Date().toISOString(),
});

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    createNotification(state, action) {
      const { payload: { days } } = action;
      const newNotification = mockedNotification(days);
      set(
        state,
        ['map', newNotification.id],
        newNotification,
      );
      setUnreadNotifications(state, { ...get(state, ['map'], {}), [newNotification.id]: newNotification });
    },
    readNotification(state, action) {
      const { payload } = action;
      const { id } = payload;
      const newNotification = { ...get(state, ['map', id], {}), read: true };
      set(
        state,
        ['map', id],
        newNotification,
      );
      setUnreadNotifications(state, { ...get(state, ['map'], {}), [id]: newNotification });
    },
    deleteNotification(state, action) {
      const { payload } = action;
      const { id } = payload;
      const newNotifications = omit(get(state, ['map'], {}), [id]);
      set(
        state,
        ['map'],
        newNotifications,
      );
      setUnreadNotifications(state, newNotifications);
    },
    markAllAsRead(state) {
      const newState = get(state, ['map'], {});
      forEach(keys(newState), (key) => { set(newState, key, { ...get(newState, [key], {}), read: true }); });
      set(
        state,
        ['map'],
        newState,
      );
      set(
        state,
        ['unread'],
        0,
      );
    },
    removeAll(state) {
      set(
        state,
        ['map'],
        {},
      );
      set(
        state,
        ['unread'],
        0,
      );
    },
  },
});

export const createNotification = (days) => (dispatch) => {
  dispatch(slice.actions.createNotification({ days }));
};

export const readNotification = (id) => (dispatch) => {
  dispatch(slice.actions.readNotification({ id }));
};

export const deleteNotification = (id) => (dispatch) => {
  dispatch(slice.actions.deleteNotification({ id }));
};

export const markAllAsRead = () => (dispatch) => {
  dispatch(slice.actions.markAllAsRead());
};

export const removeAll = () => (dispatch) => {
  dispatch(slice.actions.removeAll());
};

export const { reducer } = slice;

export default slice;
