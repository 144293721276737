import { useMemo, useEffect } from 'react';
import {
  Grid,
  InputAdornment,
  LinearProgress,
  Typography,
  FormHelperText,
} from '@mui/material';
import { toNumber, sum } from 'lodash';
import { useWatch, useFormState } from 'react-hook-form';

import TextField from '../../forms/TextField';
import { ControlledSelectDropdown } from '../../forms/SelectDropdown';
import Legend from '../../forms/Legend';
import TitleSection from '../../forms/TitleSection';

import { ControlledRadioGroup } from '../../forms/RadioGroup';
import { ControlledCurrencyInput } from '../../forms/CurrencyInput';

import {
  FormFields,
} from './Schema';
import {
  ACCOUNT_TYPE_OPTIONS,
  PAYMENT_ENVIRONMENT_OPTIONS,
} from './options';
import usePermissions from '../../../IAM/usePermissions';

const useLinearProgress = (control, fields) => {
  const values = useWatch({
    control,
    name: fields,
  });

  const value = sum(values.map(toNumber));

  const color = useMemo(() => {
    if (value > 100) return 'secondary';
    if (value === 100) return 'primary';
    return 'inherit';
  }, [value]);

  const displayValue = useMemo(() => {
    return Math.min(value, 100);
  }, [value]);
  return { color, displayValue, value };
};

function AccountInformation({ control, setError, clearErrors }) {
  const permissions = usePermissions();
  const disableSensitiveFields = !permissions.merchants.editMerchantSensitive();
  const { errors } = useFormState({ control });
  const transactionProgress = useLinearProgress(control, [FormFields.B2B_TRANSACTIONS_PERC, FormFields.B2C_TRANSACTIONS_PERC]);
  const cardStatisticsProgress = useLinearProgress(control, [
    FormFields.CP_SWIPE_CHIP,
    FormFields.CP_KEYED,
    FormFields.CNP_KEYED,
  ]);
  useEffect(() => {
    if ((cardStatisticsProgress.value > 0 && cardStatisticsProgress.value < 100) || cardStatisticsProgress.value > 100) {
      setError(FormFields.CARD_TOTAL, { message: 'Total must be 100%' });
    } else {
      clearErrors(FormFields.CARD_TOTAL);
    }
    if (transactionProgress.value !== 100) {
      setError(FormFields.TRANSACTION_TOTAL, { message: 'Total must be 100%' });
    } else {
      clearErrors(FormFields.TRANSACTION_TOTAL);
    }
  }, [cardStatisticsProgress.value, transactionProgress.value]);
  return (
    <Grid
      container
      spacing={1}
    >
      <Legend>
        Cardholder Statement Descriptor
      </Legend>
      <Grid item xs={12} sm={6} sx={{ pt: 0 }}>
        <TextField
          control={control}
          name={FormFields.CARD_NAME}
          label="Billing statement account name"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          control={control}
          name={FormFields.CS_NUMBER}
          label="Customer Service number"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledRadioGroup
          required
          label="Account Type"
          control={control}
          name={FormFields.TYPE}
          options={ACCOUNT_TYPE_OPTIONS}
        />
      </Grid>
      <TitleSection>
        Payment Details
      </TitleSection>
      <Grid item xs={12}>
        <ControlledSelectDropdown
          control={control}
          disabled={disableSensitiveFields}
          name={FormFields.PAYMENT_ENV}
          label="Payment environment"
          options={PAYMENT_ENVIRONMENT_OPTIONS}
          disableClear
          required
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Typography fontWeight="bold">
          Transaction Types
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled={disableSensitiveFields}
          name={FormFields.B2C_TRANSACTIONS_PERC}
          control={control}
          label="Consumer"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled={disableSensitiveFields}
          name={FormFields.B2B_TRANSACTIONS_PERC}
          control={control}
          label="Business"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <LinearProgress
          color={errors[FormFields.TRANSACTION_TOTAL] ? 'secondary' : transactionProgress.color}
          variant="determinate"
          value={transactionProgress.displayValue}
        />
        {errors[FormFields.TRANSACTION_TOTAL] && (
          <FormHelperText error>
            {errors[FormFields.TRANSACTION_TOTAL]?.message}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Typography fontWeight="bold">
          Payment Card Averages
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledCurrencyInput
          name={FormFields.MONTHLY_AVERAGE}
          control={control}
          label="Monthly volume"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledCurrencyInput
          name={FormFields.SALE_AVERAGE}
          control={control}
          label="Average sale"
          required
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Typography fontWeight="bold">
          Card Entry Method Statistics
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name={FormFields.CP_SWIPE_CHIP}
          control={control}
          label="Swipe/Chip (CP)"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name={FormFields.CP_KEYED}
          control={control}
          label="Keyed (CP)"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name={FormFields.CNP_KEYED}
          control={control}
          label="Keyed (CNP)"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </Grid>
      <Grid item xs={12}>
        <LinearProgress
          color={errors[FormFields.CARD_TOTAL] ? 'secondary' : cardStatisticsProgress.color}
          variant="determinate"
          value={cardStatisticsProgress.displayValue}
        />
        {errors[FormFields.CARD_TOTAL] && (
          <FormHelperText error>
            {errors[FormFields.CARD_TOTAL]?.message}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <TextField
          control={control}
          name={FormFields.SERVICES_DESCRIPTION}
          label="Product/Services description"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <TextField
          control={control}
          name={FormFields.COMMENTS}
          label="Comments"
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  );
}

export default AccountInformation;
