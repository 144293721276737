import {
  Box, CircularProgress, Typography,
} from '@mui/material';

function Loading({ label }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CircularProgress sx={{ alignSelf: 'center' }} />
      { label && (
        <Typography variant="h6" gutterBottom component="div" sx={{ alignSelf: 'center' }}>
          { label }
        </Typography>
      )}
    </Box>
  );
}

export default Loading;
