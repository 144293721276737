import * as Yup from 'yup';
import { parse, isDate } from 'date-fns';
import { isNull } from 'lodash';

function parseDateString(value, originalValue) {
  // Quick fix so that the default InputMask value also counts as undefined
  if (originalValue === '' || originalValue === '__/__/____') {
    return undefined;
  }
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'MM/dd/yyyy', new Date());

  return parsedDate;
}

const string = Yup
  .string()
  .nullable()
  .notRequired()
  .max(80, 'Should be at most 80 characters long');

// check to see if the database can hold required char amount
const comment = string
  .notRequired()
  .max(500, 'Should be at most 500 characters long');

const name = string
  .min(2, 'Should be at least 2 characters long.')
  .matches(/^[ a-zA-Z0-9À-ÿ\u00f1\u00d1]*$/g, 'Special characters are not allowed.');

const requiredName = name.required('Required');

// I don't think the max does anything here but the ContactInfo test expext the string to be under 80
const email = string
  .email('Must be a valid email')
  .max(255, 'Should be at most 255 characters long');

const requiredEmail = email.required('Required');

const confirmEmail = (formKey = '') => string
  .oneOf([Yup.ref(`${formKey}-email`)], 'Email is not a match')
  .required('Required');

const confirmEmailField = (passRefFieldName) => string
  .oneOf([Yup.ref(passRefFieldName)], 'Email is not a match')
  .required('Required');

// const US_PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const phone = string
  // .matches(US_PHONE_REGEX, { message: 'Please input a valid US phone number', excludeEmptyString: true })
  .max(20, 'Should be at most 20 characters long');

const requiredPhone = phone.required('Required');

const requiredNumber = Yup.string().required('Required').matches(/^[0-9]*$/, 'Must be a number');

const requiredIntacctID = string
  .max(18, 'Should be at most 18 characters long')
  // .min(15, 'Should be at least 15 characters long') not sure about Intacct min length yet
  .required('Required');

const requiredString = string
  .nullable()
  .required('Required');

const birthDate = Yup
  .date()
  .transform(parseDateString)
  .typeError('Should be a valid date format MM/DD/YYYY');

const requiredBirthDate = birthDate
  .required('Required');

const requiredOptionObject = Yup.object().shape({
  text: Yup.string().required(),
  value: Yup.string().required(),
}).nullable().required('Required');

const requiredOption = Yup.lazy((item) => {
  switch (typeof item) {
    case 'object':
      return requiredOptionObject;
    default:
      return requiredString;
  }
});

const passRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d-~`!@#$%^&*()_+={[}\]|\\:;“’<,>.?/]{7,}$/;

const ssnRegex = /^\d{3}-?\d{2}-?\d{4}$/;

const ssn = Yup.string().matches(ssnRegex, 'Please input a valid SSN');

const requiredSsn = Yup.string().required('Required').matches(ssnRegex, 'Please input a valid SSN');

const password = string
  .transform((value) => (isNull(value) ? '' : value))
  .required('No password provided.')
  .min(7, 'Password must be at least 7 characters.')
  .matches(
    passRegex,
    'Password must contain at least one letter and number.',
  );

const confirmPassword = (passRefFieldName) => string
  .required('Confirm password is required')
  .oneOf([Yup.ref(passRefFieldName)], 'Passwords must match');

const requiredStringArray = Yup.array().of(Yup.string())
  .min(1, 'Must have at least 1 item')
  .required('Required');

export const YupValidations = {
  comment,
  name,
  requiredName,
  email,
  requiredEmail,
  confirmEmail,
  phone,
  requiredPhone,
  string,
  requiredString,
  requiredIntacctID,
  requiredBirthDate,
  birthDate,
  requiredOption,
  password,
  confirmPassword,
  confirmEmailField,
  requiredStringArray,
  requiredNumber,
  requiredSsn,
  ssn,
};
