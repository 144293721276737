import client from '../httpClient';
import ErrorFactory from '../../shared/errors';
import useLoggedUser from '../../hooks/useLoggedUser';

export async function ACHReturnListRequest({ queryKey }) {
  const {
    loggedUser: { id: userID },
  } = useLoggedUser();
  try {
    // eslint-disable-next-line no-unused-vars
    const [_, params] = queryKey;
    const response = await client.get(
      `/transactions?userId=${userID}&method=1`,
      {
        params,
      },
    );
    return {
      ...response.data,
      transactions: response.data.transactions.map((val) => ({
        id: val.transactionId,
        ...val,
      })),
    };
  } catch (err) {
    throw ErrorFactory(err);
  }
}
