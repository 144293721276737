/* eslint-disable no-unused-vars */
import { useState } from 'react';
import PortalPermission from './PortalPermission';
import { RESOURCES, ACTIONS, ROLES } from './configuration';

const getPermissionFromLocalStorage = () => {
  const permissions = localStorage.getItem('permissions');
  if (permissions && permissions !== 'undefined') {
    return permissions;
  }
  return '[]';
};

function usePermissions() {
  const [permissions] = useState(PortalPermission(JSON.parse(getPermissionFromLocalStorage())));

  const hasPermission = (permission) => {
    const hasIt = permissions[permission[0]]?.includes(permission[1]) || permissions[permission[0]]?.includes('*');
    return Boolean(hasIt);
  };

  return {
    hasPermission,
    hasSomePermission: (requestedPermissions) => {
      return requestedPermissions.some(hasPermission);
    },
    users: {
      getRoleOptions: () => {
        const RolesOptions = ROLES.filter((role) => hasPermission([`roles/${role.value}`, ACTIONS.CREATE]));
        return RolesOptions;
      },
    },
    merchants: {
      editMerchantSensitive: () => hasPermission([RESOURCES.MERCHANTS_RISK, ACTIONS.UPDATE]),
    },
  };
}

export default usePermissions;
