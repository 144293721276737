import {
  experimentalStyled,
  Table, TableBody, TableCell, TableHead, Typography,
} from '@mui/material';
import MuiTableRow from '@mui/material/TableRow';
import { v4 as uuidv4 } from 'uuid';

import {
  capitalize, isEmpty, keys, map, get, isNil, filter,
} from 'lodash';

const TableRow = experimentalStyled(MuiTableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F8F8F8',
  },
  // // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

export const RowTable = ({
  data,
  rows: inputRows,
  autoGenerateRows = false,
  labelWidth = '15%',
  omit = [],
}) => {
  let rows = inputRows;
  if (autoGenerateRows) {
    rows = map(keys(data), (field) => {
      const camelCasedToLowerCaseSpaced = field.replace(/([A-Z])/g, ' $1').toLowerCase();
      const label = capitalize(camelCasedToLowerCaseSpaced);
      const valueGetter = (dataSet) => `${get(dataSet, [field], '-')}`;
      return { label, valueGetter };
    });
  }
  const tableContent = map(filter(rows, ({ label }) => !omit.includes(label)), ({ label, valueGetter, dataTestId }) => {
    let value = null;
    try {
      value = valueGetter(data) || '-';
      value = (isNil(value) || value === 'null' || value === 'undefined') ? '-' : value;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    if (typeof value === 'string' ? isEmpty(value.trim()) : isEmpty(value)) {
      return null;
    }
    return (
      <TableRow key={`${value}-${uuidv4()}`}>
        <TableCell align="left" sx={{ width: labelWidth }}>
          <Typography color="textPrimary" variant="subtitle2" fontSize={15} fontWeight="bold">
            {label}
          </Typography>
        </TableCell>
        <TableCell data-testid={dataTestId} align="left">
          {value}
        </TableCell>
      </TableRow>
    );
  });
  const noData = (
    <TableRow key="no-data">
      <TableCell data-testid="noDataFound">
        No data found
      </TableCell>
    </TableRow>
  );
  return (
    <Table>
      <TableHead />
      <TableBody sx={{ overflow: 'hidden' }}>
        {isEmpty(tableContent.filter((notNull) => notNull)) ? noData : tableContent}
      </TableBody>
    </Table>
  );
};
