import Accordion from './Accordion';
import useAccordionPersist from './useAccordionPersist';

export const AccordionPersist = ({
  section, Component, label, getAmountAlert = null,
}) => (props) => {
  const { expanded, toggle, amountAlerts } = useAccordionPersist(section, getAmountAlert);

  return (
    <Accordion onExpandedToggle={toggle} amountAlerts={amountAlerts} expanded={expanded} label={label}>
      <Component {...props} isActive={expanded} />
    </Accordion>
  );
};

export default Accordion;
