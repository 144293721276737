import { React, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  get,
  defaultTo,
  isEmpty,
} from 'lodash';

import {
  Box,
  Container,
  Grid,
  Card,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material';

// ICONS
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import Trash from '../../icons/Trash';
import Plus from '../../icons/Plus';
import X from '../../icons/X';

// REDUX
import {
  fetchFundingGroupById,
  removeMerchant,
  fetchAvailableMerchants,
  addMerchant,
  fetchMerchantsInFundingGroup,
} from '../../redux/slices/fundingGroups';

import { MSPDataGrid } from '../../components/DataGrid';
import Breadcrumbs from '../../components/shared/Breadcrumbs';

import useSettings from '../../hooks/useSettings';
import { mainContainerSX } from '../../shared/styles';
import { ActionsMenu } from '../../components/ActionsMenu';

const FundingGroupMembers = () => {
  const [search, setSearch] = useState('');
  const [addMerchantModal, setAddMerchantModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [addingMerchant, setAddingMerchant] = useState(null);
  const [removeMerchantModal, setRemoveMerchantModal] = useState(false);
  const [merchantToRemove, setMerchantToRemove] = useState(null);
  const [successAdding, setSuccessAdding] = useState(false);
  const [failAdding, setFailAdding] = useState(false);
  const [successRemoving, setSuccessRemoving] = useState(false);
  const [failRemoving, setFailRemoving] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { settings } = useSettings();
  const dispatcher = useDispatch();

  const fundingGroup = useSelector((state) => get(state, 'fundingGroupsReducer.response.groups', {}));
  const loading = useSelector((state) => get(state, 'fundingGroupsReducer.isLoading', true));
  // const availableMerchants = useSelector((state) => get(state, 'fundingGroupsReducer.availableMerchants.merchants', []));
  const searchedMerchants = useSelector((state) => get(state, 'fundingGroupsReducer.merchantSearch', []));

  useEffect(() => {
    fetchFundingGroupById(id)(dispatcher);
  }, []);

  useEffect(() => {
    if (!isEmpty(search)) {
      fetchMerchantsInFundingGroup({ params: { merchantName: search }, fundingGroupId: id })(dispatcher);
    }
  }, [search]);

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Merchant',
      flex: 2,
      renderCell: ({ merchantId, formattedValue }) => (
        <RouterLink
          color="textPrimary"
          component={RouterLink}
          to={`/merchant/${merchantId}`}
          variant="subtitle2"
        >
          {formattedValue}
        </RouterLink>
      ),
    },
    {
      field: 'primaryContact',
      headerName: 'Primary Contact',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (cell) => {
        const { formattedValue } = cell;
        return (
          <RouterLink
            color="textPrimary"
            component={RouterLink}
            to={`/users/${formattedValue}`}
            variant="subtitle2"
          >
            {formattedValue}
          </RouterLink>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Added On',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString('en-US'),
    },
    {
      field: 'createdBy',
      headerName: 'Added By',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: ' ',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (merchant) => (
        <Button
          onClick={() => {
            setMerchantToRemove(merchant.row);
            setRemoveMerchantModal(true);
          }}
        >
          <Trash fontSize="small" />
        </Button>
      ),
    },
  ];

  const notification = (open, close, message, severity) => {
    const handleClose = () => close(false);
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    );
  };

  const addMerchantstyle = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    width: 700,
    height: 280,
    padding: '20px',
  };

  const removeMerchantStyle = {
    ...addMerchantstyle,
    height: 200,
    padding: '30px',
  };

  const openAddMerchantModal = async () => {
    await fetchAvailableMerchants(get(fundingGroup, '[0].accountID', null))(dispatcher);
    setAddMerchantModal(true);
  };

  const handleAddMerchant = async () => {
    const requestData = {
      fundingGroup: get(fundingGroup, '[0].id', null),
      merchant: addingMerchant,
    };
    const { error } = await addMerchant(requestData)(dispatcher);
    if (error) {
      setFailAdding(true);
    } else {
      fetchFundingGroupById(id)(dispatcher);
      setSuccessAdding(true);
    }
    setAddMerchantModal(false);
  };

  const handleRemoveMerchant = async () => {
    const { error } = await removeMerchant({ fundingGroup: id, merchant: merchantToRemove.merchantID })(dispatcher);
    if (error) {
      setFailRemoving(true);
    } else {
      fetchFundingGroupById(id)(dispatcher);
      setSuccessRemoving(true);
    }
    setRemoveMerchantModal(false);
  };

  const breadcrumbsItems = [
    {
      id: 'home',
      redirect: '/',
      render: 'Home',
    },
    {
      id: 'funding-groups',
      redirect: '/payment-processing/funding-groups',
      render: 'Funding Groups',
    },
    {
      id: 'funding-group-members',
      render: !loading ? defaultTo(get(fundingGroup, '[0].name', null), 'Not Found') : 'Funding Groups',
    },
  ];

  return (
    <>
      <Helmet>
        <title>MSP | Funding Groups</title>
      </Helmet>
      <Box
        sx={mainContainerSX}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                {!loading ? defaultTo(get(fundingGroup, '[0].name', null), 'Not Found') : 'Funding Groups' }
              </Typography>
              <Breadcrumbs items={breadcrumbsItems} />
            </Grid>
            <Grid item>
              <ActionsMenu
                actions={[
                  {
                    icon: <SettingsIcon />,
                    label: 'Settings',
                    onClick: () => navigate(`../../${id}/settings`),
                  },
                  {
                    icon: <Plus />,
                    label: 'Add Merchant',
                    onClick: () => openAddMerchantModal(),
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Card style={{ marginTop: 25 }}>
            <TextField
              placeholder="Search member merchants"
              value={search}
              onChange={(e) => {
                setSearch(e?.target?.value || '');
              }}
              onBlur={() => {}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                my: 2,
                ml: 2,
                width: '98%',
              }}
            />
          </Card>
          <Card sx={{ mt: 3 }}>
            <MSPDataGrid
              cols={columns}
              getRowId={(r) => r.merchantID}
              tableData={!isEmpty(search) ? defaultTo(get(searchedMerchants, 'members', null), []) : defaultTo(get(fundingGroup, '[0].members', null), [])}
            />
          </Card>
        </Container>
        <Modal
          open={addMerchantModal}
          onClose={() => setAddMerchantModal(false)}
        >
          <Box sx={addMerchantstyle}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h2">
                Add Member Merchant Account
              </Typography>
              <Button
                onClick={() => setAddMerchantModal(false)}
              >
                <X />
              </Button>

            </div>
            <Typography sx={{ mt: 2 }}>
              Add an existing merchant account to this group. When added to the group, the account will inherit all group funding settings.
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: 20,
              }}
            >
              <Button
                color="primary"
                size="large"
                variant="outlined"
                style={{ marginRight: 15 }}
                onClick={() => setAddMerchantModal(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleAddMerchant}
              >
                Add
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={removeMerchantModal}
          onClose={() => setRemoveMerchantModal(false)}
        >
          <Box sx={removeMerchantStyle}>
            <Typography style={{ display: 'flex', alignItems: 'center' }} variant="h6" component="h2">
              <WarningIcon style={{ color: 'red', fontSize: 30, marginRight: 10 }} />
              Remove Merchant
            </Typography>

            <Typography sx={{ mt: 2 }}>
              {`Are you sure you want to remove "${merchantToRemove?.name}" from this Funding Group?`}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: 20,
              }}
            >
              <Button
                color="primary"
                size="large"
                variant="outlined"
                style={{ marginRight: 15 }}
                onClick={() => setRemoveMerchantModal(false)}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                size="large"
                variant="contained"
                onClick={handleRemoveMerchant}
              >
                Remove
              </Button>
            </div>
          </Box>
        </Modal>
        {notification(successRemoving, setSuccessRemoving, 'Merchant was removed successfully.', 'success')}
        {notification(failRemoving, setFailRemoving, 'Merchant was not removed.', 'error')}
        {notification(successAdding, setSuccessAdding, 'Merchant was added to the Funding Group.', 'success')}
        {notification(failAdding, setFailAdding, 'Merchant was not added to the Funding Group.', 'error')}
      </Box>
    </>
  );
};

export default FundingGroupMembers;
