import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { get } from 'lodash';
import { useStore } from 'react-redux';
import ContactInfo, { FormFields, ValidationSchema, defaultValue } from '../../forms/ContactInfo';
import CopyInfoForm from '../../shared/CopyInfoForm';
import PersistedSectionForm from '../../shared/PersistedSectionForm';
import { AccordionPersist } from '../../shared/Accordion/Accordion';

export {
  ValidationSchema,
  FormFields,
};

const section = 'addAccount.itManagerInfo';
const copyFrom = 'addAccount.applicantContact';

const ITManagerInfo = ({
  name, control, copyValues,
}) => {
  const store = useStore();

  const handleCopyFrom = (from, _) => {
    const state = store.getState();
    const fromFrom = get(state, `persistedFormsReducer.${from}.form`, {});

    copyValues(fromFrom);
  };

  return (
    <Box
      display="grid"
    >
      <Typography color="textPrimary" variant="body1" sx={{ mb: 2 }}>
        Please provide the following details about the IT Manager or Supervisor
      </Typography>
      <Grid container spacing={1}>
        <CopyInfoForm onCopy={handleCopyFrom} copyFrom={copyFrom} copyTo={section}>
          Copy from Applicant Contact Information
        </CopyInfoForm>
        <ContactInfo control={control} name={name} validate />
      </Grid>
    </Box>
  );
};

const ITManagerInfoPersisted = AccordionPersist({
  label: 'IT Manager/Supervisor',
  section,
  Component: PersistedSectionForm({
    section,
    Component: ITManagerInfo,
    schema: ValidationSchema({ validate: true }),
    defaultValues: defaultValue,
  }),
});

export default ITManagerInfoPersisted;
