import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import ControlledComponent from '../../utils/ControlledComponent';

function SelectDropdown({
  name, label, value, onChange, options, required, error, disableClear = false, dataTestId, ...rest
}) {
  const handleChange = (e) => {
    onChange(name, e?.target?.value);
  };
  const handleBlur = (e) => {
    const hoverValue = e?.target?.getAttribute('data-value');
    if (hoverValue) onChange(name, hoverValue);
  };
  return (
    <FormControl fullWidth error={!!error} required={required} {...rest}>
      <InputLabel id={`${name}-${label}`}>
        {label}
      </InputLabel>
      <Select
        data-testid={dataTestId}
        label={label}
        labelId={`${label}-${name}`}
        id="select"
        value={value}
        onChange={handleChange}
        onClose={handleBlur}
        {...rest}
      >
        { (!disableClear && !!value) && (
        <MenuItem
          sx={{
            background: '#f4f5f7',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          key="null"
          value={null}
        >
          Clear Selection
        </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} data-testid={`orgType${option.label.replace(/\s+/g, '')}`}>{option.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}

export const ControlledSelectDropdown = ControlledComponent(SelectDropdown);

export default SelectDropdown;
