import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Alert,
  Grid,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RenewPasswordForm } from '../components/renew-password';
import { ReactComponent as CoreLogo } from '../components/logos/Core1.svg';

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [onRetry, setOnRetry] = useState(false);

  useEffect(() => {
    if (!location?.state?.email) { navigate('/'); }
  }, location);

  return (
    <>
      <Helmet>
        <title>MSP | Renew Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '80px', pt: '80px' }}>
          <div className="login-logo">
            <Link to="/">
              <CoreLogo />
            </Link>
          </div>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <Grid sx={{ width: '100%' }}>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Renew Password
                  </Typography>
                  {!onRetry && (
                    <Alert sx={{ mt: 2 }} severity="warning">
                      Your password has expired.
                      {' '}
                      <br />
                      {' '}
                      For your security,
                      please enter a new password.
                    </Alert>
                  )}
                </Grid>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <RenewPasswordForm
                  email={location?.state?.email}
                  onRetry={onRetry}
                  setOnRetry={setOnRetry}
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
export default ForgotPassword;
