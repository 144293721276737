import { createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { GetTransactions } from '../../../api';

export const fetchTransactionsThunk = createAsyncThunk('transactions/fetchAll', async (params, { rejectWithValue }) => {
  try {
    const response = await GetTransactions(params);
    return {
      ...response.data,
      transactions: response.data.transactions.map((row) => ({
        ...row,
        id: row.transactionId,
      })),
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const transactionsReducers = {
  [fetchTransactionsThunk.fulfilled]: (state, { payload }) => {
    set(
      state,
      'list.response',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
  [fetchTransactionsThunk.pending]: (state) => {
    set(
      state,
      'list.isLoading',
      true,
    );
  },
  [fetchTransactionsThunk.rejected]: (state, { payload }) => {
    set(
      state,
      'list.error',
      payload,
    );
    set(
      state,
      'list.isLoading',
      false,
    );
  },
};
