import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Card, CardContent, Container, Divider, Link, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import LoadingScreen from '../components/LoadingScreen';

import { LoginForm } from '../components/login/LoginForm';
import { ReactComponent as CoreLogo } from '../components/logos/Core1.svg';
import SecurityCode from '../components/login/SecurityCode';
import './styles.css';

const Signin = () => {
  // This fakeLoading is a bit silly but it was complained that the Signin component would blink in the screen if you refresh any other page,
  // which is a safe and natural consequence of how our AuthGuard works, because we render Signin while authentication isn't fully computed,
  // but just to keep the refresh more "visually consistent" and QA happier I'm adding this
  const [fakeLoading, setFakeLoading] = useState(true);
  const [loginValues, setLoginValues] = useState({});
  const [multiFactorRequired, setMultiFactorRequired] = useState(useSelector((state) => get(state, 'loginReducer.login.loggedUser.multiFactorRequired', false)));
  const [multiFactorEnabled, setMultiFactorEnabled] = useState(useSelector((state) => get(state, 'loginReducer.login.loggedUser.multiFactorEnabled', false)));
  const showUserPass = !multiFactorRequired;
  const showSecurityCode = multiFactorRequired && multiFactorEnabled;

  useEffectOnce(() => {
    setTimeout(() => {
      setFakeLoading(false);
    }, 600);
  });

  return (
    <>
      <Helmet>
        <title>MSP | Sign In</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        {fakeLoading
          ? <LoadingScreen />
          : (
            <Container
              maxWidth="sm"
              sx={{ py: '80px', pt: '80px' }}
            >
              <div className="login-logo">
                <CoreLogo />
              </div>
              <Card>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 4,
                  }}
                >
                  { showUserPass && (
                  <>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 3,
                      }}
                    >
                      <div>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h4"
                        >
                          Sign in
                        </Typography>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <LoginForm
                        setLoginValues={setLoginValues}
                        setMultiFactorRequired={setMultiFactorRequired}
                        setMultiFactorEnabled={setMultiFactorEnabled}
                      />
                    </Box>
                    <Divider sx={{ my: 3 }} />
                    <Link
                      color="textSecondary"
                      component={RouterLink}
                      to="/forgot-password"
                      variant="body2"
                    >
                      Forgot Password
                    </Link>
                  </>
                  )}
                  { showSecurityCode
            && (
            <SecurityCode
              loginValues={loginValues}
            />
            )}
                </CardContent>
              </Card>
            </Container>
          )}
      </Box>
    </>
  );
};
export default Signin;
