import * as Yup from 'yup';
import isNaN from 'lodash/isNaN';
import { BankingAccountTypeValues } from '../../../shared/constants';
import { YupValidations } from '../../../shared/validations';
import {
  BankingAccountValidation,
  DefaultValues as BankingDefaultValues,
} from './banking/BankingAccountForm';

export const FormFields = {
  PAYMENT_METHOD_TYPE: 'paymentMethodType',
  ACCEPTED_CARDS: 'acceptedCards',
  FUNDING_SOURCE: 'fundingSource',
  CARD_FUNDING_TYPE: 'cardFundingType',
  CARD_FUNDING_DELAY: 'cardFundingDelay',
  ACH_FUNDING_TYPE: 'achFundingType',
  ACH_FUNDING_DELAY: 'achFundingDelay',
  AMEX_TPSP_FUNDING_TYPE: 'amexTpspFundingType',
  AMEX_TPSP_FUNDING_DELAY: 'amexTpspFundingDelay',
  CONV_FEES_NET_DEPOSIT: 'convenienceFeesNetDeposit',
  COMBINED_DEPOSIT: 'combinedDeposit',
  PROCESSOR: 'processor',
  GATEWAY: 'gateway',
  GATEWAY_GROUP: 'gatewayGroup',
  GATEWAY_FEE_GROUP: 'gatewayFeeGroup',
  SERVICE_FEE_TYPE: 'serviceFeetype',
  SERVICE_FEE_FIXED_AMOUNT: 'serviceFeeFixedAmount',
  SERVICE_FEE_PERCENTAGE: 'serviceFeePercentage',
  MERCHANT_ACCOUNT: 'merchantAccount',
  MERCHANT_ACCOUNT_CODE: 'merchantAccountCode',
  USER: 'user',
  PASSWORD: 'password',
  // banking accounts
  CREDIT_DEPOSIT: 'creditDeposit',
  CREDIT_CHARGEBACK: 'creditChargeback',
  CREDIT_CONVENIENCE_FEES: 'creditConvenienceFees',
  CREDIT_PROCESSING_FEES: 'creditProcessingFees',
  DEBIT_DEPOSIT: 'debitDeposit',
  DEBIT_CHARGEBACK: 'debitChargeback',
  DEBIT_CONVENIENCE_FEES: 'debitConvenienceFees',
  AMEX_ROUTING: 'amexRouting',
  AMEX_DDA: 'amexDda',
  ACH_RETURN: 'achReturn',
  ACH_CHARGEBACK: 'achChargeback',
};

export const BankingFieldsPerType = {
  card: [
    FormFields.CREDIT_DEPOSIT,
    FormFields.CREDIT_CHARGEBACK,
    FormFields.CREDIT_CONVENIENCE_FEES,
    FormFields.CREDIT_PROCESSING_FEES,
    FormFields.DEBIT_DEPOSIT,
    FormFields.DEBIT_CHARGEBACK,
    FormFields.DEBIT_CONVENIENCE_FEES,
  ],
  amex_tpsp: [
    FormFields.AMEX_DDA,
    FormFields.AMEX_ROUTING,
  ],
  ach: [
    FormFields.ACH_RETURN,
    FormFields.ACH_CHARGEBACK,
  ],
};

export const BankTypeValuePerFormField = {
  [FormFields.CREDIT_DEPOSIT]: BankingAccountTypeValues.CREDIT_DEPOSITS,
  [FormFields.CREDIT_CHARGEBACK]: BankingAccountTypeValues.CREDIT_CHARGEBACKS,
  [FormFields.CREDIT_CONVENIENCE_FEES]: BankingAccountTypeValues.CREDIT_CONVENIENCE_FEES,
  [FormFields.CREDIT_PROCESSING_FEES]: BankingAccountTypeValues.CREDIT_PROCESSING_FEES,
  [FormFields.DEBIT_DEPOSIT]: BankingAccountTypeValues.DEBIT_DEPOSITS,
  [FormFields.DEBIT_CHARGEBACK]: BankingAccountTypeValues.DEBIT_CHARGEBACKS,
  [FormFields.DEBIT_CONVENIENCE_FEES]: BankingAccountTypeValues.DEBIT_CONVENIENCE_FEES,
  [FormFields.AMEX_DDA]: BankingAccountTypeValues.AMEX_DDA_ACCOUNT,
  [FormFields.AMEX_ROUTING]: BankingAccountTypeValues.AMEX_ROUTING_ACCOUNT,
  [FormFields.ACH_RETURN]: BankingAccountTypeValues.ACH_RETURN_ACCOUNT,
  [FormFields.ACH_CHARGEBACK]: BankingAccountTypeValues.ACH_CHARGEBACK_ACCOUNT,
};

export const ValidationSchema = {
  [FormFields.PAYMENT_METHOD_TYPE]: YupValidations.requiredString,
  [FormFields.FUNDING_SOURCE]: YupValidations.requiredString,
  [FormFields.PROCESSOR]: YupValidations.requiredString,
  [FormFields.GATEWAY]: YupValidations.requiredString,
  [FormFields.SERVICE_FEE_TYPE]: YupValidations.requiredString,
  [FormFields.CARD_FUNDING_TYPE]: Yup.string().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.CARD_FUNDING_DELAY]: Yup.string().when([FormFields.PAYMENT_METHOD_TYPE, FormFields.CARD_FUNDING_TYPE], {
    is: (paymentMethodType, cardFundingType) => paymentMethodType === 'card' && cardFundingType === 'fbo',
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.ACH_FUNDING_TYPE]: Yup.string().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'ach',
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.ACH_FUNDING_DELAY]: Yup.string().when([FormFields.PAYMENT_METHOD_TYPE, FormFields.ACH_FUNDING_TYPE], {
    is: (paymentMethodType, cardFundingType) => paymentMethodType === 'ach' && cardFundingType !== 'fbo',
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.AMEX_TPSP_FUNDING_TYPE]: Yup.string().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'amex_tpsp',
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.AMEX_TPSP_FUNDING_DELAY]: Yup.string().when([FormFields.PAYMENT_METHOD_TYPE, FormFields.AMEX_TPSP_FUNDING_TYPE], {
    is: (paymentMethodType, cardFundingType) => paymentMethodType === 'amex_tpsp' && cardFundingType === 'fbo',
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.CONV_FEES_NET_DEPOSIT]: Yup.string().when([FormFields.CARD_FUNDING_TYPE, FormFields.ACH_FUNDING_TYPE, FormFields.AMEX_TPSP_FUNDING_TYPE], {
    is: (cardFundingType, achFundingType, amexTpspFundingType) => cardFundingType === 'fbo' || achFundingType === 'fbo' || amexTpspFundingType === 'fbo',
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.COMBINED_DEPOSIT]: Yup.string().when([FormFields.PAYMENT_METHOD_TYPE, FormFields.CARD_FUNDING_TYPE, FormFields.ACH_FUNDING_TYPE, FormFields.AMEX_TPSP_FUNDING_TYPE], {
    is: (paymentMethod, cardFundingType, achFundingType, amexTpspFundingType) => ((paymentMethod === 'ach' && achFundingType === 'fbo')
    || (paymentMethod === 'amex_tpsp' && amexTpspFundingType === 'fbo')
    || (paymentMethod === 'card' && cardFundingType === 'fbo')),
    then: YupValidations.requiredString,
    otherwise: YupValidations.string,
  }),
  [FormFields.GATEWAY_GROUP]: YupValidations.string
    .when([`${FormFields.GATEWAY}`, `${FormFields.PAYMENT_METHOD_TYPE}`], (integration, paymentMethodType, schema) => {
      if (integration === 'bluefin') return schema.required('Required');
      return schema.notRequired();
    }),
  [FormFields.GATEWAY_FEE_GROUP]: YupValidations.string
    .when([`${FormFields.GATEWAY}`, `${FormFields.PAYMENT_METHOD_TYPE}`], (integration, paymentMethodType, schema) => {
      if (integration === 'bluefin') return schema.required('Required');
      return schema.notRequired();
    }),
  [FormFields.SERVICE_FEE_FIXED_AMOUNT]: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
    .when([`${FormFields.SERVICE_FEE_TYPE}`], (serviceFeeType, schema) => {
      if (['fixed', 'combination'].includes(serviceFeeType)) return schema.required('Required');
      return schema.notRequired();
    }),
  [FormFields.SERVICE_FEE_PERCENTAGE]: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
    .when([`${FormFields.SERVICE_FEE_TYPE}`], (serviceFeeType, schema) => {
      if (['percentage', 'combination'].includes(serviceFeeType)) return schema.required('Required');
      return schema.notRequired();
    }),
  [FormFields.MERCHANT_ACCOUNT]: YupValidations.requiredString,
  [FormFields.MERCHANT_ACCOUNT_CODE]: YupValidations.requiredString,
  [FormFields.USER]: YupValidations.string
    .when([`${FormFields.GATEWAY}`, `${FormFields.PAYMENT_METHOD_TYPE}`], (integration, paymentMethodType, schema) => {
      if (integration !== 'bluefin') return schema.required('Required');
      return schema.notRequired();
    }),
  [FormFields.PASSWORD]: YupValidations.requiredString,
  [FormFields.ACCEPTED_CARDS]: Yup.object(),
  [FormFields.CREDIT_DEPOSIT]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.CREDIT_CHARGEBACK]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.CREDIT_CONVENIENCE_FEES]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.CREDIT_PROCESSING_FEES]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.DEBIT_DEPOSIT]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.DEBIT_CHARGEBACK]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.DEBIT_CONVENIENCE_FEES]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'card',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.AMEX_ROUTING]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'amex_tpsp',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.AMEX_DDA]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'amex_tpsp',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.ACH_RETURN]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'ach',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
  [FormFields.ACH_CHARGEBACK]: Yup.object().when(FormFields.PAYMENT_METHOD_TYPE, {
    is: (paymentMethodType) => paymentMethodType === 'ach',
    then: BankingAccountValidation({ required: true }),
    otherwise: BankingAccountValidation(),
  }).default(BankingDefaultValues),
};

export const DefaultValues = {
  [FormFields.PAYMENT_METHOD_TYPE]: '',
  [FormFields.FUNDING_SOURCE]: '',
  [FormFields.PROCESSOR]: '',
  [FormFields.GATEWAY]: '',
  [FormFields.MERCHANT_ACCOUNT]: '',
  [FormFields.MERCHANT_ACCOUNT_CODE]: '',
  [FormFields.USER]: '',
  [FormFields.PASSWORD]: '',
  [FormFields.ACCEPTED_CARDS]: {},
  [FormFields.CREDIT_DEPOSIT]: BankingDefaultValues,
  [FormFields.CREDIT_CHARGEBACK]: BankingDefaultValues,
  [FormFields.CREDIT_CONVENIENCE_FEES]: BankingDefaultValues,
  [FormFields.CREDIT_PROCESSING_FEES]: BankingDefaultValues,
  [FormFields.DEBIT_DEPOSIT]: BankingDefaultValues,
  [FormFields.DEBIT_CHARGEBACK]: BankingDefaultValues,
  [FormFields.DEBIT_CONVENIENCE_FEES]: BankingDefaultValues,
  [FormFields.AMEX_ROUTING]: BankingDefaultValues,
  [FormFields.AMEX_DDA]: BankingDefaultValues,
  [FormFields.ACH_RETURN]: BankingDefaultValues,
  [FormFields.ACH_CHARGEBACK]: BankingDefaultValues,
};
