import {
  createContext, useMemo,
} from 'react';

const noOp = () => null;

export const TreeContext = createContext({
  isSelected: noOp, select: noOp, deselect: noOp,
});

export const getFlatChildren = (node) => {
  const allChildren = [];
  let hasOneChildOnly = node.children?.length === 1;

  const getChildren = (childNode) => {
    allChildren.push(childNode);
    if (childNode?.children?.length === 1) {
      hasOneChildOnly = true;
      return;
    }
    childNode?.children?.forEach(getChildren);
  };
  if (!hasOneChildOnly) node?.children?.forEach(getChildren);
  return allChildren;
};

export const ToFlatHash = (rootNode) => {
  const flat = {};
  const concatItems = (node) => {
    flat[node.id] = node;
    node.children?.forEach(concatItems);
  };

  rootNode?.children?.forEach(concatItems);
  return flat;
};

// onChange:func => { node: {id, labelProp}, checkedStatus }
function Tree({ children, selected, onChange }) {
  const value = useMemo(() => ({
    isSelected: (node) => Boolean(selected[node?.id]),
    select: (node) => onChange(node, true),
    deselect: (node) => onChange(node, false),
  }), [selected]);

  return (
    <TreeContext.Provider value={value}>
      { children }
    </TreeContext.Provider>
  );
}

export default Tree;
