import {
  Grid, Typography,
} from '@mui/material';

import FullContactInformation, { ValidationSchema, defaultValue } from '../../../forms/FullContactInformation';
import PersistedSectionForm from '../../../shared/PersistedSectionForm';

function SingleBeneficialOwner({ control, name }) {
  return (
    <>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
        Beneficial Owner
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Please provide the following details about the
        {' '}
        <strong>Beneficial Owner</strong>
      </Typography>
      <Grid container>
        <FullContactInformation control={control} name={name} withOwnership validate />
      </Grid>
    </>
  );
}

const section = 'addAccount.singleBeneficialOwner';

export default PersistedSectionForm({
  section,
  Component: SingleBeneficialOwner,
  schema: ValidationSchema({ validate: true }),
  defaultValues: defaultValue,
});
