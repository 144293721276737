import { useRef, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { has } from 'lodash';
import {
  Box, Button, TextField, Checkbox, FormControlLabel, Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export const LoginForm = (props) => {
  const navigate = useNavigate();
  const { setLoginValues, setMultiFactorRequired, setMultiFactorEnabled } = props;
  const { login, expiredMessageError } = useAuth();
  const [rememberMe, setRememberMe] = useState(false);
  const ref = useRef(null);

  const saveValues = () => {
    const { values: loginValues } = ref.current;
    loginValues.rememberMe = rememberMe;
    setLoginValues(loginValues);
  };
  const [lockedTimestamp, setLockedTimestamp] = useState(0);
  const [counter, setCounter] = useState(30);

  useEffect(() => {
    let interval;
    if (lockedTimestamp > 0) {
      const timestampDate = new Date(lockedTimestamp);
      const lockedTime = new Date(timestampDate.getTime() + (30 * 60000));
      const lockedInterval = Math.ceil((lockedTime.getTime() - new Date().getTime()) / 60000);
      if (lockedInterval < 30 && lockedInterval >= 0) setCounter(lockedInterval);
      if (lockedInterval <= 0) setCounter(0);
      interval = setInterval(() => setCounter((prevCount) => prevCount - 1), 60000);
      if (counter <= 0) clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [lockedTimestamp, counter]);

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup
            .string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup
            .string()
            .max(255)
            .required('Password is required'),
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          saveValues();
          await login(values.email, values.password, rememberMe, null,
            setMultiFactorRequired, setMultiFactorEnabled);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          if (err.message?.toLowerCase() === expiredMessageError) {
            navigate('/renew-password', { state: { email: values.email, fromLogin: true } });
          }
          if (has(err, 'lastLoginAttempt') && (new Date(err.lastLoginAttempt).getTime() + (30 * 60000)) > new Date().getTime()) setLockedTimestamp(new Date(err.lastLoginAttempt).getTime());
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors, handleChange, handleSubmit, isSubmitting, touched, values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoComplete="username"
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            sx={{ mb: 3 }}
          />
          <TextField
            autoComplete="current-password"
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <FormControlLabel
            sx={{ mr: 1 }}
            control={(
              <Checkbox
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                name="rememberme"
                color="primary"
              />
        )}
            label="Remember me"
          />
          {errors.submit && (
          <Box sx={{ mt: 1, mb: 2 }}>
            {lockedTimestamp > 0
              ? (
                <Alert severity="error">
                  {errors.submit}
                  {' '}
                  {counter}
                  {' '}
                  minutes
                </Alert>
              )
              : (<Alert severity="error">{errors.submit}</Alert>)}

          </Box>
          )}
          <Box sx={{ mt: 1 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
