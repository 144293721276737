import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  filter, first, map, size,
} from 'lodash';
import usePermissions from '../IAM/usePermissions';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 32,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const ActionsMenu = ({ actions = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { hasPermission } = usePermissions();

  const renderMultipleActions = () => {
    return (
      <>
        <Button
          data-testid="actionsMenuBtn"
          id="multiple-actions"
          aria-controls={open ? 'multiple-actions' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            display: {
              xl: 'flex',
              md: 'flex',
              lg: 'flex',
              sm: 'flex',
              xs: 'none',
            },
            mt: {
              xl: 1,
              lg: 1,
              xs: -1,
              sm: -1,
            },
          }}
        >
          Actions
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleClick}
          sx={{
            display: {
              xl: 'none',
              md: 'none',
              lg: 'none',
              sm: 'none',
              xs: 'flex',
            },
            mt: 1,
          }}
        >
          <KeyboardArrowDownIcon />
        </Button>
        <StyledMenu
          id="multiple-actions-menu"
          MenuListProps={{
            'aria-labelledby': 'multiple-actions-menu',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {map(actions, ({
            disabled = false, icon, label, onClick = () => {}, hideIf = false, permission = [], dataTestId,
          }) => {
            const show = !hideIf && (permission?.length ? hasPermission(permission) : true);
            return show && (
              <MenuItem
                disabled={disabled}
                data-testid={dataTestId || label.replace(/\s+/g, '-').toLowerCase()}
                onClick={(e) => {
                  handleClose();
                  onClick(e);
                }}
                disableRipple
                key={uuidv4()}
              >
                {icon}
                {label}
              </MenuItem>
            );
          })}
        </StyledMenu>
      </>
    );
  };

  const renderSingleAction = (visibleActions) => {
    if (size(visibleActions) === 0) {
      return <></>;
    }
    const {
      icon = <></>, label = '', disabled = false, onClick = () => {}, hideIf = false, permission = [], dataTestId, ...props
    } = first(visibleActions) || {};
    const show = !hideIf && (permission?.length ? hasPermission(permission) : true);
    return (
      show ? (
        <>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            disabled={disabled}
            onClick={onClick}
            startIcon={icon}
            sx={{
              display: {
                xl: 'flex',
                md: 'flex',
                lg: 'flex',
                sm: 'flex',
                xs: 'none',
              },
              mt: 1,
            }}
            data-testid={dataTestId}
            {...props}
          >
            {label}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            sx={{
              mt: 1,
              display: {
                xl: 'none',
                md: 'none',
                lg: 'none',
                sm: 'none',
                xs: 'flex',
              },
            }}
            onClick={onClick}
            disabled={disabled}
          >
            {icon}
          </Button>
        </>
      ) : null
    );
  };

  const visibleActions = filter(actions, ({ hideIf = false }) => !hideIf);

  return (
    <div>
      {size(visibleActions) > 1 ? renderMultipleActions() : renderSingleAction(visibleActions)}
    </div>
  );
};
