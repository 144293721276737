import {
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ControlledPhoneInput } from '../PhoneInput';
import { ControlledInputText } from '../InputText';
import { prefixFormName } from '../../../utils/formatting/Form';

export const FormFields = {
  BANK_NAME: 'bankName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  EMAIL: 'email',
};

function BankContact({
  name,
  disabled = false,
  validate,
  control,
  dataTestId,
}) {
  return (
    <>
      <Grid item xs={12}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.BANK_NAME)}
          data-testid={`${dataTestId}BankName`}
          label="Bank name"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.FIRST_NAME)}
          data-testid={`${dataTestId}FirstName`}
          label="First name"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.LAST_NAME)}
          data-testid={`${dataTestId}LastName`}
          label="Last name"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledPhoneInput
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.PHONE)}
          data-testid={`${dataTestId}PhoneNumber`}
          label="Phone number"
          required={validate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          disabled={disabled}
          control={control}
          name={prefixFormName(name, FormFields.EMAIL)}
          data-testid={`${dataTestId}Email`}
          label="Email address"
          required={validate}
        />
      </Grid>
    </>
  );
}

BankContact.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  validate: PropTypes.bool,
};

export default BankContact;
