import ErrorFactory from '../../../shared/errors';
import client from '../../httpClient';
import { useAddTenderRequest } from '../../../hooks/accounts/tenders/useAddTenderRequest';

export const AddTenderToMID = ({ accountTypes }) => (payload) => {
  try {
    return client.post(
      `/merchants/${payload?.merchantId}/tenders`,
      { tenderType: useAddTenderRequest(payload, accountTypes) },
    );
  } catch (err) {
    throw ErrorFactory(err);
  }
};
