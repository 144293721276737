/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

import {
  fetchFundingGroupsThunk,
  fetchFundingGroupsReducers,
  fetchFundingGroupsByAccountThunk,
  removeMerchantThunk,
  removeMerchantReducers,
  fetchAvailableMerchantsThunk,
  fetchAvailableMerchantsReducers,
  addMerchantThunk,
  fetchMerchantsInFundingGroupThunk,
  fetchMerchantsInFundingGroupReducers,
  addFundingGroupThunk,
  editFundingGroupThunk,
  fetchFundingGroupByIdThunk,
  fetchFundingGroupsByAccountReducers,
  fetchFundingGroupByIdReducers,
  updateMIDTendersReducers,
  updateMIDTendersThunk,
} from '../api/fundingGroups';

export const initialState = {
  resetForm: 0,
};

const slice = createSlice({
  name: 'fundingGroups',
  initialState,
  reducers: {
    resetForm(state) {
      state.resetForm += 1;
    },
    resetUpdatedTenders(state) {
      set(
        state,
        'updatedTenders',
        undefined,
      );
    },
  },
  extraReducers: {
    ...fetchFundingGroupsReducers,
    ...removeMerchantReducers,
    ...fetchAvailableMerchantsReducers,
    ...fetchMerchantsInFundingGroupReducers,
    ...fetchFundingGroupsByAccountReducers,
    ...fetchFundingGroupByIdReducers,
    ...updateMIDTendersReducers,
  },
});

export const { reducer } = slice;

export const resetForm = () => (dispatch) => {
  dispatch(slice.actions.resetForm());
};

export const fetchFundingGroups = (payload) => async (dispatch) => {
  await dispatch(fetchFundingGroupsThunk(payload));
};

export const fetchFundingGroupsByAccount = (payload) => async (dispatch) => {
  await dispatch(fetchFundingGroupsByAccountThunk(payload));
};

export const fetchFundingGroupById = (payload) => async (dispatch) => {
  await dispatch(fetchFundingGroupByIdThunk(payload));
};

export const removeMerchant = (payload) => (dispatch) => {
  return dispatch(removeMerchantThunk(payload));
};

export const addMerchant = (payload) => (dispatch) => {
  return dispatch(addMerchantThunk(payload));
};

export const fetchAvailableMerchants = (payload) => async (dispatch) => {
  return dispatch(fetchAvailableMerchantsThunk(payload));
};

export const fetchMerchantsInFundingGroup = (params) => async (dispatch) => {
  dispatch(fetchMerchantsInFundingGroupThunk(params));
};

export const addFundingGroup = (payload) => async (dispatch) => {
  return dispatch(addFundingGroupThunk(payload));
};

export const editFundingGroup = (payload) => async (dispatch) => {
  return dispatch(editFundingGroupThunk(payload));
};

export const updateMIDTenders = (payload) => async (dispatch) => {
  return dispatch(updateMIDTendersThunk(payload));
};

export const resetUpdatedTenders = () => async (dispatch) => {
  return dispatch(slice.actions.resetUpdatedTenders());
};

export default slice;
