import {
  DialogContentText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { find, get, map } from 'lodash';
import { AccountTypeNames } from '../../../../../shared/constants';
import { useAccountTypes } from '../../../../../hooks/systemConfig/useAccountTypes';

function MIDTable({ rows, editValues, bankAccountType }) {
  if (!rows || !rows.length) {
    return null;
  }
  const renderCell = ({ row, prop }) => {
    const curr = get(row, prop);
    const updated = get(editValues, prop);
    if (curr !== updated) {
      return (
        <TableCell align="center">
          <Typography inline fontSize={16} sx={{ textDecoration: 'line-through', ml: 1 }}>{curr}</Typography>
          <Typography inline fontSize={16} fontWeight={600}>{updated}</Typography>
        </TableCell>
      );
    }
    return (
      <TableCell>
        <Typography fontSize={16} fontWeight={600}>{updated}</Typography>
      </TableCell>
    );
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400, maxWidth: 550 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>MID</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">Account Type</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">Bank Name</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">Account Number</TableCell>
            <TableCell sx={{ fontWeight: 700 }} align="center">Routing ABA Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
                {row.name}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 600 }}>
                {AccountTypeNames[bankAccountType?.type]}
              </TableCell>
              {map(['bankName', 'accountNumber', 'routingAbaNumber'], (p) => renderCell({ row, prop: p }))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ConfirmationStep({ meta = {} }) {
  const bankAccountTypes = useAccountTypes();
  const { selectedMids = [], selection = {}, editBank = {} } = meta;
  const { bankAccountType = {} } = selection;
  const { tenderType = '', type: bankType = '' } = bankAccountType;
  const { id: bankTypeId } = find(bankAccountTypes, ({ value }) => value === bankType);
  const data = map(selectedMids, (mid) => {
    const tender = find(mid?.tenderTypes, ({ paymentMethod = '' }) => paymentMethod.replaceAll('_', '') === tenderType.replaceAll('_', ''));
    const { bankingSettings = [] } = (tender || {});
    const bankingInfo = find(bankingSettings, ({ accountTypeID }) => accountTypeID === bankTypeId);
    return {
      name: mid?.name,
      ...bankingInfo,
    };
  });
  return (
    <>
      <Grid item textAlign="center" xs={12}>
        <FeedbackIcon color="warning" sx={{ fontSize: '80px' }} />
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <DialogContentText sx={{ typography: 'h6' }}>
          This action requires confirmation.
        </DialogContentText>
        <Typography variant="body2" mt={2}>
          Are you sure you wish to apply the changes to the selected items?
        </Typography>
        <Grid item sx={{ mt: 2 }}>
          <MIDTable
            rows={data}
            editValues={{ ...editBank, bankName: editBank?.name }}
            bankAccountType={bankAccountType}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ConfirmationStep;
